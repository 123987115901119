import { InfoBuyer } from '../../../components/infoBuyer';

export const TransactionBuyer = () => {
	return (
		<>
			<InfoBuyer
				titleTable="Buyer Transaction History"
				tableTransaction={false}
			/>
		</>
	);
};
