import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { colors } from '../../../styles';
import { clientSideBarMenu } from '../clientMenu/index';
interface IMenuSeller {
	id: number;
	title: string;
	link: string;
}

export const ClientView = () => {
	const numRecuperado = parseInt(localStorage.getItem('menuClick') || '');
	const [selectedItem, setSelectedItem] = useState<number>(numRecuperado);

	const handleItemClick = (idMenu: number) => {
		localStorage.setItem('menuClick', idMenu.toString());
		setSelectedItem(idMenu);
	};
	return (
		<>
			{clientSideBarMenu &&
				clientSideBarMenu.map((value: IMenuSeller) => (
					<ListItemButton
						key={value.id}
						onClick={() => handleItemClick(value.id)}
						sx={{
							textAlign: 'center',
							borderBottom: '1px solid',
							color: colors.primaryLight,
							backgroundColor:
								selectedItem === value.id ? 'rgb(202, 213, 232, .3)' : null,
							transition: 'background-color 0.3s ease-in-out',
							display: 'flex',
							justifyContent: 'center',
							width: '100%',
							a: {
								width: '100%'
							},
							// height: '140px',
							padding: '0',
							'&:hover': {
								backgroundColor: 'rgb(202, 213, 232, .2)'
							}
						}}
					>
						<Link to={value.link} style={{ textDecoration: 'none' }}>
							<Box
								sx={{
									padding: '70px 12px'
								}}
							>
								<ListItemText>
									<Typography
										sx={{
											fontSize: selectedItem === value.id ? '1.5rem' : '1rem',
											transition: 'font-size 0.3s ease-in-out'
										}}
										color="#D4D7DE"
										fontSize="1rem"
										fontWeight="400"
										lineHeight="24px"
									>
										{value.title}
									</Typography>
								</ListItemText>
							</Box>
						</Link>
					</ListItemButton>
				))}
		</>
	);
};
