import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { ApiPromise, WsProvider, } from "@polkadot/api";
import { keyring as uiKeyring } from "@polkadot/ui-keyring";
import { waitReady } from "@polkadot/wasm-crypto";
import dayjs from 'dayjs';
import {sha256} from "js-sha256";

import {
	Box,
	Button,
	Card,
	FormHelperText,
	Grid,
	InputBase,
	InputLabel,
	MenuItem,
	Select,
	styled,
	Typography
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import EnergyImg from '../../assets/imgs/energy.png';
import informationIssueImg from '../../assets/imgs/informationIssue.png';
import Key from '../../assets/imgs/key.png';
import RecIssueImg from '../../assets/imgs/recIsue.png';
import { ConfirmationModal } from '../../components/confirmationModal';
import { CertificatesService } from '../../services/certificates.service';
import { colors } from '../../styles';
import { ICertificateOrg } from '../../types/certificateOrg';
import { SelectAmount } from '../RecIssue/selectAmount';
import { SelectMode } from '../RecIssue/selectMode';
import { MemberService } from '../../services/member.service';
import { AssetService } from '../../services/asset.service';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
type OriginCertOnchainType = Array<[string, number, [number,number], number ]>;

interface ISeller {
	id: string;
	first_name: string;
	last_name: string;
	role: string;
	wallet_address: string;
}

interface IRecTransfer {
	setGoTotransferT: (value: boolean) => void;
}
interface Item {
	origin_id: string;
	origin_hash: string;
	amount: number;
	date: Date;
}

interface SellerData{
	id: string;
	wallet: string;
	name: string
}

interface IOnchainOrigin {
	origin_id: string;
	amount: number;
}

export const RecTransfer = ({ setGoTotransferT }: IRecTransfer) => {
	const memberService = new MemberService();
	const assetService = new AssetService();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [user, setUser] = useState<any>({});
	const [asset, setAsset] = useState<any>({});
	const [selectModeLabel, setSelectModeLabel] = useState<string>('');
	const [selectWallet, setSelectWallet] = useState<string>('');
	const [idSeller, setIdSeller] = useState<string>('');
	const [totalAvaliable, setTotalAvaliable] = useState<number>(0);
	const [validateTotalEnergy, setValidateTotalEnergy] = useState<boolean>(false);
	const [inputAmount, setInputAmount] = useState<string>('');
	const [sellerTransfer, setSellerTransfer] = useState<SellerData[]>([]);
	const [passwordAuthorization, setPasswordAuthorization] =
		useState<string>('');
	const [openAuthorization, setOpenAuthorization] = useState<boolean>(false);
	const [loadingAuthorization, setLoadingAuthorization] =
		useState<boolean>(false);

	const getTransferRow: ICertificateOrg = useSelector(
		(state: any) => state.totalRecAvailable
	);

	const certificatesService = new CertificatesService();
	
	const CopyGetSelectedCertificates = {...getTransferRow};

	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250
			}
		}
	};

	const getUserInfo = async () => {
		const getUser: any = await memberService.getMyInfo();
		if (getUser.status === 200) {
			setUser(getUser.data);
		}
		// if error
	};

	const getAssetInfo = async () => {
		const getAsset: any = await assetService.getAssetById(CopyGetSelectedCertificates.totalRec[0].assetId);
		if (getAsset) {
			setAsset(getAsset);
		}
		// if error
	};



	useEffect(() => {
		getUserInfo();
		getAssetInfo();
	}, []);

	async function matTransferRec(toAccount: string, ocert: [OriginCertOnchainType], operationDate: string, recHash: string) {
		try {
			await waitReady();
			uiKeyring.loadAll({ ss58Format: 42, type: "sr25519" });
		} catch (error) {
			console.log(error)
		} finally {
			const provider = new WsProvider(`${process.env.REACT_APP_PARACHAIN_ADDRESS}`);
			const api = await ApiPromise.create({ provider });
			try {
				const holderPair = uiKeyring.getPair(asset.wallet_address);
				try {
					holderPair.unlock(passwordAuthorization);
				} catch (error) {
					console.log(error)
					enqueueSnackbar('The password of your wallet is invalid, try again', {
						variant: 'error'
					});
					return;
				}
				const genesisHash = api.genesisHash;
				const runtimeVersion = api.runtimeVersion;
				const nonce = await api.rpc.system.accountNextIndex(holderPair.address);
				const extrinsic = api.tx.recs.transferRec(toAccount, `${process.env.REACT_APP_MULTILEDGERS_PARACHAIN_ID}`, `${process.env.REACT_APP_CCEE_PARACHAIN_ID}`, ocert, operationDate, recHash).sign(holderPair, { genesisHash, blockHash: genesisHash, nonce, runtimeVersion });
				await api.disconnect();
				return extrinsic
				//
			} catch (error) {
				console.log(error)
				enqueueSnackbar('Wallet not found, please consider recovering it on the My Profile page. A new tab will be created in 5 seconds.', {
					variant: 'error'
				});
				const timeout = setTimeout(()=>{
					window.open(`${process.env.REACT_APP_CLIENT_URL}/profile`, "_blank", 'noopener,noreferrer'	);
				},5000)
				clearTimeout(timeout);
				return;
			}
		}
	}
	useEffect(() => {
		const getAllSeller = async () => {
			const respGetAllSeller = await certificatesService.getAllSeller();
			const { data } = respGetAllSeller;

			if (respGetAllSeller.status === 200) {
				const sellers = data.filter(
					(element: ISeller) => element.role === 'seller'
				);

				const nameSeller: SellerData[] = sellers.map((item: ISeller) => ({
					id: item.id,
					wallet: item.wallet_address,
					name: `${item.first_name} ${item.last_name}`
				}));
				setSellerTransfer(nameSeller);
			}
		};
		getAllSeller();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (CopyGetSelectedCertificates.totalRec.length > 0) {
			const amountTotal = CopyGetSelectedCertificates.totalRec.reduce((acumulador: any, objeto: any) => {
				return acumulador + Number(objeto.amountRec);
			}, 0);
			setTotalAvaliable(amountTotal);
		}
	}, [CopyGetSelectedCertificates]);

	const resetStates = () => {
		setSelectModeLabel('');
		setInputAmount('0');
		setTotalAvaliable(0);
	};

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;

		const newValue = value.replace(/[.,]/g, '');

		if (Number(newValue) > totalAvaliable || Number(newValue) <= 0) {
			setValidateTotalEnergy(true);
		} else {
			setValidateTotalEnergy(false);
		}
		setInputAmount(newValue);

	};

	const handleCloseAuthoraztion = (value: boolean) => {
		setOpenAuthorization(value);
	};

	const onSubmit = () => {
		if (!asset.wallet_address){
			enqueueSnackbar("You haven't registered your asset wallet yet.", {
				variant: 'error'
			});
			return; 
		}
		handleCloseAuthoraztion(true);
	};

	const getIdSeller = (seller: any) => {
		// const [id, name] = seller;
		setIdSeller(seller);
		setSelectModeLabel(seller);
		const sellers = sellerTransfer.find(
			(element: any) => element.id === seller
		);
		if(sellers){
			setSelectWallet(sellers.wallet)
		}
		
	};

	function calculeQuotient(amount: number, divisor: number) {
		const averageAmount = Math.floor(amount / divisor);
		const remainder = amount % divisor;
		return { averageAmount, remainder };
	}

	function calculateList(list: Item[], final_amount: number): Item[] {
		const listLength = list.length;
		const { averageAmount, remainder } = calculeQuotient(
			final_amount,
			listLength
		);
		const orderedList = list.sort(
			(a, b) => a.date.getTime() - b.date.getTime()
		);
		const certValues = new Array<number>(orderedList.length);
		const remainerValues = new Array<number>(orderedList.length).fill(0);
		remainerValues[0] = remainder;
		orderedList.map((item, index) => {
			certValues[index] = item.amount;
			remainerValues[index] =
				item.amount - averageAmount - remainerValues[index];
		});
		while (remainerValues.some(v => v < 0)) {
			for (let i = 0; i < remainerValues.length; i++) {
				if (remainerValues[i] < 0) {
					for (let j = 0; j < remainerValues.length; j++) {
						if (remainerValues[j] > 0) {
							const amountntnt = remainerValues[j] + remainerValues[i];
							if (amountntnt <= 0) {
								remainerValues[j] = 0;
								remainerValues[i] = amountntnt;
							} else {
								remainerValues[i] = 0;
								remainerValues[j] = amountntnt;
								break;
							}
						}
					}
				}
			}
		}
		const finalll = certValues.map((item, index) => {
			return item - remainerValues[index];
		});
		const newList = orderedList.map((item, index) => {
			return { ...item, amount: finalll[index] };
		});
		return newList;
	}

	const onSubmitAuthorization = async () => {
		if (!CopyGetSelectedCertificates.totalRec) return;
		const output: Item[] = CopyGetSelectedCertificates.totalRec.map((item: any) => ({
			origin_id: item.id,
			origin_hash: item.recHash,
			amount: Number(item.amountRec),
			date: item.startDate
		}));

		const originCertificate = calculateList(output, Number(inputAmount));

		const orderedList = CopyGetSelectedCertificates.totalRec.sort(
			(a: any, b: any) => a.startDate.getTime() - b.startDate.getTime()
		);

		const [paramsCert] = orderedList;
		const newStartDate = paramsCert.startDate;
		const newEndDate = orderedList[orderedList.length - 1].endDate;
		setLoadingAuthorization(true);

		//ONCHAIN
		const dateFormatted = dayjs().format('YYYY-MM-DD');
		const onchainOrigin: OriginCertOnchainType = originCertificate.map(item => {
			return [item.origin_hash, item.amount,[0,0],0];
		});
		const RecCertId = '0x' + sha256.update(window.crypto.randomUUID()).hex();
		const signedTransaction = await matTransferRec(selectWallet, [onchainOrigin], dateFormatted, RecCertId)

		if (!signedTransaction){
			enqueueSnackbar("Wallet Signature", {
				variant: 'error'
			});
			setLoadingAuthorization(false);
			return;
		}

		const notify = enqueueSnackbar('Processing on the blockchain.', {
			variant: 'info',
			persist: true
		});
		
		// setLoadingAuthorization(false);
		handleCloseAuthoraztion(false);

		const respCertificate = await certificatesService.createTransferRec({
			enterprise_id: paramsCert.enterpriseId,
			asset_id: paramsCert.assetId,
			rec_id: paramsCert.recId,
			start_date: newStartDate,
			end_date: newEndDate,
			operation_type: 'transfer',
			origins: originCertificate,
			from: paramsCert.to,
			rec_hash: RecCertId,
			to: idSeller,
			holder_wallet: selectWallet,
			extrinsic: signedTransaction
		});

		if ([400, 401, 404, 500].includes(respCertificate.status)) {
			enqueueSnackbar(respCertificate.statusText, {
				variant: 'error'
			});
			closeSnackbar(notify)
			setLoadingAuthorization(false);
			handleCloseAuthoraztion(false);
		}
		if (respCertificate.status === 200) {
			resetStates();
			closeSnackbar(notify)
			enqueueSnackbar('Certificate sent successfully', {
				variant: 'success'
			});
			setLoadingAuthorization(false);
			handleCloseAuthoraztion(false);
			setTimeout(() => {
				location.reload()
			}, 2000);
		}
	};

	const BootstrapInput = styled(InputBase)(() => ({
		'& .MuiInputBase-input': {
			display: 'flex',
			alignItems: 'center',
			padding: '14px 25px',
			borderRadius: '9px',
			// backgroundColor: 'transparent',
			border: '1px solid #000',
			'& .MuiSvgIcon-root': {
				marginTop: '100px',
				color: '#fff'
			},
			'&:focus': {
				borderRadius: '10px'
			},
			':after': {
				borderBottom: 'none',
				borderRight: '1px solid #000',
				marginRight: '50px'
			}
		}
	}));

	return (
		<>
			<Box position="absolute" bottom="0" width="35%" left="152px">
				<img src={RecIssueImg} alt="backgroundimage"></img>
			</Box>
			<Grid container md={12} marginBottom="54px">
				<Grid item md={12} display="flex">
					<Button
						variant="outlined"
						onClick={() => setGoTotransferT(false)}
						sx={{
							width: '107px',
							height: '40px',
							borderRadius: '25px',
							border: '1px solid #000',
							padding: '8px 24px',
							color: '#000',
							textTransform: 'initial',
							fontSize: '1.25rem',
							'&:hover': {
								border: '1px solid #000'
							}
						}}
					>
						<Typography fontSize="1.25rem" lineHeight="32px">
							Back
						</Typography>
					</Button>
					<Typography
						fontWeight="700"
						fontSize="1.75rem"
						lineHeight="40px"
						color={colors.primary}
						marginBottom="9px"
						marginLeft="96px"
					>
						REC TRANSFER
					</Typography>
				</Grid>
			</Grid>

			<Grid container md={12} gap={5}>
				<Grid item md={4}>
					<Card sx={{ marginLeft: '25px' }}>
						<Box display="flex" alignItems="center">
							<SelectMode />
							<Typography sx={{ marginLeft: '21px', fontSize: '1.25rem' }}>
								SELECT HOLDER:
							</Typography>
						</Box>

						<Box marginTop="65px" marginBottom="75px" marginLeft="34px">
							<InputLabel sx={{ marginBottom: '8px' }}>
								SELECT A SELLER
							</InputLabel>
							<Select
								sx={{ maxWidth: '352px' }}
								fullWidth
								placeholder="select..."
								input={<BootstrapInput />}
								labelId="demo-simple-select-label"
								id="country"
								variant="standard"
								value={idSeller}
								MenuProps={MenuProps}
								onChange={e => getIdSeller(e.target.value)}
							>
								{sellerTransfer ?
									sellerTransfer.map(({ name, index, ...rest }: any) => (
										<MenuItem key={index} value={rest.id}>
											{name}
										</MenuItem>
									))
									: 
									<MenuItem value="">
										<em>select...</em>
									</MenuItem>
								}
							</Select>
						</Box>
					</Card>
				</Grid>
				<Grid item md={4}>
					<Card sx={{ height: '600px', position: 'relative' }}>
						<Box display="flex" alignItems="center">
							<SelectAmount step={2} />
							<Typography sx={{ marginLeft: '21px', fontSize: '1.25rem' }}>
								SELECT AMOUNT:
							</Typography>
						</Box>
						{selectModeLabel !== '' && (
							<Card sx={{ marginTop: '57px' }}>
								<Box marginLeft="100px">
									<Typography fontSize="1.25rem">
										TOTAL AVAILABLE REC’S
									</Typography>
								</Box>
								<Box
									display="flex"
									alignItems="center"
									marginLeft="29px"
									marginBottom="48px"
								>
									<img src={EnergyImg} alt="eletric"></img>
									<Typography
										sx={{
											marginLeft: '23px',
											fontSize: '2.25rem',
											fontWeight: '700',
											color: '#00CA95'
										}}
									>
										{Number(totalAvaliable).toLocaleString('en-US', {
											minimumFractionDigits: 0
										})}
									</Typography>
								</Box>

								<Box marginLeft="40px">
									<InputLabel
										error={false}
										shrink
										htmlFor="job_title"
										sx={{
											color: colors.neutralDark,
											fontSize: '25px',
											lineHeight: '32px'
										}}
									>
										Type in amount of REC’s to transfer
									</InputLabel>
									<NumericFormat
										value={Number(inputAmount)}
										allowLeadingZeros
										decimalSeparator="."
										thousandSeparator=","
										placeholder="0"
										style={{
											width: '298px',
											outline: validateTotalEnergy ? 'blue' : '#F32053',
											padding: '16px 14px',
											border: `1px solid ${
												validateTotalEnergy ? '#F32053' : '#3A4049'
											}`,
											boxShadow: '0px 2px 8px rgb(0 0 0 / 15%)',
											borderRadius: '9px',
											marginTop: '8px',
											fontSize: '30px',
											color: validateTotalEnergy
												? '#F32053'
												: colors.neutralDark
										}}
										onChange={handleInputChange}
									/>
									{validateTotalEnergy && (
										<FormHelperText
											error
											sx={{
												fontWeight: '700',
												fontSize: '14px',
												lineHeight: '32px',
												fontFamily: 'sen',
												'&.Mui-error': {
													color: '#F32053'
												}
											}}
										>
											UNVAILABLE AMOUNT
										</FormHelperText>
									)}
								</Box>

								<Box
									marginLeft="29px"
									sx={{ position: 'absolute', bottom: '0px' }}
								>
									<Box display="flex" alignItems="center">
										<Box marginTop="5px">
											<img src={informationIssueImg}></img>
										</Box>
										<Typography
											sx={{
												color: colors.supportDark,
												fontSize: '20px',
												marginLeft: '17px'
											}}
										>
											Important:
										</Typography>
									</Box>
									<Box marginTop="16px">
										<Typography
											color={colors.neutralDark}
											fontSize="20px"
											lineHeight="32px"
										>
											In case of multiple certificates, <br /> partial amounts
											reduced by <br /> average
										</Typography>
									</Box>
								</Box>
							</Card>
						)}
					</Card>
				</Grid>
				<Grid item md={3}>
					<Card>
						<Box display="flex" alignItems="center">
							<SelectAmount step={3} />
							<Typography sx={{ marginLeft: '21px', fontSize: '1.25rem' }}>
								CONFIRMATION:
							</Typography>
						</Box>
						{selectModeLabel !== '' && (
							<Card sx={{ marginTop: '57px' }}>
								<Box marginLeft="50px">
									<Typography
										color={colors.neutralDark}
										fontSize="20px"
										lineHeight="32px"
									>
										Please confirm your transfer by <br /> clicking button below
									</Typography>
								</Box>

								<LoadingButton
									disableElevation
									disabled={validateTotalEnergy}
									loading={false}
									onClick={onSubmit}
									sx={{
										marginLeft: '50px',
										marginTop: '65px',
										width: '242px',
										borderRadius: '25px',
										padding: '8px 32px',
										display: 'flex',
										alignItems: 'flex-end',
										backgroundColor:
											!validateTotalEnergy && inputAmount
												? colors.primary
												: colors.primaryLight,
										'&:hover': {
											backgroundColor:
												!validateTotalEnergy && inputAmount
													? colors.primary
													: colors.primaryLight
										}
									}}
								>
									<Box paddingRight="19px">
										<img src={Key} alt="key"></img>
									</Box>
									<Typography
										color="#fff"
										fontSize="24px"
										fontWeight="700"
										lineHeight="32px"
									>
										TRANSFER
									</Typography>
								</LoadingButton>
							</Card>
						)}
					</Card>
				</Grid>
				{openAuthorization &&
					<ConfirmationModal
						open={openAuthorization}
						title="Transfer of RECs"
						subTitle="This action will prompt you to authorize the transfer of RECs."
						walletName={asset.wallet_name}
						walletAddress={asset.wallet_address}
						handleCloseAuthorization={() => handleCloseAuthoraztion(false)}
						onSubmitAuthorization={onSubmitAuthorization}
						setPasswordAuthorization={setPasswordAuthorization}
						loadingAuthorization={loadingAuthorization}
						confirm="Sign"
						cancel="Cancel"
					/>
				}
			</Grid>
		</>
	);
};
