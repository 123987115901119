import axios from 'axios';

import { Api } from './api/axios-config';
import { GenericExtrinsic } from '@polkadot/types';
import { AnyTuple } from '@polkadot/types-codec/types';
export type IMemberEnterprise = {
	enterprise_id: string;
	name: string;
	country: string;
	state: string;
	city: string;
	address: string;
	number: string;
	complement: string;
	cnpj: string;
	zipcode: string;
	certified_by: string;
	extrinsic: GenericExtrinsic<AnyTuple>,
};

export type ITotalMWh = {
	totalMWh: number;
};

export class EnterpriseService {
	async updateEnterprise(memberEnterprise: IMemberEnterprise) {
		try {
			const respAddMember = await Api.put(`/enterprises`, memberEnterprise);
			return respAddMember;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async updateEnterpriseTotalMWh(memberEnterprise: ITotalMWh) {
		try {
			const respAddMember = await Api.patch(`/enterprises/totalMWh`, memberEnterprise);
			return respAddMember;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async listEnterprise() {
		try {
			const respListEnt = await Api.get(`/enterprises/`);
			return respListEnt;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async getEnterpriseLogo() {
		try {
			const respListEnt = await Api.get(`/enterprises/logo`);
			return respListEnt;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async addLogo(logo: any) {
		try {
			const respAddLogo = await Api.patch('/enterprises/logo', logo);
			return respAddLogo;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async removeMemberEnt(enterprise_id: string, member_id: string) {
		try {
			const respDelete = await Api.delete(
				`/enterprises/members/${enterprise_id}/${member_id}`
			);
			return respDelete;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async getCountries() {
		return axios.get(`https://countriesnow.space/api/v0.1/countries`);
	}

	async getStates(country: string) {
		return axios.post(`https://countriesnow.space/api/v0.1/countries/states`, {
			country
		});
	}

	async getCities(country: string, state: string) {
		return axios.post(
			`https://countriesnow.space/api/v0.1/countries/state/cities`,
			{
				country,
				state
			}
		);
	}

	async getZipCode(enterpriseZipCode: number) {
		try {
			const resZipCode = await axios.get(
				`https://viacep.com.br/ws/${enterpriseZipCode}/json/`
			);
			return resZipCode;
		} catch (error) {
			console.log(error);
		}
	}
}
