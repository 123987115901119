// Core
import React, { FC } from 'react';

import { Box, BoxProps } from '@mui/material';

export const BoxInfo: FC<BoxProps> = ({ children, ...props }) => {
	return (
		<Box {...props} sx={{ display: 'flex ', gap: '20px' }}>
			{children}
		</Box>
	);
};
