import {
	Box,
	Grid,
	InputAdornment,
	TextField,
	Typography
} from '@mui/material';

import { useEffect, useState } from 'react';
import { ButtonAdd } from '../../../components/buttonAdd';
import { BuyersRegDate } from '../../../components/buyersRegDate';
import { colors } from '../../../styles';
import { FormBuyer } from './formBuyer';
import { ListBuyers } from './listBuyers';
import { RegistredBuyers } from './registredBuyers';

import Search from '../../../assets/imgs/search.svg';

interface IDAddBuyer {
	idBuyer: string;
}

export const AddBuyer = ({ idBuyer }: IDAddBuyer) => {
	const [showFormBuyer, setShowFormBuyer] = useState(false);
	const [formsuccess, setFormsuccess] = useState<boolean>(false);
	const [searchInput, setSearchInput] = useState<string>('');

	const addBuyerForm = (value: boolean) => {
		setShowFormBuyer(value);
	};

	useEffect(() => {
		if (formsuccess) {
			addBuyerForm(false);
		}
	}, [formsuccess]);

	useEffect(() => {
		if (idBuyer) {
			setShowFormBuyer(true);
		}
	}, [idBuyer]);

	return (
		<>
			<Grid
				container
				md={12}
				gap={5}
				flexWrap="nowrap"
				justifyContent="space-between"
			>
				<Grid item md={6} marginLeft={showFormBuyer ? '95px' : '95px'}>
					{!showFormBuyer ? (
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Box marginRight="37px" sx={{ cursor: 'pointer' }}>
								<Typography
									fontSize="1.75rem"
									color={colors.primary}
									fontWeight="700"
									lineHeight="32px"
								>
									Add Buyer
								</Typography>
							</Box>
							<Box>
								<ButtonAdd onClick={() => addBuyerForm(true)} />
							</Box>
						</Box>
					) : (
						<FormBuyer setShowFormBuyer={setShowFormBuyer} setFormsuccess={setFormsuccess} />
					)}
				</Grid>

				{/* <Grid item md={5}>
						<BuyersRegDate marginTop="-20px" marginRight="63px">
							BUYERS
						</BuyersRegDate>
					</Grid> */}
				<Grid item md={3} marginRight="20px" marginTop="-90px">
					<RegistredBuyers />
					<div
						style={{
							borderBottom: '1px solid',
							width: '100%',
							marginTop: '4px',
							color: colors.neutralDark,
							marginBottom: '20px'
						}}
					></div>
					<TextField
						fullWidth
						variant="filled"
						placeholder="search"
						onChange={(e: any) => setSearchInput(e.target.value)}
						sx={{
							m: 1,
							margin: '0',
							maxWidth: '430px',
							'& .MuiFilledInput-root': {
								backgroundColor: '#F7F7F7',
								borderRadius: '16px',
								paddingBottom: '15px',
								paddingLeft: '10px',
								marginBottom: '21px'
							}
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment
									position="end"
									sx={{ paddingTop: '10px', paddingRight: '10px' }}
								>
									<img src={Search}></img>
								</InputAdornment>
							),
							disableUnderline: true
						}}
					/>
					<ListBuyers searchInput={searchInput} />
				</Grid>
			</Grid>
		</>
	);
};
