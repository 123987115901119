const INITIAL_STATE = false;

interface IPayload {
	status: boolean;
}

interface IAction {
	type: string;
	payload: IPayload;
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function (state = INITIAL_STATE, action: IAction) {
	switch (action.type) {
		case 'WALLET_RECOVERY_ASSET':
			return action.payload.status;
		default:
			return state;
	}
}
