import {
	Divider,
	Grid,
	InputAdornment,
	TextField,
	Typography
} from '@mui/material';
import { colors } from '../../../../styles';

export const RegistredBuyers = () => {
	return (
		<>
			<Grid container md={12}>
				<Grid item>
					<Typography
						fontSize="24px"
						fontWeight="700"
						color={colors.neutralDark}
					>
						REGISTERED BUYERS
					</Typography>
				</Grid>
			</Grid>
		</>
	);
};
