import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useSnackbar } from 'notistack';
import { parseISO, format } from 'date-fns';

import { Grid, Typography, Card, Box } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { CardHeader } from '../../components/cardHeader';
import { CertificatesService } from '../../services/certificates.service';
import { ProfileService } from '../../services/profile.service';
import { ProfileUsers } from '../../profile-users';

import { colors } from '../../styles';
import { ICertificateOrg, IOrigins } from '../../types/certificateOrg';
import { ICertificate } from '../../types/certificateTranfer';
import { HeadCell } from '../../types/headCell';
import { Table } from './table';
import { useNavigate } from 'react-router-dom';

interface ICertificateHome {
	setGoTotransferT: any;
	buttonRetired?: boolean;
}

export const CertificateHome = ({
	setGoTotransferT,
	buttonRetired = true
}: ICertificateHome) => {
	const profileService = new ProfileService();
	const certificatesService = new CertificatesService();
	const { enqueueSnackbar } = useSnackbar();
	const history = useNavigate();

	// const [goTotransfer, setGoTotransfer] = useState<boolean>(false);
	const [userProfile, setUserProfile] = useState<string>('');
	
	const [stateObject, setStateObject] = useState<boolean>(false);
	const [selectedPage, setSelectedPage] = useState<number>(1);
	const [selectedSize, setSelectedSize] = useState<number>(5);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [validateStatusRec, setValidateStatusRec] = useState<boolean>(false);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const [gotOrgCertificate, setGotOrgCertificate] = useState<ICertificateOrg[] | null>();
	
	const getSelectedCertificates: any = useSelector(
	(state: any) => state.totalRecAvailable
	);

	const CopyGetSelectedCertificates = {...getSelectedCertificates};
	const [quantityRowSelected, setQuantityRowSelected] = useState<number>(0);
	const [totalAmountRec, setTotalAmountRec] = useState<number>(0);

	useEffect(() => {
		const profileUser = async () => {
			const myProfile: any = await profileService.getUserProfile()
			if(myProfile.status == 200 && myProfile.data.role){
				setUserProfile(myProfile.data.role);
			}else{
				history('/signin')
			}
		};
		profileUser();
	}, []);

	useEffect(()=>{
		if(getSelectedCertificates.totalRec){
			setQuantityRowSelected(getSelectedCertificates.totalRec.length)
			setTotalAmountRec(
				CopyGetSelectedCertificates.totalRec.reduce(
				   (acumulador: any, objeto: any) => {
					   return acumulador + Number(objeto.amountRec);
				   },
				   0
			   ) 
		   )
		}
	},[getSelectedCertificates])
	
	useEffect(() => {
		const getAllCertificates = async () => {
			let respGetAllCert = [];
			if(!userProfile) {
				setIsLoaded(true);
				setGotOrgCertificate([])
			}
			if (userProfile == ProfileUsers.SELLER) {
				respGetAllCert = await certificatesService.getMyCertificates(
					selectedPage,
					selectedSize
				);
			} else if (userProfile == ProfileUsers.OWNER) {
				respGetAllCert = await certificatesService.getAllAssetsCertificates(
					selectedPage,
					selectedSize
				);
			}
			else{
				setGotOrgCertificate([])
			}
			setIsLoaded(true);
			if (respGetAllCert.status === 200) {
				setTotalPages(respGetAllCert.data[1]);
				const respAllCertFormated: ICertificateOrg[] =
					respGetAllCert.data[0].map((item: any) => ({
						id: item.id,
						beneficiaryId: item.to,
						createdAt: item.created_at,
						isCheckbox: true,
						statusCheck: false,
						enterpriseId: item.enterprise_id,
						assetId: item.asset_id,
						assetSourceType: item.asset_source,
						recId: item.rec_id,
						assetName: item.asset_name,
						date: format(new Date(item.created_at), 'yyyy-MM-dd HH:mm'),
						holder: item.provider_name,
						beneficiary: item.holder_name,
						beneficiaryWallet: item.holder_wallet,
						from: item.from,
						to: item.to,
						status: item.rec_status,
						opType: item.operation_type,
						orgName: item.enterprise_name,
						powerUnit: item.asset_name,
						assetCountry: item.asset_country,
						assetState: item.asset_state,
						assetCity: item.asset_city,
						co2PerMWh: item.asset_co2_per_mwh,
						startOfOperation: item.asset_start_of_operation,
						certificate_id_range: item.certificate_id_range,
						period: format(new Date(item.start_date), 'yyyy-MMM'),
						startDate: new Date(item.start_date),
						endDate: new Date(item.end_date),
						assetUnitID: item.asset_unit_id,
						amountRec: Number(item.amount),
						recLabel: item.rec_label,
						certHash: item.cert_hash,
						recHash: item.rec_hash,
						blockHash: item.block_hash,
						amount: Number(item.amount),
						originCertificates: item.origin_certificates
					}));
				setGotOrgCertificate(respAllCertFormated);
			}
		};
		getAllCertificates();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedPage, userProfile]);

	const validateRetiredRec = (
		arr: ICertificateOrg[]
	): {
		validateCertificate: boolean;
		errors: string[];
	} => {
		const errors = [];
		const checkHolder = !arr.every(
			element => element.beneficiary === arr[0].beneficiary
		);
		const checkRec = !arr.every(element => element.recId === arr[0].recId);
		const checkSource = !arr.every(
			element => element.assetSourceType === arr[0].assetSourceType
		);
		const checkRecAmount = !arr.every(element => element.amountRec > 0);
		const checkPeriod = !arr.every(
			element => element.period.split('/')[1] === arr[0].period.split('/')[1]
		);

		if (checkHolder) errors.push('Holder');
		if (checkRec) errors.push('REC');
		if (checkSource) errors.push('Source');
		if (checkPeriod) errors.push('Period');
		if (checkRecAmount) errors.push('Amount');
		return {
			validateCertificate:
				checkHolder ||
				checkRec ||
				// checkSource ||
				checkPeriod ||
				checkRecAmount,
			errors
		};
	};

	useEffect(() => {
		if (stateObject && CopyGetSelectedCertificates) {
			const { validateCertificate, errors } = validateRetiredRec(
				CopyGetSelectedCertificates.totalRec
			);
			setValidateStatusRec(validateCertificate);
			setStateObject(false);
			if (validateCertificate) {
				enqueueSnackbar(
					`You can not combine these certificates,
					this fields are differents: ${errors.join(', ')}`,
					{
						variant: 'error',
						autoHideDuration: 5000
					}
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stateObject]);
	const headCells: HeadCell[] = [
		{
			id: 'checkbox',
			numeric: true,
			disablePadding: false,
			label: ''
		},
		{
			id: 'date',
			numeric: true,
			disablePadding: false,
			label: 'Date UTC-3'
		},
		{
			id: 'holder',
			numeric: true,
			disablePadding: false,
			label: 'Provider'
		},
		{
			id: 'beneficiary',
			numeric: true,
			disablePadding: false,
			label: 'Holder'
		},
		{
			id: 'status',
			numeric: true,
			disablePadding: false,
			label: 'Status'
		},
		{
			id: 'assetSourceType',
			numeric: true,
			disablePadding: false,
			label: 'Source Type'
		},
		{
			id: 'orgName',
			numeric: true,
			disablePadding: false,
			label: 'Org Name'
		},
		{
			id: 'recLabel',
			numeric: true,
			disablePadding: false,
			label: 'REC Label'
		},
		{
			id: 'assetName',
			numeric: true,
			disablePadding: false,
			label: 'Power Unit'
		},
		{
			id: 'assetState',
			numeric: true,
			disablePadding: false,
			label: 'Location'
		},
		{
			id: 'period',
			numeric: true,
			disablePadding: false,
			label: 'Period'
		},
		{
			id: 'assetUnitID',
			numeric: true,
			disablePadding: false,
			label: 'Unit ID'
		},
		{
			id: 'amountRec',
			numeric: true,
			disablePadding: false,
			label: 'Amount REC'
		},
		{
			id: 'report',
			numeric: true,
			disablePadding: false,
			label: 'Report'
		}
	];

	const onSubmitTransfer = () => {
		setGoTotransferT(true);
		// dispatchEvent();
		// setGoTotransfer(true);
	};

	useEffect(() => {
		if (stateObject) {
			const amountTotal = CopyGetSelectedCertificates.totalRec.reduce(
				(acumulador: any, objeto: any) => {
					return acumulador + Number(objeto.amountRec);
				},
				0
			);
			setTotalAmountRec(amountTotal);
			setStateObject(false);
		}
	}, [CopyGetSelectedCertificates, getSelectedCertificates, stateObject]);

	return (
		<>
			<Grid container md={12} marginBottom="65px" display="flex">
				<Grid item md={7}>
					<Grid item md={12} marginBottom="37px">
						<Typography
							fontWeight="700"
							fontSize="1.75rem"
							lineHeight="40px"
							color={colors.primary}
							marginBottom="9px"
						>
							CERTIFICATES
						</Typography>
					</Grid>
					<Grid item md={12}>
						<LoadingButton
							disableElevation
							onClick={onSubmitTransfer}
							loading={false}
							variant="contained"
							disabled={quantityRowSelected < 1 || validateStatusRec}
							// disabled={validateStatusRec}
							sx={{
								height: '40px',
								width: '202px',
								backgroundColor:
									quantityRowSelected < 1
										? colors.primaryLight
										: colors.primary,
								borderRadius: '25px',
								'&.MuiButton-root.Mui-disabled': {
									backgroundColor: colors.primaryLight
								},
								padding: '4px 34px',
								marginRight: '48px',
								boxShadow: 'none',
								'&:hover': {
									backgroundColor:
										quantityRowSelected < 1
											? colors.primaryLight
											: colors.primary
								}
							}}
						>
							<Typography
								fontWeight="400"
								fontSize="20px"
								textTransform="initial"
								color="#fff"
							>
								Transfer Rec
							</Typography>
						</LoadingButton>
					</Grid>
				</Grid>
				<Grid item md={5} display="flex">
					<CardHeader
						totalAmountRec={totalAmountRec}
						title="Total REC's"
						subTitle="REC"
					/>
					<CardHeader
						totalAmountRec={quantityRowSelected}
						title="Total Certificates"
						subTitle="Certs"
					/>
				</Grid>
			</Grid>

			<Grid container md={12}>
				<Grid item sx={{ width: '99%', overflowX: 'auto' }}>
					<Table
						title=""
						titles={headCells}
						transactions={gotOrgCertificate ? gotOrgCertificate : null}
						isLoaded={isLoaded}
						setStateObject={setStateObject}
						setQuantityRowSelected={setQuantityRowSelected}
						setSelectedPage={setSelectedPage}
						setSelectedSize={setSelectedSize}
						totalPages={totalPages}
						getSelectedCertificates={CopyGetSelectedCertificates}
						sx={{
							border: `1px solid ${colors.primaryLight}`,
							borderRadius: '16px',
							overflow: 'hidden'
						}}
					/>
				</Grid>
			</Grid>
		</>
	);
};
