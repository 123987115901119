import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import { Box, Button, Card, Typography } from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import { keyring } from "@polkadot/ui-keyring";
import { waitReady } from "@polkadot/wasm-crypto";
import { mnemonicValidate } from "@polkadot/util-crypto";

import Wallet from '../../../assets/imgs/walletBackground.png';
import { ProfileService } from '../../../services/profile.service';

export type TMnemonicSelected = {
	words: Array<object>;
	nameWallet: string;
	walletAddress: string;
	passwordInput: string;
	confirmPasswordInput: string;
	setValidatePassword?: any;
	recovery?: boolean;
};

export const MnemonicSelected = ({
	words,
	walletAddress,
	nameWallet,
	passwordInput,
	confirmPasswordInput,
	recovery,
	setValidatePassword
}: TMnemonicSelected) => {
	const { enqueueSnackbar } = useSnackbar();
	const profileService = new ProfileService();
	const [loading, setLoading] = useState<boolean>(false);
	const [openAuthorization, setOpenAuthorization] = useState<boolean>(false);
	const [loadingAuthorization, setLoadingAuthorization] =
		useState<boolean>(false);
	const history = useNavigate();

	const handleCloseAuthoraztion = (value: boolean) => {
		setOpenAuthorization(value);
	};

	const onSubmit = () => {
		// handleCloseAuthoraztion(true);
		const createWallet = async () => {
			try {
				await waitReady();
				keyring.loadAll({ ss58Format: 42, type: "sr25519" });
			} catch (error) {
				console.log(error)
			}
			finally {
				const isValidMnemonic = mnemonicValidate(words.join(' '));
				if(!isValidMnemonic){
					enqueueSnackbar('Mnemonic is not valid.', {
						variant: 'info'
					});	
					return
				}
				const {pair} = keyring.addUri(words.join(' '), passwordInput, { name: nameWallet });
				onSubmitAuthorization(pair.address);
			}
		}
		createWallet();
	};
	const onSubmitAuthorization = async (walletAddr: string) => {
		if (!nameWallet) return;

		if (passwordInput !== confirmPasswordInput) {
			setValidatePassword(true);
			return;
		}
		setLoading(true);
		setLoadingAuthorization(true);
		const respCreateWallet = await profileService.createWallet(nameWallet, walletAddr);
		setValidatePassword(false);
		if (respCreateWallet.status === 200) {
			setLoading(false);
			setLoadingAuthorization(false);
			history('/profile');
			enqueueSnackbar('Wallet has been created successfully', {
				variant: 'success'
			});
		}
		if ([400, 404, 303, 500].includes(respCreateWallet.status)) {
			setLoading(false);
			setLoadingAuthorization(false);
			enqueueSnackbar(respCreateWallet.statusText, {
				variant: 'error'
			});
		}
		handleCloseAuthoraztion(false);
	};

	return (
		<>
			<Card
				sx={{
					backgroundColor: 'transparent'
					// opacity: '1'
				}}
			>
				<Box display="flex" alignItems="center" marginBottom="37px">
					<Box marginRight="23px">
						<img src={Wallet} alt="wallet" />
					</Box>
					<Box>
						<Typography
							marginBottom="12px"
							fontWeight={700}
							fontSize={'22px'}
							color={'#CAD5E8'}
						>
							{nameWallet}
						</Typography>
						<Typography fontSize="1.25rem" color="#CAD5E8" lineHeight="32px">
							{walletAddress}
						</Typography>
					</Box>
				</Box>

				<Box>
					<Box
						sx={{
							display: 'flex',
							overflow: 'hidden',
							flexWrap: 'wrap',
							alignItems: `${!words.length ? 'center' : 'flex-start'}`,
							justifyContent: `${!words.length ? 'center' : 'auto'}`,
							padding: '28px 50px 22px 50px',
							border: '1px solid #CAD5E8',
							borderRadius: '16px',
							width: '613px',
							height: '254px'
						}}
					>
						{!words.length && (
							<Box display="flex">
								<Typography fontSize="36px" fontWeight="700" color="#a5aab1">
									Mnemonic
								</Typography>
							</Box>
						)}
						{words.map((value: any) => (
							<Typography
								fontSize="1.75rem"
								paddingRight="32px"
								height="0px"
								fontWeight="700"
								lineHeight="40px"
								color="#CAD5E8"
							>
								{value}
							</Typography>
						))}
					</Box>
				</Box>
				<Box
					display="flex"
					justifyContent="flex-end"
					marginRight="60px"
					marginTop="96px"
				>
					<LoadingButton
						variant="contained"
						onClick={onSubmit}
						loading={loading}
						disabled={!passwordInput || !confirmPasswordInput || !nameWallet}
						sx={{
							minWidth: '273px',
							padding: '8px 32px',
							borderRadius: '25px',
							boxShadow: 'none',
							background: `${nameWallet}` ? '#00CA95' : '#CAD5E8',
							'&:hover': {
								backgroundColor: `${nameWallet}` ? '#00CA95' : '#CAD5E8'
							}
						}}
					>
						<Typography
							lineHeight="32px"
							fontSize="1.5rem"
							fontWeight="700"
							color="#fff"
							sx={{
								textTransform: 'initial'
							}}
						>
							Recover wallet
						</Typography>
					</LoadingButton>
				</Box>
			</Card>
		</>
	);
};
