import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList } from 'recharts';


export const VerticalBarChart = (data: any ) => {
  const colors = ['#777', '#a4981E', '#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink'];
  const [verticalBarData, setVerticalBarData] = useState<{beneficiary: string, total_recs: number}[]>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [chartWidth, setChartWidth] = useState(850);
  const [chartHeight, setChartHeight] = useState(430);

  useEffect(() => {
    function handleResize() {
      setChartWidth(window.innerWidth * 0.3);
      setChartHeight(window.innerHeight * 0.5);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(()=>{
        if (data.data.length > 0){
          setVerticalBarData(data.data)
      }else{
          setVerticalBarData(undefined)
      }
    },[data])

    const renderCustomizedLabel2 = (props: any) => {
      const { x, y, width, height, value, fill } = props;
      const radius = 30;
      if(width > 100){
        return (
          <g>
            <text x={x + width / 2} y={y + height / 2} fill="#fff" textAnchor="middle" fontSize={20} fontWeight={700} dominantBaseline="middle">
              {value}
            </text>
          </g>
        )
      }else{
        return (
          <g>
            <text x={x + width + 100 } y={y + height / 2} fill="#636C7A" textAnchor="middle" fontSize={20} fontWeight={700} dominantBaseline="middle">
              {value.lenght > 12 ? `value.slice(0,12)...` : value}
            </text>
          </g>
        )
      }
    };

      const renderCustomizedLabel3 = (props: any) => {
        const { x, y, width, height, value, fill } = props;
        const radius = 30;
        if(width > 100){
          return (
            <g>
            <text x={x + width + 50} y={y + height / 2} fill="#636C7A" textAnchor="middle" fontSize={20} fontWeight={700} dominantBaseline="middle">
              {value} RECs
            </text>
          </g>
          )
        }else{
          return (
            <g>
            <text x={x + width + 250} y={y + height / 2} fill="#636C7A" textAnchor="middle" fontSize={20} fontWeight={700} dominantBaseline="middle">
              {value} RECs
            </text>
          </g>
          )
        }
      };

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setIsLoaded(true)
      }, 150);
  
      return () => clearTimeout(timeoutId);
    }, []);

    return (
        <>
         {isLoaded ?
            verticalBarData ?
            <BarChart
            layout="vertical"
                width={chartWidth}
                height={chartHeight}
                data={verticalBarData}
                margin={{
                  top: 20,
                  right: 100,
                  left: 20,
                  bottom: 5,
                }}
                barGap={5}
            >
                <CartesianGrid strokeDasharray="5 5" />
                <Tooltip />
                <XAxis type="number" />
                <YAxis dataKey="beneficiary" type="category" hide />
                <Bar dataKey="total_recs" radius={[20, 20, 20, 20]} barSize={40}>
                  {verticalBarData?.map((entry: any, index: number) => (
                      <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                  ))}
                  <LabelList dataKey="beneficiary" content={renderCustomizedLabel2} />
                  <LabelList dataKey="total_recs" content={renderCustomizedLabel3} />
                </Bar>
            </BarChart>
            :
            <Box
                textAlign='center'
                display='flex'
                height='80%'
                width='100%'
                alignItems='center'
            >
                <Typography
                    fontFamily="Sen"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="20px"
                    lineHeight="44px"
                    color="#6F7075"
                    width='100%'
                    >
                    No registered Clients
                </Typography>
            </Box>
            :
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80%'
                }}
            >
                <CircularProgress />
            </Box>
            }
        </>
    );
  }