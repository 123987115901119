import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useSnackbar } from 'notistack';
import { MemberService } from '../../../../services/member.service';

import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	Grid,
	InputBase,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	styled,
	Typography
} from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';

import numbersJson from '../../../../assets/numberJson.json';
import { InputLoadImage } from '../../../../components/inputLoadImage';
import { UploadLogo } from '../../../../components/uploadLogo';
import { ClientService } from '../../../../services/client.service';
import { colors } from '../../../../styles';
import { SchemaBuyerDefault } from './SchemaBuyer';
import { setTimeout } from 'timers/promises';
import { useParams } from 'react-router-dom';

interface IFormBuyer {
	beneficiary: string;
	cpfCnpj: string;
	country: string;
	phone: string;
	firstPhone: string;
	zipCode: string;
	city: string;
	state: string;
	address: string;
	numberAddress: string;
	email: string;
}

interface IFormBuyerComponent {
	setFormsuccess: (value: boolean) => void;
	setShowFormBuyer: (value: boolean) => void;
}

interface IClientRequest {
	id: string;
	beneficiary: string;
	cnpj: string;
	country: string;
	phone: string;
	zipcode: string;
	state: string;
	city: string;
	address: string;
	number: string;
	email: string;
}

export const FormBuyer = ({ setFormsuccess, setShowFormBuyer }: IFormBuyerComponent) => {
	const clientService = new ClientService();
	const memberService = new MemberService();

	const { enqueueSnackbar } = useSnackbar();
	const { clientId } = useParams();
	const [client, setClient] = useState({} as IClientRequest);
	const [loading, setLoading] = useState(false);
	const [countries, setCountries] = useState<Array<any>>();
	const [states, setStates] = useState<Array<any>>([]);
	const [countriesToState, setCountriesToState] = useState<string>('');
	const [phoneCode, setPhoneCode] = useState<any>();
	const [loadImage, setLoadImage] = useState<boolean>(false);
	const [uploadFile, setUploadFile] = useState<File | null>(null);
	const [inputValues, setInputValues] = useState<any>('');
	const [zipCodeField, setZipCodeField] = useState<string>('');
	const [cityField, setCityField] = useState<string>('');
	const [addressField, setAddressField] = useState<string>('');
	const [phoneField, setPhoneField] = useState<string>();

	const [uniqueIdentification, setUniqueIdentification] = useState('');
	const [maskCnpjCpf, setMaskCnpjCpf] = useState('');

	const BootstrapInput = styled(InputBase)(() => ({
		'& .MuiInputBase-input': {
			display: 'flex',
			alignItems: 'center',
			padding: '14px 25px',
			borderRadius: '9px',
			// backgroundColor: 'transparent',
			border: '1px solid #000',
			'& .MuiSvgIcon-root': {
				marginTop: '100px',
				color: '#fff'
			},
			'&:focus': {
				borderRadius: '10px'
			},
			':after': {
				borderBottom: 'none',
				borderRight: '1px solid #000',
				marginRight: '50px'
			}
		}
	}));

	const form = useForm<IFormBuyer>({
		resolver: yupResolver(SchemaBuyerDefault)
	});

	const setCountriesSelect = (e: any) => {
		form.setValue('country', e.target.value);
		setCountriesToState(e.target.value);
		if (e.target.value == 'Brazil'){
			setPhoneCode('+55');
		}
		if (e.target.value == 'United States'){
			setPhoneCode('+1');
		}
	};

	const handleOnChange = (event: any) => {
		const { name, value } = event.target;
		if (name === '') {
			setPhoneField(event.target.value);
			form.setValue('phone', event.target.value);
		}

		form.setValue(name, event.target.value);
		// if(name == 'email' && !value.includes('@') && !value.includes('.com')){
		// 	form.setValue('email', '');
		// }
		setInputValues({ ...inputValues, [name]: value });
	};

	useEffect(() => {
		const getCoutries = async () => {
			const respCountries = await clientService.getCountries();
			if (respCountries.status === 200) {
				const { data } = respCountries.data;
				setCountries(data);
			}
		};
		getCoutries();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const getStates = async () => {
			if (countriesToState) {
				const respStates = await clientService.getStates(countriesToState);
				if (respStates.status === 200) {
					const { data } = respStates.data;
					setStates(data.states);
				}
				else{
					setStates([])
				}
			}
		};
		getStates();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countriesToState]);

	const maskCpfCnpj = (cpfCnpj: string) => {
		form.setValue('cpfCnpj', cpfCnpj);
		setUniqueIdentification(cpfCnpj);
	};

	const focusMask = () => {
		setMaskCnpjCpf('99.999.999/9999-99');
	};

	const getMask = () => {
		const cpfCnpjNumbers = uniqueIdentification.match(/\d+/g) || [];
		const newNumber = cpfCnpjNumbers.join('');

		if (newNumber.length === 11) {
			setMaskCnpjCpf('999.999.999-99');
		} else {
			setMaskCnpjCpf('99.999.999/9999-99');
		}
	};

	const getZipCode = async (e: any) => {
		form.setValue('zipCode', e.target.value);
		setZipCodeField(e.target.value);

		if(e.target.value.length == 9) { //ZIPCODE = 8 digits + '-' = 9 digits
			const resZipCode = await clientService.getZipCode(e.target.value);
			if (resZipCode?.status === 200 && !resZipCode?.data.erro) {
				const { localidade, logradouro } = resZipCode.data;
				form.setValue('city', localidade);
				form.setValue('address', logradouro);
				setCityField(localidade);
				setAddressField(logradouro);
			}else{
				form.setValue('city', '');
				form.setValue('address', '');
				setCityField('');
				setAddressField('');	
			}
		}
	};

	const handlePhoneCode = (e: any) => {
		setPhoneCode(e.target.value);

	};

	const handleAddress = (e: any) => {
		setAddressField(e.target.value);
		form.setValue('address', e.target.value);
	};

	const handleCity = (e: any) => {
		setCityField(e.target.value);
		form.setValue('city', e.target.value);
	};

	const validateButton = () => {
		if (
			inputValues.beneficiary &&
			uniqueIdentification &&
			(uniqueIdentification.length === 18 || uniqueIdentification.length === 14) &&
			countriesToState &&
			inputValues.state &&
			inputValues.numberAddress &&
			zipCodeField &&
			zipCodeField.length === 9 &&
			phoneField &&
			cityField &&
			addressField &&
			// uploadFile &&
			inputValues.email &&
			inputValues.email.includes('@') &&
			inputValues.email.includes('.com')
		) {
			return true;
		}
		return false;
	};

	const saveLogo = async (file: any) => {
		const formData = new FormData();
		formData.append('logo', file);

		setLoadImage(true);
		const saveLogoImage = await clientService.updateLogoById(
			client.id,
			formData
		);
		if (saveLogoImage.status === 200) {
			setLoadImage(false);
			enqueueSnackbar('The logo has been uploaded', {
				variant: 'success'
			});
			return true;
		} else {
			setLoadImage(false);
			enqueueSnackbar('The client logo was not saved on server', {
				variant: 'error'
			});
			return false;
		}
	};

	const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		if (files?.length) {
			const file = files[0];
			const logoSaved = await saveLogo(file);

			if (logoSaved) {
				setUploadFile(file);
			}
		}
	};

	const onSubmit = async () => {
		const value = { ...form.getValues() };

		setLoading(true);
		enqueueSnackbar('Creating a new client', {
			variant: 'info',
			autoHideDuration: 6000
		});

		const respSubmitEnt = await clientService.addClient({
			enterprise_id: localStorage.getItem('enterprise_id') || '',
			country: countriesToState,
			cnpj: value.cpfCnpj,
			beneficiary: value.beneficiary,
			phone: value.phone,
			zipcode: value.zipCode,
			state: value.state,
			city: value.city,
			address: value.address,
			number: value.numberAddress,
			email: value.email
		});

		setFormsuccess(true);
		if (respSubmitEnt.status === 200) {
			setLoading(false);
			window.location.reload();
			enqueueSnackbar('Client has been created successfully ', {
				variant: 'success',
				autoHideDuration: 6000
			});
		} else {
			setLoading(false);
			enqueueSnackbar(respSubmitEnt.statusText, {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right'
				}
			});
		}
	};

	// const handleKeyPress = (event: { key: string; }) => {
	// 	// if (event.key === 'Enter' && validateButton()) {
	// 	// 	console.log('clicked')
	// 	// 	// onSubmit();
	// 	// }
	// 	if (event.key === 'Escape') {
	// 		setShowFormBuyer(false);
	// 	}
	// };
	
	// useEffect(() => {
	// 	// Add event listener when component mounts
	// 	document.addEventListener('keydown', handleKeyPress);
	
	// 	// Remove event listener when component unmounts
	// 	return () => {
	// 	  document.removeEventListener('keydown', handleKeyPress);
	// 	};
	// }, []);

	return (
		<>
			<form onSubmit={() => {}}>
				<Grid container md={12} columnSpacing={{ md: '20px' }} spacing={3}>
					<Grid item md={12} marginBottom="6px">
						<Typography
							fontSize="1.75rem"
							color={colors.primary}
							fontWeight="700"
							lineHeight="32px"
						>
							EDIT DATA
						</Typography>
					</Grid>
					<Grid item md={12}>
						<Controller
							control={form.control}
							name="beneficiary"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel
										required
										error={!!form?.formState?.errors?.beneficiary}
										shrink
										htmlFor="beneficiary"
									>
										BENEFICIARY
									</InputLabel>
									<OutlinedInput
										fullWidth
										style={{
											border: 'none',
											borderRadius: '9px',
											marginTop: '20px'
										}}
										error={!!form?.formState?.errors?.beneficiary}
										placeholder="type here..."
										{...field}
										type="string"
										// value={clientId ? client.beneficiary : ''}
										onChange={e => handleOnChange(e)}
									/>
									{!!form?.formState?.errors?.beneficiary && (
										<FormHelperText error>
											{form?.formState?.errors?.beneficiary?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={8}>
						<Controller
							control={form.control}
							name="cpfCnpj"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel
										required
										error={!!form?.formState?.errors?.cpfCnpj}
										shrink
										htmlFor="cpfCnpj"
									>
										CPJ/CNPJ
									</InputLabel>
									<InputMask
										autoComplete="off"
										type="string"
										mask={maskCnpjCpf}
										value={field.value}
										onChange={e => maskCpfCnpj(e.target.value)}
										onBlur={getMask}
										onFocus={focusMask}
										maskChar={null}
									>
										{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
										{/* @ts-ignore */}
										{() => (
											<OutlinedInput
												fullWidth
												style={{
													border: 'none',
													borderRadius: '9px',
													marginTop: '20px'
												}}
												error={!!form?.formState?.errors?.cpfCnpj}
												placeholder="type here..."
											/>
										)}
									</InputMask>
									{!!form?.formState?.errors?.cpfCnpj && (
										<FormHelperText error>
											{form?.formState?.errors?.cpfCnpj?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>
					{/* <Grid item md={2} marginLeft="15px">
						<InputLoadImage
							error={false}
							id="input-load-image"
							image={uploadFile ? URL.createObjectURL(uploadFile) : null}
							name="fileLogo"
							textLabel="Insert Logo"
							onChange={handleImageUpload}
						/>
					</Grid> */}
					<Grid item md={5.5}>
						<Controller
							control={form.control}
							name="country"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel
										required
										error={!!form?.formState?.errors?.country}
										shrink
										htmlFor="country"
										sx={{ position: 'relative', marginTop: '3px' }}
									>
										COUNTRY
									</InputLabel>
									<Select
										{...field}
										error={!!form?.formState?.errors?.country}
										fullWidth
										input={<BootstrapInput />}
										labelId="demo-simple-select-label"
										id="country"
										variant="standard"
										value={countriesToState}
										onChange={(e: any) => setCountriesSelect(e)}
									>
										{countries &&
											countries.map(({ iso3, country }) => (
												<MenuItem key={iso3} value={country}>
													{country}
												</MenuItem>
											))}
									</Select>
									{!!form?.formState?.errors?.country && (
										<FormHelperText error>
											{form?.formState?.errors?.country?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={2.5}>
						<Controller
							control={form.control}
							name="firstPhone"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel
										required
										error={false}
										shrink
										htmlFor="firstPhone"
										sx={{ position: 'relative', marginTop: '3px' }}
									>
										PHONE
									</InputLabel>
									<Select
										{...field}
										fullWidth
										input={<BootstrapInput />}
										labelId="demo-simple-select-label"
										id="firstPhone"
										variant="standard"
										value={phoneCode}
										onChange={(e: any) => handlePhoneCode(e)}
									>
										<MenuItem value="placeholder" disabled>
											select ...
										</MenuItem>
										{numbersJson.map(number => (
											<MenuItem key={number.name} value={number.dialCode}>
												<Box
													sx={{
														display: 'flex',
														alignItems: 'center'
													}}
												>
													<img
														src={number.flag}
														alt={number.name}
														style={{
															height: '20px',
															width: '26px',
															objectFit: 'cover'
														}}
													/>
													<Typography
														sx={{
															fontSize: '.875rem',
															color: '#000'
														}}
													>
														{number.dialCode}
													</Typography>
												</Box>
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={4}>
						<Controller
							control={form.control}
							name="phone"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputMask
										name="phone"
										error={!!form?.formState?.errors?.phone}
										autoComplete="off"
										type="string"
										mask="(99) 99999-9999"
										value={field.value}
										onChange={e => handleOnChange(e)}
										maskChar={null}
									>
										{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
										{/* @ts-ignore */}
										{() => (
											<OutlinedInput
												fullWidth
												style={{
													border: 'none',
													borderRadius: '9px',
													marginTop: '20px'
												}}
												error={!!form?.formState?.errors?.phone}
												placeholder="(00) 00000-0000"
											/>
										)}
									</InputMask>
									{!!form?.formState?.errors?.phone && (
										<FormHelperText error>
											{form?.formState?.errors?.phone?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={4}>
						<Controller
							control={form.control}
							name="zipCode"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel
										required
										error={!!form?.formState?.errors?.zipCode}
										shrink
										htmlFor="zipCode"
									>
										ZIPCODE
									</InputLabel>
									<InputMask
										autoComplete="off"
										type="string"
										mask="99999-999"
										value={field.value}
										onChange={e => getZipCode(e)}
										maskChar={null}
									>
										{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
										{/* @ts-ignore */}
										{() => (
											<OutlinedInput
												fullWidth
												style={{
													border: 'none',
													borderRadius: '9px',
													marginTop: '20px'
												}}
												error={!!form?.formState?.errors?.zipCode}
												placeholder="type here..."
											/>
										)}
									</InputMask>
									{!!form?.formState?.errors?.zipCode && (
										<FormHelperText error>
											{form?.formState?.errors?.zipCode?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={3}>
						<Controller
							control={form.control}
							name="state"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel
										required
										error={!!form?.formState?.errors?.state}
										shrink
										htmlFor="state"
										sx={{ position: 'relative', marginTop: '3px' }}
									>
										STATE / UF
									</InputLabel>
									<Select
										{...field}
										error={!!form?.formState?.errors?.state}
										fullWidth
										input={<BootstrapInput />}
										labelId="demo-simple-select-label"
										id="state"
										variant="standard"
										onChange={e => handleOnChange(e)}
										sx={{
											':before': {
												borderRight: '1px solid #A2CBFE',
												marginRight: '50px',
												height: '100%'
											}
										}}
									>
										{states &&
											states.map(({ iso3, name }) => (
												<MenuItem key={iso3} value={name}>
													{name}
												</MenuItem>
											))}
									</Select>
									{!!form?.formState?.errors?.state && (
										<FormHelperText error>
											{form?.formState?.errors?.state?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={5}>
						<Controller
							control={form.control}
							name="city"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel
										required
										error={!!form?.formState?.errors?.city}
										shrink
										htmlFor="city"
									>
										CITY
									</InputLabel>
									<OutlinedInput
										fullWidth
										style={{
											border: 'none',
											borderRadius: '9px',
											marginTop: '20px'
										}}
										error={!!form?.formState?.errors?.city}
										placeholder="type here..."
										{...field}
										type="string"
										onChange={e => handleCity(e)}
									/>
									{!!form?.formState?.errors?.city && (
										<FormHelperText error>
											{form?.formState?.errors?.city?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={5}>
						<Controller
							control={form.control}
							name="address"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel
										required
										error={!!form?.formState?.errors?.address}
										shrink
										htmlFor="address"
									>
										ADDRESS
									</InputLabel>
									<OutlinedInput
										fullWidth
										style={{
											border: 'none',
											borderRadius: '9px',
											marginTop: '20px'
										}}
										error={!!form?.formState?.errors?.address}
										placeholder="type here..."
										{...field}
										type="string"
										onChange={e => handleAddress(e)}
									/>
									{!!form?.formState?.errors?.address && (
										<FormHelperText error>
											{form?.formState?.errors?.address?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={2}>
						<Controller
							control={form.control}
							name="numberAddress"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel
										required
										error={!!form?.formState?.errors?.numberAddress}
										shrink
										htmlFor="numberAddress"
									>
										NUMBER
									</InputLabel>
									<OutlinedInput
										fullWidth
										style={{
											border: 'none',
											borderRadius: '9px',
											marginTop: '20px'
										}}
										error={!!form?.formState?.errors?.numberAddress}
										placeholder="type here..."
										{...field}
										type="string"
										onChange={e => handleOnChange(e)}
									/>
									{!!form?.formState?.errors?.numberAddress && (
										<FormHelperText error>
											{form?.formState?.errors?.numberAddress?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={5}>
						<Controller
							control={form.control}
							name="email"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel
										required
										error={!!form?.formState?.errors?.email}
										shrink
										htmlFor="email"
									>
										E-MAIL
									</InputLabel>
									<OutlinedInput
										fullWidth
										style={{
											border: 'none',
											borderRadius: '9px',
											marginTop: '20px'
										}}
										error={!!form?.formState?.errors?.email}
										placeholder="type here..."
										{...field}
										type="string"
										onChange={e => handleOnChange(e)}
									/>
									{!!form?.formState?.errors?.email && (
										<FormHelperText error>
											{form?.formState?.errors?.email?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={12} marginTop="23px">
						<Button
							variant="outlined"
							onClick={()=>{setShowFormBuyer(false)}}
							sx={{
								width: '165px',
								height: '48px',
								borderRadius: '25px',
								border: '1px solid #000',
								padding: '8px 24px',
								color: '#000',
								textTransform: 'initial',
								fontSize: '1.25rem',
								'&:hover': {
									border: '1px solid #000'
								}
							}}
						>
							<Typography fontSize="1.25rem" lineHeight="32px">
								CANCEL
							</Typography>
						</Button>
						<LoadingButton
							disableElevation
							variant="contained"
							loading={loading}
							onClick={() => form.handleSubmit(onSubmit)()}
							disabled={!validateButton()}
							sx={{
								marginLeft: '40px',
								width: '154px',
								height: '48px',
								backgroundColor: validateButton()
									? colors.primary
									: colors.primaryLight,
								borderRadius: '25px',
								padding: '8px 24px',
								'&.MuiButton-root.Mui-disabled': {
									backgroundColor: colors.primaryLight
								},
								'&:hover': {
									backgroundColor: validateButton()
										? colors.primary
										: colors.primaryLight
								}
							}}
						>
							<Typography
								color="#fff"
								fontSize="1.25rem"
								fontWeight="400"
								sx={{
									textTransform: 'initial'
								}}
							>
								SAVE
							</Typography>
						</LoadingButton>
					</Grid>
				</Grid>
			</form>
		</>
	);
};
