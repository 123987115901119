import { Box, Typography } from '@mui/material';

interface EnterpriseInfoProps {
	enterprise: string;
	cnpj: string;
}

export const EnterpriseInfo = ({ enterprise, cnpj }: EnterpriseInfoProps) => {
	return (
		<Box component="main" sx={{ paddingTop: '20px' }}>
			<Typography
				sx={{
					fontSize: '20px',
					fontWeight: 400,
					lineHeight: '32px',
					letterSpacing: '0px',
					color: '#000000'
				}}
			>
				{enterprise || 'Enterprise'}
			</Typography>
			<Typography
				sx={{
					fontSize: '20px',
					fontWeight: 400,
					lineHeight: '32px',
					letterSpacing: '0px',
					color: '#000000'
				}}
			>
				{cnpj || '00000000000000'}
			</Typography>
		</Box>
	);
};
