import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useLocation, useNavigate } from 'react-router-dom';

import {
	Box,
	Button,
	Card,
	FormHelperText,
	Grid,
	InputLabel,
	Typography
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import EnergyImg from '../../assets/imgs/energy.png';
import informationIssueImg from '../../assets/imgs/informationIssue.png';
import RecIssueImg from '../../assets/imgs/recIsue.png';
import { Header } from '../../components/header';
import { SideBar } from '../../components/sideBar';
import { colors } from '../../styles';
import { ITransactionCertificate } from '../../types/buyerTransaction';
import { HeadCell } from '../../types/headCell';
import { SelectAmount } from '../RecIssue/selectAmount';
import { SelectMode } from '../RecIssue/selectMode';
import { Table } from './table';

interface IDataTransaction {
	id: string;
	orgName: string;
	period: string;
	amount: number;
}

export const RecRetirement = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [validateTotalEnergy, setValidateTotalEnergy] = useState<boolean>(false);
	const [gotUser, setGotUser] = useState<any>([]);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const [totalAmountSelected, setTotalAmountSelected] = useState<number>(0);
	const [selectedAmount, setSelectedAmount] = useState<number>(0);
	const [valueAmount, setValueAmount] = useState<number>(0);
	const [validateButton, setValidateButton] = useState<boolean>(false);
	const [validateEmptyField, setValidateEmptyField] = useState<boolean>(false);
	const [dataTableSelected, setDataTableSelected] = useState<ITransactionCertificate[]>();

	const headCell: HeadCell[] = [
		{
			id: 'unitName',
			numeric: true,
			disablePadding: false,
			label: 'Unit Name'
		},
		{
			id: 'period',
			numeric: true,
			disablePadding: false,
			label: 'Period'
		},
		{
			id: 'amountRec',
			numeric: true,
			disablePadding: false,
			label: 'Amount Rec'
		},
		{
			id: 'maxAmount',
			numeric: true,
			disablePadding: false,
			label: 'Max Amount'
		}
	];

	const { data, totalAmount, dataPreview } = location?.state;
	useEffect(() => {
		if (dataPreview) {
			setValidateEmptyField(false);
			setGotUser(dataPreview);
			setIsLoaded(true);
		} else {
			setGotUser(data);
			setIsLoaded(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		setTotalAmountSelected(totalAmount);
	}, [totalAmount]);

	useEffect(() => {
		setSelectedAmount(valueAmount);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [valueAmount]);

	const backToOperationHistory = () => {
		localStorage.setItem('retrimentPage', 'tOperation');
		navigate('/rec/operation-history');
	};

	const validate = () => {
		return (
			valueAmount > totalAmountSelected || validateButton || validateEmptyField
		);
	};

	return (
		<>
			<Header />
			<SideBar />
			<Box
				component="main"
				sx={{
					marginLeft: '234px',
					height: '100vh',
					paddingTop: '146px'
				}}
			>
				<Box position="absolute" bottom="0" width="35%" right="0">
					{/* <img src={RecIssueImg} alt="backgroundimage"></img> */}
				</Box>
				<Grid container md={12} marginBottom="54px">
					<Grid item md={12} display="flex">
						<Button
							variant="outlined"
							onClick={backToOperationHistory}
							sx={{
								width: '107px',
								height: '40px',
								borderRadius: '25px',
								border: '1px solid #000',
								padding: '8px 24px',
								color: '#000',
								textTransform: 'initial',
								fontSize: '1.25rem',
								'&:hover': {
									border: '1px solid #000'
								}
							}}
						>
							<Typography fontSize="1.25rem" lineHeight="32px">
								Back
							</Typography>
						</Button>
						<Typography
							fontWeight="700"
							fontSize="1.75rem"
							lineHeight="40px"
							color={colors.primary}
							marginBottom="9px"
							marginLeft="96px"
						>
							REC RETIREMENT
						</Typography>
					</Grid>
				</Grid>
				<Grid container md={12} gap={5}>
					<Grid item md={4}>
						<Card sx={{ marginLeft: '25px' }}>
							<Box display="flex" alignItems="center">
								<SelectMode />
								<Typography sx={{ marginLeft: '21px', fontSize: '1.25rem' }}>
									VERIFY AND SELECT:
								</Typography>
							</Box>
							{/* table here */}
							<Box marginTop="65px" marginBottom="25px" marginLeft="34px">
								{gotUser.length > 0 && (
									<Table
										setDataTableSelected={setDataTableSelected}
										sx={{
											width: '96%',
											border: `1px solid #CAD5E8`,
											borderRadius: '16px',
											overflow: 'hidden'
										}}
										setValueAmount={setValueAmount}
										setValidateButton={setValidateButton}
										setValidateEmptyField={setValidateEmptyField}
										title="Selected Certificates"
										titles={headCell}
										isLoaded={isLoaded}
										transactions={gotUser ? gotUser : null
										}
									/>
								)}
							</Box>
						</Card>
					</Grid>
					<Grid item md={4}>
						<Card sx={{ height: '600px', position: 'relative' }}>
							<Box display="flex" alignItems="center">
								<SelectAmount step={2} />
								<Typography sx={{ marginLeft: '21px', fontSize: '1.25rem' }}>
									INSERT AMOUNT:
								</Typography>
							</Box>
							{true && (
								<Card sx={{ marginTop: '57px' }}>
									<Box marginLeft="100px">
										<Typography fontSize="1.25rem">
											TOTAL AVAILABLE REC’S
										</Typography>
									</Box>
									<Box
										display="flex"
										alignItems="center"
										marginLeft="29px"
										marginBottom="48px"
									>
										<img src={EnergyImg} alt="eletric"></img>
										<Typography
											sx={{
												marginLeft: '23px',
												fontSize: '2.25rem',
												fontWeight: '700',
												color: '#00CA95'
											}}
										>
											{Number(totalAmountSelected).toLocaleString('en-US', {
												minimumFractionDigits: 0
											})}
										</Typography>
									</Box>

									<Box marginLeft="40px">
										<InputLabel
											error={false}
											shrink
											htmlFor="job_title"
											sx={{
												color: colors.neutralDark,
												fontSize: '25px',
												lineHeight: '32px'
											}}
										>
											Confirm the selected amount
										</InputLabel>
										<NumericFormat
											// value={inputAmount}
											disabled
											allowLeadingZeros
											decimalSeparator="."
											thousandSeparator=","
											placeholder="0"
											value={selectedAmount}
											style={{
												width: '298px',
												background: 'transparent',
												padding: '16px 14px',
												border: '0px hidden',
												// outline: validateTotalEnergy ? 'blue' : '#F32053',
												// border: `${valueAmount > totalAmountSelected
												// 	? '0px solid'
												// 	: '0px hidden'} ${
												// 	valueAmount > totalAmountSelected
												// 		? '#F32053'
												// 		: '#3A4049'
												// }`,
												// boxShadow: '0px 2px 8px rgb(0 0 0 / 15%)',
												borderRadius: '9px',
												marginTop: '8px',
												fontSize: '30px',
												color:
													valueAmount > totalAmountSelected
														? '#F32053'
														: colors.neutralDark
											}}
										/>
										{valueAmount > totalAmountSelected && (
											<FormHelperText
												error
												sx={{
													fontWeight: '700',
													fontSize: '14px',
													lineHeight: '32px',
													fontFamily: 'sen',
													'&.Mui-error': {
														color: '#F32053'
													}
												}}
											>
												UNVAILABLE AMOUNT
											</FormHelperText>
										)}
									</Box>
								</Card>
							)}
						</Card>
					</Grid>
					<Grid item md={3}>
						<Card>
							<Box display="flex" alignItems="center">
								<SelectAmount step={3} />
								<Typography sx={{ marginLeft: '21px', fontSize: '1.25rem' }}>
									FINALIZE:
								</Typography>
							</Box>
							<Card sx={{ marginTop: '57px' }}>
								<Box
									display="flex"
									alignItems="center"
									marginLeft="35px"
									marginBottom="18px"
								>
									<Box marginTop="5px">
										<img src={informationIssueImg}></img>
									</Box>
									<Typography
										sx={{
											color: colors.supportDark,
											fontSize: '20px',
											marginLeft: '17px'
										}}
									>
										Important:
									</Typography>
								</Box>
								<Box marginLeft="35px">
									<Typography
										color={colors.neutralDark}
										fontSize="20px"
										lineHeight="32px"
									>
										This operation is conditioned to the available amount of tokens in your wallet.
									</Typography>
								</Box>

								<LoadingButton
									disableElevation
									disabled={validate()}
									loading={false}
									onClick={() =>
										navigate('/rec/operation-history/rec-retirement/preview', {
											state: {
												data: dataTableSelected
													? dataTableSelected
													: dataPreview,
												totalAmountSelected
											}
										})
									}
									sx={{
										marginLeft: '35px',
										marginTop: '65px',
										width: '242px',
										borderRadius: '25px',
										padding: '8px 32px',
										display: 'flex',
										alignItems: 'flex-end',
										backgroundColor: validate()
											? colors.primaryLight
											: colors.primary,
										'&:hover': {
											backgroundColor: validate()
												? colors.primaryLight
												: colors.primary
										}
									}}
								>
									<Typography
										color="#fff"
										fontSize="24px"
										fontWeight="700"
										lineHeight="32px"
									>
										PREVIEW
									</Typography>
								</LoadingButton>
							</Card>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};
