/* eslint-disable import/extensions */
import { Api } from './api/axios-config';
interface IResMember {
	status: number;
	statusText: string;
	data: Array<object>;
}

interface IUserProfile {
	first_name: string;
	last_name: string;
	job_title: string;
	cpf: number;
	phone: number;
	country: string;
	state: string;
	city: string;
	address: string;
	email: string;
	zipcode: number;
	number: number;
}

export type TMember = {
	email: string;
	role: string;
	enterprise_id: string;
};

export class ProfileService {
	async addUserProfile(userProfile: IUserProfile) {
		try {
			const resAddUser: IResMember = await Api.put(`/users`, userProfile);
			return resAddUser;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async getUserProfile() {
		try {
			const resGetUser: IResMember = await Api.get(`/users/my-info`);
			return resGetUser;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async createWallet(wallet_name: string, wallet_address: string) {
		try {
			const resWallet: IResMember = await Api.patch(`/users/wallet`, {
				wallet_address: wallet_address,
				wallet_name
			});
			return resWallet;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async updateLogo(logo: any) {
		try {
			const respUpdateLogo = await Api.patch(`/users/logo`, logo);
			return respUpdateLogo;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}
}
