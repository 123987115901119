/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Typography, Checkbox, Button } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { getOnchainAPI, getKeyring } from '../../onchain/substrate'
import { mnemonicGenerate } from '@polkadot/util-crypto';
import { keyring } from "@polkadot/ui-keyring";
import { waitReady } from "@polkadot/wasm-crypto";
import BackgroundWalee from '../../assets/imgs/backgroundwalee.png';
import Warning from '../../assets/imgs/errorInfo.png';
import { Header } from '../../components/header';
import { SideBar } from '../../components/sideBar';
import { createWalletAsset } from '../../stores/nextStepAseet/nextStepAsset.actions';
import { colors } from '../../styles';
import { CardWalletAsset } from './cardWalletAsset';

export const CreateAssetWallet = () => {

  const history = useNavigate();
  const dispatch = useDispatch();
  const [checked, setChecket] = useState<boolean>(false);
  const [mnemonic, setMnemonic] = useState<string>('');
  const [walletAddress, setWalletAddress] = useState<string>('');
  const [loadedWallet, setLoadedWallet] = useState<boolean>(false);

  const nextStepAsset = useSelector((state: any) => state.nextStepAsset);
 
  useEffect(() => {
    const getAPI = async () => {
      const api = await getOnchainAPI();
    } 
    const createWalletOnchain = async () => {
      try {
        if (!loadedWallet){
          await waitReady();
          keyring.loadAll({ ss58Format: 42, type: "sr25519" });
          setLoadedWallet(true)
        }
      } catch (error) {
				console.log(error)
			} finally {
        const mnemonic_raw = mnemonicGenerate();
        const { pair, json } = keyring.addUri(mnemonic_raw);
        if (walletAddress == '' && mnemonic == ''){
          setWalletAddress(pair.address);
          setMnemonic(mnemonic_raw);
        }
        keyring.forgetAccount(pair.address);
      }
    } 
    getAPI();
    createWalletOnchain();
  }, [])
  
  const gotToNextStep = () => {
    dispatch(createWalletAsset(true));
  }

  const gotToProfile = () => {
    if (nextStepAsset) {
      dispatch(createWalletAsset(false));
    } else {
      history('/rec/assets');
    }
  }

  const goToNextStep = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecket(e.target.checked);
  }

  const CheckMnemonic = () => {
    return (
      <FormGroup sx={{ marginLeft: '15px' }}>
        <FormControlLabel
          control={
            <Checkbox checked={checked} onChange={e => goToNextStep(e)}
              sx={{
                color: '#000',
                '&.Mui-checked': {
                  color: '#00CA95',
                },
              }}
            />
          }
          label={
            <Typography
              fontSize="1.25rem"
              fontWeight="700"
            >
              I have saved my mnemonic seed safely
            </Typography>
          }
        />
      </FormGroup>
    )
  }

  return (
    <>
      <Header />
      <SideBar />
      <Box>
        <Box
          position="absolute"
          top="230px"
          marginLeft="180px"
        >
          <Button
            onClick={gotToProfile}
            variant="outlined"
            sx={{
              borderRadius: '25px',
              width: '107px',
              border: '1px solid #000',
              color: '#000',
              '&:hover': {
                border: '1px solid #000',
              }
            }}
          >
            Back
          </Button>
        </Box>
        {walletAddress && <CardWalletAsset mnemonic={mnemonic} walletAddress={walletAddress} />}
        <Box
          position="absolute"
          bottom="0"
          width="35%"
          left="152px"
        >
          <img
            src={BackgroundWalee}
            alt="backgroundimage"
          >
          </img>
        </Box>
        <Box
          display="flex"
          position="absolute"
          bottom="10px"
          right="150px"
        >
          <Box
            sx={{
              alignSelf: 'flex-start',
              marginTop: '0',
              marginLeft: '54px'
            }}>
            {!nextStepAsset && (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  height="100%"
                  marginBottom="14px"
                >
                  <Box marginRight="28px">
                    <img src={Warning} />
                  </Box>
                  <Typography
                    color="#F32053"
                    fontSize="1.25rem"
                    alignSelf="center"
                  >
                    Keep it carefully to not lose your assets.
                  </Typography>
                </Box>
                <CheckMnemonic />

                <Button
                  onClick={gotToNextStep}
                  disabled={!checked}
                  variant="contained"
                  sx={{
                    marginLeft: '355px',
                    marginTop: '40px',
                    marginBottom: '10px',
                    minWidth: '177px',
                    height: '48px',
                    backgroundColor: colors.primary,
                    borderRadius: '25px',
                    padding: '8px 32px',
                    '&:hover': {
                      backgroundColor: colors.primary,
                    }
                  }}>
                  <Typography
                    sx={{ textTransform: 'initial' }}
                    color="#fff"
                    fontSize="1.5rem"
                    fontWeight="700"
                  >
                    Next step
                  </Typography>
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
