import { RecTokenService } from '../../services/rec.service';

const INITIAL_STATE: any[] = [];

interface IAction {
	type: string;
}

const recTokenService = new RecTokenService();

const listTokenAseet = async () => {
	const resplistTokenAseet: any = await recTokenService.listRecToken();
	return resplistTokenAseet;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function (state = INITIAL_STATE, action: IAction) {
	switch (action.type) {
		case 'GET_REC_ASSETS':
			return listTokenAseet();
		default:
			return state;
	}
}
