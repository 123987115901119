import { FC, useEffect, useRef, ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import {
	Box,
	Paper,
	PaperProps,
	TableContainer,
	Typography,
	Table as TableM,
	TableSortLabel,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TextField,
	ButtonBase,
	Checkbox,
	FormGroup,
	FormControlLabel,
	Pagination,
	PaginationItem,
	CircularProgress
} from '@mui/material';

import { visuallyHidden } from '@mui/utils';

import filterIcon from '../../../assets/icon/filterTableHeader.png';

import PDF from '../../../assets/imgs/pdf.png';
import { getTotalAvailableRec } from '../../../stores/totalAvailableRec/totalAvailableRec.actions';
import { transferRec } from '../../../stores/transferRec/transferRec.actions';
import { colors } from '../../../styles';
import { ICertificateOrg, IOrigins } from '../../../types/certificateOrg';
import { HeadCell } from '../../../types/headCell';
import { customScroll } from '../../../view/commonStyles';
import { TextTableBody } from './textTableBody';
import { renderPDF } from '../../../certificateModel/model';
import { useSnackbar } from 'notistack';

type Order = 'asc' | 'desc';

interface ITable extends PaperProps {
	title: string;
	titles: any;
	transactions: any;
	isLoaded: boolean;
	totalPages: number;
	setSelectedPage: (value: number) => void;
	setSelectedSize: (value: number) => void;
}
interface ISortInput {
	[key: string]: number | string;
}
const initSortInput: ISortInput = {
	date: '',
	sellerOrg: '',
	seller: '',
	buyer: '',
	opType: '',
	status: '',
	sourceType: '',
	orgName: '',
	unitID: 0,
	location: '',
	period: '',
	uniqueId: '',
	amount: 0
};

export const TableHistory: FC<ITable> = ({
	sx,
	title,
	titles,
	transactions,
	isLoaded,
	totalPages,
	setSelectedPage,
	setSelectedSize,
	...props
}) => {
	const [pdfBase64, setPDFBase64] = useState('');
	const [order, setOrder] = useState<Order>('desc');
	const [orderBy, setOrderBy] = useState<string>('date');
	const [isQueryLoaded, setIsQueryLoaded] = useState<boolean>(false);
	const [page, setPage] = useState(1);
	const [sortedData, setSortedData] = useState<any | undefined | null>(
		transactions
	);
	const [sortInput, setSortInput] = useState(initSortInput);
	const firstDivRef = useRef(null);
	const secondDivRef = useRef(null);
	const { enqueueSnackbar } = useSnackbar();

	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value);
		setSelectedPage(value)
	};

	const handleCreatePDF = async ( transaction: ICertificateOrg ) => {
		enqueueSnackbar('Your download will start soon', {
			variant: 'info'
		});
		const res = await renderPDF({
			id: transaction.id,
			beneficiaryId: transaction.beneficiaryId,
			createdAt: transaction.date,
			provider: transaction.holder,
			status: transaction.status,
			beneficiary: transaction.beneficiary,
			beneficiaryWallet: transaction.beneficiaryWallet,
			sourceType: transaction.assetSourceType,
			orgName: transaction.orgName,
			powerUnit: transaction.powerUnit,
			country: transaction.assetCountry,
			state: transaction.assetState,
			city: transaction.assetCity,
			period: transaction.period,
			unitID: transaction.assetUnitID,
			amountRec: transaction.amountRec.toString(),
			asset_name: transaction.powerUnit,
			operationType: transaction.opType,
			recLabel: transaction.recLabel,
			hash: transaction.recHash,
			startDate: transaction.startDate.toString(),
			endDate: transaction.endDate.toString(),
			origins: transaction.originCertificates,
			assetCo2PerMWh: transaction.co2PerMWh,
			assetStartOfOperation: transaction.startOfOperation,
			certificateIdRange: transaction.certificate_id_range,
			blockHash: transaction.blockHash,
			setPDFBase64: setPDFBase64,
			download: true
		});

		if(!res){
			enqueueSnackbar('Error to download this certificate. Check if your enteprise has a valid logo or this certificate was finished completely', {
				variant: 'error'
			});
		}
	};

	useEffect(() => {
		setSortedData(transactions);
		setIsQueryLoaded(isLoaded);
	}, [transactions]);

	useEffect(() => {
		setIsQueryLoaded(isLoaded);
	}, [isLoaded]);

	const handleScrollFirst = (scroll: any) => {
		const current: any = secondDivRef.current;
		if (current) {
			current.scrollLeft = scroll.target.scrollLeft;
		}
	};

	const handleScrollSecond = (scroll: any) => {
		const current: any = firstDivRef.current;
		if (current) {
			current.scrollLeft = scroll.target.scrollLeft;
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-shadow
	function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
		if (
			orderBy === 'sellerOrg' ||
			orderBy === 'seller' ||
			orderBy === 'buyer' ||
			orderBy === 'opType' ||
			orderBy === 'status' ||
			orderBy === 'sourceType' ||
			orderBy === 'orgName' ||
			orderBy === 'location'
		) {
			const first: any = a[orderBy];
			const second: any = b[orderBy];
			if (
				!!first.replace(/\d+/, '') &&
				!!second.replace(/\d+/, '') &&
				first.replace(/\d+/, '') === second.replace(/\d+/, '') &&
				(first.replace(/\D+/, '') || second.replace(/\D+/, ''))
			) {
				return +second.replace(/\D+/, '') < +first.replace(/\D+/, '')
					? -1
					: +second.replace(/\D+/, '') > +first.replace(/\D+/, '')
					? 1
					: 0;
			}
		}
		if (orderBy === 'date') {
			const first: any = a[orderBy];
			const firstReFormat = first.replace(/(\d{2})\/(\d{2})/, '$2/$1');
			const second: any = b[orderBy];
			const secondReFormat = second.replace(/(\d{2})\/(\d{2})/, '$2/$1');
			return (
				new Date(secondReFormat).valueOf() - new Date(firstReFormat).valueOf()
			);
		}
		if (orderBy === 'period') {
			const first: any = `01/${a[orderBy]}`;
			const second: any = `01/${b[orderBy]}`;
			return new Date(second).valueOf() - new Date(first).valueOf();
		}
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}

		return 0;
	}
	function getComparator<Key extends keyof any>(
		// eslint-disable-next-line @typescript-eslint/no-shadow
		order: Order,
		// eslint-disable-next-line @typescript-eslint/no-shadow
		orderBy: Key
	): (
		a: { [key in Key]: number | string | any },
		b: { [key in Key]: number | string | any }
	) => number {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: string
	) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const createSortHandler =
		(property: string) => (event: React.MouseEvent<unknown>) => {
			handleRequestSort(event, property);
		};

	const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setSortInput({ ...sortInput, [name]: value.toLowerCase() });
	};

	const handleBlur = () => {
		const validate = Object.keys(sortInput).filter(i => !!sortInput[i]);
		let newData: any | null = transactions;
		if (validate.length) {
			validate?.forEach((key: any) => {
				if (newData) {
					newData = newData.filter((i: any) => {
						if (typeof i[key] === 'number') {
							return i[key] === +sortInput[key];
						} else {
							return i[key]
								.toLowerCase()
								.includes(sortInput[key]?.toString().toLowerCase());
						}
					});
				}
			});
			setSortedData(newData);
		} else {
			setSortedData(transactions);
		}
	};

	const handleKeyDown = (
		event: React.KeyboardEvent<HTMLInputElement>
	): void => {
		if (event.key === 'Enter') {
			handleBlur();
		}
	};

	return (
		<Paper
			{...props}
			sx={{
				boxShadow: 'none',
				...sx
			}}
		>
			<TableContainer
				sx={{
					overflow: !!sortedData?.length ? 'hidden' : 'auto',
					...customScroll
				}}
				// onScroll={handleScrollFirst}
				ref={firstDivRef}
			>
				<Box display={'flex'}>
					<Typography
						sx={{
							padding: '16px 0 0 16px',
							fontFamily: 'Sen',
							fontStyle: 'normal',
							fontWeight: 700,
							fontSize: '24px',
							lineHeight: '30px',
							letterSpacing: '0.4px'
						}}
					>
						{title}
					</Typography>
					{isLoaded &&
						<Pagination renderItem={(item) => (
								<PaginationItem
									sx={{color: '#6F7075'}}
									{...item}
								/>
							)}
							count={totalPages}
							disabled={!isLoaded}
							showFirstButton
							showLastButton
							color='standard'
							shape='rounded'
							page={page}
							onChange={handleChange}
							sx={{marginLeft: 'auto', marginTop: '1%', marginRight: '2%'}}
						/>
					}
				</Box>
				<TableM>
					<TableHead>
						<TableRow>
							{titles &&
								// eslint-disable-next-line @typescript-eslint/no-shadow
								titles.slice().map((title: any, index: any) => (
									<TableCell
										align={title.numeric ? 'left' : 'center'}
										key={title.id}
										padding={title.disablePadding ? 'none' : 'normal'}
										sortDirection={orderBy === title.id ? order : false}
										sx={{
											paddingRight:
												(titles.length - 1 === index && '40px') || '16px',
											borderTopLeftRadius: 0
										}}
									>
										{title.id !== 'report' ? (
											<TableSortLabel
												sx={{ width: '100%' }}
												hideSortIcon
												direction={orderBy === title.id ? order : 'asc'}
												onClick={createSortHandler(title.id)}
											>
												<Box
													display="flex"
													justifyContent="space-between"
													alignItems="center"
													width="100%"
												>
													<Typography
														sx={{
															width: '100%',
															whiteSpace: 'nowrap',
															fontSize: '12px',
															fontWeight: '700',
															color: '#9FA2B4'
														}}
													>
														{title.label}
													</Typography>
													<img src={filterIcon}></img>
												</Box>
												{orderBy === title.id ? (
													<Box component="span" sx={visuallyHidden}>
														{order === 'desc'
															? 'sorted descending'
															: 'sorted ascending'}
													</Box>
												) : null}
											</TableSortLabel>
										) : (
											<Typography
												sx={{
													fontSize: '0.75rem',
													fontWeight: '700',
													color: '#9FA2B4'
												}}
											>
												{title.label}
											</Typography>
										)}
										{title.id !== 'report' && (
											<TextField
												name={title.id}
												placeholder="search ..."
												sx={{
													input: {
														width: title.id === 'amountRec' ? '100px' : '100%',
														borderRadius: '4px',
														backgroundColor: 'rgba(217, 217, 217, 0.8)',
														boxShadow: 'none !important',
														padding: '4px 6px',
														fontSize: '12px',
														fontWeight: '400'
													},
													fieldset: {
														border: 'none !important',
														boxShadow: 'none !important'
													}
												}}
												onBlur={handleBlur}
												onKeyUp={handleKeyDown}
												onChange={onChangeInput}
											/>
										)}
									</TableCell>
								))}
						</TableRow>
					</TableHead>
				</TableM>
			</TableContainer>
			<TableContainer
				onScroll={handleScrollSecond}
				ref={secondDivRef}
				sx={{
					height: 'calc(100vh - 380px)',
					minHeight: '300px',
					...customScroll
				}}
			>
				<TableM stickyHeader aria-label="sticky table">
					<TableBody>
						{sortedData &&
							sortedData
								.slice()
								.sort(getComparator(order, orderBy))
								.map((transaction: ICertificateOrg) => (
									<TableRow key={transaction.id}>
										<TableCell>
											<TextTableBody sx={{ whiteSpace: 'normal' }}>
												{transaction.date}
											</TextTableBody>
										</TableCell>
										<TableCell>
											<TextTableBody>{transaction.assetName}</TextTableBody>
										</TableCell>
										<TableCell>
											<TextTableBody>{transaction.holder}</TextTableBody>
										</TableCell>
										<TableCell>
											<TextTableBody>{transaction.opType}</TextTableBody>
										</TableCell>
										<TableCell>
											<TextTableBody>{transaction.status}</TextTableBody>
										</TableCell>
										<TableCell>
											<TextTableBody>{transaction.orgName}</TextTableBody>
										</TableCell>
										<TableCell>
											<TextTableBody>{transaction.assetUnitID}</TextTableBody>
										</TableCell>
										<TableCell>
											<TextTableBody>{transaction.period}</TextTableBody>
										</TableCell>
										<TableCell>
											<TextTableBody>{transaction.assetUnitID}</TextTableBody>
										</TableCell>
										<TableCell>
											<TextTableBody>{transaction.amount}</TextTableBody>
										</TableCell>
										<TableCell>
											<ButtonBase
												component="a"
												onClick={async () => handleCreatePDF(transaction)}>
												<img src={PDF}></img>
											</ButtonBase>
										</TableCell>
									</TableRow>
								))}
					</TableBody>
				</TableM>
				{!isQueryLoaded && (
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '100%'
								// paddingTop: '30px'
							}}
						>
							<CircularProgress />
						</Box>
					)}
				{sortedData && sortedData?.length < 1  && isQueryLoaded && (
					<Typography
						sx={{
							display: 'flex',
							paddingTop: '20px',
							width: '100%',
							justifyContent: 'center',
							alignItems: 'center',
							fontSize: '1.75rem',
							fontWeight: '700',
							color: colors.primary
							// fontSize: fonts.size[700],
							// fontFamily: fonts.family.primary,
							// fontWeight: fonts.weight.secondary,
							// color: colors.secondary[400]
						}}
					>
						Empty table!
					</Typography>
				)}
			</TableContainer>
		</Paper>
	);
};
