import { Card, Box, Typography } from '@mui/material';

import { colors } from '../../../styles';
import { formatTextLimit } from '../../../utils/formatTextLenght';

/* eslint-disable prettier/prettier */
interface InfoAssets {
	name: string;
	code: string;
	total_recs: number;
	total_mwh: number;
	logo: string;
}

interface TInfoRec {
	imgRec: string;
	infoAssets: InfoAssets;
	idRec: string;
}
export const InfoRec = ({ imgRec, infoAssets, idRec }: TInfoRec) => {

	return (
		<>
			<Card sx={{ marginBottom: '43px' }}>
				<Box
					overflow="hidden"
					position="relative"
					maxWidth="812px"
					height="140px"
					border={`1px solid ${colors.primaryLight}`}
					borderRadius="16px"
					padding="24px 12px 25px 57px"
					display="flex"
					alignItems="center"
				>
					<Box marginRight="70px">
						<img
							src={infoAssets.logo ? infoAssets.logo : imgRec}
							width="90px"
							style={{ borderRadius: '50%' }}
						></img>
					</Box>
					<Box>
						<Box marginBottom="14px" maxWidth="310px">
							<Typography
								sx={{ wordWrap: 'break-word' }}
								fontWeight="700"
								fontSize="1.5rem"
								lineHeight="32px"
							>
								{formatTextLimit(infoAssets.name, 45)}
							</Typography>
						</Box>
						<Box display="flex" color={colors.neutralDark}>
							<Typography fontWeight="700" lineHeight="32px" marginRight="18px">
								ID
							</Typography>
							<Typography fontWeight="700" lineHeight="32px">
								{idRec}
							</Typography>
						</Box>
					</Box>
					<Box
						position="absolute"
						right="0"
						sx={{
							width: '207px',
							height: '100%',
							backgroundColor: colors.primaryLight,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<Typography fontSize="10px" fontWeight="400">
							TOTAL ENERGY BANK
						</Typography>
						<Typography color={colors.primary} fontSize="2rem" fontWeight="700">
							{(Number(infoAssets.total_mwh)/1000000).toLocaleString('en-US', {
								minimumFractionDigits: 0
							})}
						</Typography>
					</Box>
				</Box>
			</Card>
		</>
	);
};
