import jwt_decode from 'jwt-decode';

import { LoginService } from '../../services/login.service';
import { EnterpriseService } from '../../services/enterprise.service';


const INITIAL_STATE: any[] = [];

interface IPayload {
	email: string;
	password: string;
	token: string;
	first_name: string;
	last_name: string;
}

interface IJwtToken {
	firstName: string;
	lastName: string;
	role: string;
}
interface IAction {
	type: string;
	payload: IPayload;
}

const loginService = new LoginService();
const enterpriseService = new EnterpriseService();


const authLogin = async (email: string, password: string) => {
	return await loginService.signin(email, password);
};

const validateTokenUser = async (tokenUser: string) => {
	try {
		const decodedJwt: IJwtToken = jwt_decode(tokenUser || '');
		if (tokenUser) {
			const name = `${decodedJwt.firstName} ${decodedJwt.lastName}`;
			localStorage.setItem(
				'fullName',
				decodedJwt.firstName + ' ' + decodedJwt.lastName
			);
			const result: any = await enterpriseService.listEnterprise();
			localStorage.setItem('enterprise_id', result.data[0].id);
			return decodedJwt.role;
		}
	} catch (error) {
		return '';
	}
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function (state = INITIAL_STATE, action: IAction) {
	switch (action.type) {
		case 'LOGIN_USER':
			return authLogin(action.payload.email, action.payload.password);
		case 'TOKEN_USER':
			return validateTokenUser(action.payload.token);
		case 'SIGNIN_DEFAULT': {
			return INITIAL_STATE;
		}
		default:
			return state;
	}
}
