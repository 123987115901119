import { Box, Grid, Typography } from '@mui/material';

import { Header } from '../../components/header';
import { InfoBuyer } from '../../components/infoBuyer';
import { SideBar } from '../../components/sideBar';
import { colors } from '../../styles';

export const OperationHistory = () => {
	return (
		<>
			<Header />
			<SideBar />
			<Box
				component="main"
				sx={{
					marginLeft: '234px',
					// p: 3,
					height: '100vh',
					paddingTop: '151px'
				}}
			>
				<Grid container md={12}>
					<Grid item md={12}>
						<Typography
							fontWeight="700"
							fontSize="1.75rem"
							lineHeight="40px"
							color={colors.primary}
							marginBottom="9px"
						>
							Operation History
						</Typography>
					</Grid>
				</Grid>
				<Grid item md={12}>
					<InfoBuyer titleTable="Transaction History" tableTransaction={true} />
				</Grid>
			</Box>
		</>
	);
};
