export function authLogin(email: string, password: string) {
	return {
		type: 'LOGIN_USER',
		payload: { email, password }
	};
}

export function validateToken(token: string) {
	return {
		type: 'TOKEN_USER',
		payload: { token }
	};
}

export function signinDefault() {
	return {
		type: 'SIGNIN_DEFAULT'
	};
}
