import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Skeleton, Typography } from '@mui/material';

import { BoxCounter } from '../../components/boxCounter';
import { EnterpriseInfo } from '../../components/enterpriseInfo';
import { Header } from '../../components/header';
import { SideBar } from '../../components/sideBar';
import { Example } from '../../components/charts/sampleChart';
import { useEffect, useState } from 'react';
import { EnterpriseService } from '../../services/enterprise.service';
import { CircularProgress } from 'material-ui-core';
import leftArrowSymbol from '../../assets/icon/esquerda.png';
import rightArrowSymbol from '../../assets/icon/direita.png';
import logoUsina from '../../assets/imgs/PowerPlantIA.jpg';

import expand from '../../assets/imgs/Expand.png';
import { ExpandedChartContainer } from '../../components/expandedChart/indes';
import { SmallThinChart } from '../../components/charts/thinChartSmall';
import { ThinChartAsset } from '../../components/charts/chartAssets/thinChartAsset';
import { ChartBarAsset } from '../../components/charts/chartAssets/chartBarAsset';
import EnergyBankComponent from '../Dashboard/energyBankComponent';
import PieGraph from '../Dashboard/pieGraph';
import { VerticalBarChart } from '../Dashboard/verticalBarGraph';
import { ClientService } from '../../services/client.service';
import { AssetService } from '../../services/asset.service';
import { IEnergyBankData } from '../../types/dashboard';
import { BackOfficeService } from '../../services/backoffice.service';
import localforage from 'localforage';
import dayjs from 'dayjs';


interface IEnterpriseData {
	name: string;
	cnpj: string;
	total_recs: string;
	total_mwh: string;
	retired_recs: string;
	issued_recs: string;
	logo: string;
}
interface IPowerUnit {
	name: string;
	logo: string;
	cnpj: string;
	code: string;
	cceeCode: string;
	issued_recs: string;
	retired_recs: string;
	total_recs: string;
	total_mwh: string;
}

export const DashboardAsset = () => {
	const history = useNavigate();
	const location = useLocation();
	const { state } = location;
	const { id } = useParams();
	const gotToProfile = () => {history('/rec/assets');}
	const [powerUnit, setPowerUnit] = useState<IPowerUnit>(state.asset);
	const [energyBankData, setEnergyBankData] = useState<any>({});
	const [chartOption, setChartOption] = useState('year');
	const [expandedChart, setExpandedChart] = useState(false);
	const [selectedYear, setSelectedYear] = useState(dayjs().year().toString());
	const [selectedMonth, setSelectedMonth] = useState('Jan');
	const [selectedDay, setSelectedDay] = useState('1');
	const [enterprise, setEnterprise] = useState<IEnterpriseData>();
	const [verticalBarChart, setVerticalBarChart] = useState();
	const [pieData, setPieData] = useState();
	const [innerHeight, setInnerHeight] = useState(850);
	const [getNewEnergyBank, setGetNewEnergyBank] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		async function getLocal() {
			try {
				const valor = await localforage.getItem('assetEnergyBankData');
				setIsLoaded(true)
				if (valor === null) {
					setGetNewEnergyBank(true);
					setEnergyBankData({} as IEnergyBankData);
				} else {
					if (valor) {
						const res = valor as IEnergyBankData;
						const expiredData = dayjs(res.expirationDate)
						if (dayjs().isAfter(expiredData) || true) {
							setGetNewEnergyBank(true);
						}
						setEnergyBankData(valor as IEnergyBankData);
					}
				}
				return valor;
			} catch (error) {
				setGetNewEnergyBank(true);
				return {} as IEnergyBankData;
			}
		}
	
		getLocal();
	}, []);
	
	useEffect(() => {
        function handleResize() {
            setInnerHeight(window.innerHeight * 0.7); // 80% da largura da tela
        }

        handleResize(); // Define o tamanho inicial

        window.addEventListener('resize', handleResize); // Atualiza o tamanho quando a janela for redimensionada

        return () => {
            window.removeEventListener('resize', handleResize); // Remove o event listener quando o componente é desmontado
        }
    }, []);

	let colorYear = '#000000';
	let colorMonth = '#000000';
	let colorHour = '#000000';
	let colorDay = '#000000';

	let backgroundColorYear = '#FFFFFF';
	let backgroundColorMonth = '#FFFFFF';
	let backgroundColorHour = '#FFFFFF';
	let backgroundColorDay = '#FFFFFF';

	let borderColorYear = '#000000';
	let borderColorMonth = '#000000';
	let borderColorHour = '#000000';
	let borderColorDay = '#000000';

	const clearChartStyle = () => {
		colorYear = '#000000';
		colorMonth = '#000000';
		colorHour = '#000000';
		colorDay = '#000000';

		backgroundColorYear = '#FFFFFF';
		backgroundColorMonth = '#FFFFFF';
		backgroundColorHour = '#FFFFFF';
		backgroundColorDay = '#FFFFFF';

		borderColorYear = '#000000';
		borderColorMonth = '#000000';
		borderColorHour = '#000000';
		borderColorDay = '#000000';
	};

	if (chartOption === 'year') {
		clearChartStyle();
		colorYear = '#FFFFFF';

		backgroundColorYear = '#636C7A';

		borderColorYear = '#636C7A';
	}

	if (chartOption === 'month') {
		colorYear = '#000000';
		colorMonth = '#FFFFFF';

		backgroundColorYear = '#FFFFFF';
		backgroundColorMonth = '#636C7A';

		borderColorYear = '#000000';
		borderColorMonth = '#636C7A';
	}

	if (chartOption === 'day') {
		colorDay = '#FFFFFF';

		backgroundColorDay = '#636C7A';

		borderColorDay = '#636C7A';
	}

	if (chartOption === 'hour') {
		colorHour = '#FFFFFF';

		backgroundColorHour = '#636C7A';

		borderColorHour = '#636C7A';
	}

	const getAssetRecs = async () => {
		const assetService = new AssetService();
		const getAllAssets: any = await assetService.listAsset();
		if (getAllAssets.status === 200 && getAllAssets.data.length > 0) {
			const res = getAllAssets.data.map((asset: any) => {
				return {name: asset.name , value: Number(asset.total_mwh)}
			})
			const result : any = [{name: 'Not issued', value: Number(powerUnit?.total_mwh)}, {name:'Issued', value: Number(powerUnit?.issued_recs)},{name: 'Retired', value: Number(powerUnit?.retired_recs)}];
			const sortedData = result.sort((a: any, b: any) => b.value - a.value);
			const totalValue = sortedData.reduce((total: any, obj: any) => total + obj.value, 0);
			const dataWithPercentage = sortedData.map((obj: { name: any; value: number; }) => ({
				name: obj.name,
				value: parseFloat(((obj.value / totalValue) * 100).toFixed(2)),
			  }));
			setPieData(dataWithPercentage);
		}
	};

	const getClients = async () => {
		if(id){
			const assetService = new AssetService();
			const getAllAssets: any = await assetService.getAssetById(id);
			if (getAllAssets.assetRecs.length > 0) {
				const res = getAllAssets.assetRecs.map((assetRec: { rec: { name: any; }; issued_recs: any; })=>{
					return {beneficiary: assetRec.rec.name , total_recs: Number(assetRec.issued_recs)}
				})
				const sortedData = res.sort((a: any, b: any) => b.value - a.value);
				setVerticalBarChart(sortedData);
			}
		}
	};

	useEffect(() => {
		const getEnergyBank = async () =>  {
			const backofficeService = new BackOfficeService();
			if(powerUnit.code){
				const response = await backofficeService.getAssetEnergyBank(powerUnit?.cceeCode);
				if(response.status == 200 && response.data.data.length > 0 ){
					const energyBankData: IEnergyBankData = response.data;
					setEnergyBankData(energyBankData)
					localforage.setItem('assetEnergyBankData', energyBankData)
					.then()
					.catch(error => {
						console.error('Error: ', error);
					});
				}else{
					setEnergyBankData({} as IEnergyBankData)
				}
			}
		};
		if (getNewEnergyBank){
			getEnergyBank();
		}
	}, [getNewEnergyBank]);
	  
	  useEffect(() => {
		getClients();
		getAssetRecs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [powerUnit, id]);

	return (
		<>
			<Header />
			<SideBar />
			<Box
				component="main"
				sx={{
					padding: '8% 24px 0px 200px',
					backgroundColor: '#F5F5F5',
					height: '100vh',
					width: '100%',
				}}
			>
				<Grid container md={12}>
					{powerUnit && (
						<>
							<Grid item md={5}>
								<Grid container md={12}>
									<Grid item md={2}>
										<Box
											sx={{
												height: '96px',
												width: '96px',
												borderRadius: '12.5185px'
											}}
										>
											{powerUnit?.logo ? (
												<img
													src={powerUnit?.logo}
													alt={'usina'}
													style={{
														height: '96px',
														width: '96px',
														borderRadius: '12.5185px'
													}}
												/>
											) : (
												<Box
													sx={{
														height: '96px',
														width: '96px',
														borderRadius: '12.5185px'
													}}
												>
													<img src={logoUsina} width="96px" height="96"></img>
												</Box>
											)}
										</Box>
									</Grid>
									<Grid item md={10}>
										<EnterpriseInfo
											enterprise={powerUnit?.name || ''}
											cnpj={
												powerUnit?.cnpj.replace(
													/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
													'$1.$2.$3/$4-$5'
												) || ''
											}
										/>
									</Grid>
								</Grid>
							</Grid>

							<Grid item md={7}>
								<Grid container md={12} sx={{ justifyContent: 'space-around' }}>
									<Grid item md={4}>
										<BoxCounter
											title="Total Energy Bank"
											value={
												(Number(powerUnit?.total_mwh)/1000000).toLocaleString('en-US', {
													minimumFractionDigits: 0
												}) || '0'
											}
											unit="MWh"
										/>
									</Grid>
									<Grid item md={4}>
										<BoxCounter
											title="Total Issued REC's"
											value={
												Number(powerUnit?.issued_recs).toLocaleString(
													'en-US',
													{
														minimumFractionDigits: 0
													}
												) || '0'
											}
											unit="REC"
										/>
									</Grid>
									<Grid item md={4}>
										<BoxCounter
											title="Total Retired REC's"
											value={
												Number(powerUnit?.retired_recs).toLocaleString(
													'en-US',
													{
														minimumFractionDigits: 0
													}
												) || '0'
											}
											unit="REC"
										/>
									</Grid>
								</Grid>
							</Grid>
						</>
					)}
				</Grid>
				<div style={{ overflow: 'auto'}}>
					<Box display="flex" width={expandedChart ? "170%" : "120%"} height={innerHeight}>
						<Grid md={12} container columnGap={2}>
							<Grid item md={expandedChart ? 6.8 : 5.3}>
								{isLoaded || (powerUnit && energyBankData.data) ? (
									<EnergyBankComponent
										title={`Energy bank of ${powerUnit.name}`}
										energyBankData={energyBankData}
										expandedChart={expandedChart}
										totalMWh={powerUnit.total_mwh}
										selectedYear={selectedYear}
										selectedMonth={selectedMonth}
										selectedDay={selectedDay}
										setExpandedChart={setExpandedChart}
										setSelectedYear={setSelectedYear}
										setSelectedMonth={setSelectedMonth}
										setSelectedDay={setSelectedDay}
									/>
								):
								<Skeleton variant='rectangular' sx={{height: '620px',
									width:'900px',
									marginTop: '5%',
									borderRadius: '44px',
									overflow: 'hidden'}}>
								</Skeleton>
								}
							</Grid>
							<Grid item md={expandedChart ? 2.5 : 3.4}>
								<Box
									sx={{
										height: innerHeight > 600 ? '88%' : '88%',
										marginTop: '8%',
										backgroundColor: '#FFFFFF',
										border: '1.38408px solid #D4D7DE',
										borderRadius: '44.2907px'
									}}
								>
									<Box sx={{ marginTop: '6%', marginLeft: '10%' }}>
										<Typography
											fontFamily="Sen"
											fontStyle="normal"
											fontWeight="700"
											fontSize="33.218px"
											lineHeight="44px"
											color="#6F7075"
										>
											RECs distribution
										</Typography>
									</Box>
									<PieGraph pieData={pieData} />
								</Box>
							</Grid>
							<Grid item md={expandedChart ? 2.5 : 3}>
								<Box
									sx={{
										height: innerHeight > 600 ? '88%' : '88%',
										width: '100%',
										marginTop: '8%',
										backgroundColor: '#FFFFFF',
										border: '1.38408px solid #D4D7DE',
										borderRadius: '44.2907px'
									}}
								>
									<Box sx={{ marginTop: '6%', marginLeft: '10%' }}>
										<Typography
											fontFamily="Sen"
											fontStyle="normal"
											fontWeight="700"
											fontSize="33.218px"
											lineHeight="44px"
											color="#6F7075"
										>
											Best-selling RECs
										</Typography>
									</Box>
									{verticalBarChart &&
										<VerticalBarChart data={verticalBarChart}/>
									}
								</Box>
							</Grid>
						</Grid>
					</Box>
				</div>
			</Box>
		</>
	);
};
