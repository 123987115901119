import { ChangeEvent, useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import { Box, Button, Grid, Typography } from '@mui/material';

import UserProfileLogo from '../../assets/imgs/userDefaultLogo.png';
import { SkeletonLogo } from '../../components/addLogo/skeletonLogo';
import { CardSide } from '../../components/cardSide';
import { Header } from '../../components/header';
import { SideBar } from '../../components/sideBar';
import { UploadLogo } from '../../components/uploadLogo';
import { EnterpriseService } from '../../services/enterprise.service';
import { ProfileService } from '../../services/profile.service';
import { colors } from '../../styles';
import { IListWallet } from '../../types/wallet';
import { InfoProfile } from './infoProfile';
import { SkeletonInfoProfile } from './infoProfile/skeletonInfoProfile';
import { ProfileForm } from './profileForm';

export const Profile = () => {
	const history = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const profileService = new ProfileService();
	const enterpriseService = new EnterpriseService();

	const [loading, setLoading] = useState<boolean>(true);
	const [success, setSuccess] = useState<boolean>(false);
	const [dataUserInfo, setDataUserInfo] = useState<IListWallet>(
		{} as IListWallet
	);
	const [dataUserEditInfo, setDataUserEditInfo] = useState<IListWallet>(
		{} as IListWallet
	);
	const [loadImage, setLoadImage] = useState<boolean>(false);
	const [backInfoProfile, setBackInfoProfile] = useState<boolean>(false);
	const [uploadFile, setUploadFile] = useState<File | null>(null);

	const goToWallet = () => {
		history('/profile/wallet/create');
	};

	const backToProfileForm = () => {
		setDataUserInfo({} as IListWallet);
	};

	const getListUserInfo = async () => {
		setLoading(true);
		const getListUser: any = await profileService.getUserProfile();
		if (getListUser.status === 200) {
			setLoading(false);
			setDataUserInfo(getListUser.data);
			setDataUserEditInfo(getListUser.data);
		}
	};

	useEffect(() => {
		getListUserInfo();
		setBackInfoProfile(false);
		setSuccess(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [success, backInfoProfile]);

	useEffect(() => {
		getListUserInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const saveLogo = async (file: any) => {
		const formData = new FormData();
		formData.append('logo', file);

		setLoadImage(true);
		const saveLogoImage: any = await profileService.updateLogo(formData);

		if (saveLogoImage.status === 200) {
			setLoadImage(false);
			enqueueSnackbar('The logo has been uploaded', {
				variant: 'success'
			});
			return saveLogoImage.data.url;
		} else {
			setLoadImage(false);
			enqueueSnackbar('The logo was not saved on server', {
				variant: 'error'
			});
			return false;
		}
	};

	const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		if (files?.length) {
			const file = files[0];
			const logoSaved = await saveLogo(file);
			localStorage.setItem('user_avatar_url',logoSaved)
			if (logoSaved) {
				setUploadFile(file);
			}
			location.reload();
		}
	};

	return (
		<>
			<Header />
			<SideBar />
			<Box
				component="main"
				sx={{
					marginLeft: '234px',
					height: '100vh',
					paddingTop: '146px'
				}}
			>
				<Grid container md={12}>
					<Grid item md={12}>
						<Box display="flex" alignItems="center">
							<Box marginRight="54px" display={dataUserInfo ? 'block' : 'none'}>
								<Button
									onClick={backToProfileForm}
									variant="outlined"
									sx={{
										width: '107px',
										height: '40px',
										borderRadius: '25px',
										border: '1px solid #000',
										padding: '8px 24px',
										color: '#000',
										'&:hover': {
											border: '1px solid #000'
										}
									}}
								>
									Edit
								</Button>
							</Box>
							<Typography
								fontWeight="700"
								fontSize="1.75rem"
								color={colors.primary}
								lineHeight="47px"
							>
								My information and Wallet
							</Typography>
						</Box>
					</Grid>
				</Grid>

				<Grid
					container
					md={12}
					sm={12}
					display="flex"
					flexWrap="wrap"
					marginTop="72px"
					columnGap={2}
				>
					<Grid item md={1.5} sm={4}>
						{loading ? (
							<SkeletonLogo />
						) : (
							<UploadLogo
								error={false}
								dataUser={dataUserInfo}
								imgDefault={UserProfileLogo}
								id="input-load-image"
								// image={uploadFile ? URL.createObjectURL(uploadFile) : null}
								image={uploadFile ? URL.createObjectURL(uploadFile) : dataUserInfo?.logo ? dataUserInfo?.logo : null}
								name="fileLogo"
								textLabel="Insert Logo"
								onChange={handleImageUpload}
								loadImage={loadImage}
							/>
						)}
					</Grid>
					<Grid item md={4} sm={6} marginLeft="13px">
						{loading ? (
							<SkeletonInfoProfile />
						) : (
							<>
								{dataUserInfo.first_name ? (
									<InfoProfile dataUserProfile={dataUserInfo} />
								) : (
									<ProfileForm
										setSuccess={setSuccess}
										dataUserProfile={dataUserEditInfo}
										setBackInfoProfile={setBackInfoProfile}
									/>
								)}
							</>
						)}
					</Grid>
					<Grid item md={6} sm={4} paddingLeft="161px" marginTop="-20px">
						<CardSide
							title="Wallet"
							subTitle="You currently don´t have any wallet."
							footer="Create your first wallet to get started"
							goToWallet={goToWallet}
							// dataUserProfile={dataUserEditInfo}
							dataWalletList={dataUserEditInfo}
						/>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};
