/* eslint-disable import/extensions */
import { Api } from './api/axios-config';
import { GenericExtrinsic } from '@polkadot/types';
import { AnyTuple } from '@polkadot/types-codec/types';

interface IResCert {
	status: number;
	statusText: string;
	data: Array<object>;
}

interface ICertificateService {
	enterprise_id: string;
	asset_id: string;
	rec_id: string;
	operation_type: string;
	start_date: Date;
	end_date: Date;
	origins: { origin_id: string; amount: number }[];
	from: string;
	rec_hash: string;
	holder_wallet: string;
	to: string;
	extrinsic?: GenericExtrinsic<AnyTuple>;
}

export class CertificatesService {
	async getAllCertificates() {
		try {
			const respAllCertificate: any = await Api.get(`/certificates`);
			return respAllCertificate;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async getAllAssetsCertificates(page: number, size: number) {
		try {
			const respAllCertificate: any = await Api.get(`/certificates/assets?page=${page}&size=${size}`);
			return respAllCertificate;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async checkCertificate(id: string) {
		try {
			const respAllCertificate: any = await Api.get(`/certificates/valid/${id}`);
			return respAllCertificate;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async getMyCertificates(page: number, size: number) {
		try {
			const respAllCertificate: any = await Api.get(`/certificates/my?page=${page}&size=${size}`);
			return respAllCertificate;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async getAllSeller() {
		try {
			const respAllSeller: any = await Api.get(`/users`);
			return respAllSeller;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async createTransferRec(certificate: ICertificateService) {
		try {
			const respCreateCert: IResCert = await Api.post(
				`/transactions/transfers`,
				certificate
			);
			return respCreateCert;
		} catch (error: any) {
			// TODO
			// const { message } = error.response.data;
			console.log(error)
			if(error.response.data.message){
				return { status: error.response.status, statusText: error.response.data.message };
			}
			return { status: 400, statusText: 'Transference failed' };
		}
	}
}
