import axios from 'axios';
const TOKEN_ACESS = localStorage.getItem('token');

const Api = axios.create({
	baseURL: `${process.env.REACT_APP_API_ADDRESS}`,
	headers: {
		Authorization: `Bearer ${TOKEN_ACESS}`
	}
});

const BackofficeApi = axios.create({
	baseURL: `${process.env.REACT_APP_BACKOFFICE_URL}`,
	headers: {
		Authorization: `Bearer ${process.env.REACT_APP_BACKOFFICE_API_KEY}`
	}
});

export { Api, BackofficeApi };
