import axios from 'axios';

import { Api } from './api/axios-config';
import { GenericExtrinsic } from '@polkadot/types';
import { AnyTuple } from '@polkadot/types-codec/types';

export type IAsset = {
	name: string;
	enterprise_id: string;
	country: string;
	state: string;
	city: string;
	address: string;
	type: string;
	code: string;
	cceeCode: string;
	co2_per_mwh: string,
	installed_capacity: string,
	start_of_operation: string,
	certified_by: string;
	cnpj: string;
	id?: string;
	extrinsic?: GenericExtrinsic<AnyTuple>,
};

export class AssetService {
	async createAsset(asset: IAsset) {
		try {
			const respCreateAsset = await Api.post(`/assets`, asset);
			return respCreateAsset;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async listAsset() {
		try {
			const respListAsset = await Api.get(`/assets`);
			return respListAsset;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async getAssetById(asset_id: string) {
		try {
			const respGetAsset = await Api.get(`/assets/${asset_id}`);
			return respGetAsset.data;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async updateLogoById(asset_id: string, logo: any) {
		try {
			const respUpdateLogo = await Api.patch(`/assets/${asset_id}/logo`, logo);
			return respUpdateLogo;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async updateTotalMWh(cceeCode: string, totalMWh: number) {
		try { 
			const respUpdateTotalMWh = await Api.patch(`/assets/totalMWh`, {cceeCode, totalMWh});
			return respUpdateTotalMWh;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async updateCertificateStatus(cceeCode: string, status: boolean) {
		try { 
			const respAssetCertificateStatus = await Api.patch(`/assets/certificate-status`, {cceeCode, status});
			return respAssetCertificateStatus;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async addLogo(name: any) {
		try {
			const respAddLogo = await Api.patch('/assets/logo', name);
			return respAddLogo;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async updateAssetById(asset: IAsset, asset_id: string) {
		try {
			const respUpdateAsset = await Api.put(`/assets/${asset_id}`, asset);
			return respUpdateAsset;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async createWallet(wallet_name: string, wallet_address: string, asset_id: string) {
		try {
			const resWallet = await Api.patch(`/assets/${asset_id}/wallet`, {
				wallet_address,
				wallet_name
			});
			return resWallet;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}
}
