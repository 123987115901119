// Core
import React, { FC } from 'react';

import { Box, TableCell as TableCellM, TableCellProps } from '@mui/material';
// import { Box } from '@mui/system';

// Assets

export const TableCell: FC<TableCellProps> = ({ sx, children, ...props }) => {
	return (
		<TableCellM sx={{ ...sx }} {...props}>
			<Box sx={{ width: '100px' }}>{children}</Box>
		</TableCellM>
	);
};
