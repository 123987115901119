import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import { Box, Button, Grid, Typography } from '@mui/material';

import PowerPlantImage from '../../assets/imgs/PowerPlantIA.jpg';
import { Header } from '../../components/header';
import { SideBar } from '../../components/sideBar';
import { RecTokenService } from '../../services/rec.service';
import { getRecAsset } from '../../stores/assetRecs/assetRecs.actions';
import { listRecTokenAsset } from '../../stores/recTokenAsset/recTokenAsset.actions';
import { colors } from '../../styles';
import { InfoRec } from './infoRec';
import { RecList } from './recList';
import { RecManager } from './recManager';
import { RecTokenForm } from './rectTokeForm';

// const id = 'a9e695f5-1484-491a-be60-f5e92849eddf';

export type TInfoAssets = {
	name: string;
	code: string;
	total_recs: number;
	total_mwh: number;
	assetRecs?: any;
	logo: string;
};

/* eslint-disable prettier/prettier */
export const RecToken = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();

	const recTokenService = new RecTokenService();

	const [viewForm, setViewForm] = useState<boolean>(false);
	const [successInsert, setSuccessInsert] = useState<boolean>(false);
	const [reloadRecList, setReloadRecList] = useState<boolean>(false);
	
	const [idRec, setIdrec] = useState<string>(id || '');

	const [infoAssets, setInfoAssets] = useState<TInfoAssets>({
		name: 'Power Unit',
		code: 'XX',
		total_recs: 0,
		total_mwh: 0,
		assetRecs: [],
		logo: PowerPlantImage
	});

	const getInfoAssetView = async () => {
		if (id){
			const respGetInfoAsset: any = await recTokenService.infoViewRecToken(
				id
			);
			if (respGetInfoAsset.status === 200) {
				setInfoAssets(respGetInfoAsset.data);
			}
		}
	};

	useEffect(() => {
		getInfoAssetView();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (successInsert) {
			getInfoAssetView();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [successInsert]);

	useEffect(() => {
		dispatch(getRecAsset(id || ''));
		dispatch(listRecTokenAsset());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Header />
			<SideBar />
			<Box
				component="main"
				sx={{
					marginLeft: '229px',
					height: '100vh',
					paddingTop: '146px'
				}}
			>
				<Grid container md={12}>
					<Grid item md={6}>
						<Typography
							fontWeight="700"
							fontSize="1.75rem"
							lineHeight="40px"
							color={colors.primary}
							marginBottom="9px"
						>
							Info view
						</Typography>
						<InfoRec imgRec={PowerPlantImage} infoAssets={infoAssets} idRec={idRec} />

						<Box display="flex" alignItems="center" marginBottom="36px">
							<Button
								variant="outlined"
								onClick={() => navigate(-1)}
								sx={{
									marginRight: '35px',
									borderColor: '#000',
									borderRadius: '25px',
									'&:hover': {
										borderColor: '#000'
									}
								}}
							>
								<Typography color="#000">Back</Typography>
							</Button>

							<Typography
								fontSize="28px"
								fontWeight="700"
								color={colors.primary}
							>
								REC MANAGER
							</Typography>
						</Box>
						{viewForm ? (
							<RecTokenForm setViewForm={setViewForm} />
						) : (
							
							<RecManager
								setViewForm={setViewForm}
								asseId={id || ''}
								setSuccessInsert={setSuccessInsert}
								setReloadRecList={setReloadRecList}
							/>
						)}
					</Grid>
					<Grid></Grid>
					<Grid item md={4} paddingLeft="101px">
						<Typography
							fontWeight="700"
							fontSize="1.75rem"
							lineHeight="40px"
							color={colors.primary}
							marginBottom="27px"
						>
							Rec List
						</Typography>
						{infoAssets.assetRecs.length > 0 && <RecList reloadRecList={reloadRecList} />}
					</Grid>
				</Grid>
			</Box>
		</>
	);
};
