export const colors = {
	primary: '#636C7A',
	success: '#00CA95',
	primaryLight: '#CAD5E8',
	primaryDark: '#3A4049',
	neutralLight: '#FFFFFF',
	neutralMedium: '#D4D7DE',
	neutralDark: '#6F7075',
	neutralBlack: '#000',
	supportDark: '#F98600',
	supportDark2: '#B86300'
};
