export const menuOwner = [
	{
		id: 1,
		title: 'Dashboard',
		link: '/rec/dashboard'
	},
	{
		id: 2,
		title: 'Config',
		link: '/rec/config'
	},
	{
		id: 3,
		title: 'Certificate',
		link: '/rec/certificates'
	},
	{
		id: 4,
		title: 'Projects',
		link: '/rec/assets'
	},
	{
		id: 5,
		title: 'Orders',
		link: '/rec/sales-history'
	},
	{
		id: 6,
		title: 'Buyers',
		link: '/rec/clients'
	}
];
