// Core
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ClientService } from '../../services/client.service';
import { ProfileService } from '../../services/profile.service';
import { format } from 'date-fns';
import { Avatar, Box, Button, Grid, Skeleton, Tooltip, Typography, } from '@mui/material';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';

import { goToTransactionBuyer } from '../../stores/goToBuyerTransaction/goToBuyerTransaction.actions';
import { colors } from '../../styles';
import { IData, ITransaction } from '../../types/buyerTransaction';
import { HeadCell } from '../../types/headCell';
import { BoxInfo } from './boxInfo';
import { CardInfo } from './cardInfo';
import { Table } from './table';
import { TextInfo } from './textInfo';
import { TitleInfo } from './titleInfo';
import ImageDefaultClient from '../../assets/imgs/CompanyImage2.jpg';
import { UploadLogo } from '../uploadLogo';
import { useSnackbar } from 'notistack';
import { ICertificateOrg, IOrigins } from '../../types/certificateOrg';
import dayjs from 'dayjs';
import { AiOutlineSafetyCertificate } from 'react-icons/ai';


const headCells: HeadCell[] = [
	{
		id: 'date',
		numeric: true,
		disablePadding: false,
		label: 'Date UTC-3'
	},
	{
		id: 'assetName',
		numeric: true,
		disablePadding: false,
		label: 'Org Name'
	},
	{
		id: 'opType',
		numeric: true,
		disablePadding: false,
		label: 'Op. Type'
	},
	{
		id: 'status',
		numeric: true,
		disablePadding: false,
		label: 'Status'
	},
	{
		id: 'assetSourceType',
		numeric: true,
		disablePadding: false,
		label: 'Source Type'
	},
	{
		id: 'assetUnitID',
		numeric: true,
		disablePadding: false,
		label: 'Unit ID'
	},
	{
		id: 'assetState',
		numeric: true,
		disablePadding: false,
		label: 'Location'
	},
	{
		id: 'period',
		numeric: true,
		disablePadding: false,
		label: 'Period'
	},
	{
		id: 'uniqueId',
		numeric: true,
		disablePadding: false,
		label: 'Unique ID'
	},
	{
		id: 'amount',
		numeric: true,
		disablePadding: false,
		label: 'Amount'
	},
	{
		id: 'report',
		numeric: true,
		disablePadding: false,
		label: 'Report'
	}
];

const headCells2: HeadCell[] = [
	{
		id: 'checkbox',
		numeric: true,
		disablePadding: false,
		label: ''
	},
	{
		id: 'date',
		numeric: true,
		disablePadding: false,
		label: 'Date UTC-3'
	},
	{
		id: 'assetName',
		numeric: true,
		disablePadding: false,
		label: 'Org Name'
	},
	{
		id: 'opType',
		numeric: true,
		disablePadding: false,
		label: 'Op. Type'
	},
	{
		id: 'status',
		numeric: true,
		disablePadding: false,
		label: 'Status'
	},
	{
		id: 'recLabel',
		numeric: true,
		disablePadding: false,
		label: 'Label'
	},
	{
		id: 'assetSourceType',
		numeric: true,
		disablePadding: false,
		label: 'Source Type'
	},
	{
		id: 'assetUnitID',
		numeric: true,
		disablePadding: false,
		label: 'Unit ID'
	},
	{
		id: 'assetState',
		numeric: true,
		disablePadding: false,
		label: 'Location'
	},
	{
		id: 'period',
		numeric: true,
		disablePadding: false,
		label: 'Period'
	},
	{
		id: 'assetUnitID',
		numeric: true,
		disablePadding: false,
		label: 'Unique ID'
	},
	{
		id: 'amount',
		numeric: true,
		disablePadding: false,
		label: 'Amount'
	},
	{
		id: 'report',
		numeric: true,
		disablePadding: false,
		label: 'Report'
	}
];

interface IIdbuyer {
	status: boolean;
	id: string;
}
interface IInfoBuyer {
	titleTable: string;
	tableTransaction: boolean;
}
interface IClient {
	beneficiary: string;
	id: string;
	cpfCnpj: string;
	phone: string;
	zipcode: string;
	city: string;
	address: string;
	number: string;
	state: string;
	created_at: string;
	registrar_id: string;
	email: string;
	country: string;
	available_recs: string;
	retired_recs: string;
	total_certificates: string;
	total_recs: string;
	wallet_address: string;
	logo: string;
	is_activate: boolean;
}

interface ITransactionRequest {
	holder_name: any;
	provider_name: any;
	id: string;
	user_id: string;
	enterprise_id: string;
	asset_id: string;
	from: string;
	to: string;
	amount: string;
	type: string;
	asset_name: string;
	asset_country: string;
	asset_state: string;
	asset_city: string;
	asset_source: string;
	asset_unit_id: string;
	rec_label: string;
	rec_id: string;
	start_date: string;
	end_date: string;
	status: string;
	hash: string;
	block: string;
	certificate_id: string;
	report: string;
	created_at: string;
	updated_at: string;
}

export const InfoBuyer: FC<IInfoBuyer> = ({ titleTable, tableTransaction }) => {
	const clientService = new ClientService();
	const profileService = new ProfileService();
	const dispatch = useDispatch();
	const idBuyer: IIdbuyer = useSelector(
		(state: any) => state.goToBuyerTransaction
	);
	const [uploadFile, setUploadFile] = useState<File | null>(null);
	const [loadImage, setLoadImage] = useState<boolean>(false);
	const [client, setClient] = useState<IClient>({} as IClient);
	const [user, setUser] = useState<any>();
	const [selectedPage, setSelectedPage] = useState<number>(1);
	const [selectedSize, setSelectedSize] = useState<number>(5);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [totalCertificates, setTotalCertificates] = useState<number>(0);

	const [transactions, setTransactions] = useState<ITransaction[]>([]);
	const [certificatesLeght, setCertificatesLenght] = useState<number>(0);
	const [isLoaded, setIsLoaded] = useState(false);
	const [gotTransactions, setGotTransactions] = useState<ICertificateOrg[] | null>();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const history = useNavigate();

	const saveLogo = async (file: any) => {
		const formData = new FormData();
		formData.append('logo', file);
		setLoadImage(true);
		if(client.id){
			const saveLogoImage = await clientService.updateLogoById(client.id,formData);
			if (saveLogoImage.status === 200) {
				setLoadImage(false);
				enqueueSnackbar('The logo has been uploaded', {
					variant: 'success'
				});
				return true;
			} else {
				setLoadImage(false);
				enqueueSnackbar('The logo was not saved on server', {
					variant: 'error'
				});
				return false;
			}
		}
	};

	const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		if (files?.length) {
			const file = files[0];
			const logoSaved = await saveLogo(file);

			if (logoSaved) {
				setUploadFile(file);
			}
		}
	};

	useEffect(()=>{
		const getUser = async () => {
			const response: any = await profileService.getUserProfile()
			setUser(response.data)
		}
		getUser()
	},[])

	useEffect(()=>{
		const getClients = async () => {
			const response: any = await clientService.listClients()
			if(user){
				const respSubmitEnt = response.data.find((client: { email: any; }) => {
					return client.email === user?.email;
				});
				// setUser(response.data)
				if(respSubmitEnt){
					setClient({
						beneficiary: respSubmitEnt.beneficiary,
						country: respSubmitEnt.country,
						email: respSubmitEnt.email,
						id: respSubmitEnt.id,
						created_at: respSubmitEnt.created_at,
						registrar_id: respSubmitEnt.registrar_id,
						state: respSubmitEnt.state,
						total_certificates: respSubmitEnt.total_certificates,
						available_recs: respSubmitEnt.available_recs,
						retired_recs: respSubmitEnt.retired_recs,
						total_recs: respSubmitEnt.total_recs,
						address: respSubmitEnt.address,
						cpfCnpj: respSubmitEnt.cpfCnpj,
						number: respSubmitEnt.number,
						phone: respSubmitEnt.phone,
						city: respSubmitEnt.city,
						zipcode: respSubmitEnt.zipcode,
						wallet_address: respSubmitEnt.wallet_address,
						logo: respSubmitEnt.logo,
						is_activate: respSubmitEnt.is_activate
					});
				}
			}
		}
		getClients()
	},[user])

	useEffect(() => {
		const getClient = async () => {
			if (idBuyer.id) {
				const respSubmitEnt: IClient = await clientService.listClientById(
					idBuyer.id
				);
				setClient({
					beneficiary: respSubmitEnt.beneficiary,
					country: respSubmitEnt.country,
					email: respSubmitEnt.email,
					id: respSubmitEnt.id,
					created_at: respSubmitEnt.created_at,
					registrar_id: respSubmitEnt.registrar_id,
					state: respSubmitEnt.state,
					total_certificates: respSubmitEnt.total_certificates,
					available_recs: respSubmitEnt.available_recs,
					retired_recs: respSubmitEnt.retired_recs,
					total_recs: respSubmitEnt.total_recs,
					address: respSubmitEnt.address,
					cpfCnpj: respSubmitEnt.cpfCnpj,
					number: respSubmitEnt.number,
					phone: respSubmitEnt.phone,
					city: respSubmitEnt.city,
					zipcode: respSubmitEnt.zipcode,
					wallet_address: respSubmitEnt.wallet_address,
					logo: respSubmitEnt.logo,
					is_activate: respSubmitEnt.is_activate
				});
			}
		};
		getClient();
	}, [idBuyer]);

	useEffect(() => {
		const clientService = new ClientService();

		const getTransactions = async () => {
			const respSubmitEnt: any = await clientService.listClientCertificates(client.id, selectedPage, selectedSize);
			setTotalPages(Math.ceil(respSubmitEnt.data[1]/selectedSize))
			setTotalCertificates(respSubmitEnt.data[1])

			const respAllCertFormated: ICertificateOrg[] = respSubmitEnt.data[0].map(
				(item: any) => ({
					id: item.id,
					beneficiaryId: item.to,
					isCheckbox: true,
					statusCheck: false,
					enterpriseId: item.enterprise_id,
					assetId: item.asset_id,
					assetSourceType: item.asset_source,
					recId: item.rec_id,
					assetName: item.asset_name,
					date: format(new Date(item.created_at), 'yyyy-MM-dd HH:mm'),
					holder: item.provider_name,
					beneficiary: item.holder_name,
					beneficiaryWallet: item.holder_wallet,
					from: item.from,
					to: item.to,
					status: item.rec_status,
					opType: item.operation_type,
					orgName: item.enterprise_name,
					powerUnit: item.asset_name,
					assetCountry: item.asset_country,
					assetState: item.asset_state,
					assetCity: item.asset_city,
					co2PerMWh: item.asset_co2_per_mwh,
					startOfOperation: item.asset_start_of_operation,
					certificate_id_range: item.certificate_id_range,
					period: format(new Date(item.start_date), 'yyyy-MMM'),
					startDate: new Date(item.start_date),
					endDate: new Date(item.end_date),
					assetUnitID: item.asset_unit_id,
					amountRec: Number(item.amount),
					recLabel: item.rec_label,
					recHash: item.rec_hash,
					blockHash: item.block_hash,
					amount: Number(item.amount),
					originCertificates: item.origin_certificates
				})
			);

			setGotTransactions(respAllCertFormated);
			setIsLoaded(true);
			setCertificatesLenght(respAllCertFormated.length);
		};

		getTransactions();
	}, [idBuyer, client, selectedPage]);

	const handleEditUser = () => {
		dispatch(goToTransactionBuyer(false, idBuyer.id));
		history(`/rec/clients/${idBuyer.id}/edit/`);
		// localStorage.setItem('idBuyer', idBuyer.id);
	};

	return (
		<Grid container item xs={12} spacing={'20px'}>
			<Grid item container xs={12}>
				<Grid
					item
					xs={12}
					sx={{
						display: 'flex',
						marginRight: '70px',
						justifyContent: 'space-between',
						flexWrap: 'wrap',
						gap: '10px',
						padding: '10px 20px',
						border: `1px solid ${colors.primaryLight}`,
						borderRadius: '16px',
						'@media (max-width: 1710px)': {
							justifyContent: 'center'
						}
					}}
				>
					{isLoaded && client ?
						<>
							<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								flexWrap: 'wrap',
								gap: '34px'
							}}
						>
							<UploadLogo
								error={false}
								dataUser={[]}
								id="input-load-image"
								image={uploadFile ? URL.createObjectURL(uploadFile) : client?.logo ? client?.logo : ImageDefaultClient}
								name="fileLogo"
								textLabel="Insert Logo"
								onChange={handleImageUpload}
								loadImage={loadImage}
							/>
							<Box>
								<Box display={'flex'}>
									<Tooltip placement="top-start" title={client.beneficiary}>
										<Typography
											sx={{
												maxWidth: '400px',
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
												marginBottom: '20px',
												marginRight: '20px',
												fontSize: '2rem',
												textAlign: 'center',
												fontWeight: '700',
												color: colors.primary
											}}
										>
											{client.beneficiary}
										</Typography>
									</Tooltip>
								</Box>
								<BoxInfo>
									<TitleInfo>ID</TitleInfo>
									<TextInfo>{client.id}</TextInfo>
								</BoxInfo>
								<BoxInfo>
									<TitleInfo>STATE:</TitleInfo>
									<TextInfo>{client.state}</TextInfo>
								</BoxInfo>
								<BoxInfo>
									<TitleInfo>COUNTRY:</TitleInfo>
									<TextInfo>{client.country}</TextInfo>
								</BoxInfo>
								<BoxInfo>
									<TitleInfo>REG DATE:</TitleInfo>
									<TextInfo>{dayjs(client.created_at).format('YYYY-MM-DD')}</TextInfo>
								</BoxInfo>
								<BoxInfo>
									<TitleInfo>CREATED BY:</TitleInfo>
									<TextInfo>{client.email}</TextInfo>
								</BoxInfo>
								<BoxInfo>
									<TitleInfo>ENABLED:</TitleInfo>
									<TextInfo>
										<Tooltip placement="top-start" title={client.is_activate ? 'Enabled' : 	'Disabled'}>
											<Box>
												<AiOutlineSafetyCertificate color={client.is_activate ? '#00CA95' :'gray' } size={30}/>
											</Box>
										</Tooltip>
									</TextInfo>
								</BoxInfo>
							</Box>
							{!tableTransaction && (
								<Box
									sx={{
										alignSelf: 'flex-end'
									}}
								>
								</Box>
							)}
							</Box>
							<Box
								sx={{
									display: 'flex',
									gap: '10px',
									flexDirection: 'column'
								}}
							>
								<Box
									sx={{
										display: 'flex',
										gap: '10px',
										justifyContent: 'flex-end',
										flexWrap: 'wrap'
									}}
								>
									<CardInfo
										number={client.total_recs}
										title={`Total REC's`}
										unit={'REC'}
									/>
									<CardInfo
										number={isLoaded ? totalCertificates: 0}
										title={'Total Certificates'}
										unit={'Certs'}
									/>
								</Box>
								<Box
									sx={{
										display: 'flex',
										gap: '10px',
										flexWrap: 'wrap',
										justifyContent: 'flex-end'
									}}
								>
									<CardInfo
										number={client.retired_recs}
										title={`Total Retired REC's`}
										unit={'REC'}
									/>
								</Box>
							</Box>
						</>
						:
						<>
							<Box display={'flex'}>
								<Skeleton animation="wave" variant="rectangular" width={200} height={200} sx={{margin: '10px'}}/>
								<Box>
									<Skeleton animation="wave" variant="rectangular" width={360} height={50} sx={{marginTop: '10px'}}/>
									<Skeleton animation="wave" variant="rectangular" width={360} height={20} sx={{marginTop: '35px'}}/>
									<Skeleton animation="wave" variant="rectangular" width={360} height={20} sx={{marginTop: '10px'}}/>
									<Skeleton animation="wave" variant="rectangular" width={360} height={20} sx={{marginTop: '10px'}}/>
									<Skeleton animation="wave" variant="rectangular" width={360} height={20} sx={{marginTop: '10px'}}/>
								</Box>
							</Box>
							<Skeleton animation="wave" variant="rectangular" width="30%" height={200} sx={{margin: '10px'}} />
						</>
					}
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Table
					sx={{
						width: '96%',
						border: `1px solid #CAD5E8`,
						borderRadius: '16px',
						overflow: 'hidden'
					}}
					title={titleTable}
					setSelectedPage={setSelectedPage}
					setSelectedSize={setSelectedSize}
					totalPages={totalPages}
					isClientActivate={client.is_activate}
					tableTransaction={tableTransaction}
					isLoaded={isLoaded}
					titles={tableTransaction ? headCells2 : headCells}
					transactions={gotTransactions ? gotTransactions : null}
				/>
			</Grid>
		</Grid>
	);
};
