import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import jwt_decode from 'jwt-decode';

import { Box, Button, Typography } from '@mui/material';

import Template from '../../../assets/imgs/template1.png';
import { validateToken } from '../../../stores/authLogin/authLogin.actions';
import { colors } from '../../../styles';
import { useEffect } from 'react';

const VALIDATE_TOKEN_EMPTY = '';
interface IJwtToken {
	role: string;
}

export const ContentHome = () => {
	const tokenUser = localStorage.getItem('token');
	const history = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		if (tokenUser) {
			dispatch(validateToken(tokenUser));
		}
	}, [tokenUser]);

	const goToTemplate = () => {
		const decodedJwt: IJwtToken = jwt_decode(tokenUser || '');
		if (tokenUser && decodedJwt) {
			if (decodedJwt.role === 'owner') {
				history('rec/dashboard');
				localStorage.setItem('menuClick', '1');
			} else if (decodedJwt.role === 'cnpj') {
				history('/rec/operation-history');
				localStorage.setItem('menuClick', '1');
			} else if (decodedJwt.role === 'cpf') {
				history('/rec/operation-history');
				localStorage.setItem('menuClick', '1');
			} else {
				history('/rec/seller-certificates');
				localStorage.setItem('menuClick', '1');
			}
		}else{
			history('/signin');
		}
	};

	const handleKeyPress = (event: { key: string; }) => {
		if (event.key === 'Enter') {
			goToTemplate();
		}
	};
	
	useEffect(() => {
		// Add event listener when component mounts
		document.addEventListener('keydown', handleKeyPress);
	
		// Remove event listener when component unmounts
		return () => {
		  document.removeEventListener('keydown', handleKeyPress);
		};
	}, []);

	return (
		<>
			<Box sx={{ marginRight: '52px' }}>
				<img src={Template} alt="template" width="100%"></img>
			</Box>

			<Box
				sx={{
					alignSelf: 'flex-start',
					marginTop: '85px'
				}}
			>
				<Typography
					fontWeight="700"
					fontSize="2.3rem"
					lineHeight="48px"
					color={colors.primary}
					marginBottom="70px"
				>
					REC BUSINESS TEMPLATE
				</Typography>
				<Box width="563px">
					<Typography
						fontWeight="700"
						fontSize="1.78rem"
						lineHeight="40px"
						color={colors.primary}
					>
						Renewable Energy Certificates <br />
						<br />
						Running this template, you can manage your REC’s operations using a
						end to end blockchain environment.
					</Typography>
					<Button
						onClick={goToTemplate}
						sx={{
							marginTop: '70px',
							width: '161px',
							backgroundColor: colors.primary,
							borderRadius: '25px',
							fontSize: '1.5rem',
							fontWeight: '700',
							lineHeight: '32px',
							'&:hover': {
								backgroundColor: colors.primary
							}
						}}
						variant="contained"
					>
						Enter
					</Button>
				</Box>
			</Box>
		</>
	);
};
