/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { createWalletAsset } from '../../../stores/nextStepAseet/nextStepAsset.actions';

import {
	Box,
	Card,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	TextField,
	Typography
} from '@mui/material';
import { MnemonicSelectedAsset } from '../mnemonictSelectedAsset';

// import { MnemonicSelected } from '../mnemonicSelected';

/* eslint-disable prettier/prettier */
export const NewWalletAsset = (props: any) => {
	const dispatch = useDispatch();
	const form = useForm<any>();
	const [nameWallet, setNameWallet] = useState<string>('');
	const [passwordInput, setPasswordInput] = useState<string>('');
	const [confirmPasswordInput, setConfirmPasswordInput] = useState<string>('');
	const [validatePassword, setValidatePassword] = useState<boolean>(false);

	const [words, setWords] = useState<Array<any>>([]);
	const [walletAddress, setWalletAddress] = useState<string>('');

	useEffect(()=>{
		setWords(props.mnemonic.split(' '));
		setWalletAddress(props.walletAddress)
	},[])
	

	const setNewNameWallet = (e: any) => {
		setNameWallet(e.target.value);
		// localStorage.setItem('nameWallet', e.target.value);
		form.setValue('wallet', e.target.value);
	};

	const inputPassword = (e: any) => {
		setPasswordInput(e.target.value);
		form.setValue('password', e.target.value);
	};

	const inputConfirmationPassword = (e: any) => {
		setConfirmPasswordInput(e.target.value);
		form.setValue('confirm', e.target.value);
	};

	useEffect(() => {
		dispatch(createWalletAsset(true));
		return () => {
			dispatch(createWalletAsset(false));
		}
	}, [])

	return (
		<>
			<form onSubmit={() => {}}>
				<Grid container md={12} marginLeft="102px">
					<Grid item md={3.5} marginTop="56px" marginRight="177px">
						<Box>
							<Controller
								control={form.control}
								name="wallet"
								render={({ field }) => (
									<FormControl variant="standard" fullWidth>
										<InputLabel
											sx={{
												marginTop: '-30px',
												color: '#636C7A',
												fontSize: '1.25rem',
												fontWeight: '700',
												lineHeight: '32px'
											}}
											error={false}
											shrink
											htmlFor="wallet"
										>
											YOUR NEW WALLET
										</InputLabel>
										<TextField
											autoFocus
											sx={{
												'& .MuiInputBase-input': {
													height: '44px',
													padding: '0',
													paddingLeft: '16px'
												},
												'& .MuiOutlinedInput-notchedOutline': {
													borderRadius: '4px !important',
													border: '1px solid #CAD5E8 !important'
												}
											}}
											id="outlined-basic"
											variant="outlined"
											label=""
											error={false}
											{...field}
											onChange={e => setNewNameWallet(e)}
										/>
										<Box marginLeft="16px" marginBottom="60px">
											<Typography color="#636C7A">
												A descriptive name for your wallet
											</Typography>
										</Box>
									</FormControl>
								)}
							/>
						</Box>
						<Box>
							<Controller
								control={form.control}
								name="password"
								render={({ field }) => (
									<FormControl variant="standard" fullWidth>
										<InputLabel
											sx={{
												marginTop: '-30px',
												color: '#636C7A',
												fontSize: '1.25rem',
												fontWeight: '700',
												lineHeight: '32px'
											}}
											error={false}
											shrink
											htmlFor="password"
										>
											PASSWORD
										</InputLabel>
										<TextField
											type="password"
											sx={{
												'& .MuiInputBase-input': {
													height: '44px',
													padding: '0',
													paddingLeft: '16px'
												},
												'& .MuiOutlinedInput-notchedOutline': {
													borderRadius: '4px !important',
													border: '1px solid #CAD5E8'
												}
											}}
											id="outlined-basic"
											variant="outlined"
											label=""
											error={validatePassword}
											{...field}
											onChange={e => inputPassword(e)}
										/>
										<Box marginLeft="16px" marginBottom="31px">
											<Typography color="#636C7A">
												A new password for this account
											</Typography>
										</Box>
									</FormControl>
								)}
							/>
						</Box>
						<Box>
							<Controller
								control={form.control}
								name="confirm"
								render={({ field }) => (
									<FormControl variant="standard" fullWidth>
										<TextField
											type="password"
											sx={{
												'& .MuiInputBase-input': {
													height: '44px',
													padding: '0',
													paddingLeft: '16px'
												},
												'& .MuiOutlinedInput-notchedOutline': {
													borderRadius: '4px !important',
													border: '1px solid #CAD5E8'
												}
											}}
											id="outlined-basic"
											variant="outlined"
											label=""
											error={validatePassword}
											{...field}
											onChange={e => inputConfirmationPassword(e)}
										/>
										<Box marginLeft="16px">
											<Typography color="#636C7A">
												Repeat password for verification
											</Typography>
										</Box>
                    {validatePassword && (
											<FormHelperText error>
												<Typography>the passwords do not match</Typography>
											</FormHelperText>
										)}
									</FormControl>
								)}
							/>
						</Box>
					</Grid>
					<Grid item md={6} alignSelf="flex-start" marginTop="25px">
						<MnemonicSelectedAsset
							words={words}
							walletAddress={walletAddress}
							nameWallet={nameWallet}
							passwordInput={passwordInput}
							confirmPasswordInput={confirmPasswordInput}
							setValidatePassword={setValidatePassword}
						/>
					</Grid>
				</Grid>
			</form>
		</>
	);
};
