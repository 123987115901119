import { Box, Grid, Skeleton, Typography } from '@mui/material';
import React, { PureComponent, RefObject, useEffect, useState } from 'react';

import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	Cell
} from 'recharts';
import { IEnergyBankData } from '../../../types/dashboard';


const data = [
	{
		name: '1',
		pv: 2400
	},
	{
		name: '2',
		uv: 3000,
		pv: 3500,
		amt: 2210
	},
	{
		name: '3',
		uv: 2000,
		pv: 4600,
		amt: 2290
	},
	{
		name: '4',
		uv: 2780,
		pv: 5700,
		amt: 2000
	},
	{
		name: '5',
		uv: 1890,
		pv: 6800,
		amt: 'full'
	},
	{
		name: '6',
		uv: 2390,
		pv: 7900,
		amt: 'full'
	},
	{
		name: '7',
		uv: 3490,
		pv: 8000,
		amt: 2100
	},
	{
		name: '8',
		uv: 3490,
		pv: 7300,
		amt: 2100
	},
	{
		name: '9',
		uv: 3490,
		pv: 6300,
		amt: 2100
	},
	{
		name: '10',
		uv: 3490,
		pv: 4300,
		amt: 2100
	},
	{
		name: '11',
		uv: 3490,
		pv: 3300,
		amt: 2100
	},
	{
		name: '12',
		uv: 3490,
		pv: 2300,
		amt: 2100
	},
	{
		name: '13',
		uv: 3490,
		pv: 1300,
		amt: 'depleted'
	},
	{
		name: '14',
		uv: 3490,
		pv: 1300,
		amt: 'depleted'
	},
	{
		name: '15',
		uv: 3490,
		pv: 1300,
		amt: 'depleted'
	},
	{
		name: '16',
		uv: 3490,
		pv: 1350,
		amt: 'depleted'
	},
	{
		name: '17',
		uv: 3490,
		pv: 1320,
		amt: 'depleted'
	},
	{
		name: '18',
		uv: 3490,
		pv: 1300,
		amt: 'depleted'
	},
	{
		name: '19',
		uv: 3490,
		pv: 1320,
		amt: 2100
	},
	{
		name: '20',
		uv: 3490,
		pv: 1400,
		amt: 2100
	},
	{
		name: '21',
		uv: 3490,
		pv: 2400,
		amt: 'full'
	},
	{
		name: '22',
		uv: 3490,
		pv: 2900,
		amt: 2100
	},
	{
		name: '23',
		uv: 3490,
		pv: 3800,
		amt: 2100
	},
	{
		name: '24',
		uv: 3490,
		pv: 4400,
		amt: 2100
	},
	{
		name: '25',
		uv: 3490,
		pv: 5200,
		amt: 2100
	},
	{
		name: '26',
		uv: 3490,
		pv: 6200,
		amt: 2100
	},
	{
		name: '27',
		uv: 3490,
		pv: 7000,
		amt: 2100
	},
	{
		name: '28',
		uv: 3490,
		pv: 7900,
		amt: 'full'
	},
	{
		name: '29',
		uv: 3490,
		pv: 8500,
		amt: 2100
	},
	{
		name: '30',
		uv: 3490,
		pv: 7900,
		amt: 2100
	},
	{
		name: '31',
		uv: 3490,
		pv: 7000,
		amt: 2100
	}
];

export type SampleChartProps = {
	energyBank: IEnergyBankData;
	chartOption: string;
	selectedYear: string;
	selectedMonth: string;
	selectedDay: string;
	expandedChart: boolean;
	setChartOption: (value: string) => void;
	setSelectedDay: (value: string) => void;
	chartContainerRef: RefObject<HTMLDivElement>;
};

export const SmallThinChart = ({
	energyBank,
	chartOption,
	selectedYear,
	selectedMonth,
	selectedDay,
	expandedChart,
	setChartOption,
	setSelectedDay,
	chartContainerRef
}: SampleChartProps) => {
    const [fatherWidth, setFatherWidth] = useState(850);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [daysOfMonth, setDaysOfMonth] = useState<Array<{
		name: string | undefined;
		MWh: number | undefined;
	}>>([{name:'', MWh:0}]);

	const [hoursOfDay, setHoursOfDay] = useState<Array<{
		name: number | undefined;
		MWh: number | undefined;
	}>>([{name:0, MWh:0}]);

	useEffect(() => {
		setIsLoaded(false)
		const timeoutId = setTimeout(() => {
			setIsLoaded(true)
		}, 20);
		return () => clearTimeout(timeoutId);

	}, [hoursOfDay, daysOfMonth]);

	useEffect(()=>{
		if(energyBank){
			setDaysOfMonth(getDaysOfMonth(selectedYear, selectedMonth));
			setHoursOfDay(getHoursOfDay(selectedYear,selectedMonth,selectedDay));
		}
	},[energyBank, selectedYear, selectedMonth, selectedDay])

	const getDaysOfMonth = (year: string, month: string) => {
		const values = energyBank.data.map((asset)=>{
			const days = asset.years
				?.find(y => y.year === year)
				?.months?.find(m => m.month === month)
				?.days?.map(({ day, total }) => ({ name: day, MWh: Math.round(total/1000000 * 100) / 100 || 0 })) || [];
			return days;
		})
		const result: {name: string;MWh: number;}[] = [];
		values.forEach(subArray => {
			subArray.forEach(item => {
				const existingItem = result.find(i => i.name === item.name);
			
				if (existingItem) {
				existingItem.MWh += item.MWh;
				existingItem.MWh = parseFloat(existingItem.MWh.toFixed(2))
				} else {
				result.push({ ...item });
				}
			});
			});
		result.sort((a, b) => Number(a.name) - Number(b.name));
		return result;
	};

	const getHoursOfDay = (year: string, month: string, day: string) => {
		let formattedDat = ''
		if ((Number(day)) < 10) {
			formattedDat =`0${(Number(day))}`
		}else{
			formattedDat = `${(Number(day))}`
		}
		const hoursArray =  energyBank.data.map((asset)=>{
			const hours = asset.years
			?.find(y => y.year === year)
			?.months?.find(m => m.month === month)
			?.days?.find(d => d.day === formattedDat)
			?.hours?.map(({ hour, MWh }) => ({ name: hour, MWh: Math.round(MWh/1000000 * 100) / 100 })) || [];
			return hours;
		})
		const result: {name: number;MWh: number;}[] = [];
		hoursArray.forEach(subArray => {
			subArray.forEach(item => {
				const existingItem = result.find(i => i.name === item.name);
				if (existingItem) {
				existingItem.MWh += item.MWh;
				existingItem.MWh = parseFloat(existingItem.MWh.toFixed(2))
				} else {
				result.push({ ...item });
				}
			});
		});

		return result;
	};

	useEffect(() => {
		function handleResize() {
			if (chartContainerRef.current) {
			setFatherWidth(chartContainerRef.current.clientWidth);
		  }
        }
        handleResize(); // Define o tamanho inicial
        window.addEventListener('resize', handleResize); // Atualiza o tamanho quando a janela for redimensionada
        return () => {
            window.removeEventListener('resize', handleResize); // Remove o event listener quando o componente é desmontado
        }
    }, [chartContainerRef, expandedChart]);

	const CustomBar = (props: any) => {
		const { x, y, width, height, fill } = props;
		const borderRadius = 6;

		return (
			<g>
				<rect
					x={x}
					y={y}
					width={fatherWidth> 810? 17: 10}
					height={height}
					rx={borderRadius}
					ry={borderRadius}
					stroke="none"
					fill={fill}
				/>
			</g>
		);
	};

	return (
		<Box sx={{ height: '320px', margin: '3% 0 0 5%', marginX: 'auto'}}>
			{isLoaded ? 
				daysOfMonth.length > 1 ?
					<>
						<BarChart
							width={expandedChart ? fatherWidth> 1500 ? 1200: fatherWidth: fatherWidth> 810? 808: fatherWidth}
							height={320}
							data={chartOption === 'day' ? daysOfMonth : hoursOfDay}
							margin={{
								top: 5,
								right: 0,
								left: 15,
								bottom: 5
							}}
							barSize={expandedChart ? 20: 20}
						>
							<XAxis
								dataKey="name"
								scale="point"
								padding={{ left: 30, right: 30 }}
								fontFamily="Sen"
								color="#000000"
								fontSize={expandedChart? '20px': '14.14px'}
								axisLine={false}
								tickLine={false}
							/>
							<YAxis label={{ value: 'MWh', angle: -90, fontSize: '20px', fontFamily: 'Sen', position: 'insideLeft' }} axisLine={false} padding={{ bottom: 20, top: 15 }} />
							<Tooltip />
							{/* <Legend /> */}
							<Bar
								dataKey="MWh"
								fill="#8884d8"
								shape={<CustomBar />}
								onClick={(e) => {
									if (chartOption === 'day') {
										setChartOption('hour');
										setSelectedDay(e.name);
									}
								}}
								label={{
									position: 'top',
									fontFamily: 'Sen',
									// fontSize: '9.60px',
									fontSize: expandedChart? '14px': '11px',
									fontWeight: '400',
									fill: '#000000'
								}}
							>
								{data.map((entry, index) => (
									<Cell
										key={`cell-${index}`}
										fill={'#636C7A'}
									/>
								))}
							</Bar>
						</BarChart>
					</>
				:
					<>
						<Box height={320} display="flex" justifyContent="center" alignItems="center">
							<Typography
								fontFamily="Sen"
								fontStyle="normal"
								fontWeight="700"
								fontSize="22.218px"
								lineHeight="34px"
								color="#6F7075"
							>
								No data was found
							</Typography>
						</Box>
					</>
				:
					<>
						<Box height={320}></Box>
					</>
			}	
			{/* <Box sx={{textAlign: 'center', width: fatherWidth*0.5,marginX: 'auto',marginBottom: '10px',}}>
				<Grid container md={12}  width={fatherWidth} textAlign={'center'} >
					<Grid item md={4}>
						<Grid container md={12}>
							<Grid item md={4}>
								<Box
									sx={{
										height: fatherWidth > 600 ? '30px' : '10px',
										width: fatherWidth > 600 ? '30px' : '10px',
										backgroundColor: '#636C7A',
										borderRadius: '50%',
										margin: '0 0 0 7px',
										'@media screen and (max-width: 1440px)': {
											height: '16px',
											width: '16px',
										}
									}}
								></Box>
							</Grid>
							<Grid item md={8} sx={{textAlign: 'left'}}>
								<Typography sx={{
									transition: 'width 0.3s ease, margin 0.3s ease',
									'@media screen and (max-width: 1440px)': {
										fontSize: fatherWidth > 600 ? '18px' : '16px',
										marginLeft: '5px',
										marginTop: '-8px'
									}
								}} fontFamily={'Sen'} fontSize="22.1453px">
									Full
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item md={4}>
						<Grid container md={12}>
							<Grid item md={4}>
								<Box
									sx={{
										height: fatherWidth > 600 ? '30px' : '10px',
										width: fatherWidth > 600 ? '30px' : '10px',
										backgroundColor: '#FFC658',
										borderRadius: '50%',
										margin: '0 0 0 7px',
										'@media screen and (max-width: 1440px)': {
											height: '16px',
											width: '16px',
										}
									}}
								></Box>
							</Grid>
							<Grid item md={8} sx={{textAlign: 'left'}}>
								<Typography sx={{
									transition: 'width 0.3s ease, margin 0.3s ease',
									'@media screen and (max-width: 1440px)': {
										fontSize: fatherWidth > 600 ? '18px' : '16px',
										marginLeft: '5px',
										marginTop: '-8px'
									}
								}} fontFamily={'Sen'} fontSize="22.1453px">
									Partial
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item md={4}>
						<Grid container md={12}>
							<Grid item md={4}>
								<Box
									sx={{
										height: fatherWidth > 600 ? '30px' : '10px',
										width: fatherWidth > 600 ? '30px' : '10px',
										backgroundColor: '#F32053',
										borderRadius: '50%',
										margin: '0 0 0 7px',
										'@media screen and (max-width: 1440px)': {
											height: '16px',
											width: '16px',
										}
									}}
								></Box>
							</Grid>
							<Grid item md={8} sx={{textAlign: 'left'}}>
								<Typography sx={{
									transition: 'width 0.3s ease, margin 0.3s ease',
									'@media screen and (max-width: 1440px)': {
										fontSize: fatherWidth > 600 ? '18px' : '16px',
										marginLeft: '5px',
										marginTop: '-8px'
									}
								}} fontFamily={'Sen'} fontSize="22.1453px">
									Depleted
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box> */}
		</Box>
	);
};
