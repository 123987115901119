/* eslint-disable import/extensions */
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { AssetManager } from '../pages/AssetManager';
import { AssetView } from '../pages/AssetView';

// eslint-disable-next-line import/extensions
import { Buyers } from '../pages/Buyer';
import { Certificate } from '../pages/Certificate';
import { ValidCertificatePage } from '../pages/ValidCertificate';
import { Configure } from '../pages/Configure';
import { Dashboard } from '../pages/Dashboard';
import { Home } from '../pages/Home';
import { OperationHistory } from '../pages/OperationHisotry';
import { Operations } from '../pages/Operations';
import { Profile } from '../pages/Profile';
import { RecIssue } from '../pages/RecIssue';
import { RecRetirement } from '../pages/RecRetirement';
import { Preview } from '../pages/RecRetirement/Preview';
import { RecToken } from '../pages/RecToken';
import { SaleManager } from '../pages/SaleManager';
import { Signin } from '../pages/Signin';
import { Signup } from '../pages/Signup';
import { Wallet } from '../pages/wallet';
import { RecoveryWallet } from '../pages/RecoveryWallet';
import { RecoveryWalletAsset } from '../pages/RecoveryWalletAsset';
import { CreateAssetWallet } from '../pages/CreateAssetWallet';
import { DashboardAsset } from '../pages/DashobardAsset';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import { useSnackbar } from 'notistack';


interface IJwtToken {
	role: string;
	exp: number;
}

export const RoutesApp: FC = () => {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const setUserRoleIntoRedux = (value: string) => {
		dispatch({ type: 'SET_USER_ROLE', payload: value });
	  };

	const PrivateRoute = ({ Item, role }: any) => {
		const tokenUser = localStorage.getItem('token');
		if (!tokenUser) {
			return <Navigate to="/signin"/>;
		}
		const user: IJwtToken = jwt_decode(tokenUser || '');
		if (!user) {
		return <Navigate to="/signin"/>;
		}
		const experitionToken = dayjs.unix(user.exp)
		if (dayjs().isAfter(experitionToken)) {
			localStorage.removeItem('token');
			enqueueSnackbar('Your token has expired, please log in again.', {
				variant: 'info'
			});
			return <Navigate to="/signin"/>;
		}

		setUserRoleIntoRedux(user.role)
	
		if (role && user.role !== role) {
			const navigate = useNavigate();

			React.useEffect(() => {
			navigate(-1);
			}, [navigate]);
		
			return null;
		}

		return <Item/>;

		// const tokenUser = localStorage.getItem('token');
		// let ownerL;

		// if (tokenUser) {
		// 	ownerL = localStorage.getItem('token');
		// }
		// return !!ownerL ? <Item/> : <Signin/>;
	};

	
	const NotFound = () => {
		const navigate = useNavigate();

		React.useEffect(() => {
		navigate(-1);
		}, [navigate]);
	
		return null;
	};
	return (
		<>
			<Router>
				<Routes>
					<Route path="/*" element={<NotFound/>}/>
					<Route path="/" element={<PrivateRoute Item={Home}/>}/>
					<Route path="/business-template" element={<PrivateRoute Item={Home}/>}/>
					<Route path="/signin" element={<Signin/>}/>
					<Route path="/signup" element={<Signup/>}/>
					<Route path="/profile" element={<PrivateRoute Item={Profile}/>}/>
					<Route path="/profile/wallet/create" element={<PrivateRoute Item={Wallet}/>}/>
					<Route path="/profile/wallet/recovery" element={<PrivateRoute Item={RecoveryWallet}/>}/>
					{/* REC TEMPLATE */}
					<Route path="/rec/seller-certificates" element={<PrivateRoute Item={Operations} role={'seller'}/>}/>
					<Route path="/rec/config" element={<PrivateRoute Item={Configure} role={'owner'}/>}/>
					<Route path="/rec/dashboard" element={<PrivateRoute Item={Dashboard} role={'owner'}/>}/>
					<Route path="/rec/asset/:id/rec" element={<PrivateRoute Item={RecToken}/>}/>
					<Route path="/rec/issue/:id" element={<PrivateRoute Item={RecIssue}/>}/>
					<Route path="/rec/issue" element={<PrivateRoute Item={RecIssue}/>}/>
					<Route path="/rec/clients" element={<PrivateRoute Item={Buyers}/>}/>
					<Route path="/rec/clients/:clientId/edit/" element={<PrivateRoute Item={Buyers}/>}/>
					<Route path="/rec/operation-history" element={<PrivateRoute Item={OperationHistory} role={'cnpj'}/>}/>
					<Route path="/rec/operation-history/rec-retirement" element={<PrivateRoute Item={RecRetirement} role={'cnpj'}/>}/>
					<Route path="/rec/operation-history/rec-retirement/preview" element={<PrivateRoute Item={Preview} role={'cnpj'}/>}/>
					<Route path="/rec/certificates" element={<PrivateRoute Item={Certificate}/>}/>
					<Route path="/rec/certificates/:id" element={<ValidCertificatePage/>}/>
					<Route path="/rec/sales-history" element={<PrivateRoute Item={SaleManager}/>}/>
					<Route path="/rec/assets" element={<PrivateRoute Item={AssetManager}/>}/>
					<Route path="/rec/assets/:id/wallet/create" element={<PrivateRoute Item={CreateAssetWallet}/>}/>
					<Route path="/rec/assets/:id/wallet/recovery" element={<PrivateRoute Item={RecoveryWalletAsset}/>}/>
					<Route path="/rec/assets/:id/dashboard" element={<PrivateRoute Item={DashboardAsset}/>}/>
				</Routes>
			</Router>
		</>
	);
}