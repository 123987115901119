import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import {
	Box,
	Grid,
	Typography,
	FormControl,
	InputLabel,
	Button,
	InputAdornment,
	IconButton,
	FormHelperText,
	OutlinedInput
} from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';

// eslint-disable-next-line import-helpers/order-imports
import { authSignup } from '../../../stores/authSignup/authSignup.actions';

// eslint-disable-next-line import/extensions
import { colors } from '../../../styles/colors';
import { SchemaSignupDefault } from './schemaSignup';
interface IState {
	showPassword: boolean;
	showPasswordConfirm: boolean;
}
interface IForm {
	type?: any;
	first_name: string;
	last_name: string;
	password: string;
	confirmPassword: string;
	token: string;
}

const style = {
	borderRadius: '9px',
	marginTop: '20px'
};

export const CardSignup = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [passwordValue, setPasswordValue] = useState<string>('');
	const [noMatchPass, setMatchPassword] = useState<boolean>(false);
	const [inputs, setInputs] = useState<any>({});

	const defaultValues = {
		first_name: '',
		last_name: '',
		password: '',
		confirmPassword: '',
		token: ''
	};

	const form = useForm<IForm>({
		resolver: yupResolver(SchemaSignupDefault)
	});

	const TextPassword = ({ color }: any) => {
		return (
			<Box
				textAlign="left"
				color={color}
				lineHeight="24px"
				marginLeft="95px"
				marginTop="17px"
			>
				<Typography fontSize="1rem" color={colors.supportDark2}>
					New password must contain at Least:
				</Typography>
				<Typography textAlign="left" fontSize="1rem">
					. 8 characters
					<br />. 1 capital leter
					<br />. 1 special character
					<br />. 1 number
				</Typography>
			</Box>
		);
	};

	const signupAuth = useSelector((state: any) => state.authSignup);
	const [values, setValues] = useState<IState>({
		showPassword: false,
		showPasswordConfirm: false
	});

	const toSignup = () => {
		history('/signin');
	};

	useEffect(() => {
		form.reset(defaultValues);
		const { password } = form.getValues();
		setPasswordValue(password);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const respSignupAuth = async () => {
			const respSignup = await signupAuth;
			if (respSignup.status === 200) {
				enqueueSnackbar('User created', {
					variant: 'success'
				});
				history('/signin');
			} else {
				if ([401, 400, 500].includes(respSignup.status)) {
					enqueueSnackbar(respSignup.statusText, {
						variant: 'error'
					});
				}
			}
		};

		respSignupAuth();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signupAuth]);

	const onSubmit = async () => {
		const {
			first_name: firstName,
			last_name: lastName,
			password,
			confirmPassword,
			token
		} = form.getValues();
		setPasswordValue(password);

		if (!firstName && !lastName && !password && !token) return;

		if (password !== confirmPassword) {
			setMatchPassword(true);
			return;
		} else {
			setMatchPassword(false);
		}

		dispatch(authSignup(firstName, lastName, password, token));
	};

	const handleField = (type: any, e: any) => {
		form.setValue(type, e.target.value);
		setInputs((prevState: any) => ({
			...prevState,
			[e.target.name]: e.target.value
		}));
	};

	const handleClickShowPassword = () => {
		setValues({
			...values,
			showPassword: !values.showPassword
		});
	};

	const handleClickShowPasswordConfirm = () => {
		setValues({
			...values,
			showPasswordConfirm: !values.showPasswordConfirm
		});
	};

	const colorButtonSignup = () => {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const { first_name, last_name, password, confirmPassword, token } = inputs;
		return !first_name || !last_name || !password || !confirmPassword || !token
			? colors.primaryLight
			: colors.primary;
	};

	return (
		<form onSubmit={() => {}}>
			<Grid container columnSpacing={3} md={12} padding="55px 20px 38px 44px">
				<Grid item xs={12} md={12} marginBottom="31px">
					<Box>
						<Typography
							fontFamily="sen"
							fontStyle="normal"
							fontWeight="700"
							fontSize="1.5rem"
							lineHeight="32px"
							color={colors.neutralDark}
						>
							CREATE ACCESS
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={6} md={6} marginBottom="20px">
					<Controller
						control={form.control}
						name="first_name"
						render={({ field }) => (
							<FormControl variant="standard" fullWidth>
								<InputLabel
									error={!!form.formState.errors.first_name}
									shrink
									htmlFor="first_name"
									required
								>
									Firstame
								</InputLabel>
								<OutlinedInput
									style={style}
									error={!!form.formState.errors.first_name}
									placeholder="firstname..."
									{...field}
									type="string"
									onChange={e => handleField('first_name', e)}
								/>
								{!!form.formState.errors.first_name && (
									<FormHelperText error>
										{form?.formState?.errors?.first_name?.message}
									</FormHelperText>
								)}
							</FormControl>
						)}
					/>
				</Grid>

				<Grid item xs={6} md={6} marginBottom="20px">
					<Controller
						control={form.control}
						name="last_name"
						render={({ field }) => (
							<FormControl variant="standard" fullWidth>
								<InputLabel
									error={!!form.formState.errors.last_name}
									shrink
									htmlFor="last_name"
									required
								>
									Lastname
								</InputLabel>
								<OutlinedInput
									error={!!form.formState.errors.last_name}
									style={style}
									placeholder="lastname..."
									{...field}
									type="string"
									onChange={e => handleField('last_name', e)}
								/>
								{!!form.formState.errors.last_name && (
									<FormHelperText error>
										{form?.formState?.errors?.last_name?.message}
									</FormHelperText>
								)}
							</FormControl>
						)}
					/>
				</Grid>

				<Grid item xs={12} md={12} marginBottom="20px">
					<Controller
						control={form.control}
						name="password"
						render={({ field }) => (
							<FormControl variant="standard" fullWidth>
								<InputLabel
									error={!!form.formState.errors.password}
									shrink
									htmlFor="password"
									required
								>
									Create new password
								</InputLabel>
								<OutlinedInput
									error={!!form.formState.errors.password}
									style={style}
									id="outlined-adornment-password"
									type={values.showPassword ? 'text' : 'password'}
									endAdornment={
										<InputAdornment position="end" sx={{ marginRight: '25px' }}>
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												edge="end"
											>
												{values.showPassword ? (
													<VisibilityOff />
												) : (
													<Visibility />
												)}
											</IconButton>
										</InputAdornment>
									}
									placeholder="password..."
									{...field}
									onChange={e => handleField('password', e)}
								/>
								{!!form.formState.errors.password ? (
									<>
										<Box
											textAlign="left"
											color={colors.supportDark2}
											lineHeight="24px"
											marginLeft="95px"
											marginTop="17px"
										>
											<Typography fontSize="1rem">
												New password must contain at Least:
											</Typography>
											<Typography textAlign="left" fontSize="1rem" color="red">
												{form?.formState?.errors?.password?.message}
											</Typography>
										</Box>
									</>
								) : (
									<>
										{!passwordValue && (
											<TextPassword color={colors.supportDark2} />
										)}
										{passwordValue && <TextPassword color={colors.success} />}
									</>
								)}
							</FormControl>
						)}
					/>
				</Grid>
				<Grid item xs={12} md={12} marginBottom="20px">
					<Controller
						control={form.control}
						name="confirmPassword"
						render={({ field }) => (
							<FormControl variant="standard" fullWidth>
								<InputLabel
									error={noMatchPass}
									shrink
									htmlFor="confirmPassword"
									required
								>
									Confirm new password
								</InputLabel>
								<OutlinedInput
									error={noMatchPass}
									style={style}
									id="outlined-adornment-password"
									type={values.showPasswordConfirm ? 'text' : 'password'}
									endAdornment={
										<InputAdornment position="end" sx={{ marginRight: '25px' }}>
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPasswordConfirm}
												edge="end"
											>
												{values.showPasswordConfirm ? (
													<VisibilityOff />
												) : (
													<Visibility />
												)}
											</IconButton>
										</InputAdornment>
									}
									placeholder="password..."
									{...field}
									onChange={e => handleField('confirmPassword', e)}
								/>
								{noMatchPass && (
									<FormHelperText error>
										{'The password dont match!'}
									</FormHelperText>
								)}
							</FormControl>
						)}
					/>
				</Grid>

				<Grid item xs={12} md={12}>
					<Controller
						control={form.control}
						name="token"
						render={({ field }) => (
							<FormControl variant="standard" fullWidth>
								<InputLabel
									error={!!form.formState.errors.token}
									shrink
									htmlFor="token"
									required
								>
									Please input received Token
								</InputLabel>
								<OutlinedInput
									style={style}
									type="string"
									error={!!form.formState.errors.token}
									placeholder="token..."
									{...field}
									onChange={e => handleField('token', e)}
								/>
								{!!form.formState.errors.token && (
									<FormHelperText error>
										{form?.formState?.errors?.token?.message}
									</FormHelperText>
								)}
							</FormControl>
						)}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={12}
					marginTop="41px"
					display="flex"
					justifyContent="center"
				>
					<LoadingButton
						disableElevation
						sx={{
							borderRadius: '25.5px',
							backgroundColor: colorButtonSignup(),
							'&:hover': {
								backgroundColor: colorButtonSignup()
							}
						}}
						loading={false}
						variant="contained"
						onClick={form.handleSubmit(onSubmit)}
					>
						<Typography
							sx={{
								padding: '8px 51px',
								fontSize: '24px',
								lineHeight: '32px',
								textTransform: 'initial',
								fontWeight: '700'
							}}
						>
							Create Account
						</Typography>
					</LoadingButton>
				</Grid>

				<Grid
					item
					xs={12}
					md={12}
					display="flex"
					alignItems="center"
					justifyContent="center"
					marginTop="23px"
				>
					<Typography
						color={colors.primary}
						lineHeight="24px"
						fontWeight="400"
						marginRight="20px"
					>
						Already have account?
					</Typography>
					<Button onClick={toSignup}>
						<Typography color={colors.primary}>LOGIN</Typography>
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};
