export function authSignup(
	first_name: string,
	last_name: string,
	password: string,
	token: string
) {
	return {
		type: 'SIGNUP_USER',
		payload: { first_name, last_name, password, token }
	};
}

export function signupDefault() {
	return {
		type: 'SIGNUP_DEFAULT'
	};
}
