import { Box, Card, Typography } from '@mui/material';

import Plus from '../../assets/imgs/plus.png';
import { ListWallet } from '../../pages/wallet/ListWallet';
import { colors } from '../../styles';
import { IListWallet } from '../../types/wallet';

export type TCardSide = {
	title: string;
	subTitle: string;
	footer: string;
	goToWallet: () => void;
	dataWalletList?: IListWallet;
};

export const CardSide = ({
	title,
	subTitle,
	footer,
	goToWallet,
	dataWalletList = {} as IListWallet
}: TCardSide) => {
	return (
		<>
			<Card
				sx={{
					padding: '42px 39px 62px 39px',
					boxShadow: 'none',
					backgroundColor: colors.primary,
					border: '1px solid #D4D7DE',
					width: '474px',
					maxHeight: '424px',
					borderRadius: '32px'
				}}
			>
				<Box>
					<Typography
						fontWeight="700"
						fontSize="2.25rem"
						lineHeight="48px"
						color="#fff"
						marginBottom={!dataWalletList ? '10px' : '31px'}
					>
						{title}
					</Typography>
					{!dataWalletList && (
						<Typography
							fontSize="1rem"
							fontWeight="400"
							lineHeight="24px"
							color="#fff"
							marginBottom="59px"
						>
							{subTitle}
						</Typography>
					)}
				</Box>
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					flexDirection="column"
				>
					{!dataWalletList?.wallet_name ? (
						<>
							<Box
								onClick={goToWallet}
								width="83px"
								height="83px"
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									backgroundColor: colors.neutralLight,
									border: '1px solid #6F7075',
									borderRadius: '50%',
									marginBottom: '72px',
									cursor: 'pointer'
								}}
							>
								<img src={Plus} alt="plus" />
							</Box>
							<Box>
								<Typography
									fontSize="1rem"
									fontWeight="400"
									lineHeight="24px"
									color="#fff"
								>
									{footer}
								</Typography>
							</Box>
						</>
					) : (
						<ListWallet dataWalletList={dataWalletList} />
					)}
				</Box>
			</Card>
		</>
	);
};
