/* eslint-disable prettier/prettier */
import { ChangeEvent, useState } from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { ApiPromise, WsProvider, } from "@polkadot/api";
import { keyring as uiKeyring } from "@polkadot/ui-keyring";
import { waitReady } from "@polkadot/wasm-crypto";

import { useSnackbar } from 'notistack';

import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	InputBase,
	styled,
	Typography,
	OutlinedInput,
	FormHelperText,
	Box
} from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';

import { AddLogo } from '../../../components/addLogo';
import { AssetService, IAsset } from '../../../services/asset.service';
import { EnterpriseService } from '../../../services/enterprise.service';
import { MemberService } from '../../../services/member.service';
import { colors } from '../../../styles';
import EnterpriseDefault from '../../../assets/imgs/enterpriseDefaultLogo2.png';
import { SchemaFormConfig } from './schemaConfig';
import { UploadLogo } from '../../../components/uploadLogo';
import { ConfirmationModal } from '../../../components/confirmationModal';
import dayjs from 'dayjs';

export type TFormAsset = {
	// enterprise_id: string;
	unitName: string;
	country: string;
	state: string;
	city: string;
	address: string;
	type: string;
	cnpj: string;
	unitCode: string;
	cceeCode: string;
	startOfOperation: string;
	co2PerMWh: string;
	installedCapacity: string;
	certifier: string;
	id?: string;
};

export type TForm = {
	setSuccess: (value: boolean) => void;
	dataUser: Array<TFormAsset>;
	setFormAsset: (value: boolean) => void;
	editAsset: boolean;
	selectedAsset: any;
	setEditAsset: (value: boolean) => void;
};

export const FormAsset = ({
	setSuccess,
	selectedAsset,
	setFormAsset,
	editAsset,
	setEditAsset
}: TForm) => {
	const assetService = new AssetService();
	const enterpriseService = new EnterpriseService();
	const memberService = new MemberService();

	const [loading, setLoading] = useState<boolean>(false);
	const [countries, setCountries] = useState<Array<any>>();
	const [states, setStates] = useState<Array<any>>([]);
	const [countriesToState, setCountriesToState] = useState<any>();
	const [stateToCity, setStateToCity] = useState<any>();
	const [uploadFile, setUploadFile] = useState<File | null>(null);
	const [loadImage, setLoadImage] = useState<boolean>(false);
	const [buttonColor, setButtonColor] = useState<string>('black');
	const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
	
	const [user, setUser] = useState<any>({});
	const [passwordAuthorization, setPasswordAuthorization] = useState<string>('');
	const [openAuthorization, setOpenAuthorization] = useState<boolean>(false);
	const [loadingAuthorization, setLoadingAuthorization] =
		useState<boolean>(false);

	const [cities, setCities] = useState<Array<any>>([]);

	const [unitTypes, setUnitTypes] = useState<Array<any>>([
		'Solar',
		'Wind',
		'Hydro'
	]);

	const [certifiers, setCertifiers] = useState<Array<any>>(['SGS']);

	const [logo, setLogo] = useState<any>();

	const form = useForm<TFormAsset>({
		resolver: yupResolver(SchemaFormConfig)
	});

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const loadDataEdit = () => {};
	const getUserInfo = async () => {
		const getUser: any = await memberService.getMyInfo();
		if (getUser.status === 200) {
			setUser(getUser.data);
		}
		// if error
	};

	const handleKeyPress = (event: { key: string; }) => {
		if (event.key === 'Enter' && !isButtonDisabled) {
			onSubmit();
		}
		if (event.key === 'Escape') {
		  goToConfig();
		}
	};
	
	useEffect(() => {
		// Add event listener when component mounts
		document.addEventListener('keydown', handleKeyPress);
	
		// Remove event listener when component unmounts
		return () => {
		  document.removeEventListener('keydown', handleKeyPress);
		};
	}, [isButtonDisabled]);
	  
	useEffect(() => {
		getUserInfo();
	}, []);

	useEffect(() => {
		loadDataEdit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getStates = async () => {
		if (countriesToState) {
			const respStates = await enterpriseService.getStates(countriesToState);
			if (respStates.status === 200) {
				const { data } = respStates.data;
				setStates(data.states);
			}
		}
	};

	const getCities = async () => {
		if (countriesToState && stateToCity) {
			const respCities = await enterpriseService.getCities(
				countriesToState,
				stateToCity
			);
			if (respCities.status === 200) {
				const { data } = respCities.data;
				setCities(data);
			}
		}
	};

	useEffect(() => {
		const getCoutries = async () => {
			const respCountries = await enterpriseService.getCountries();
			if (respCountries.status === 200) {
				const { data } = respCountries.data;
				setCountries(data);
			}
		};

		if (editAsset) {
			setCountriesToState(selectedAsset.country);
			setStateToCity(selectedAsset.state);

			form.setValue('unitName', selectedAsset.name);
			form.setValue('country', selectedAsset.country);
			form.setValue('state', selectedAsset.state);
			form.setValue('city', selectedAsset.city);
			form.setValue('address', selectedAsset.address);
			form.setValue('type', selectedAsset.type);
			form.setValue('cnpj', selectedAsset.cnpj);
			form.setValue('co2PerMWh', selectedAsset.co2_per_mwh);
			form.setValue('installedCapacity', selectedAsset.installed_capacity);
			form.setValue('startOfOperation', dayjs(selectedAsset.start_of_operation).format('YYYY-MM-DD'));
			form.setValue('unitCode', selectedAsset.code);
			form.setValue('cceeCode', selectedAsset.cceeCode);
			form.setValue('certifier', selectedAsset.certified_by);
			checkFormFilled();
		}
		getCoutries();
		getStates();
		getCities();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// change button color when form is completely filled

	useEffect(() => {
		getStates();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countriesToState]);

	const checkFormFilled = () => {
		const value = { ...form.getValues() };
		if (
			value.unitName &&
			value.country &&
			value.state &&
			value.city &&
			value.address &&
			value.type &&
			value.cnpj &&
			!!Number(value.cnpj.replace(/[./-]/g, '')) &&
			value.co2PerMWh &&
			value.installedCapacity &&
			value.startOfOperation &&
			value.unitCode &&
			value.cceeCode &&
			value.certifier
		) {
			setIsButtonDisabled(false)
			setButtonColor(colors.primary);
		} else {
			setIsButtonDisabled(true)
			setButtonColor('#CAD5E8');
		}
	};

	useEffect(() => {
		getCities();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stateToCity]);

	const onSubmit = () => {
		if (!user.wallet_address){
			enqueueSnackbar("You haven't registered your wallet in your profile yet.", {
				variant: 'error'
			});
			return; 
		}
		setOpenAuthorization(true);
	};

	const handleCloseAuthoraztion = () => {
		setOpenAuthorization(false);
	};

	const onSubmitAuthorization = async () => {
		const createPowerUnit = async () => {
			try {
				await waitReady();
				uiKeyring.loadAll({ ss58Format: 42, type: "sr25519" });
			} catch (error) {
				console.log(error)
			} finally {
				const provider = new WsProvider(`${process.env.REACT_APP_PARACHAIN_ADDRESS}`);
				const api = await ApiPromise.create({ provider });
				try {
					const userPair = uiKeyring.getPair(user.wallet_address);
					try {
						userPair.unlock(passwordAuthorization);
					} catch (error) {
						console.log(error)
						enqueueSnackbar('The password of your wallet is invalid, try again', {
							variant: 'error'
						});
						return;
					}
					const genesisHash = api.genesisHash;
					const runtimeVersion = api.runtimeVersion;
					
					let nonce = await api.rpc.system.accountNextIndex(user.wallet_address);
	
					const header = await api.derive.accounts.info(user.wallet_address)
	
					const notify = enqueueSnackbar('Processing on the blockchain.', {
						variant: 'info',
						persist: true
					});
	
					if (buttonColor === '#CAD5E8') {
						enqueueSnackbar('Fill all the fields.', {
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right'
							}
						});
						return;
					}
			
					const value = { ...form.getValues() };
					const cnpjNormalize = value.cnpj.replace(/[./-]/g, '');
					const unitCodeString = value.unitCode;
					const cceeCodeString = value.cceeCode;
					if (!value.cnpj || !value.unitName) return;
			
					if (!cnpjNormalize) {
						enqueueSnackbar('Invalid CNPJ.', {
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right'
							}
						});
						return;
					}

					setLoading(true);
					setLoadingAuthorization(true);
					if (editAsset) {
						const respSubmitEnt = await assetService.updateAssetById(
							{
								name: value.unitName,
								enterprise_id: localStorage.getItem('enterprise_id') || '',
								country: countriesToState,
								state: value.state,
								city: value.city,
								address: value.address,
								type: value.type,
								code: unitCodeString,
								cceeCode: cceeCodeString,
								co2_per_mwh: value.co2PerMWh,
								installed_capacity: value.installedCapacity,
								start_of_operation: value.startOfOperation,
								certified_by: value.certifier,
								cnpj: cnpjNormalize
							},
							selectedAsset.id
						);
						if (!logo) {
							if (respSubmitEnt.status === 200) {
								enqueueSnackbar('Asset updated', {
									variant: 'success'
								});
								setLoading(false);
								setLoadingAuthorization(false);
								setSuccess(true);
								setFormAsset(false);
								window.location.reload();
							} else {
								setLoading(false);
								setLoadingAuthorization(false);
								enqueueSnackbar(respSubmitEnt.statusText, {
									variant: 'error',
									anchorOrigin: {
										vertical: 'top',
										horizontal: 'right'
									}
								});
							}
						}
	
						if (respSubmitEnt.status === 200 && logo) {
							const formData = new FormData();
	
							const saveLogoImage = await enterpriseService.addLogo(formData);
							if (saveLogoImage.status === 200) {
								enqueueSnackbar('The Enterprise logo has been uploaded', {
									variant: 'success'
								});
								setLoading(false);
								setLoadingAuthorization(false);
								setSuccess(true);
								window.location.reload();
							}
	
							if ([400, 401, 500, 404].includes(saveLogoImage.status)) {
								setLoading(false);
								setLoadingAuthorization(false);
								enqueueSnackbar(saveLogoImage.statusText, {
									variant: 'error'
								});
							}
						}
	
						if (respSubmitEnt.status === 401) {
							setLoading(false);
							setSuccess(false);
							setLoadingAuthorization(false);
							enqueueSnackbar(respSubmitEnt.statusText, {
								variant: 'error'
							});
						}
					}

					if (!editAsset) {
						const signedTransaction = api.tx.powerPlants.updatePowerPlant(`${process.env.REACT_APP_CLIENT_PARACHAIN_ID}`, `${process.env.REACT_APP_MULTILEDGERS_PARACHAIN_ID}`, `${process.env.REACT_APP_CCEE_PARACHAIN_ID}`, unitCodeString, cnpjNormalize , value.unitName, countriesToState, value.type, dayjs(value.startOfOperation).format('YYYY-MM-DD')).sign(userPair, { genesisHash, blockHash: genesisHash, nonce, runtimeVersion });
						const respSubmitEnt = await assetService.createAsset({
							name: value.unitName,
							enterprise_id: localStorage.getItem('enterprise_id') || '',
							country: countriesToState,
							state: value.state,
							city: value.city,
							address: value.address,
							type: value.type,
							code: unitCodeString,
							cceeCode: cceeCodeString,
							co2_per_mwh: value.co2PerMWh,
							installed_capacity: value.installedCapacity,
							start_of_operation: value.startOfOperation,
							certified_by: value.certifier,
							cnpj: cnpjNormalize,
							extrinsic: signedTransaction
						});

						await api.disconnect()
						
						if (respSubmitEnt.status === 200) {
							setLoading(false);
							setLoadingAuthorization(false);
							setSuccess(true);
							closeSnackbar(notify)
							enqueueSnackbar(respSubmitEnt.statusText, {
								variant: 'success',
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right'
								}
							});
							window.location.reload();
						}
						if ([400, 401, 500, 404].includes(respSubmitEnt.status)) {
							setLoading(false);
							setLoadingAuthorization(false);
							setSuccess(false);
							closeSnackbar(notify)
							enqueueSnackbar(respSubmitEnt.statusText, {
								variant: 'error'
							});
						}
						setOpenAuthorization(false);
					}
				} catch (error) {
					console.log(error)
					enqueueSnackbar('Wallet not found, please consider recovering it on the My Profile page. A new tab will be created in 5 seconds.', {
						variant: 'error'
					});
					const timeout = setTimeout(()=>{
						window.open(`${process.env.REACT_APP_CLIENT_URL}/profile`, "_blank", 'noopener,noreferrer'	);
					},5000)
					clearTimeout(timeout);
					return;
				}
			}
		}
		createPowerUnit()
	};

	const goToConfig = () => {
		if (editAsset) {
			setEditAsset(false);
		}
		setFormAsset(false);
	};

	const BootstrapInput = styled(InputBase)(() => ({
		'& .MuiInputBase-input': {
			display: 'flex',
			alignItems: 'center',
			padding: '14px 14px',
			borderRadius: '9px',
			backgroundColor: '#FFF',
			border: '1px solid #000',
			'& .MuiSvgIcon-root': {
				marginTop: '100px',
				color: '#fff'
			},
			'&:focus': {
				borderRadius: '10px'
			},
			':after': {
				borderBottom: 'none',
				borderRight: '1px solid #000',
				marginRight: '50px'
			}
		}
	}));

	const setCountriesSelect = (e: any) => {
		form.setValue('country', e.target.value);
		setCountriesToState(e.target.value);
	};

	const setStatesSelect = (e: any) => {
		form.setValue('state', e.target.value);
		setStateToCity(e.target.value);
	};

	const saveLogo = async (file: any) => {
		const formData = new FormData();
		formData.append('logo', file);

		setLoadImage(true);
		const saveLogoImage = await assetService.updateLogoById(selectedAsset.id,formData);
		if (saveLogoImage.status === 200) {
			setLoadImage(false);
			// localStorage.setItem(`asset-${selectedAsset.id}-logo` )
			enqueueSnackbar('The Asset logo has been uploaded', {
				variant: 'success'
			});
			return true;
		} else {
			setLoadImage(false);
			enqueueSnackbar('not saved logo on server', {
				variant: 'error'
			});
			return false;
		}
	};

	const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		if (files?.length) {
			const file = files[0];
			const logoSaved = await saveLogo(file);

			if (logoSaved) {
				setUploadFile(file);
			}
		}
	};

	return (
		<>
			<form onSubmit={() => {}} style={{ width: '700px' }}>
				<Grid container md={12} rowGap={3} columnSpacing={4}>
					<Grid item md={6}>
						<Controller
							control={form.control}
							name="country"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel
										required
										error={false}
										shrink
										htmlFor="country"
										sx={{
											position: 'relative',
											marginTop: '3px',
											fontSize: '16px'
										}}
									>
										COUNTRY
									</InputLabel>
									<Select
										{...field}
										fullWidth
										input={<BootstrapInput />}
										labelId="demo-simple-select-label"
										id="country"
										variant="standard"
										value={countriesToState}
										onChange={(e: any) => {
											setCountriesSelect(e);
											checkFormFilled();
										}}
									>
										{countries &&
											countries.map(({ iso3, country }) => (
												<MenuItem key={iso3} value={country}>
													{country}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={6}>
						<Controller
							control={form.control}
							name="cnpj"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth disabled={editAsset ? true : false}>
									<InputLabel
										required
										error={!!form.formState.errors.cnpj}
										shrink
										htmlFor="cnpj"
									>
										CNPJ
									</InputLabel>
									<InputMask
										autoComplete="off"
										type="string"
										mask="99.999.999/9999-99"
										value={field.value}
										onChange={(e: any) => {
											form.setValue('cnpj', e.target.value);
											checkFormFilled();
										}}
									>
										{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
										{/* @ts-ignore */}
										{() => (
											<OutlinedInput
												fullWidth
												style={{
													border: 'none',
													borderRadius: '9px',
													marginTop: '20px'
												}}
												error={!!form.formState.errors.cnpj}
												placeholder="__.___.___/____-__"
												type="string"
											/>
										)}
									</InputMask>
									{!!form.formState.errors.cnpj && (
										<FormHelperText error>
											{form?.formState?.errors?.cnpj?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={12}>
						<Controller
							control={form.control}
							name="unitName"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel
										required
										error={!!form.formState.errors.unitName}
										shrink
										htmlFor="name"
									>
										UNIT NAME
									</InputLabel>
									<OutlinedInput
										fullWidth
										style={{
											border: 'none',
											borderRadius: '9px',
											marginTop: '20px'
										}}
										error={!!form.formState.errors.unitName}
										placeholder="type here..."
										{...field}
										type="string"
										onChange={e => {
											form.setValue('unitName', e.target.value);
											checkFormFilled();
										}}
									/>
									{!!form.formState.errors.unitName && (
										<FormHelperText error>
											{form?.formState?.errors?.unitName?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={6}>
						<Controller
							control={form.control}
							name="state"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel
										required
										error={false}
										shrink
										htmlFor="state"
										sx={{ position: 'relative' }}
									>
										STATE
									</InputLabel>
									<Select
										{...field}
										fullWidth
										input={<BootstrapInput />}
										labelId="demo-simple-select-label"
										id="state"
										variant="standard"
										value={stateToCity}
										onChange={(e: any) => {
											setStatesSelect(e);
											checkFormFilled();
										}}
										sx={{
											':before': {
												borderRight: '1px solid #A2CBFE',
												marginRight: '50px',
												height: '100%'
											}
										}}
									>
										{states &&
											states.map(({ iso3, name }) => (
												<MenuItem key={iso3} value={name}>
													{name}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={6}>
						<Controller
							control={form.control}
							name="city"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel
										required
										error={false}
										shrink
										htmlFor="city"
										sx={{ position: 'relative' }}
									>
										CITY
									</InputLabel>
									<Select
										{...field}
										fullWidth
										input={<BootstrapInput />}
										labelId="demo-simple-select-label"
										id="state"
										variant="standard"
										value={form.getValues('city')}
										onChange={e => {
											form.setValue('city', e.target.value);
											checkFormFilled();
										}}
										sx={{
											':before': {
												borderRight: '1px solid #A2CBFE',
												marginRight: '50px',
												height: '100%'
											}
										}}
									>
										{cities &&
											cities.map(name => (
												<MenuItem key={name} value={name}>
													{name}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							)}
						/>
					</Grid>

					<Grid item md={6}>
						<Controller
							control={form.control}
							name="address"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel
										required
										error={!!form.formState.errors.address}
										shrink
										htmlFor="city"
									>
										ADDRESS
									</InputLabel>
									<OutlinedInput
										fullWidth
										style={{
											border: 'none',
											borderRadius: '9px',
											marginTop: '20px'
										}}
										error={!!form.formState.errors.address}
										placeholder="type here..."
										{...field}
										type="string"
										onChange={e => {
											form.setValue('address', e.target.value);
											checkFormFilled();
										}}
									/>
									{!!form.formState.errors.address && (
										<FormHelperText error>
											{form?.formState?.errors?.address?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={6}>
						<Controller
							control={form.control}
							name="certifier"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth disabled={editAsset ? true : false}>
									<InputLabel
										required
										error={false}
										shrink
										htmlFor="type"
										sx={{ position: 'relative' }}
									>
										UNIT TYPE
									</InputLabel>
									<Select
										{...field}
										fullWidth
										input={<BootstrapInput />}
										labelId="demo-simple-select-label"
										id="type"
										variant="standard"
										value={form.getValues('type')}
										onChange={e => {
											form.setValue('type', e.target.value);
											checkFormFilled();
										}}
										sx={{
											':before': {
												borderRight: '1px solid #A2CBFE',
												marginRight: '50px',
												height: '100%'
											}
										}}
									>
										{unitTypes &&
											unitTypes.map(i => (
												<MenuItem key={i} value={i}>
													{i}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={6}>
						<Controller
							control={form.control}
							name="startOfOperation"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth disabled={editAsset ? true : false}>
									<InputLabel
										required
										error={!!form.formState.errors.startOfOperation}
										shrink
										htmlFor="number"
									>
										OPERATIONS START DATE
									</InputLabel>
									<OutlinedInput
										fullWidth
										style={{
											border: 'none',
											borderRadius: '9px',
											marginTop: '20px'
										}}
										error={!!form.formState.errors.startOfOperation}
										placeholder="e.g. YYYY-MM-DD"
										{...field}
										type="date"
										inputProps={{
											max:  dayjs().format('YYYY-MM-DD'),
										  }}
										onChange={e => {
											form.setValue('startOfOperation', e.target.value);
											checkFormFilled();
										}}
									/>
									{!!form.formState.errors.startOfOperation && (
										<FormHelperText error>
											{form?.formState?.errors?.startOfOperation?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={3}>
						<Controller
							control={form.control}
							name="co2PerMWh"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth disabled={editAsset ? true : false}>
									<InputLabel
										required
										error={!!form.formState.errors.co2PerMWh}
										shrink
										htmlFor="number"
									>
										CO2 / MWh
									</InputLabel>
									<OutlinedInput
										fullWidth
										style={{
											border: 'none',
											borderRadius: '9px',
											marginTop: '20px'
										}}
										error={!!form.formState.errors.co2PerMWh}
										placeholder="e.g. 0.00"
										{...field}
										type="number"
										inputProps={{
											step: "0.01"
										  }}
										onChange={e => {
											form.setValue('co2PerMWh', e.target.value);
											checkFormFilled();
										}}
									/>
									{!!form.formState.errors.co2PerMWh && (
										<FormHelperText error>
											{form?.formState?.errors?.co2PerMWh?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={3}>
						<Controller
							control={form.control}
							name="installedCapacity"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth disabled={editAsset ? true : false}>
									<InputLabel
										required
										error={!!form.formState.errors.installedCapacity}
										shrink
										htmlFor="number"
									>
										CAPACITY (MW)
									</InputLabel>
									<OutlinedInput
										fullWidth
										style={{
											border: 'none',
											borderRadius: '9px',
											marginTop: '20px'
										}}
										error={!!form.formState.errors.installedCapacity}
										placeholder="e.g. 0.00"
										{...field}
										type="number"
										inputProps={{
											step: "0.01"
										  }}
										onChange={e => {
											form.setValue('installedCapacity', e.target.value);
											checkFormFilled();
										}}
									/>
									{!!form.formState.errors.installedCapacity && (
										<FormHelperText error>
											{form?.formState?.errors?.installedCapacity?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={6}>
						<Controller
							control={form.control}
							name="unitCode"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth disabled={editAsset ? true : false}>
									<InputLabel
										required
										error={!!form.formState.errors.unitCode}
										shrink
										htmlFor="number"
									>
										CEG
									</InputLabel>
									<OutlinedInput
										fullWidth
										style={{
											border: 'none',
											borderRadius: '9px',
											marginTop: '20px'
										}}
										error={!!form.formState.errors.unitCode}
										placeholder="e.g. GGG.FF.UF.999999-D.VV"
										{...field}
										type="string"
										onChange={e => {
											form.setValue('unitCode', e.target.value);
											checkFormFilled();
										}}
									/>
									{!!form.formState.errors.unitCode && (
										<FormHelperText error>
											{form?.formState?.errors?.unitCode?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={3}>
					<Controller
							control={form.control}
							name="cceeCode"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth disabled={editAsset ? true : false}>
									<InputLabel
										required
										error={!!form.formState.errors.cceeCode}
										shrink
										htmlFor="number"
									>
										CCEE CODE
									</InputLabel>
									<OutlinedInput
										fullWidth
										style={{
											border: 'none',
											borderRadius: '9px',
											marginTop: '20px'
										}}
										error={!!form.formState.errors.cceeCode}
										placeholder="e.g. 500"
										{...field}
										type="string"
										onChange={e => {
											form.setValue('cceeCode', e.target.value);
											checkFormFilled();
										}}
									/>
									{!!form.formState.errors.cceeCode && (
										<FormHelperText error>
											{form?.formState?.errors?.cceeCode?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={3}>
						<Controller
							control={form.control}
							name="certifier"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth disabled={editAsset ? true : false}>
									<InputLabel
										required
										error={false}
										shrink
										htmlFor="number"
										sx={{ position: 'relative' }}
									>
										CERTIFIED BY
									</InputLabel>
									<Select
										{...field}
										fullWidth
										input={<BootstrapInput />}
										labelId="demo-simple-select-label"
										id="certifier"
										variant="standard"
										value={form.getValues('certifier')}
										onChange={e => {
											form.setValue('certifier', e.target.value);
											checkFormFilled();
										}}
										sx={{
											':before': {
												borderRight: '1px solid #A2CBFE',
												marginRight: '50px',
												height: '100%'
											}
										}}
									>
										{certifiers &&
											certifiers.map(i => (
												<MenuItem key={i} value={i}>
													{i}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							)}
						/>
					</Grid>
					<Grid
						item
						md={12}
						marginTop="59px"
						display="flex"
						justifyContent="flex-start"
						width="100%"
					>
						<Button
							onClick={goToConfig}
							variant="outlined"
							sx={{
								borderRadius: '25px',
								border: '1px solid #001',
								padding: '4px 30px',
								width: '107px',
								marginRight: '52px',
								'&:hover': {
									borderColor: '#68707c',
									backgroundColor: 'transparent'
								}
							}}
						>
							<Typography
								color="#000"
								fontSize="1.25rem"
								sx={{
									textTransform: 'initial'
								}}
							>
								Back
							</Typography>
						</Button>
						<LoadingButton
							disableElevation
							disabled={isButtonDisabled}
							variant="contained"
							loading={loading}
							onClick={onSubmit}
							sx={{
								width: '169px',
								backgroundColor: buttonColor,
								borderRadius: '25px',
								padding: '8px 24px',
								'&:hover': {
									backgroundColor: '#68707c'
								}
							}}
						>
							<Typography
								color="#fff"
								fontSize="1.25rem"
								fontWeight="400"
								sx={{
									textTransform: 'initial'
								}}
							>
								Save data
							</Typography>
						</LoadingButton>
					</Grid>
					{openAuthorization &&
						<ConfirmationModal
							open={openAuthorization}
							title={
								editAsset
									? 'Editing power plant information'
									: 'Creating a Power Unit'
							}
							subTitle={
								editAsset
									? 'This action will make you authorize the alteration of power plant information Addition of REC.'
									: 'This action will make you authorize the creation of a power unit.'
							}
							walletName={user.wallet_name}
							walletAddress={user.wallet_address}
							handleCloseAuthorization={handleCloseAuthoraztion}
							setPasswordAuthorization={setPasswordAuthorization}
							onSubmitAuthorization={onSubmitAuthorization}
							loadingAuthorization={loadingAuthorization}
							confirm="Sign"
							cancel="Cancel"
						/>
					}
				</Grid>
			</form>
		</>
	);
};
