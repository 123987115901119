import { RecTokenService } from '../../services/rec.service';

const INITIAL_STATE: any[] = [];

interface IPayload {
	idAsset: string;
}

interface IAction {
	type: string;
	payload: IPayload;
}

const recTokenService = new RecTokenService();

const getRecAssets = async (idAsset: string) => {
	const resplistTokenAseet: any = await recTokenService.infoViewRecToken(
		idAsset
	);
	return resplistTokenAseet;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function (state = INITIAL_STATE, action: IAction) {
	switch (action.type) {
		case 'GET_ASSET_REC':
			return getRecAssets(action.payload.idAsset);
		default:
			return state;
	}
}
