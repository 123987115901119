/* eslint-disable prettier/prettier */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Button } from '@mui/material';
import BackgroundWalee from '../../assets/imgs/backgroundwalee.png';
import { Header } from '../../components/header';
import { SideBar } from '../../components/sideBar';
import { createWallet } from '../../stores/nextStep/nextStep.actions';
import { CardWalletAsset } from './cardWalletAsset';

export const RecoveryWalletAsset = () => {

  const history = useNavigate();
  const dispatch = useDispatch();
  const [checked, setChecket] = useState<boolean>(false);
  const [mnemonic, setMnemonic] = useState<string>('');
  const [walletAddress, setWalletAddress] = useState<string>('');
  const [loadedWallet, setLoadedWallet] = useState<boolean>(false);

  const nextStepWallet = useSelector((state: any) => state.nextStep);
  const recoveryWallet = useSelector((state: any) => state.recovery);

  const gotToProfile = () => {
    history('/rec/assets');
  }

  return (
    <>
      <Header />
      <SideBar />
      <Box>
        <Box
          position="absolute"
          top="230px"
          marginLeft="180px"
        >
          <Button
            onClick={gotToProfile}
            variant="outlined"
            sx={{
              borderRadius: '25px',
              width: '107px',
              border: '1px solid #000',
              color: '#000',
              '&:hover': {
                border: '1px solid #000',
              }
            }}
          >
            Back
          </Button>
        </Box>
        <CardWalletAsset mnemonic={mnemonic} walletAddress={walletAddress} />
        <Box
          position="absolute"
          bottom="0"
          width="35%"
          left="152px"
        >
          <img
            src={BackgroundWalee}
            alt="backgroundimage"
          // width="50%"
          >
          </img>
        </Box>
        <Box
          display="flex"
          position="absolute"
          bottom="10px"
          right="150px"
        // marginLeft="152px"
        // marginTop="5px"
        >
          <Box
            sx={{
              alignSelf: 'flex-start',
              marginTop: '0',
              marginLeft: '54px'
            }}>
          </Box>
        </Box>
      </Box>
    </>
  );
};
