/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useSnackbar } from 'notistack';

import {
	Box,
	Select,
	styled,
	InputBase,
	MenuItem,
	Typography,
	InputLabel
} from '@mui/material';

import { Label } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import LoadingButton from '@mui/lab/LoadingButton';

import { MyButton } from '../../../components/myButton';
import { RecTokenService } from '../../../services/rec.service';
import { getRecAsset } from '../../../stores/assetRecs/assetRecs.actions';
import { colors } from '../../../styles';
import { IRecListToken } from '../../../types/recListToken';
import { ConfirmationModal } from '../../../components/confirmationModal';
import { useNavigate } from 'react-router-dom';

export type TRecManager = {
	setViewForm: (value: boolean) => void;
	asseId: string;
	setSuccessInsert: (value: boolean) => void;
	setReloadRecList: (value: boolean) => void;
};

export const RecManager = ({
	setViewForm,
	asseId,
	setSuccessInsert,
	setReloadRecList
}: TRecManager) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const recTokenService = new RecTokenService();
	const { enqueueSnackbar } = useSnackbar();
	const [dataListRecSelect, setDataListRecSelect] = useState<
		Array<IRecListToken>
	>([]);
	const [recSelect, setRecSelect] = useState<string>('select');
	const [insertLoading, setInsertLoading] = useState<boolean>(false);
	const [disabledButton, setDisabledButton] = useState<boolean>(true);
	const [passwordAuthorization, setPasswordAuthorization] = useState<string>('');
	const [openAuthorization, setOpenAuthorization] = useState<boolean>(false);
	const [loadingAuthorization, setLoadingAuthorization] =
		useState<boolean>(false);

	const listTokenAsset = useSelector((state: any) => state.listRecTokenAsset);

	const addNewrecLabel = () => {
		setViewForm(true);
	};

	const BootstrapInput = styled(InputBase)(() => ({
		'& .MuiInputBase-input': {
			display: 'flex',
			alignItems: 'center',
			padding: '14px 25px',
			borderRadius: '9px',
			// backgroundColor: 'transparent',
			border: '1px solid #000',
			'& .MuiSvgIcon-root': {
				marginTop: '100px',
				color: '#fff'
			},
			'&:focus': {
				borderRadius: '10px'
			},
			':after': {
				borderBottom: 'none',
				borderRight: '1px solid #000',
				marginRight: '50px'
			}
		}
	}));

	useEffect(() => {
		const getListAssetToken = async () => {
			const respRecListToken = await listTokenAsset;
			setDataListRecSelect(respRecListToken);
		};
		getListAssetToken();
	}, [listTokenAsset]);

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;

	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250
			}
		}
	};

	const onSubmit = () => {
		setOpenAuthorization(true);
	};

	const handleCloseAuthoraztion = () => {
		setOpenAuthorization(false);
	};

	const onSubmitAuthorization = async () => {
		setInsertLoading(true);
		setLoadingAuthorization(true);
		const respInsertRec = await recTokenService.insertRecToken(
			asseId,
			recSelect
		);
		if (respInsertRec.status === 200) {
			setInsertLoading(false);
			setLoadingAuthorization(false);
			dispatch(getRecAsset(asseId));
			enqueueSnackbar('Rec inserted successfully', {
				variant: 'success'
			});
			setReloadRecList(true)
		}

		if ([400, 401, 404, 500].includes(respInsertRec.status)) {
			setInsertLoading(false);
			setSuccessInsert(false);
			setLoadingAuthorization(false);
			enqueueSnackbar(respInsertRec.statusText, {
				variant: 'error'
			});
		}
		setOpenAuthorization(false);
	};

	return (
		<>
			<Box marginLeft="215px">
				<Box marginBottom="35px">
					<Typography>SELECT OPTION</Typography>
				</Box>
				<Box
					sx={{
						position: 'relative',
						overflow: 'hidden',
						maxWidth: '352px',
						border: '1px solid #79747E',
						height: '80px',
						borderRadius: '12px',
						backgroundColor: '#636C7A',
						display: 'flex',
						alignItems: 'center',
						marginBottom: '35px'
					}}
				>
					<Box display="flex" alignItems="center" marginLeft="16px">
						<Box
							sx={{
								width: '40px',
								height: '40px',
								borderRadius: '20px',
								backgroundColor: '#00CA95',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								marginRight: '16px'
							}}
						>
							<Typography fontWeight="500" color="#fff" lineHeight="24px">
								N
							</Typography>
						</Box>
						<Typography
							fontSize="14px"
							fontWeight="500"
							fontFamily="Sen, Roboto"
							color="#fff"
						>
							Create a new REC Label
						</Typography>
					</Box>
					<Box
						onClick={addNewrecLabel}
						sx={{
							position: 'absolute',
							right: '0',
							width: '80px',
							height: '80px',
							backgroundColor: '#F0F0F0',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							cursor: 'pointer',
							'&:hover': {
								backgroundColor: '#e8e6e6'
								// transition: 'backgroundColor 5000ms linear'
							}
						}}
					>
						<AddIcon sx={{ color: '#6F5F5F', fontSize: '34px' }} />
					</Box>
				</Box>

				<Box>
					<InputLabel sx={{ marginBottom: '8px' }}>
						ADD EXISTING REC LABEL
					</InputLabel>
					<Select
						sx={{ maxWidth: '352px' }}
						fullWidth
						placeholder="select..."
						input={<BootstrapInput />}
						labelId="demo-simple-select-label"
						id="country"
						variant="standard"
						value={recSelect}
						MenuProps={MenuProps}
						onChange={e => {
							setRecSelect(e.target.value)
							if(e.target.value != "select"){
								setDisabledButton(false)
							} else{
								setDisabledButton(true)
							}
						}}
					>
						<MenuItem value="select">
							<em>select...</em>
						</MenuItem>
						{dataListRecSelect &&
							dataListRecSelect.map(({ name, id }) => (
								<MenuItem
									key={id}
									value={id}
									sx={{
										borderBottom: `1px solid ${colors.neutralMedium}`,
										margin: 'auto 10px'
									}}
								>
									{name}
								</MenuItem>
							))}
					</Select>
				</Box>
				<Box sx={{ marginTop: '168px' }}>
					<LoadingButton
						onClick={onSubmit}
						loading={insertLoading}
						disabled={disabledButton}
						variant="contained"
						sx={{
							color: '#fff',
							backgroundColor:
								recSelect === 'select' ? colors.primaryLight : colors.primary,
							borderRadius: '25px',
							padding: '8px 32px',
							marginRight: '48px',
							boxShadow: 'none',
							'&:hover': {
								backgroundColor: colors.primaryLight
							}
						}}
					>
						<Typography
							fontWeight="700"
							fontSize="20px"
							textTransform="initial"
						>
							Insert REC
						</Typography>
					</LoadingButton>
					<MyButton
						onClick={() => navigate(-1)}
						variant="outlined"
						color="#000"
						border="1px solid"
						borderRadius="25px"
						padding="4px 34px"
						boxShadow="none"
					>
						<Typography
							fontWeight="400"
							fontSize="20px"
							textTransform="initial"
						>
							Cancel
						</Typography>
					</MyButton>
				</Box>
				{openAuthorization &&
					<ConfirmationModal
						open={openAuthorization}
						title="Adding REC to the power plant"
						subTitle="This action will make you authorize the addition of a new REC to the power plant."
						handleCloseAuthorization={handleCloseAuthoraztion}
						onSubmitAuthorization={onSubmitAuthorization}
						loadingAuthorization={loadingAuthorization}
						confirm="Add"
						cancel="Cancel"
					/>
				}
			</Box>
		</>
	);
};
