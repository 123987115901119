import * as Yup from 'yup';

export const SchemaFormProfile = Yup.object().shape({
	first_name: Yup.string().required('Type your first name').matches(/^[A-Za-z]+$/, 'First name must contain only letters'),
	last_name: Yup.string().required('Type your last name').matches(/^[A-Za-z]+$/, 'Last name must contain only letters'),
	job_title: Yup.string().required('Type your job title').matches(/^[A-Za-z\s]+$/, 'Job title must contain only letters and spaces'),
	cpf: Yup.string().required('Type your CPF'),
	phone: Yup.string().required('Type your phone').min(10, 'Invalid phone number'),
	city: Yup.string().required('Select a city').matches(/^[A-Za-z\s]+$/, 'City must contain only letters and spaces'),
	address: Yup.string().required('Type your address'),
	zipcode: Yup.string().required('Type your zip code').matches(/^\d{8}/, 'Invalid zip code format'),
	email: Yup.string().email('Invalid email address').required('Type your email'),
});
