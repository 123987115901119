import { FC, useEffect, useRef, ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
	Box,
	Paper,
	PaperProps,
	TableContainer,
	Typography,
	Table as TableM,
	TableSortLabel,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	CircularProgress
} from '@mui/material';

import { visuallyHidden } from '@mui/utils';

import filterIcon from '../../../../assets/icon/filterTableHeader.png';

import PDF from '../../../../assets/imgs/pdf.png';
import { getTotalAvailableRec } from '../../../../stores/totalAvailableRec/totalAvailableRec.actions';
import { transferRec } from '../../../../stores/transferRec/transferRec.actions';
import { colors } from '../../../../styles';
import { ICertificateOrg, IDataPreview } from '../../../../types/certificateOrg';
import { HeadCell } from '../../../../types/headCell';
import { customScroll } from '../../../../view/commonStyles';
import { TextTableBody } from './textTableBody';

interface ITable extends PaperProps {
	title: string;
	titles: HeadCell[];
	transactions: ICertificateOrg[] | null;
	isLoaded: boolean;
}
let newObject: any[] = [];
type Order = 'asc' | 'desc';

interface ISortInput {
	[key: string]: number | string;
}
const initSortInput: ISortInput = {
	date: '',
	sellerOrg: '',
	seller: '',
	buyer: '',
	opType: '',
	status: '',
	sourceType: '',
	orgName: '',
	unitID: 0,
	location: '',
	period: '',
	uniqueId: '',
	amount: 0
};

export const Table: FC<ITable> = ({
	sx,
	title,
	titles,
	transactions,
	isLoaded,
	...props
}) => {
	const dispath = useDispatch();
	const [order, setOrder] = useState<Order>('desc');
	const [orderBy, setOrderBy] = useState<string>('date');

	const [sortedData, setSortedData] = useState<
		ICertificateOrg[] | undefined | null
	>(transactions);
	const [selected, setSelected] = useState(false);
	const [sortInput, setSortInput] = useState(initSortInput);

	const firstDivRef = useRef(null);
	const secondDivRef = useRef(null);

	useEffect(() => {
		setSortedData(transactions);
	}, [transactions]);

	const handleScrollFirst = (scroll: any) => {
		const current: any = secondDivRef.current;
		if (current) {
			current.scrollLeft = scroll.target.scrollLeft;
		}
	};

	const handleScrollSecond = (scroll: any) => {
		const current: any = firstDivRef.current;
		if (current) {
			current.scrollLeft = scroll.target.scrollLeft;
		}
	};

	useEffect(() => {
		if (newObject.length > 0) {
			newObject = [];
		}
	}, []);

	// const sendTransfer = (e: any, object: any) => {
	// };

	function descendingComparator<T>(a: T, b: T, orderByTile: keyof T) {
		if (
			orderByTile === 'sellerOrg' ||
			orderByTile === 'seller' ||
			orderByTile === 'buyer' ||
			orderByTile === 'opType' ||
			orderByTile === 'status' ||
			orderByTile === 'sourceType' ||
			orderByTile === 'orgName' ||
			orderByTile === 'location'
		) {
			const first: any = a[orderByTile];
			const second: any = b[orderByTile];
			if (
				!!first.replace(/\d+/, '') &&
				!!second.replace(/\d+/, '') &&
				first.replace(/\d+/, '') === second.replace(/\d+/, '') &&
				(first.replace(/\D+/, '') || second.replace(/\D+/, ''))
			) {
				return +second.replace(/\D+/, '') < +first.replace(/\D+/, '')
					? -1
					: +second.replace(/\D+/, '') > +first.replace(/\D+/, '')
					? 1
					: 0;
			}
		}
		if (orderByTile === 'date') {
			const first: any = a[orderByTile];
			const firstReFormat = first.replace(/(\d{2})\/(\d{2})/, '$2/$1');
			const second: any = b[orderByTile];
			const secondReFormat = second.replace(/(\d{2})\/(\d{2})/, '$2/$1');
			return (
				new Date(secondReFormat).valueOf() - new Date(firstReFormat).valueOf()
			);
		}
		if (orderByTile === 'period') {
			const first: any = `01/${a[orderByTile]}`;
			const second: any = `01/${b[orderByTile]}`;
			return new Date(second).valueOf() - new Date(first).valueOf();
		}
		if (b[orderByTile] < a[orderByTile]) {
			return -1;
		}
		if (b[orderByTile] > a[orderByTile]) {
			return 1;
		}

		return 0;
	}

	function getComparator<Key extends keyof any>(
		orderComparator: Order,
		orderByComparator: Key
	): (
		a: { [key in Key]: number | string | any },
		b: { [key in Key]: number | string | any }
	) => number {
		return orderComparator === 'desc'
			? (a, b) => descendingComparator(a, b, orderByComparator)
			: (a, b) => -descendingComparator(a, b, orderByComparator);
	}

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: string
	) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const createSortHandler =
		(property: string) => (event: React.MouseEvent<unknown>) => {
			handleRequestSort(event, property);
		};

	const handleBlur = () => {
		const validate = Object.keys(sortInput).filter(i => !!sortInput[i]);
		let newData: ICertificateOrg[] | null = transactions;
		if (validate.length) {
			validate?.forEach((key: any) => {
				if (newData) {
					newData = newData.filter((i: any) => {
						if (typeof i[key] === 'number') {
							return i[key] === +sortInput[key];
						} else {
							return i[key]
								.toLowerCase()
								.includes(sortInput[key]?.toString().toLowerCase());
						}
					});
				}
			});
			setSortedData(newData);
		} else {
			setSortedData(transactions);
		}
	};

	return (
		<>
			<Paper {...props} sx={{ boxShadow: 'none', ...sx }}>
				<TableContainer
					sx={{
						overflow: !!sortedData?.length ? 'hidden' : 'auto',
						...customScroll
					}}
					onScroll={handleScrollFirst}
					ref={firstDivRef}
				>
					<TableM>
						<TableHead>
							<TableRow
								sx={{
									position: 'relative'
								}}
							>
								{titles &&
									// eslint-disable-next-line @typescript-eslint/no-shadow
									titles.slice().map((title, index) => (
										<TableCell
											align={title.numeric ? 'left' : 'center'}
											key={title.id}
											padding={title.disablePadding ? 'none' : 'normal'}
											sortDirection={orderBy === title.id ? order : false}
											sx={{
												paddingRight:
													(titles.length - 1 === index && '40px') || '16px',
												borderTopLeftRadius: index === 0 ? '16px' : 0
											}}
										>
											{title.id !== 'report' ? (
												<TableSortLabel
													sx={{ width: '100%' }}
													hideSortIcon
													direction={orderBy === title.id ? order : 'asc'}
													onClick={createSortHandler(title.id)}
												>
													<Box
														display="flex"
														justifyContent="space-between"
														alignItems="center"
														width="100%"
													>
														<Typography
															sx={{
																width: '100%',
																whiteSpace: 'nowrap',
																fontSize: '12px',
																fontWeight: '700',
																color: '#9FA2B4'
															}}
														>
															{title.label}
														</Typography>
														<img src={filterIcon}></img>
													</Box>
													{orderBy === title.id ? (
														<Box component="span" sx={visuallyHidden}>
															{order === 'desc'
																? 'sorted descending'
																: 'sorted ascending'}
														</Box>
													) : null}
												</TableSortLabel>
											) : (
												<Typography
													sx={{
														fontSize: '0.75rem',
														fontWeight: '700',
														color: '#9FA2B4'
													}}
												>
													{title.label}
												</Typography>
											)}
										</TableCell>
									))}
							</TableRow>
						</TableHead>
					</TableM>
				</TableContainer>

				<TableContainer
					onScroll={handleScrollSecond}
					ref={secondDivRef}
					sx={{
						height: 'calc(100vh - 550px)',
						minHeight: '300px',
						...customScroll
					}}
				>
					<TableM>
						<TableBody>
							{sortedData &&
								sortedData
									.slice()
									.sort(getComparator(order, orderBy))
									.map(transaction => (
										<TableRow key={transaction.id}>
											<TableCell width="100px">
												<TextTableBody
													sx={{ fontSize: '13px', whiteSpace: 'wrap' }}
												>
													{transaction.date}
												</TextTableBody>
											</TableCell>
											<TableCell>
												<TextTableBody>{transaction.orgName}</TextTableBody>
											</TableCell>
											<TableCell>
												<TextTableBody>{transaction.opType}</TextTableBody>
											</TableCell>
											<TableCell width="130px">
												<TextTableBody>{transaction.status}</TextTableBody>
											</TableCell>
											<TableCell width="150px">
												<TextTableBody>{transaction.recLabel}</TextTableBody>
											</TableCell>
											<TableCell>
												<TextTableBody>{transaction.assetSourceType}</TextTableBody>
											</TableCell>
											<TableCell>
												<TextTableBody>{transaction.assetCountry}</TextTableBody>
											</TableCell>
											<TableCell sx={{ paddingRight: '0' }}>
												<TextTableBody>{transaction.period}</TextTableBody>
											</TableCell>
											<TableCell width="210px">
												<TextTableBody>{transaction.assetUnitID}</TextTableBody>
											</TableCell>
											<TableCell width="180px">
												<TextTableBody>{transaction.amountRec}</TextTableBody>
											</TableCell>
										</TableRow>
									))}
						</TableBody>
					</TableM>
					{!isLoaded && (
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '100%'
								// paddingTop: '30px'
							}}
						>
							<CircularProgress />
						</Box>
					)}
				{sortedData && sortedData.length < 1 && isLoaded && (
						<Typography
							sx={{
								display: 'flex',
								paddingTop: '20px',
								width: '100%',
								justifyContent: 'center',
								alignItems: 'center',
								fontSize: '1.75rem',
								fontWeight: '700',
								color: colors.primary
							}}
						>
							Empty table!
						</Typography>
					)}
				</TableContainer>
			</Paper>
		</>
	);
};
