/* eslint-disable import/extensions */
import { useState } from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import {
	Box,
	Button,
	Grid,
	Typography,
	InputLabel,
	FormControl,
	InputAdornment,
	IconButton,
	FormHelperText,
	OutlinedInput
} from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';

// eslint-disable-next-line import/extensions
import {
	authLogin,
	signinDefault
} from '../../../stores/authLogin/authLogin.actions';
import { signupDefault } from '../../../stores/authSignup/authSignup.actions';
import { goToRecoveryPassword } from '../../../stores/forgotPassword/forgotPassword.actions';
import { colors } from '../../../styles/colors';
import { colorButton } from '../../../utils/colorButton';
import { SchemaSigninDefault } from './schemaSignin';

interface IState {
	amount: string;
	password: string;
	weight: string;
	weightRange: string;
	showPassword: boolean;
}
interface IFormSignin {
	email: string;
	password: string;
}

export const CardSignin = () => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [fillFieldEmail, setFillFieldEmail] = useState<string>('');
	const [fillFieldPassword, setFillFieldPassword] = useState<string>('');
	const [errorPassword, setErrorPassword] = useState<boolean>(false);
	const [loadingLogin, setLoadingLogin] = useState<boolean>(false);

	// const loginAuth = useSelector(useLoginAuth);
	const loginAuth = useSelector((state: any) => state.authLogin);

	const [values, setValues] = useState<IState>({
		amount: '',
		password: '',
		weight: '',
		weightRange: '',
		showPassword: false
	});

	const form = useForm<IFormSignin>({
		resolver: yupResolver(SchemaSigninDefault)
	});

	const handleClickShowPassword = () => {
		setValues({
			...values,
			showPassword: !values.showPassword
		});
	};

	const toSigin = () => {
		dispatch(goToRecoveryPassword(false));
		history('/signup');
	};

	useEffect(() => {
		dispatch(signupDefault());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const respLoginAuth = async () => {
			setLoadingLogin(true);
			const respLogin = await loginAuth;
			if (respLogin.status === 200) {
				localStorage.setItem('token', respLogin.data.token);

				localStorage.setItem(
					'enterprise_id',
					respLogin.data.user.enterprise_id
				);

				localStorage.setItem('user_avatar_url', respLogin.data.user.logo);

				enqueueSnackbar('Sucess', {
					variant: 'success'
				});

				window.location.replace('/');
			} else {
				if (respLogin.status === 401) {
					setErrorPassword(true);
					localStorage.removeItem('token');

					dispatch(signinDefault());
					enqueueSnackbar(respLogin.statusText, {
						variant: 'error'
					});
				}
			}
			setLoadingLogin(false);
		};

		respLoginAuth();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loginAuth]);

	const emailInput = (e: any) => {
		form.setValue('email', e.target.value);
		setFillFieldEmail(e.target.value);
	};

	const passwordInput = (e: any) => {
		form.setValue('password', e.target.value);
		setFillFieldPassword(e.target.value);
		setErrorPassword(false);
	};

	const onSubmit = async () => {
		const { email, password } = form.getValues();
		if (!email || !password) return;
		dispatch(authLogin(email, password));
	};

	const goToRecoveryPasswordPage = () => {
		history('/signup');
		dispatch(goToRecoveryPassword(true));
	};


	const handleKeyPress = (event: { key: string; }) => {
		if (event.key === 'Enter' && !loadingLogin) {
			onSubmit();
		}
	};
	
	useEffect(() => {
		// Add event listener when component mounts
		document.addEventListener('keydown', handleKeyPress);
	
		// Remove event listener when component unmounts
		return () => {
		  document.removeEventListener('keydown', handleKeyPress);
		};
	}, [loadingLogin]);
	  

	return (
		<form onSubmit={() => {}}>
			<Grid container md={12} padding="66px 44px 40px 44px">
				<Grid item xs={12} md={12} marginBottom="52px">
					<Box marginBottom="40px" sx={{ filter: 'grayscale(100%)' }}>
						<Typography
							fontFamily="sen"
							fontStyle="normal"
							fontWeight="700"
							fontSize="1.5rem"
							color={colors.supportDark}
						>
							Welcome to MAT!
						</Typography>
					</Box>

					<Box>
						<Typography
							fontFamily="sen"
							fontStyle="normal"
							fontWeight="700"
							fontSize="3rem"
							lineHeight="64px"
							color={colors.neutralDark}
						>
							Login
						</Typography>
					</Box>
				</Grid>

				<Grid item xs={12} md={12} marginBottom="41px">
					<Controller
						control={form.control}
						name="email"
						render={({ field }) => (
							<FormControl variant="standard" fullWidth>
								<InputLabel error={false} shrink htmlFor="email">
									Type your login
								</InputLabel>
								<OutlinedInput
									sx={{
										marginTop: '20px'
									}}
									error={false}
									placeholder="user or email"
									{...field}
									type="string"
									onChange={e => emailInput(e)}
								/>
								{/* {!!form.formState.errors.email && (
									<FormHelperText error>
										{form?.formState?.errors?.email?.message}
									</FormHelperText>
								)} */}
							</FormControl>
						)}
					/>
				</Grid>

				<Grid item xs={12} md={12}>
					<Controller
						control={form.control}
						name="password"
						render={({ field }) => (
							<FormControl variant="standard" fullWidth>
								<InputLabel error={errorPassword} shrink htmlFor="password">
									Type your password
								</InputLabel>
								<OutlinedInput
									sx={{
										marginTop: '20px'
									}}
									id="outlined-adornment-password"
									type={values.showPassword ? 'text' : 'password'}
									endAdornment={
										<InputAdornment position="end" sx={{ marginRight: '25px' }}>
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												edge="end"
											>
												{values.showPassword ? (
													<VisibilityOff />
												) : (
													<Visibility />
												)}
											</IconButton>
										</InputAdornment>
									}
									error={errorPassword}
									placeholder="password"
									{...field}
									onChange={e => passwordInput(e)}
								/>
								{errorPassword && (
									<FormHelperText error>{'Invalid password'}</FormHelperText>
								)}
							</FormControl>
						)}
					/>
					<Box sx={{ cursor: 'pointer' }} onClick={goToRecoveryPasswordPage}>
						<Typography
							fontSize="12px"
							lineHeight="20px"
							marginTop="18px"
							textAlign="right"
						>
							Forgot your password?
						</Typography>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					md={12}
					marginTop="49px"
					display="flex"
					justifyContent="center"
				>
					<LoadingButton
						disableElevation
						disabled={loadingLogin}
						sx={{
							borderRadius: '25px',
							backgroundColor: colorButton(fillFieldEmail, fillFieldPassword),
							'&:hover': {
								backgroundColor: colorButton(fillFieldEmail, fillFieldPassword)
							}
						}}
						loading={loadingLogin}
						variant="contained"
						onClick={form.handleSubmit(onSubmit)}
					>
						<Typography
							sx={{
								color: colors.neutralLight,
								padding: '8px 32px',
								fontSize: '24px',
								lineHeight: '32px',
								fontWeight: '700',
								textTransform: 'initial'
							}}
						>
							Enter now
						</Typography>
					</LoadingButton>
				</Grid>

				<Grid
					item
					xs={12}
					md={12}
					display="flex"
					alignItems="center"
					justifyContent="center"
					marginTop="56px"
				>
					<Typography
						color={colors.primary}
						lineHeight="24px"
						fontWeight="400"
						marginRight="20px"
					>
						First time here?
					</Typography>
					<Button onClick={toSigin}>
						<Typography color={colors.primary}>CREATE ACCESS</Typography>
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};
