// Core
import React, { FC } from 'react';

import { Tooltip, Typography, TypographyProps } from '@mui/material';

import { colors } from '../../../../styles';

export const TextTableBody: FC<TypographyProps> = ({
	sx,
	children,
	...props
}) => {
	return (
		<Tooltip placement="top-start" title={children}>
			<Typography
				{...props}
				sx={{
					maxWidth: '100px',
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					fontSize: '.75rem',
					fontWeight: '700',
					color: colors.primaryDark,
					...sx
				}}
			>
				{children}
			</Typography>
		</Tooltip>
	);
};
