/* eslint-disable import/extensions */
import { Api } from './api/axios-config';

interface IUser {
	first_name: string;
	last_name: string;
	password: string;
	token: string;
}
export class LoginService {
	async signin(email: string, password: string) {
		try {
			const resLogin = await Api.post(`/sessions/`, {
				email,
				password
			});
			return resLogin;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async signup(user: IUser) {
		try {
			const resLogin = await Api.post(`/enterprises/members`, user);
			return resLogin;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}
}
