import { Skeleton } from '@mui/material';

export const SkeletonLogo = () => {
	return (
		<>
			<Skeleton
				variant="circular"
				sx={{
					position: 'relative',
					width: '160px',
					height: '160px',
					borderRadius: '16px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			></Skeleton>
		</>
	);
};
