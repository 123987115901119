import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Grid, Typography } from '@mui/material';

import Wallet from '../../../assets/icon/create-wallet-page.svg';
import WalletGreen from '../../../assets/icon/create-wallet-page-green.svg';
import Recovery from '../../../assets/icon/recovery-page.svg';
import Graph from '../../../assets/icon/graph-page.svg';
import Tooltip from '@mui/material/Tooltip';
import { ChangeEvent, useEffect, useState } from 'react';
import { UploadLogo } from '../../uploadLogo';
import { useSnackbar } from 'notistack';
import { AssetService } from '../../../services/asset.service';
import { ApiPromise, WsProvider } from '@polkadot/api';
import { AiOutlineEdit } from 'react-icons/ai';
import { TbCertificate, TbCertificateOff} from 'react-icons/tb';

import { BsTrash } from 'react-icons/bs';
import ImageDefaultClient from '../../../assets/imgs/PowerPlantIA.jpg';


export const UnitManager = ({ asset, setAssetInfo }: any) => {
	const assetService = new AssetService();
	const walletCreate = useSelector(
		(state: any) => state.createWalletAsset
	)
	const walletRecovery = useSelector(
		(state: any) => state.recoveryWalletAsset
	)
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	
	const [uploadFile, setUploadFile] = useState<File | null>(null);
	const [loadImage, setLoadImage] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isAssetCertified, setIsAssetCertified] = useState<boolean>();

	const history = useNavigate();

	const goToRecLabel = () => {
		history(`/rec/asset/${asset.id}/rec`);
	};
	const goToRecoveryWalletAsset = () => {
		history(`/rec/assets/${asset.id}/wallet/recovery`);
	}
	const goToCreateWalletAsset = () => {
		history(`/rec/assets/${asset.id}/wallet/create`);
	}
	const goToDashboardAsset = () => {
		history(`/rec/assets/${asset.id}/dashboard/`, { state: { asset } })
	}
	const goToRecIssue = () => {
		history(`/rec/issue/${asset.id}`);
	};
	const saveLogo = async (file: any) => {
		const formData = new FormData();
		formData.append('logo', file);

		setLoadImage(true);
		const saveLogoImage = await assetService.updateLogoById(asset.id,formData);
		if (saveLogoImage.status === 200) {
			setLoadImage(false);
			enqueueSnackbar('The logo has been uploaded', {
				variant: 'success'
			});
			return true;
		} else {
			setLoadImage(false);
			enqueueSnackbar('The logo was not saved on server', {
				variant: 'error'
			});
			return false;
		}
	};

	const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		if (files?.length) {
			const file = files[0];
			const logoSaved = await saveLogo(file);

			if (logoSaved) {
				setUploadFile(file);
			}
		}
	};

	useEffect(()=>{
		const getAssetCertificateStatus = async () => {
			const provider = new WsProvider(`${process.env.REACT_APP_PARACHAIN_ADDRESS}`);
			const api = await ApiPromise.create({ provider });
			const data = await api.query.myPowerPlants.listPowerPlants(asset.code);
			setIsLoading(false)
			const jsonData: any = data.toHuman()
			if(jsonData?.certified != undefined && jsonData?.certified != null){
				setIsAssetCertified(jsonData.certified)
				await assetService.updateCertificateStatus(asset.code, jsonData.certified)
			}
			await api.disconnect()
		}
		getAssetCertificateStatus()
	},[asset])

	return (
		<>
			<Grid container md={12}>
				<Grid item md={1.5}>
				<Button
						variant="outlined"
						onClick={() => setAssetInfo(false)}
						sx={{
							width: '107px',
							height: '40px',
							borderRadius: '25px',
							border: '1px solid #000',
							padding: '8px 24px',
							color: '#000',
							textTransform: 'initial',
							fontSize: '1.25rem',
							'&:hover': {
								border: '1px solid #000'
							}
						}}
					>
						<Typography fontSize="1.25rem" lineHeight="32px">
							Back
						</Typography>
					</Button>
				</Grid>
				<Grid item md={4}>
					<Typography
						fontFamily={'Sen'}
						fontStyle="normal"
						fontWeight="700"
						fontSize="28px"
						lineHeight="40px"
						color="#636C7A"
						sx={{ marginBottom: '20px', marginLeft: '30px' }}
					>
						Unit Manager
					</Typography>
				</Grid>
				<Grid item md={3}>
					<Tooltip title={!isAssetCertified ? 'Asset not certified' : !asset.wallet_address && 'You must create wallet first'} placement="top">
						<Box
							onClick={() => {
								if (isLoading){
									enqueueSnackbar('Wait for the asset validation', {variant: 'info'})
									return
								}
								if (!isAssetCertified){
									enqueueSnackbar('This asset is not certified', {variant: 'info'})
									return
								}
								if(!asset?.wallet_address){
									enqueueSnackbar('This asset must have a wallet', {variant: 'info'})
									return
								}
								goToRecLabel();
							}}
							sx={{
								backgroundColor: '#6F7075',
								width:"95%",
								height: '40px',
								borderRadius: '20px',
								opacity: isAssetCertified ? 1: 0.3,
								pointerEvents: isAssetCertified ? 'initial':'not-allowed' ,
								':hover': { cursor: asset.wallet_address ? 'pointer': 'not-allowed', }
							}}
						>
							<Typography
								fontFamily={'Sen'}
								fontStyle="normal"
								fontWeight="400"
								fontSize="20px"
								lineHeight="32px"
								color="#FFFFFF"
								textAlign={'center'}
								sx={{ paddingTop: '3px' }}
							>
								Create label
							</Typography>
						</Box>
					</Tooltip>
				</Grid>
				<Grid item md={3}>
					<Tooltip title={!isAssetCertified ? 'Asset not certified' : !asset.wallet_address && 'You must create wallet first'} placement="top">
						<Box
							onClick={() => {
								if (isLoading){
									enqueueSnackbar('Wait for the asset validation', {variant: 'info'})
									return
								}
								if (!isAssetCertified){
									enqueueSnackbar('This asset is not certified', {variant: 'info'})
									return
								}
								if(!asset?.wallet_address){
									enqueueSnackbar('This asset must have a wallet', {variant: 'info'})
									return
								}
								goToRecIssue();
							}}
							sx={{
								backgroundColor: '#6F7075',
								width:"95%",
								height: '40px',
								borderRadius: '20px',
								opacity: isAssetCertified ? 1: 0.3,
								pointerEvents: isAssetCertified ? 'initial':'not-allowed' ,
								':hover': { cursor: asset.wallet_address ? 'pointer': 'not-allowed', }
							}}
						>
							<Typography
								fontFamily={'Sen'}
								fontStyle="normal"
								fontWeight="400"
								fontSize="20px"
								lineHeight="32px"
								color="#FFFFFF"
								textAlign={'center'}
								sx={{
									paddingTop: '3px',
								}}
							>
								Issue REC
							</Typography>
						</Box>
					</Tooltip>
				</Grid>
			</Grid>

			<Box
				component="main"
				sx={{
					maxWidth: '940px',
					marginRight: '20px',
					height: '253px',
					border: '1px solid #E0E0E0',
					borderRadius: '20px'
				}}
			>
				<Grid container md={12}>
					<Grid item md={3}>
						<Box sx={{ margin: '45px 0 0 30px' }}>
							<UploadLogo
								error={false}
								dataUser={[]}
								id="input-load-image"
								image={uploadFile ? URL.createObjectURL(uploadFile) : asset?.logo ? asset?.logo : ImageDefaultClient}
								name="fileLogo"
								textLabel="Insert Logo"
								onChange={handleImageUpload}
								loadImage={loadImage}
							/>
							{/* {asset?.logo ? (
								<img
									src={asset?.logo}
									width={'160px'}
									height={'160px'}
									alt="unit2"
								/>
							) : (
								<img
									src={Ellipse10}
									width={'160px'}
									height={'160px'}
									alt="unisst"
								/>
							)} */}
						</Box>
					</Grid>
					<Grid item md={5}>
						<Typography
							fontFamily={'Sen'}
							fontStyle="normal"
							fontWeight="700"
							fontSize="24px"
							lineHeight="32px"
							sx={{ marginBottom: '20px', marginTop: '34px' }}
						>
							{asset?.name?.toUpperCase()}
						</Typography>
						<Box sx={{ marginBottom: '8px' }}>
							<Typography
								display={'inline'}
								fontFamily={'Sen'}
								fontStyle="normal"
								fontWeight="400"
								fontSize="16px"
								lineHeight="19px"
								color="#6F7075"
								sx={{ marginBottom: '9px' }}
							>
								CEG:
							</Typography>
							<Typography
								display={'inline'}
								fontFamily={'Sen'}
								fontStyle="normal"
								fontWeight="400"
								fontSize="15px"
								lineHeight="17px"
								color="#000000"
								sx={{ marginLeft: '18px' }}
							>
								{asset?.code}
							</Typography>
						</Box>
						<Box sx={{ marginBottom: '8px' }}>
							<Typography
								display={'inline'}
								fontFamily={'Sen'}
								fontStyle="normal"
								fontWeight="400"
								fontSize="16px"
								lineHeight="19px"
								color="#6F7075"
								sx={{ marginBottom: '9px' }}
							>
								UNIT TYPE:
							</Typography>
							<Typography
								display={'inline'}
								fontFamily={'Sen'}
								fontStyle="normal"
								fontWeight="400"
								fontSize="15px"
								lineHeight="17px"
								color="#000000"
								sx={{ marginLeft: '18px' }}
							>
								{asset?.type}
							</Typography>
						</Box>
						<Box sx={{ marginBottom: '8px' }}>
							<Typography
								display={'inline'}
								fontFamily={'Sen'}
								fontStyle="normal"
								fontWeight="400"
								fontSize="16px"
								lineHeight="19px"
								color="#6F7075"
								sx={{ marginBottom: '9px' }}
							>
								STATE:
							</Typography>
							<Typography
								display={'inline'}
								fontFamily={'Sen'}
								fontStyle="normal"
								fontWeight="400"
								fontSize="15px"
								lineHeight="17px"
								color="#000000"
								sx={{ marginLeft: '18px' }}
							>
								{asset?.state}
							</Typography>
						</Box>
						<Box sx={{ marginBottom: '8px' }}>
							<Typography
								display={'inline'}
								fontFamily={'Sen'}
								fontStyle="normal"
								fontWeight="400"
								fontSize="16px"
								lineHeight="19px"
								color="#6F7075"
								sx={{ marginBottom: '9px' }}
							>
								COUNTRY:
							</Typography>
							<Typography
								display={'inline'}
								fontFamily={'Sen'}
								fontStyle="normal"
								fontWeight="400"
								fontSize="15px"
								lineHeight="17px"
								color="#000000"
								sx={{ marginLeft: '18px' }}
							>
								{asset?.country}
							</Typography>
						</Box>
						<Box sx={{ marginBottom: '8px' }}>
							<Typography
								display={'inline'}
								fontFamily={'Sen'}
								fontStyle="normal"
								fontWeight="400"
								fontSize="16px"
								lineHeight="19px"
								color="#6F7075"
								sx={{ marginBottom: '9px' }}
							>
								LOCATION:
							</Typography>
							<Typography
								display={'inline'}
								fontFamily={'Sen'}
								fontStyle="normal"
								fontWeight="400"
								fontSize="15px"
								lineHeight="17px"
								color="#000000"
								sx={{ marginLeft: '18px' }}
							>
								{asset?.address}
							</Typography>
						</Box>
						<Box alignItems={'top'} display={'flex'}>
							<Typography
								// display={'inline'}
								fontFamily={'Sen'}
								fontStyle="normal"
								fontWeight="400"
								fontSize="16px"
								lineHeight="19px"
								color="#6F7075"
							>
								CERTIFIED:
							</Typography>
							<Typography
								display={'inline'}
								fontFamily={'Sen'}
								fontStyle="normal"
								fontWeight="400"
								fontSize="15px"
								lineHeight="17px"
								color={isAssetCertified ? "#00CA95":"#F32053"}
								sx={{ marginLeft: '18px', marginRight: '4px' }}
							>
								{isLoading ? '...' : isAssetCertified ? asset?.certified_by : `Pending`}
							</Typography>
							{!isLoading && isAssetCertified && 
							<Tooltip title="Certified">
								<Box margin={0} padding={0}>
									<TbCertificate color={'#00CA95'} size={20}/>
								</Box>
							</Tooltip>
							
							}
							{!isLoading && !isAssetCertified &&
							<Tooltip title="Not certified">
								<Box marginTop={-0}>
									<TbCertificateOff color={'#F32053'} size={20}/>
								</Box>
							</Tooltip>

							}
						</Box>
					</Grid>
					<Grid
						item
						md={4}
						sx={{
							backgroundColor: 'rgba(205, 211, 225, 0.1)',
							height: '253px',
							borderRadius: '0 20px 20px 0px'
						}}
					>
						<Box sx={{ marginTop: '22.5px' }}>
							<Box
								display="flex"
								justifyContent="center"
								top="20px"
								left="5px"
							>
								<Box
									onClick={() => {
										if (!asset?.wallet_address) {
										  goToCreateWalletAsset();
										}
									  }}
									sx={{
										cursor: asset.wallet_address ? 'not-allowed': 'pointer',
										width: '43px',
										height: '43px',
										border: `1px solid ${asset?.wallet_address ? '#00CA95' : '#CDD3E1'}`,
										borderRadius: '10px',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center'
									}}>
									<Tooltip title={asset.wallet_address ? 'Asset already has a wallet': 'Create wallet'} placement="top">
										<img src={asset?.wallet_address ? WalletGreen : Wallet} alt='wallet' />
									</Tooltip>
								</Box>
								<Box
									onClick={() => {
										if (asset?.wallet_address) {
											goToRecoveryWalletAsset();
										}
									  }}
									sx={{
										cursor: asset.wallet_address ? 'pointer': 'not-allowed',
										width: '43px',
										height: '43px',
										border: '1px solid #CDD3E1',
										borderRadius: '10px',
										margin: 'auto 20px',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center'
									}}>
									<Tooltip title={asset.wallet_address ? 'Recovery wallet': 'You must create wallet first'} placement="top">
										<img src={Recovery} alt='Recovery' />
									</Tooltip>
								</Box>
								<Box
									onClick={goToDashboardAsset}
									sx={{
										cursor: 'pointer',
										width: '43px',
										height: '43px',
										border: '1px solid #CDD3E1',
										borderRadius: '10px',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center'
									}}>
									<Tooltip title="Graph" placement="top">
										<img src={Graph} alt='Graph' />
									</Tooltip>
								</Box>
							</Box>
							<Typography
								color="#000000"
								fontFamily="Sen"
								fontWeight="400"
								fontSize="16px"
								lineHeight="19px"
								sx={{ marginTop: '7px' }}
								textAlign={'center'}
							>
								TOTAL ENERGY BANK
							</Typography>
							<Box textAlign={'center'} sx={{ marginTop: '5px' }}>
								<Typography
									display={'inline'}
									color="#000000"
									fontFamily="Sen"
									fontWeight="700"
									fontSize="32.8157px"
									lineHeight="55px"
									textAlign={'center'}
									sx={{ marginTop: '7px', marginRight: '5px' }}
								>
									{(Number(asset?.total_mwh)/1000000).toLocaleString('en-US', {
										minimumFractionDigits: 0
									})}
								</Typography>
								<Typography
									display={'inline'}
									color="#000000"
									fontFamily="Sen"
									fontWeight="400"
									fontSize="16px"
									lineHeight="19px"
									sx={{ marginTop: '7px' }}
								>
									MWh
								</Typography>
							</Box>
							{/* <Box sx={{ marginTop: '39.5px' }}>
								<Grid container md={12}>
									<Grid item md={6}>
										<Box
											sx={{
												width: '108.13px',
												height: '31.08px',
												borderRadius: '64.7455px',
												backgroundColor: '#00CA95',
												marginLeft: '28.19px',
												':hover': {
													cursor: 'not-allowed'
												}
											}}
										>
											<Box
												sx={{
													border: '0.647455px solid #CDD3E1',
													width: '79.91px',
													height: '31.08px',
													borderRadius: '64.7455px',
													display: 'flex',
													alignItems: 'center',
													paddingLeft: '5px',
													backgroundColor: '#FFFFFF',
												}}
											>
												<AiOutlineEdit size={20}/>
												<Typography
													color="#000000"
													fontFamily="Sen"
													fontWeight="400"
													fontSize="12.9491px"
													lineHeight="21px"
													marginLeft={'4px'}
													textAlign={'center'}
												>
													Edit
												</Typography>
											</Box>
										</Box>
									</Grid>
									<Grid item md={6}>
										<Box
											sx={{
												width: '108.13px',
												height: '31.08px',
												borderRadius: '64.7455px',
												backgroundColor: '#F32053',
												marginLeft: '10px',
												':hover': {
													cursor: 'not-allowed'
												}
											}}
										>
											<Box
												sx={{
													border: '0.647455px solid #CDD3E1',
													width: '79.91px',
													height: '31.08px',
													display: 'flex',
													alignItems: 'center',
													borderRadius: '64.7455px',
													paddingLeft: '5px',
													backgroundColor: '#FFFFFF'
												}}
											>
												<BsTrash size={20}/>
												<Typography
													color="#000000"
													fontFamily="Sen"
													fontWeight="400"
													fontSize="12.9491px"
													lineHeight="21px"
													marginLeft={'4px'}
													textAlign={'center'}
												>
													Delete
												</Typography>
											</Box>
										</Box>
									</Grid>
								</Grid>
							</Box> */}
						</Box>
					</Grid>
				</Grid>
			</Box >
		</>
	);
};
