import { useEffect, useState } from 'react';

import {
	Box,
	Dialog,
	Typography,
	DialogContent,
	DialogActions,
	Button,
	DialogTitle,
	Card,
	Grid,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	IconButton,
	Tooltip
} from '@mui/material';

import { useSnackbar } from 'notistack';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import LoadingButton from '@mui/lab/LoadingButton';
import { formatTextLimit } from '../../utils/formatTextLenght';
import CopyIcon from '../../assets/imgs/copy.png';

export enum ModalType {
	warning,
	info
}

interface ImodalGeneric {
	type?: ModalType;
	open: boolean;
	idMember?: string;
	onSubmitAuthorization: () => void;
	loadingAuthorization: boolean;
	title: string;
	walletName?: string;
	walletAddress?: string;
	subTitle: string;
	warningMessage?: string;
	handleCloseAuthorization: () => void;
	setPasswordAuthorization?: (value: string) => void;
	confirm: string;
	cancel: string;
}

interface IState {
	password: string;
	showPassword: boolean;
}

export const ConfirmationModal: React.FC<ImodalGeneric> = ({
	open,
	type = ModalType.info,
	walletName,
	walletAddress,
	warningMessage = '',
	confirm,
	cancel,
	onSubmitAuthorization,
	loadingAuthorization,
	title,
	subTitle,
	handleCloseAuthorization,
	setPasswordAuthorization
}) => {
	const fullName = localStorage.getItem('fullName') || '';
	const { enqueueSnackbar } = useSnackbar();

	const [localPassword, setLocalPassword] = useState<string>('');
	const [validateButton, setValidateButton] = useState<string>('');
	const [values, setValues] = useState<IState>({
		password: '',
		showPassword: false
	});

	const changePasswordAuthorization = (e: any) => {
		setValidateButton(e.target.value);
		setLocalPassword(e.target.value);
		if(setPasswordAuthorization){
			setPasswordAuthorization(e.target.value);
		}
	};

	const handleClickShowPassword = () => {
		setValues({
			...values,
			showPassword: !values.showPassword
		});
	};

	const clipBoard = () => {
		if(walletAddress){
			navigator.clipboard.writeText(walletAddress);
			enqueueSnackbar('Hash copied', {
				variant: 'info'
			});
		}
	};

	const handleKeyPress = (event: { key: string; }) => {
		if (event.key === 'Enter') {
			onSubmitAuthorization();
		}
		if (event.key === 'Escape') {
			handleCloseAuthorization();
		}
	};
	
	useEffect(() => {
		// Add event listener when component mounts
		document.addEventListener('keydown', handleKeyPress);
	
		// Remove event listener when component unmounts
		return () => {
		  document.removeEventListener('keydown', handleKeyPress);
		};
	}, []);

	return (
		<>
			<Dialog
				sx={{
					'& .MuiPaper-root': {
						border: 'none',
						position: 'relative',
						borderRadius: '20px !important',
						backgroundColor: '#Ffff',
						boxShadow: '0px 4px 35px rgba(0, 0, 0, 0.00)'
					}
				}}
				aria-describedby="confirmationDialog"
				open={open}
				onClose={handleCloseAuthorization}
				PaperProps={{
					style: {
						borderRadius: '18px',
						width: '551px'
					}
				}}
			>
				<DialogTitle
					sx={{
						position: 'relative',
						backgroundColor: type == ModalType.info? 'rgb(210, 255, 243, .3)' : 'rgb(251, 164, 184, .3)' ,
						padding: '64px 23px 63px 48px'
					}}
				>
					<Box
						onClick={handleCloseAuthorization}
						position="absolute"
						right="20px"
						top="20px"
						sx={{ cursor: 'pointer' }}
					>
						<Typography fontSize="20px">X</Typography>
					</Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							backgroundColor: 'transparent',
							'&.MuiPaper-root': {
								border: 'none'
							}
						}}
					>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								marginRight: '32px',
								minWidth: '87px',
								minHeight: '87px',
								borderRadius: '50%',
								backgroundColor: type == ModalType.info ? '#00CA95' : '#F32053'
							}}
						>
							{type == ModalType.info ?
								<DoneIcon sx={{ color: '#fff', fontSize: '34px' }} />
								:
								<ErrorIcon sx={{ color: '#FBA4B8', fontSize: '34px' }} />
							}
						</Box>
						<Box display="flex" flexDirection="column">
							<Typography
								color={type == ModalType.info? "#00CA95" : "#F32053"}
								fontWeight="700"
								fontSize="20px"
								marginBottom="3px"
							>
								{title}
							</Typography>
							<Typography
								sx={{ color: '#6F7075', fontSize: '16px', lineHeight: '25px' }}
							>
								{subTitle}
							</Typography>
						</Box>
					</Box>
				</DialogTitle>
				<DialogContent
					sx={{
						marginTop: '25px',
						marginBottom: '81px',
						padding: 0
					}}
					>
				{setPasswordAuthorization ?
				<>
					<Card
						sx={{
							width: '100%',
							display: 'flex',
							paddingLeft: '48px',
							marginBottom: '27px',
							alignContent: 'center',
							'&.MuiPaper-root': {
								border: 'none',
								backgroundColor: 'inherit'
							}
						}}
					>
						<Box marginRight="20px">
							{walletName &&
								<Tooltip title={walletName}>
									<Box display="flex" >
										<Typography color="#008864" marginRight="11px">
											walletName:
										</Typography>
										<Typography>{formatTextLimit(walletName, 10)}</Typography>
									</Box>
								</Tooltip>
							}
						</Box>
						<Box>
							{walletAddress &&
								<Tooltip title={walletAddress}>
									<Box display="flex" onClick={clipBoard}>
										<Typography color="#008864" marginRight="11px">
											Wallet:{' '}
										</Typography>
										<Typography marginRight="10px">
											{formatTextLimit(walletAddress, 15)}
										</Typography>
										<img
											src={CopyIcon}
											alt="copy"
											style={{ cursor: 'pointer' }}
										></img>
									</Box>
								</Tooltip>
							}
						</Box>
					</Card>
					<Card
						sx={{
							paddingLeft: '48px',
							'&.MuiPaper-root': {
								border: 'none',
								backgroundColor: 'inherit'
							}
						}}
					>
							<Grid container md={12}>
								<Grid item md={11}>
									<InputLabel error={false} shrink htmlFor="password" autoSave='off'>
										<Typography color="#008864" fontSize="1.25rem">
											Type your password:
										</Typography>
									</InputLabel>
									<OutlinedInput
										autoComplete='something'
										sx={{ maxWidth: '451px' }}
										fullWidth
										error={false}
										placeholder="password"
										value={localPassword}
										id="outlined-adornment-password"
										type={values.showPassword || localPassword.length == 0 ? 'text' : 'password'}
										endAdornment={
											<InputAdornment position="end" sx={{ marginRight: '25px' }}>
												<IconButton
													autoSave='something'
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													edge="end"
												>
													{values.showPassword ? (
														<VisibilityOff />
													) : (
														<Visibility />
													)}
												</IconButton>
											</InputAdornment>
										}
										onChange={event => changePasswordAuthorization(event)}
									/>
								</Grid>
							</Grid>
					</Card>
				</>
				:
				<>
				<Card
					sx={{
						paddingLeft: '48px',
						'&.MuiPaper-root': {
							border: 'none',
							backgroundColor: 'inherit'
						}
					}}
				>
						<Grid container md={12}>
							<Grid item md={11} display={'flex'}>
								<Typography
									sx={{ color: '#000', fontSize: '20px', lineHeight: '25px' }}
								>
									{warningMessage}
								</Typography>
							</Grid>
						</Grid>
				</Card>
			</>
				}
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						justifyContent: 'center',
						marginBottom: '39px'
					}}
					autoSave='off'
				>
					<Button
						autoSave='off'
						onClick={handleCloseAuthorization}
						variant="outlined"
						color="success"
						sx={{
							width: '124px',
							height: '43px',
							marginRight: '73px',
							padding: '4px 24px',
							borderRadius: '25px',
							color: '#000',
							borderColor: '#000',
							textTransform: 'initial',
							'&:hover': {
								borderColor: '#000'
							}
						}}
					>
						<Typography fontSize="20px"> {cancel}</Typography>
					</Button>
					<LoadingButton
						autoSave='off'
						loading={loadingAuthorization}
						onClick={onSubmitAuthorization}
						disabled={(!validateButton || !changePasswordAuthorization) && !!setPasswordAuthorization}
						variant="outlined"
						color="error"
						sx={{
							width: '124px',
							height: '43px',
							padding: '4px 24px',
							borderRadius: '25px',
							color: '#000',
							borderColor: '#000',
							fontSize: '20px',
							backgroundColor: type == ModalType.info ? '#D2FFF3' : "#FBA4B8" ,
							textTransform: 'initial',
							'&:hover': {
								borderColor: '#000',
								backgroundColor: type == ModalType.info? '#D2FFF3' : "#FBA4B8"
							}
						}}
					>
						{confirm}
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</>
	);
};
