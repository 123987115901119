import { ICertificateOrg } from '../../types/certificateOrg';

const INITIAL_STATE: ICertificateOrg[] = [];

interface IPayload {
	totalRec: ICertificateOrg[];
	payload: any;
}

interface IAction {
	type: string;
	payload: IPayload;
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function (state = INITIAL_STATE, action: IAction) {
	switch (action.type) {
		case 'GET_TOTAL_REC':
			// return action.payload.totalRec;
			return {
				...state,
				totalRec: action.payload.totalRec
			};
		default:
			return state;
	}
}
