// Core
import React, { FC, ChangeEvent } from 'react';

import {
	Avatar,
	Box,
	BoxProps,
	Input,
	InputLabel,
	InputLabelProps,
	InputProps
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import Camera from '../../assets/imgs/camera.png';
import enterpriseDefaultLogo2 from '../../assets/imgs/enterpriseDefaultLogo2.png';
import { colors } from '../../styles';
import { CircularProgress } from 'material-ui-core';

interface IInputLoadImage extends BoxProps {
	id: string;
	name?: string;
	error?: boolean;
	textLabel: string;
	inputProps?: InputProps;
	InputLabelProps?: InputLabelProps;
	image?: string | null;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	dataUser: any;
	imgDefault?: string;
	loadImage?: boolean;
	circle?: boolean;
	DisabledUpload?: boolean;
}

export const UploadLogo: FC<IInputLoadImage> = ({
	id,
	error,
	circle,
	inputProps,
	textLabel,
	// eslint-disable-next-line @typescript-eslint/no-shadow
	InputLabelProps,
	onChange,
	name,
	image,
	dataUser,
	imgDefault,
	loadImage,
	DisabledUpload=false,
	...props
}) => {
	const fullName = localStorage.getItem('fullName');


	function stringToColor(string: string) {
		let hash = 0;
		let i;

		/* eslint-disable no-bitwise */
		for (i = 0; i < string.length; i += 1) {
			hash = string.charCodeAt(i) + ((hash << 5) - hash);
		}

		let color = '#';

		for (i = 0; i < 3; i += 1) {
			const value = (hash >> (i * 8)) & 0xff;
			color += `00${value.toString(16)}`.slice(-2);
		}
		/* eslint-enable no-bitwise */

		return color;
	}
	function stringAvatar(name: string) {
		return {
			sx: {
				bgcolor: stringToColor(name)
			},
			children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
		};
	}
	const ImageEnterprise = () => {
		return (
			<>
				<Box
					sx={{
						position: 'relative',
						minHeight: '169px',
						minWidth: '159px',
						borderRadius: !circle ? '16px' : '50%',
						border: '1px solid #D4D7DE',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					{imgDefault ? (
						<img src={imgDefault} width="160px"></img>
					) : (
						<img src={enterpriseDefaultLogo2} width="160px"></img>
					)}
				</Box>
			</>
		);
	};
	return (
		<>
			{dataUser.length ? (
				<ImageEnterprise />
			) : (
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						gap: '50px',
						position: 'relative'
					}}
					{...props}
				>
					<Input
						error={error}
						id={id}
						disabled={DisabledUpload}
						name={name}
						sx={{ display: 'none' }}
						onChange={onChange}
						{...inputProps}
						disableUnderline
						type="file"
					/>
					{image ? (
						<InputLabel
							htmlFor={id}
							sx={{
								overflow: 'hidden',
								position: 'relative',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								cursor: DisabledUpload ? 'default' : 'pointer',
								border: `1px dashed #D4D7DE`,
								borderRadius: !circle ? '16px' : '50%'
							}}
							{...InputLabelProps}
						>
							<img
								src={image}
								style={{ border: '16px', objectFit: 'contain' }}
								width="155px"
								height="150px"
							/>
						</InputLabel>
					) : (
						<InputLabel
							htmlFor={id}
							sx={{
								display: 'flex',
								position: 'relative',
								justifyContent: 'center',
								alignItems: 'center',
								cursor: 'pointer',
								border: `1px dashed #D4D7DE`,
								borderRadius: !circle ? '16px' : '50%',
								minWidth: '169px',
								minHeight: '169px'
							}}
							{...InputLabelProps}
						>
							<Avatar
								style={{
									borderRadius: '70px',
									// position: 'absolute',
									// top: '1px',
									// left: '1px',
									// right: 0,
									cursor: 'pointer',
									width: '120px',
									height: '120px',
									fontSize: '30px'
								}}
								// onClick={handleClickMenu}
								{...stringAvatar(fullName ? fullName : ' Name')}
							/>
	
						</InputLabel>
					)}
					{!DisabledUpload && 
						<Box
							sx={{
								marginLeft: '60px',
								width: '36px',
								height: '36px',
								backgroundColor: colors.primary,
								borderRadius: '18px',
								position: 'absolute',
								bottom: '-30px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								cursor: 'pointer'
							}}
						>
							<label style={{ cursor: 'pointer' }}>
								<AddIcon sx={{ color: '#fff' }} />
								<Input
									error={error}
									id={id}
									name={name}
									sx={{ display: 'none' }}
									onChange={onChange}
									{...inputProps}
									disableUnderline
									type="file"
								/>
							</label>
						</Box>
					}
				</Box>
			)}
		</>
	);
};
