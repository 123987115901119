import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Typography, Grid } from '@mui/material';
import { goToTransactionBuyer } from '../../../../stores/goToBuyerTransaction/goToBuyerTransaction.actions';
import { colors } from '../../../../styles';
import { ClientService } from '../../../../services/client.service';
import { ConfirmationModal, ModalType } from '../../../../components/confirmationModal';

import { useSnackbar } from 'notistack';
import { FiUserCheck, FiUserX } from 'react-icons/fi';
import ImageDefaultClient from '../../../../assets/imgs/CompanyImage2.jpg';


interface IListMemberData {
	id: string;
	beneficiary: string;
	logo: string;
	is_activate: boolean;
}

interface IListBuyers {
	searchInput: string;
}

export const ListBuyers = ({ searchInput }: IListBuyers) => {
	const dispath = useDispatch();
	const clientService = new ClientService();
	const { enqueueSnackbar } = useSnackbar();
	
	const [refreshClients, setRefreshClients] = useState<boolean>(false);
	const [indexTest, setIndexTest] = useState<number>();
	const [filteredList, setfilteredList] = useState<Array<any>>([]);
	const [clientId, setClientId] = useState<string>('');
	const [openRemove, setOpenRemove] = useState<boolean>(false);
	const [openApproved, setOpenApproved] = useState<boolean>(false);

	// const [clients, setClients] = useState([]);
	const [assetsOverflow, setAssetsOverflow] = useState<string>('hidden');
	const [dataListMember, setDataListMember] = useState<Array<IListMemberData>>([]);

	useEffect(() => {
		const listAssets = async () => {
			const response: any = await clientService.listClients();
			setDataListMember(response.data);
			setfilteredList(response.data);

			if (response.data.length > 7) {
				setAssetsOverflow('scroll');
			} else {
				setAssetsOverflow('hidden');
			}
		};
		listAssets();
	}, [refreshClients]);

	const classessd = {
		color: 'none'
	};

	const goToTransaction = (id: string) => {
		// passar o id pra outra pagina listBy
		dispath(goToTransactionBuyer(true, id));
	};

	const disableBuyer = (id: string) => {
		setClientId(id);
		setOpenRemove(true);
	};

	const activeBuyer = (id: string) => {
		setClientId(id);
		setOpenApproved(true)
	};

	// await submitActiveBuyer(id);

	useEffect(() => {
		if (!searchInput) {
			setfilteredList(dataListMember);
			return;
		}

		const filterSearch = dataListMember.filter((resultList: any) => {
			if (searchInput) {
				return (
					resultList.beneficiary
						.toLowerCase()
						.trim()
						.indexOf(searchInput.toLowerCase()) !== -1
				);
			}
		});

		setfilteredList(filterSearch.length > 0 ? filterSearch : filteredList);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchInput]);

	const handleClose = () => {
		setOpenRemove(false);
		setOpenApproved(false)
	};

	const submitActiveBuyer = async () => {
		const result = await clientService.activeCNPJClient(clientId)
		if (result.status === 200) {
			enqueueSnackbar('The buyer has been enabled', {
				variant: 'success'
			});
			setRefreshClients(!refreshClients)
		}else{			
			enqueueSnackbar(result.statusText, {
				variant: 'error'
			});
		}
		handleClose();
	};

	const submitDisableBuyer = async () => {
		const result = await clientService.disableCNPJClient(clientId)
		if (result.status === 200) {
			enqueueSnackbar('The buyer has been disabled', {
				variant: 'success'
			});
			setRefreshClients(!refreshClients)
		}else{
			enqueueSnackbar(result.statusText, {
				variant: 'error'
			});
		}
		handleClose();
	};

	function compareClients( a: { beneficiary: number; }, b: { beneficiary: number; } ) {
		if ( a.beneficiary < b.beneficiary ){
		  return -1;
		}
		if ( a.beneficiary > b.beneficiary ){
		  return 1;
		}
		return 0;
	  }

	return (
		<>
		<Grid container md={12}>
			<>
				{openApproved &&
					<ConfirmationModal
						open={openApproved}
						title={`Active a client ${dataListMember.find((value)=> value.id == clientId)?.beneficiary}`}
						subTitle="This action will prompt you to authorize the activation of a client."
						handleCloseAuthorization={handleClose}
						onSubmitAuthorization={submitActiveBuyer}
						// setPasswordAuthorization={}
						loadingAuthorization={false}
						confirm="Active"
						cancel="Cancel"

					/>
				}
				{openRemove &&
					<ConfirmationModal
						type={ModalType.warning}
						warningMessage='Are you sure you want to disable this buyer?'
						open={openRemove}
						title={`Disable the buyer ${dataListMember.find((value)=> value.id == clientId)?.beneficiary}`}
						subTitle='This action will disable this buyer'
						handleCloseAuthorization={handleClose}
						onSubmitAuthorization={submitDisableBuyer}
						loadingAuthorization={false}
						confirm="Disable"
						cancel="Cancel"
					/>
				}
				{filteredList?.length > 0 ?
					filteredList.sort(compareClients).map((value: IListMemberData, index: number) => (
						<Grid
							item
							md={10}
							display="flex"
							alignItems="center"
							marginBottom="16px"
							sx={{
								opacity: value.is_activate ? 1 : 0.6
							}}
						>
							<>
								{value?.logo ? (
									<Box>
										<img
											width="51px"
											height="51px"
											style={{
												borderRadius: '25px',
												marginRight: '4px'
											}}
											src={value?.logo}
										></img>
									</Box>
								) : (
									<Box
										sx={{
											width: '51px',
											height: '51px',
											marginRight: '4px',
											borderRadius: '25px',
											backgroundColor: '#F6F6F6',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											fontWeight: '700',
											fontFamily: 'sen'
										}}
									>
										{/* {value.beneficiary.charAt(0)} */}
										<img
											src={ImageDefaultClient}
											style={{
												borderRadius: '25px',
												marginRight: '4px',
												// border: '16px',
												objectFit: 'contain' 
											}}
											width="51px"
											height="51px"
										/>
									</Box>
								)}
								<Box
									sx={{
										position: 'relative',
										flexGrow: '1'
									}}
								>
									<Box
										style={classessd}
										onClick={() => {
											goToTransaction(value.id);
										}}
										sx={{
											position: 'relative',
											zIndex: '10',
											display: 'flex',
											alignItems: 'center',
											paddingLeft: '30px',
											height: '48px',
											backgroundColor: '#F6F6F6',
											borderRadius: '100px',
											cursor: 'pointer',
											marginRight: `${index === indexTest ? '110px' : ''}`,
											transition: `${index === indexTest ? '.6s' : '.4s'}`
										}}
									>
										<Typography
											maxWidth={index === indexTest ? '60%' : '230px'}
											overflow="hidden"
											textOverflow="ellipsis"
											whiteSpace="nowrap"
											color={index === indexTest ? '#000' : ''}
										>
											{`${value?.beneficiary}`}
										</Typography>
									</Box>
									<Box
										onMouseEnter={() => setIndexTest(index)}
										onMouseLeave={() => setIndexTest(-1)}
										onClick={value.is_activate ? () => disableBuyer(value.id) :  () => activeBuyer(value.id)}
										sx={{
											cursor: 'pointer',
											position: 'absolute',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'flex-end',
											paddingRight: '20px',
											zIndex: '1',
											top: '0',
											right: '-50px',
											width: '100%',
											height: '48px',
											backgroundColor: value.is_activate ? '#F32053' : '#00AA00',
											borderRadius: '100px'
										}}
									>
										{value.is_activate ?
										<>
											<Typography
												marginRight="10px"
												fontSize="1.10rem"
												color={colors.neutralLight}
											>
												DISABLE
											</Typography>
											<FiUserX color='white' size={20}/>			
										</>
										:
										<>
											<Typography
												marginRight="10px"
												fontSize="1.10rem"
												color={colors.neutralLight}
											>
												ENABLE
											</Typography>
											<FiUserCheck color='white' size={20}/>			
										</>
										}
									</Box>
								</Box>
							</>
						</Grid>
					))
					:
					<Box textAlign={'center'} height={100} width={'100%'} display={'flex'} justifyContent={'center'}>
							<Typography
								fontWeight="600"
								fontSize="1.25rem"
								color={colors.primary}
								height={100}
								lineHeight={5}
							>
								No clients registered yet
							</Typography>
					</Box>
				}
			</>
		</Grid>
		</>
	);
};
