import { ModeSelect } from '../../../components/modeSelect';

interface ISelectAmount {
	step?: number;
}

export const SelectAmount = ({ step = 3 }: ISelectAmount) => {
	return (
		<>
			<ModeSelect numberMode={step} />
		</>
	);
};
