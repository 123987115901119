import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { ClientService } from '../../../services/client.service';
import { useSnackbar } from 'notistack';
import { ApiPromise, WsProvider, } from "@polkadot/api";
import { keyring as uiKeyring } from "@polkadot/ui-keyring";
import { waitReady } from "@polkadot/wasm-crypto";
import dayjs from 'dayjs';
import { getTotalAvailableRec } from '../../../stores/totalAvailableRec/totalAvailableRec.actions';
import {sha256} from "js-sha256";

import {
	Box,
	Button,
	Card,
	FormHelperText,
	Grid,
	InputBase,
	InputLabel,
	MenuItem,
	Select,
	styled,
	Typography
} from '@mui/material';

import { ICertificateOrg } from '../../../types/certificateOrg';

import { LoadingButton } from '@mui/lab';

import EnergyImg from '../../../assets/imgs/energy.png';
import informationIssueImg from '../../../assets/imgs/informationIssue.png';
import Key from '../../../assets/imgs/key.png';
import RecIssueImg from '../../../assets/imgs/recIsue.png';
import { colors } from '../../../styles';
import { SelectAmount } from '../../RecIssue/selectAmount';
import { SelectMode } from '../../RecIssue/selectMode';
import { RecList } from '../../RecToken/recList';
import { ConfirmationModal } from '../../../components/confirmationModal';
import { CertificatesService } from '../../../services/certificates.service';
import { MemberService } from '../../../services/member.service';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
type OriginCertOnchainType = Array<[string, number, [number,number], number ]>;

interface IRecTransfer {
	setGoTotransferT: (value: boolean) => void;
}

interface IClient {
	id: string;
	name: string;
	wallet: string;
	isActivate: string;
}

interface Item {
	origin_id: string;
	origin_hash: string;
	amount: number;
	date: Date;
}

interface SellerData{
	id: string;
	wallet: string;
	name: string
}

export const OperationBoard = ({ setGoTotransferT }: IRecTransfer) => {
	const certificatesService = new CertificatesService();
	const memberService = new MemberService();
	const dispath = useDispatch();
	const [user, setUser] = useState<any>({});
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [selectModeLabel, setSelectModeLabel] = useState<string>('select');
	const [selectWallet, setSelectWallet] = useState<string>('');
	const [totalAvaliable, setTotalAvaliable] = useState<number>(0);
	const [showSteps, setShowSteps] = useState<boolean>(false);
	const [selectedClientId, setSelectedClientId] = useState<string>('');
	const [selectedClient, setSelectedClient] = useState<IClient>();

	const [validateTotalEnergy, setValidateTotalEnergy] =
		useState<boolean>(false);
	const [inputAmount, setInputAmount] = useState<string>('');
	const [openAuthorization, setOpenAuthorization] = useState<boolean>(false);
	const [passwordAuthorization, setPasswordAuthorization] =
		useState<string>('');
	const [loadingAuthorization, setLoadingAuthorization] =
		useState<boolean>(false);

	const [dataClients, setDataClients] = useState<Array<IClient>>([]);

	const getTransferRow: ICertificateOrg = useSelector(
		(state: any) => state.totalRecAvailable
	);

	const CopyGetSelectedCertificates = {...getTransferRow};

	const getUserInfo = async () => {
		const getUser: any = await memberService.getMyInfo();
		if (getUser.status === 200) {
			setUser(getUser.data);
		}
		// if error
	};

	useEffect(() => {
		getUserInfo();
	}, []);
	
	useEffect(() => {
		const clientService = new ClientService();

		const getTransactions = async () => {
			const respSubmitEnt: any = await clientService.listClients();
			const output = respSubmitEnt.data.map(
				(item: { id: string; beneficiary: string; logo: string, wallet_address: string, is_activate: boolean }) => ({
					id: item.id,
					name: item.beneficiary,
					logo: item.logo,
					wallet: item.wallet_address,
					isActivate: item.is_activate
				})
			);

			setDataClients(output);
		};
		getTransactions();
	}, []);

	useEffect(() => {
		if(selectedClientId){
			const client = dataClients.find(
				(element: any) => element.id === selectedClientId
			);
			setSelectedClient(client)
			if(client){
				setSelectWallet(client.wallet)
			}
		}
	}, [selectedClientId]);

	async function matTransferRec(toAccount: string, multiledgersParaId: string, cceeParaId: string, ocert: [OriginCertOnchainType], operationDate: string, recHash: string) {
		try {
			await waitReady();
			uiKeyring.loadAll({ ss58Format: 42, type: "sr25519" });
		} catch (error) {
			console.log(error)
		} finally {
			const provider = new WsProvider(`${process.env.REACT_APP_PARACHAIN_ADDRESS}`);
			const api = await ApiPromise.create({ provider });
			try {
				const holderPair = uiKeyring.getPair(user.wallet_address);
				try {
					holderPair.unlock(passwordAuthorization);
				} catch (error) {
					console.log(error)
					enqueueSnackbar('The password of your wallet is invalid, try again', {
						variant: 'error'
					});
					return;
				}
				const genesisHash = api.genesisHash;
				const runtimeVersion = api.runtimeVersion;
				const nonce = await api.rpc.system.accountNextIndex(holderPair.address);
				const extrinsic = api.tx.recs.transferRec(toAccount, `${process.env.REACT_APP_MULTILEDGERS_PARACHAIN_ID}`, `${process.env.REACT_APP_CCEE_PARACHAIN_ID}`, ocert, operationDate, recHash).sign(holderPair, { genesisHash, blockHash: genesisHash, nonce, runtimeVersion });
				await api.disconnect();
				return extrinsic
				//
			} catch (error) {
				console.log(error)
				enqueueSnackbar('Wallet not found, please consider recovering it on the My Profile page. A new tab will be created in 5 seconds.', {
					variant: 'error'
				});
				const timeout = setTimeout(()=>{
					window.open(`${process.env.REACT_APP_CLIENT_URL}/profile`, "_blank", 'noopener,noreferrer'	);
				},5000)
				clearTimeout(timeout);
				return;
			}
		}
	}

	useEffect(() => {
		if (CopyGetSelectedCertificates.totalRec.length > 0) {
			const amountTotal = CopyGetSelectedCertificates.totalRec.reduce((acumulador: any, objeto: any) => {
				return Number(acumulador) + Number(objeto.amountRec);
			}, 0);

			setTotalAvaliable(amountTotal);
		}
	}, [CopyGetSelectedCertificates]);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;

		const newValue = value.replace(/[.,]/g, '');

		if (Number(newValue) > totalAvaliable || Number(newValue) <= 0) {
			setValidateTotalEnergy(true);
		} else {
			setValidateTotalEnergy(false);
		}
		setInputAmount(newValue);
	};

	const handleCloseAuthoraztion = (value: boolean) => {
		setOpenAuthorization(value);
	};

	const onSubmit = () => {
		if (!user.wallet_address){
			enqueueSnackbar("You haven't registered your wallet in your profile yet.", {
				variant: 'error'
			});
			return; 
		}
		handleCloseAuthoraztion(true);
	};

	function calculeQuotient(amount: number, divisor: number) {
		const averageAmount = Math.floor(amount / divisor);
		const remainder = amount % divisor;
		return { averageAmount, remainder };
	}

	function calculateList(list: Item[], final_amount: number): Item[] {
		const listLength = list.length;
		const { averageAmount, remainder } = calculeQuotient(
			final_amount,
			listLength
		);
		const orderedList = list.sort(
			(a, b) => a.date.getTime() - b.date.getTime()
		);
		const certValues = new Array<number>(orderedList.length);
		const remainerValues = new Array<number>(orderedList.length).fill(0);
		remainerValues[0] = remainder;
		orderedList.map((item, index) => {
			certValues[index] = item.amount;
			remainerValues[index] =
				item.amount - averageAmount - remainerValues[index];
		});
		while (remainerValues.some(v => v < 0)) {
			for (let i = 0; i < remainerValues.length; i++) {
				if (remainerValues[i] < 0) {
					for (let j = 0; j < remainerValues.length; j++) {
						if (remainerValues[j] > 0) {
							const amountntnt = remainerValues[j] + remainerValues[i];
							if (amountntnt <= 0) {
								remainerValues[j] = 0;
								remainerValues[i] = amountntnt;
							} else {
								remainerValues[i] = 0;
								remainerValues[j] = amountntnt;
								break;
							}
						}
					}
				}
			}
		}
		const finalll = certValues.map((item, index) => {
			return item - remainerValues[index];
		});
		const newList = orderedList.map((item, index) => {
			return { ...item, amount: finalll[index] };
		});
		return newList;
	}

	const onSubmitAuthorization = async () => {
		if (!CopyGetSelectedCertificates.totalRec) return;
		const output: Item[] = CopyGetSelectedCertificates.totalRec.map((item: ICertificateOrg) => ({
			origin_id: item.id,
			origin_hash: item.recHash,
			amount: Number(item.amountRec),
			date: item.startDate
		}));

		const originCertificate = calculateList(output, Number(inputAmount));

		const orderedList = CopyGetSelectedCertificates.totalRec.sort(
			(a: any, b: any) => a.startDate.getTime() - b.startDate.getTime()
		);

		const [paramsCert] = orderedList;
		setLoadingAuthorization(true);
		const newStartDate = paramsCert.startDate;
		const newEndDate = orderedList[orderedList.length - 1].endDate;

		//ONCHAIN
		const dateFormatted = dayjs().format('YYYY-MM-DD');
		const onchainOrigin: OriginCertOnchainType = originCertificate.map(item => {
			return [item.origin_hash, item.amount, [0,0], 0];
		});
		const RecCertId = '0x' + sha256.update(window.crypto.randomUUID()).hex();

		const signedTransaction = await matTransferRec(selectWallet, `${process.env.REACT_APP_MULTILEDGERS_PARACHAIN_ID}`,`${process.env.REACT_APP_CCEE_PARACHAIN_ID}`, [onchainOrigin], dateFormatted, RecCertId)

		if (!signedTransaction){
			enqueueSnackbar("Wallet Signature problem", {
				variant: 'error'
			});
			setLoadingAuthorization(false);
			return;
		}
		if (!selectedClient){
			return
		}

		const notify = enqueueSnackbar('Processing on the blockchain.', {
			variant: 'info',
			persist: true
		});

		handleCloseAuthoraztion(false);

		const respCertificate = await certificatesService.createTransferRec({
			enterprise_id: paramsCert.enterpriseId,
			asset_id: paramsCert.assetId,
			rec_id: paramsCert.recId,
			start_date: newStartDate,
			end_date: newEndDate,
			operation_type: 'transfer',
			origins: originCertificate,
			from: paramsCert.to,
			rec_hash: RecCertId,
			to: selectedClient.id,
			holder_wallet: selectedClient.wallet,
			extrinsic: signedTransaction
		});

		if ([400, 401, 404, 500].includes(respCertificate.status)) {
			closeSnackbar(notify)
			enqueueSnackbar(respCertificate.statusText, {
				variant: 'error'
			});
		}
		if (respCertificate.status === 200) {
			// resetStates();
			closeSnackbar(notify)
			enqueueSnackbar('Certificate sent successfully', {
				variant: 'success'
			});
			setLoadingAuthorization(false);
		}
		handleCloseAuthoraztion(false);
	};

	const BootstrapInput = styled(InputBase)(() => ({
		'& .MuiInputBase-input': {
			display: 'flex',
			alignItems: 'center',
			padding: '14px 25px',
			borderRadius: '9px',
			// backgroundColor: 'transparent',
			border: '1px solid #000',
			'& .MuiSvgIcon-root': {
				marginTop: '100px',
				color: '#fff'
			},
			'&:focus': {
				borderRadius: '10px'
			},
			':after': {
				borderBottom: 'none',
				borderRight: '1px solid #000',
				marginRight: '50px'
			}
		}
	}));

	return (
		<>
			{/* <Box position="absolute" bottom="0" width="35%" left="152px">
				<img src={RecIssueImg} alt="backgroundimage"></img>
			</Box> */}
			<Grid container md={12} marginBottom="54px">
				<Grid item md={12} display="flex">
					<Button
						variant="outlined"
						onClick={() => setGoTotransferT(false)}
						sx={{
							width: '107px',
							height: '40px',
							borderRadius: '25px',
							border: '1px solid #000',
							padding: '8px 24px',
							color: '#000',
							textTransform: 'initial',
							fontSize: '1.25rem',
							'&:hover': {
								border: '1px solid #000'
							}
						}}
					>
						<Typography fontSize="1.25rem" lineHeight="32px">
							Back
						</Typography>
					</Button>
					<Typography
						fontWeight="700"
						fontSize="1.75rem"
						lineHeight="40px"
						color={colors.primary}
						marginBottom="9px"
						marginLeft="96px"
						textTransform="uppercase"
					>
						operation board
					</Typography>
				</Grid>
			</Grid>

			<Grid container md={12} gap={5}>
				<Grid item md={4}>
					<Card sx={{ marginLeft: '25px' }}>
						<Box display="flex" alignItems="center">
							<SelectMode />
							<Typography sx={{ marginLeft: '21px', fontSize: '1.25rem' }}>
								SELECT A CLIENT
							</Typography>
						</Box>

						<Box marginTop="40px">
							<RecList dataClients={dataClients} setShowSteps={setShowSteps} setSelectedClientId={setSelectedClientId} />
						</Box>
					</Card>
				</Grid>
				{showSteps && (
					<Grid item md={4}>
						<Card sx={{ height: '600px', position: 'relative' }}>
							<Box display="flex" alignItems="center">
								<SelectAmount step={2} />
								<Typography sx={{ marginLeft: '21px', fontSize: '1.25rem' }}>
									INSERT AMOUNT:
								</Typography>
							</Box>
							<Card sx={{ marginTop: '57px' }}>
								<Box marginLeft="100px">
									<Typography fontSize="1.25rem">
										TOTAL AVAILABLE REC’S
									</Typography>
								</Box>
								<Box
									display="flex"
									alignItems="center"
									marginLeft="29px"
									marginBottom="48px"
								>
									<img src={EnergyImg} alt="eletric"></img>
									<Typography
										sx={{
											marginLeft: '23px',
											fontSize: '2.25rem',
											fontWeight: '700',
											color: '#00CA95'
										}}
									>
										{Number(totalAvaliable).toLocaleString('en-US', {
											minimumFractionDigits: 0
										})}
									</Typography>
								</Box>

								<Box marginLeft="40px">
									<InputLabel
										error={false}
										shrink
										htmlFor="job_title"
										sx={{
											color: colors.neutralDark,
											fontSize: '25px',
											lineHeight: '32px'
										}}
									>
										Type in amount of REC’s to transfer
									</InputLabel>
									<NumericFormat
										value={Number(inputAmount)}
										allowLeadingZeros
										decimalSeparator="."
										thousandSeparator=","
										placeholder="0"
										style={{
											width: '298px',
											outline: validateTotalEnergy ? 'blue' : '#F32053',
											padding: '16px 14px',
											border: `1px solid ${
												validateTotalEnergy ? '#F32053' : '#3A4049'
											}`,
											boxShadow: '0px 2px 8px rgb(0 0 0 / 15%)',
											borderRadius: '9px',
											marginTop: '8px',
											fontSize: '30px',
											color: validateTotalEnergy
												? '#F32053'
												: colors.neutralDark
										}}
										onChange={handleInputChange}
									/>
									{validateTotalEnergy && (
										<FormHelperText
											error
											sx={{
												fontWeight: '700',
												fontSize: '14px',
												lineHeight: '32px',
												fontFamily: 'sen',
												'&.Mui-error': {
													color: '#F32053'
												}
											}}
										>
											UNVAILABLE AMOUNT
										</FormHelperText>
									)}
								</Box>
							</Card>
						</Card>
					</Grid>
				)}
				{showSteps && (
					<Grid item md={3}>
						<Card>
							<Box display="flex" alignItems="center">
								<SelectAmount step={3} />
								<Typography sx={{ marginLeft: '21px', fontSize: '1.25rem' }}>
									FINALIZE:
								</Typography>
							</Box>
							<Card sx={{ marginTop: '57px' }}>
								<Box
									display="flex"
									alignItems="center"
									marginLeft="35px"
									marginBottom="18px"
								>
									<Box marginTop="5px">
										<img src={informationIssueImg}></img>
									</Box>
									<Typography
										sx={{
											color: colors.supportDark,
											fontSize: '20px',
											marginLeft: '17px'
										}}
									>
										Important:
									</Typography>
								</Box>
								<Box marginLeft="35px">
									<Typography
										color={colors.neutralDark}
										fontSize="20px"
										lineHeight="32px"
									>
										This operation is conditioned to the available amount of
										tokens in your wallet
									</Typography>
								</Box>

								<LoadingButton
									disableElevation
									disabled={validateTotalEnergy}
									loading={false}
									onClick={onSubmit}
									sx={{
										marginLeft: '35px',
										marginTop: '65px',
										width: '242px',
										borderRadius: '25px',
										padding: '8px 32px',
										display: 'flex',
										alignItems: 'flex-end',
										backgroundColor:
											!validateTotalEnergy && inputAmount
												? colors.primary
												: colors.primaryLight,
										'&:hover': {
											backgroundColor:
												!validateTotalEnergy && inputAmount
													? colors.primary
													: colors.primaryLight
										}
									}}
								>
									<Typography
										color="#fff"
										fontSize="24px"
										fontWeight="700"
										lineHeight="32px"
									>
										AUTHORIZE
									</Typography>
								</LoadingButton>
							</Card>
						</Card>
					</Grid>
				)}
				{openAuthorization &&
					<ConfirmationModal
						open={openAuthorization}
						title="Transfer of RECs"
						subTitle="This action will prompt you to authorize the transfer of RECs."
						walletName={user.wallet_name}
						walletAddress={user.wallet_address}
						handleCloseAuthorization={() => handleCloseAuthoraztion(false)}
						onSubmitAuthorization={onSubmitAuthorization}
						setPasswordAuthorization={setPasswordAuthorization}
						loadingAuthorization={loadingAuthorization}
						confirm="Sign"
						cancel="Cancel"
					/>
				}
			</Grid>
		</>
	);
};
