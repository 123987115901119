/* eslint-disable import/extensions */
import './App.css';
import { useState } from 'react';
import { Provider } from 'react-redux';

import { SnackbarProvider } from 'notistack';

import { ThemeProvider } from '@mui/material/styles';
import React, { Component, ReactNode, ErrorInfo } from 'react';
import { RoutesApp } from './routes';
import store from './stores/store';
import { customTheme } from './styles';

// interface ErrorBoundaryProps {
// 	children: ReactNode;
//   }
  
//   interface ErrorBoundaryState {
// 	hasError: boolean;
//   }
  
//   class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
// 	constructor(props: ErrorBoundaryProps) {
// 	  super(props);
// 	  this.state = { hasError: false };
// 	}
  
// 	componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
// 	  // Log the error to an error reporting service
// 	  console.log(error, errorInfo);
// 	  this.setState({ hasError: true });
// 	}
  
// 	render(): ReactNode {
// 	  if (this.state.hasError) {
// 		// You can render a fallback UI here
// 		return <p>Something went wrong. Please try again later.</p>;
// 	  }
  
// 	  return this.props.children;
// 	}
//   }


const App = () => {
	return (
		<>
			<ThemeProvider theme={customTheme}>
				<Provider store={store}>
					<SnackbarProvider
						autoHideDuration={3000}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}
					>
						<RoutesApp />
					</SnackbarProvider>
				</Provider>
			</ThemeProvider>
		</>
	);
};

export default App;
