import { FC, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Box, Button, Card, Grid, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';
import {sha256} from "js-sha256";
import { CardHeader } from '../../../components/cardHeader';
import { Header } from '../../../components/header';
import { SideBar } from '../../../components/sideBar';
import { colors } from '../../../styles';
import { ICertificateOrg, IDataPreview } from '../../../types/certificateOrg';
import { HeadCell } from '../../../types/headCell';
import { Table } from './table';
import { TransactionService } from '../../../services/transactions.service';
import { ConfirmationModal } from '../../../components/confirmationModal';
import { ApiPromise, WsProvider, } from "@polkadot/api";
import { keyring as uiKeyring } from "@polkadot/ui-keyring";
import { waitReady } from "@polkadot/wasm-crypto";
import { ClientService } from '../../../services/client.service';
import { useSnackbar } from 'notistack';

interface Item {
	origin_id: string;
	origin_hash: string;
	amount: number;
	date: Date;
}

type OriginCertOnchainType = Array<[string, number, [number,number], number ]>;

export const Preview: FC = () => {
	const clientService = new ClientService();
	const history = useNavigate();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [client, setClient] = useState<any>({});
	const navigate = useNavigate();
	const location = useLocation();
	const { data, totalAmountSelected } = location.state;
	const [isLoaded, setIsLoaded] = useState<boolean>(true);
	const [dataPreview, setDataPreview] = useState<ICertificateOrg[]>(data);
	const [totalSum, setTotalSum] = useState<number>(0);
	const [passwordAuthorization, setPasswordAuthorization] =
		useState<string>('');
	const [openAuthorization, setOpenAuthorization] = useState<boolean>(false);
	const [loadingAuthorization, setLoadingAuthorization] =
		useState<boolean>(false);

	useEffect(()=>{
		setDataPreview(data)
	},[data])

	useEffect(() => {
		const TotalSelected: number = data.reduce((acc: number, obj: any) => {
			return Number(acc) + Number(obj.amountRec);
		}, 0);
		setTotalSum(TotalSelected);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const getUserInfo = async () => {
		if(dataPreview[0].to){
			const getClient: any = await clientService.listClientById(dataPreview[0].to);
			if (getClient) {
				setClient(getClient);
			} 
		}
	};

	useEffect(() => {
			getUserInfo();
	}, []);

	async function retireREC(ocert: [OriginCertOnchainType], operationDate: string, beneficiary: string, clientAddress: string, recHash: string) {
		try {
			await waitReady();
			uiKeyring.loadAll({ ss58Format: 42, type: "sr25519" });
		} catch (error) {
			console.log(error)
		} finally {
		const provider = new WsProvider(`${process.env.REACT_APP_PARACHAIN_ADDRESS}`);
		const api = await ApiPromise.create({ provider });
		try {
			const userPair = uiKeyring.getPair(client.wallet_address);
			try {
				userPair.unlock(passwordAuthorization);
			} catch (error) {
				console.log(error)
				enqueueSnackbar('The password of your wallet is invalid, try again', {
					variant: 'error'
				});
				setLoadingAuthorization(false);
				return;
			}
			const genesisHash = api.genesisHash;
			const runtimeVersion = api.runtimeVersion;
			const nonce = await api.rpc.system.accountNextIndex(userPair.address);
			const extrinsic = api.tx.recs.retireRec(`${process.env.REACT_APP_MULTILEDGERS_PARACHAIN_ID}`, `${process.env.REACT_APP_CCEE_PARACHAIN_ID}`, ocert, operationDate, beneficiary, clientAddress, recHash).sign(userPair, { genesisHash, blockHash: genesisHash, nonce, runtimeVersion });
			await api.disconnect()
			return extrinsic
			//	
		} catch (error) {
			console.log(error)
			enqueueSnackbar('Wallet not found, please consider recovering it on the My Profile page. A new tab will be created in 5 seconds.', {
				variant: 'error'
			});
			setLoadingAuthorization(false);
			const timeout = setTimeout(()=>{
				window.open(`${process.env.REACT_APP_CLIENT_URL}/profile`, "_blank", 'noopener,noreferrer'	);
			},5000)
			clearTimeout(timeout);
			return;
		}
	}
	}

	const handleCloseAuthoraztion = (value: boolean) => {
		setOpenAuthorization(value);
	};

	const onSubmit = () => {
		if (!client.wallet_address){
			enqueueSnackbar("You haven't registered your wallet in your profile yet.", {
				variant: 'error'
			});
			return; 
		}
		handleCloseAuthoraztion(true);
	};

	const onSubmitAuthorization = async () => {
		const transactionService = new TransactionService();
		const orderedList = dataPreview.sort(
			(a, b) => a.startDate.getTime() - b.startDate.getTime()
		);

		const newStartDate = orderedList[0].startDate;
		const newEndDate = orderedList[orderedList.length - 1].endDate;
		const output = dataPreview.map(item => ({
			origin_id: item.id,
			amount: Number(item.amountRec)
		}));

		const onchainOrigin: OriginCertOnchainType = dataPreview.map(item => {
			return [item.recHash, Number(item.amountRec), [0,0], 0];
		});

		setLoadingAuthorization(true);
		// ONCHAIN
		const dateFormatted = dayjs().format('YYYY-MM-DD');
		const RecCertId = '0x' + sha256.update(window.crypto.randomUUID()).hex();
		const consumptionAddress = `${client.country} - ${client.state} - ${client.city}`
		if (!client.country || !client.state || !client.city){
			enqueueSnackbar("Client doesn't have an address", {
				variant: 'error'
			});
			setLoadingAuthorization(false);
			return;
		}

		const signedTransaction = await retireREC([onchainOrigin], dateFormatted, client.wallet_address, consumptionAddress, RecCertId)

		if (!signedTransaction){
			enqueueSnackbar("Wallet Signature problem", {
				variant: 'error'
			});
			return;
		}

		const notify = enqueueSnackbar('Processing on the blockchain.', {
			variant: 'info',
			persist: true
		});
		
		handleCloseAuthoraztion(false);

		const createRetirementData = {
			asset_id: dataPreview[0].assetId,
			client_id: dataPreview[0].to,
			rec_id: dataPreview[0].recId,
			start_date: newStartDate,
			end_date: newEndDate,
			operation_type: 'retirement',
			origins: output,
			enterprise_id: dataPreview[0].enterpriseId,
			rec_hash: RecCertId,
			holder_wallet: client.wallet_address,
			extrinsic: signedTransaction
		};

		const respCertificate = await transactionService.createRetirement(createRetirementData);
		if ([400, 401, 404, 500].includes(respCertificate.status)) {
			enqueueSnackbar(respCertificate.statusText, {
				variant: 'error'
			});
			closeSnackbar(notify)
		}
		if (respCertificate.status === 200) {
			closeSnackbar(notify)
			enqueueSnackbar('Certificate sent successfully', {
				variant: 'success'
			});
			setLoadingAuthorization(false);
		}
		localStorage.setItem('retrimentPage', 'tOperation');
		navigate('/rec/operation-history');
		handleCloseAuthoraztion(false);
	};

	const headCells: HeadCell[] = [
		{
			id: 'date',
			numeric: true,
			disablePadding: false,
			label: 'Date UTC-3'
		},
		{
			id: 'orgName',
			numeric: true,
			disablePadding: false,
			label: 'Org Name'
		},
		{
			id: 'opType',
			numeric: true,
			disablePadding: false,
			label: 'Op. Type'
		},
		{
			id: 'status',
			numeric: true,
			disablePadding: false,
			label: 'Status'
		},
		{
			id: 'label',
			numeric: true,
			disablePadding: false,
			label: 'Label'
		},
		{
			id: 'sourceType',
			numeric: true,
			disablePadding: false,
			label: 'Source Type'
		},
		{
			id: 'location',
			numeric: true,
			disablePadding: false,
			label: 'Location'
		},
		{
			id: 'period',
			numeric: true,
			disablePadding: false,
			label: 'Period'
		},
		{
			id: 'uniqueId',
			numeric: true,
			disablePadding: false,
			label: 'Unique ID'
		},
		{
			id: 'amountRec',
			numeric: true,
			disablePadding: false,
			label: 'Amount Rec'
		}
	];

	return (
		<>
			<Header />
			<SideBar />
			<Box
				component="main"
				sx={{
					marginLeft: '234px',
					height: '100vh',
					paddingTop: '146px'
				}}
			>
				<Grid
					container
					md={12}
					marginBottom="65px"
					display="flex"
					justifyContent="space-between"
				>
					<Grid item md={1} marginRight="30px">
						<Button
							variant="outlined"
							onClick={() =>
								navigate('/rec/operation-history/rec-retirement/', {
									state: { dataPreview: data, totalAmount: totalAmountSelected }
								})
							}
							sx={{
								width: '107px',
								height: '40px',
								borderRadius: '25px',
								border: '1px solid #000',
								padding: '8px 24px',
								color: '#000',
								textTransform: 'initial',
								fontSize: '1.25rem',
								'&:hover': {
									border: '1px solid #000'
								}
							}}
						>
							<Typography fontSize="1.25rem" lineHeight="32px">
								Back
							</Typography>
						</Button>
					</Grid>

					<Grid item md={5}>
						<Grid item md={12} marginBottom="24px">
							<Typography
								fontWeight="700"
								fontSize="1.75rem"
								lineHeight="40px"
								color={colors.primary}
								marginBottom="9px"
							>
								CONFIRMATION
							</Typography>
						</Grid>
						<Card sx={{ display: 'flex', flexDirection: 'column' }}>
							<Box marginBottom="19px" display="flex">
								<Box display="flex" marginRight="38px">
									<Typography
										marginRight="18px"
										color={colors.neutralDark}
										fontSize="20px"
										lineHeight="24px"
									>
										BENEFICIARY:
									</Typography>
									<Typography lineHeight="24px">
										{dataPreview[0]?.beneficiary.length < 20 ? dataPreview[0]?.beneficiary : `${dataPreview[0]?.beneficiary.slice(0, 20)}...`}
									</Typography>
								</Box>
								<Box display="flex">
									<Typography
										marginRight="18px"
										color={colors.neutralDark}
										fontSize="20px"
										lineHeight="24px"
									>
										PERIOD:
									</Typography>
									<Typography>{dayjs(dataPreview[0].startDate).format('YYYY-MM') + ' to ' + dayjs(dataPreview[0].endDate).format('YYYY-MM')}</Typography>
								</Box>
							</Box>
							<Box display="flex" marginRight="38px">
								<Typography
									marginRight="18px"
									color={colors.neutralDark}
									fontSize="20px"
									lineHeight="24px"
								>
									CONSUMPTION LOCATION:
								</Typography>
								<Typography lineHeight="24px">
									{dataPreview[0].assetCountry}
								</Typography>
							</Box>
						</Card>
					</Grid>
					<Grid item md={5} display="flex">
						<CardHeader
							totalAmountRec={totalSum}
							title="Total REC's"
							subTitle="REC"
							marginRight="24px"
						/>
						<CardHeader
							totalAmountRec={data.length}
							title="Total Certificates"
							subTitle="Certs"
						/>
					</Grid>
				</Grid>

				<Grid container md={12}>
					<Grid item sx={{ width: '97%', overflowX: 'auto' }}>
						<Table
							title="Retirement selection"
							titles={headCells}
							transactions={dataPreview ? dataPreview : null}
							isLoaded={isLoaded}
							sx={{
								border: `1px solid ${colors.primaryLight}`,
								borderRadius: '16px',
								overflow: 'hidden'
							}}
						/>
					</Grid>
				</Grid>
				<Box display="flex" justifyContent="flex-end" marginRight="45px">
					<LoadingButton
						disableElevation
						disabled={false} // Sempre será habilitado
						loading={false}
						onClick={onSubmit}
						sx={{
							marginTop: '65px',
							width: '202px',
							height: '40px',
							borderRadius: '25px',
							padding: '8px 32px',
							backgroundColor: false ? colors.primaryLight : colors.primary,
							'&:hover': {
								backgroundColor: false ? colors.primaryLight : colors.primary
							}
						}}
					>
						<Typography
							color="#fff"
							fontSize="20px"
							fontWeight="400"
							lineHeight="32px"
							sx={{ textTransform: 'initial' }}
						>
							Confirm
						</Typography>
					</LoadingButton>
				</Box>
				{openAuthorization &&
					<ConfirmationModal
						open={openAuthorization}
						title="Retirement of RECs"
						subTitle="This action will cause you to authorize the retirement of RECs."
						walletName={client.wallet_name}
						walletAddress={client.wallet_address}
						handleCloseAuthorization={() => handleCloseAuthoraztion(false)}
						onSubmitAuthorization={onSubmitAuthorization}
						setPasswordAuthorization={setPasswordAuthorization}
						loadingAuthorization={loadingAuthorization}
						confirm="Sign"
						cancel="Cancel"
					/>
				}
			</Box>
		</>
	);
};
