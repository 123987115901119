import { ApiPromise, WsProvider, Keyring } from '@polkadot/api';
import { mnemonicGenerate } from '@polkadot/util-crypto';

let apiInstance: ApiPromise | null = null;
let keyring: Keyring | null = null;

async function getOnchainAPI(): Promise<ApiPromise> {
    if (!apiInstance) {
        // Construct
        const wsProvider = new WsProvider('wss://rpc.polkadot.io');
        apiInstance = await ApiPromise.create({ provider: wsProvider });
        // Do something
    }

    return apiInstance;
}

async function getKeyring(): Promise<Keyring> {
    if (!keyring) {
        // Construct
        keyring = new Keyring({ type: 'sr25519' });
    }

    return keyring;
}


export { getOnchainAPI, getKeyring, mnemonicGenerate };