/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { useSnackbar } from 'notistack';

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  InputBase,
  styled,
  Typography,
  OutlinedInput,
  FormHelperText
} from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';

import { EnterpriseService } from '../../../services/enterprise.service';
import { ProfileService } from '../../../services/profile.service';
import { colors } from '../../../styles';
import { IListWallet } from '../../../types/wallet';
import { SchemaFormProfile } from './schemaProfile';

export type TForm = {
  setSuccess: (value: boolean) => void;
  setBackInfoProfile: (value: boolean) => void;
  dataUserProfile?: IListWallet;
};

export const ProfileForm = ({
  setSuccess,
  setBackInfoProfile,
  dataUserProfile
}: TForm) => {
  const profileService = new ProfileService();
  const enterpriseService = new EnterpriseService();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [countries, setCountries] = useState<Array<any>>();
  const [states, setStates] = useState<Array<any>>([]);
  const [countriesToState, setCountriesToState] = useState<any>();

  const form = useForm<IListWallet>({
    resolver: yupResolver(SchemaFormProfile)
  });

  const loadDataEdit = () => {
    if (dataUserProfile) {
      form.setValue('first_name', dataUserProfile.first_name);
      form.setValue('last_name', dataUserProfile.last_name);
      form.setValue('job_title', dataUserProfile.job_title);
      form.setValue('cpf', dataUserProfile.cpf);
      form.setValue('phone', dataUserProfile.phone);
      setCountriesToState(dataUserProfile.country);
      form.setValue('state', dataUserProfile.state);
      form.setValue('city', dataUserProfile.city);
      form.setValue('address', dataUserProfile.address);
      form.setValue('email', dataUserProfile.email);
      form.setValue('zipcode', dataUserProfile.zipcode);
      form.setValue('address_number', dataUserProfile.address_number);
    };
  }

  useEffect(() => {
    loadDataEdit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const BootstrapInput = styled(InputBase)(() => ({
    '& .MuiInputBase-input': {
      display: 'flex',
      alignItems: 'center',
      padding: '14px 25px',
      borderRadius: '9px',
      // backgroundColor: 'transparent',
      border: '1px solid #000',
      '& .MuiSvgIcon-root': {
        marginTop: '100px',
        color: '#fff'
      },
      '&:focus': {
        borderRadius: '10px'
      },
      ':after': {
        borderBottom: 'none',
        borderRight: '1px solid #000',
        marginRight: '50px'
      }
    }
  }));

  const goToProfile = () => {
    setBackInfoProfile(true)
  }

  useEffect(() => {
    const getCoutries = async () => {
      const respCountries = await enterpriseService.getCountries();
      if (respCountries.status === 200) {
        const { data } = respCountries.data;
        setCountries(data);
      }
    }
    getCoutries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getStates = async () => {
      if (countriesToState) {
				const respStates = await enterpriseService.getStates(countriesToState);
				if (respStates.status === 200) {
					const { data } = respStates.data;
					setStates(data.states);
				}
			}
    }
    getStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesToState])

  const setCountriesSelect = (e: any) => {
    form.setValue('country', e.target.value);
    setCountriesToState(e.target.value);
  }

  const getZipCode = async (e: any) => {
    form.setValue('zipcode', Number(e.target.value));
    const resZipCode = await enterpriseService.getZipCode(e.target.value);

    if (resZipCode?.status === 200) {
      const { localidade, logradouro } = resZipCode.data;
      form.setValue('city', localidade);
      form.setValue('address', logradouro);
    }
  }

  const onSubmit = async () => {
    const value = { ...form.getValues() };
    const cpfNormalize = Number(value.cpf.replace(/[./-]/g, ""));
    const phoneNormalize = Number(value.phone.replace(/\D/g, ""));

    if (!value.first_name || !value.cpf) return;

    setLoading(true);
    const respSubmitForm = await profileService.addUserProfile({
      first_name: value.first_name,
      last_name: value.last_name,
      job_title: value.job_title,
      cpf: cpfNormalize,
      phone: phoneNormalize,
      country: value.country,
      state: value.state,
      city: value.city,
      address: value.address,
      email: value.email,
      zipcode: value.zipcode,
      number: value.address_number
    })

    if (respSubmitForm.status === 200) {
      enqueueSnackbar('Updated', {
        variant: 'success'
      });
      setLoading(false);
      setSuccess(true);
    }

    if ([401, 400, 404].includes(respSubmitForm.status)) {
      setLoading(false);
      setSuccess(false);
      enqueueSnackbar(respSubmitForm.statusText, {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <form onSubmit={() => { }}>
        <Grid
          container
          md={12}
          rowGap={2}
          columnSpacing={2}
          marginTop="-12px"
        >
          <Grid item md={6}>
            <Controller
              control={form.control}
              name="first_name"
              render={({ field }) => (
                <FormControl variant="standard" fullWidth>
                  <InputLabel
                    error={!!form.formState.errors.first_name}
                    shrink
                    htmlFor="first_name">
                    FIRST NAME
                  </InputLabel>
                  <OutlinedInput
                    autoFocus
                    fullWidth
                    style={{
                      border: 'none',
                      borderRadius: '9px',
                      marginTop: '20px'
                    }}
                    error={!!form.formState.errors.first_name}
                    placeholder="type here..."
                    {...field}
                    type="string"
                    onChange={e => {
                      form.setValue('first_name', e.target.value);
                    }}
                  />
                  {!!form.formState.errors.first_name && (
                    <FormHelperText error>
                      {form?.formState?.errors?.first_name?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={form.control}
              name="last_name"
              render={({ field }) => (
                <FormControl variant="standard" fullWidth>
                  <InputLabel
                    error={!!form.formState.errors.last_name}
                    shrink
                    htmlFor="last_name">
                    LAST NAME
                  </InputLabel>
                  <OutlinedInput
                    autoFocus
                    fullWidth
                    style={{
                      border: 'none',
                      borderRadius: '9px',
                      marginTop: '20px'
                    }}
                    error={!!form.formState.errors.last_name}
                    placeholder="type here..."
                    {...field}
                    type="string"
                    onChange={e => {
                      form.setValue('last_name', e.target.value);
                    }}
                  />
                  {!!form.formState.errors.last_name && (
                    <FormHelperText error>
                      {form?.formState?.errors?.last_name?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={form.control}
              name="job_title"
              render={({ field }) => (
                <FormControl variant="standard" fullWidth>
                  <InputLabel
                    error={!!form.formState.errors.job_title}
                    shrink
                    htmlFor="job_title">
                    JOB TITLE
                  </InputLabel>
                  <OutlinedInput
                    autoFocus
                    fullWidth
                    style={{
                      border: 'none',
                      borderRadius: '9px',
                      marginTop: '20px'
                    }}
                    error={!!form.formState.errors.job_title}
                    placeholder="type here..."
                    {...field}
                    type="string"
                    onChange={e => {
                      form.setValue('job_title', e.target.value);
                    }}
                  />
                  {!!form.formState.errors.job_title && (
                    <FormHelperText error>
                      {form?.formState?.errors?.job_title?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormControl variant="standard" fullWidth>
                  <InputLabel
                    error={!!form.formState.errors.phone}
                    shrink
                    htmlFor="phone"
                  >
                    PHONE
                  </InputLabel>
                  <InputMask
                    mask="(99) 99999-9999"
                    maskChar=""
                    value={field.value}
                    onChange={(e) => {
                      form.setValue('phone', e.target.value);
                    }}
                  >
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    {() => (
                      <OutlinedInput
                        autoFocus
                        fullWidth
                        style={{
                          border: 'none',
                          borderRadius: '9px',
                          marginTop: '20px'
                        }}
                        error={!!form.formState.errors.phone}
                        placeholder="Type here..."
                        type="tel"
                      />
                    )}
                  </InputMask>


                  {/* <OutlinedInput
                    autoFocus
                    fullWidth
                    style={{
                      border: 'none',
                      borderRadius: '9px',
                      marginTop: '20px'
                    }}
                    error={!!form.formState.errors.phone}
                    placeholder="type here..."
                    {...field}

                    type="string"
                    onChange={e => {
                      form.setValue('phone', Number(e.target.value));
                    }}
                  /> */}
                  {!!form.formState.errors.phone && (
                    <FormHelperText error>
                      {form?.formState?.errors?.phone?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={form.control}
              name="country"
              render={({ field }) => (
                <FormControl variant="standard" fullWidth>
                  <InputLabel
                    error={false}
                    shrink
                    htmlFor="country"
                    sx={{ position: 'relative', marginTop: '3px' }}
                  >
                    COUNTRY
                  </InputLabel>
                  <Select
                    {...field}
                    fullWidth
                    input={<BootstrapInput />}
                    labelId="demo-simple-select-label"
                    id="country"
                    variant="standard"
                    value={countriesToState}
                    onChange={(e: any) => setCountriesSelect(e)}
                  >
                    {countries &&
                      countries.map(({ iso3, country }) => (
                        <MenuItem
                          key={iso3}
                          value={country}>
                          {country}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={form.control}
              name="cpf"
              render={({ field }) => (
                <FormControl variant="standard" fullWidth disabled={dataUserProfile?.cpf ? true : false}>
                  <InputLabel
                    error={!!form.formState.errors.cpf}
                    shrink
                    htmlFor="cpf"
                  >
                    CPF
                  </InputLabel>
                  <InputMask
                    autoComplete="off"
                    type="string"
                    mask="999.999.999-99"
                    value={field.value}
                    onChange={e => {
                      form.setValue('cpf', e.target.value);
                    }}
                  >
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    {() => (
                      <OutlinedInput
                        fullWidth
                        style={{
                          border: 'none',
                          borderRadius: '9px',
                          marginTop: '20px'
                        }}
                        error={!!form.formState.errors.cpf}
                        placeholder="type here..."
                      />
                    )}
                  </InputMask>
                  {!!form.formState.errors.cpf && (
                    <FormHelperText error>
                      {form?.formState?.errors?.cpf?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={4}>
            <Controller
              control={form.control}
              name="zipcode"
              render={({ field }) => (
                <FormControl variant="standard" fullWidth>
                  <InputLabel
                    error={!!form.formState.errors.zipcode}
                    shrink
                    htmlFor="zipcode"
                  >
                    ZIPCODE
                  </InputLabel>
                  <OutlinedInput
                    autoComplete="off"
                    fullWidth
                    style={{
                      border: 'none',
                      borderRadius: '9px',
                      marginTop: '20px'
                    }}
                    error={!!form.formState.errors.zipcode}
                    placeholder="type here..."
                    {...field}
                    type="string"
                    onChange={e => getZipCode(e)}
                  />
                  {!!form.formState.errors.zipcode && (
                    <FormHelperText error>
                      {form?.formState?.errors?.zipcode?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={8}>
            <Controller
              control={form.control}
              name="state"
              render={({ field }) => (
                <FormControl variant="standard" fullWidth>
                  <InputLabel
                    error={false}
                    shrink
                    htmlFor="state"
                    sx={{ position: 'relative' }}
                  >
                    STATE
                  </InputLabel>
                  <Select
                    {...field}
                    fullWidth
                    input={<BootstrapInput />}
                    labelId="demo-simple-select-label"
                    id="state"
                    variant="standard"
                    onChange={e => {
                      form.setValue('state', e.target.value);
                    }}
                    sx={{
                      ':before': {
                        borderRight: '1px solid #A2CBFE',
                        marginRight: '50px',
                        height: '100%'
                      }
                    }}
                  >
                    {states &&
                      states.map(({ iso3, name }) => (
                        <MenuItem
                          key={iso3}
                          value={name}
                        >
                          {name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item md={4}>
            <Controller
              control={form.control}
              name="city"
              render={({ field }) => (
                <FormControl variant="standard" fullWidth>
                  <InputLabel
                    error={!!form.formState.errors.city}
                    shrink
                    htmlFor="city"
                  >
                    CITY
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    style={{
                      border: 'none',
                      borderRadius: '9px',
                      marginTop: '20px'
                    }}
                    error={!!form.formState.errors.city}
                    placeholder="type here..."
                    {...field}
                    type="string"
                    onChange={e => {
                      form.setValue('city', e.target.value);
                    }}
                  />
                  {!!form.formState.errors.city && (
                    <FormHelperText error>
                      {form?.formState?.errors?.city?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={8}>
            <Controller
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormControl variant="standard" fullWidth>
                  <InputLabel
                    error={!!form.formState.errors.address}
                    shrink
                    htmlFor="address"
                  >
                    ADDRESS
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    style={{
                      border: 'none',
                      borderRadius: '9px',
                      marginTop: '20px'
                    }}
                    error={!!form.formState.errors.address}
                    placeholder="type here..."
                    {...field}
                    type="string"
                    onChange={e => {
                      form.setValue('address', e.target.value);
                    }}
                  />
                  {!!form.formState.errors.address && (
                    <FormHelperText error>
                      {form?.formState?.errors?.address?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>

          <Grid item md={4}>
            <Controller
              control={form.control}
              name="address_number"
              render={({ field }) => (
                <FormControl variant="standard" fullWidth>
                  <InputLabel
                    error={!!form.formState.errors.address_number}
                    shrink
                    htmlFor="number"
                  >
                    NUMBER
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    style={{
                      border: 'none',
                      borderRadius: '9px',
                      marginTop: '20px'
                    }}
                    error={!!form.formState.errors.address_number}
                    placeholder="type here..."
                    {...field}
                    type="string"
                    onChange={e => {
                      form.setValue('address_number', Number(e.target.value));
                    }}
                  />
                  {!!form.formState.errors.address_number && (
                    <FormHelperText error>
                      {form?.formState?.errors?.address_number?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={8}>
            <Controller
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormControl variant="standard" fullWidth disabled={dataUserProfile?.email ? true : false}>
                  <InputLabel
                    error={!!form.formState.errors.email}
                    shrink
                    htmlFor="email"
                    >
                    E-mail
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    style={{
                      border: 'none',
                      borderRadius: '9px',
                      marginTop: '20px'
                    }}
                    error={!!form.formState.errors.email}
                    placeholder="type here..."
                    {...field}
                    type="string"
                    onChange={e => {
                      form.setValue('email', e.target.value);
                    }}
                  />
                  {!!form.formState.errors.email && (
                    <FormHelperText error>
                      {form?.formState?.errors?.email?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid
            item
            marginTop="36px"
            paddingTop="0"
            display="flex"
            justifyContent="flex-end"
            width="100%"
          >
            <Button
              onClick={goToProfile}
              variant="outlined"
              sx={{
                borderRadius: '25px',
                border: '1px solid #001',
                padding: '4px 30px',
                width: '107px',
                marginRight: '52px',
                '&:hover': {
                  borderColor: '#68707c',
                  backgroundColor: 'transparent'
                }
              }}
            >
              <Typography
                color="#000"
                fontSize="1.25rem"
                sx={{
                  textTransform: 'initial'
                }}
              >
                Back
              </Typography>
            </Button>
            <LoadingButton
              variant="contained"
              loading={loading}
              // disabled={true}
              onClick={() => form.handleSubmit(onSubmit)()}
              sx={{
                width: '169px',
                backgroundColor: colors.primary,
                borderRadius: '25px',
                padding: '8px 24px',
                '&:hover': {
                  backgroundColor: '#68707c'
                }
              }}
            >
              <Typography
                color="#fff"
                fontSize="1.25rem"
                fontWeight="400"
                sx={{
                  textTransform: 'initial'
                }}
              >
                Save data
              </Typography>
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
