import { Box, Card, Typography } from '@mui/material';

interface ICardHeader {
	totalAmountRec?: number;
	title: string;
	subTitle: string;
	marginRight?: string;
}
export const CardHeader = ({
	totalAmountRec,
	title,
	subTitle,
	marginRight
}: ICardHeader) => {
	return (
		<Card
			sx={{
				boxShadow: '0px 5px 30px rgba(0, 0, 0, 0.05)',
				padding: '17px 34px 13px 32px',
				borderRadius: '16px',
				width: '313px',
				height: '109px',
				marginRight: marginRight ? marginRight : '40px'
			}}
		>
			<Typography
				fontWeight="700"
				lineHeight="24px"
				color="#000"
				marginBottom="10px"
			>
				{title}
			</Typography>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between'
				}}
			>
				<Typography fontSize="28px">
					{Number(totalAmountRec).toLocaleString('en-US', {
						minimumFractionDigits: 0
					})}
				</Typography>
				<Typography>{subTitle}</Typography>
			</Box>
		</Card>
	);
};
