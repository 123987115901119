import { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { useSelector } from 'react-redux';

import { Header } from '../../components/header';
import { SideBar } from '../../components/sideBar';
import { SaleHome } from './saleHome';

export const SaleManager = () => {
	const [goTotransfer, setGoTotransfer] = useState<boolean>(false);
	// const getCheckBoxState = useSelector((state: any) => state.getCheckBox);
	return (
		<>
			<Header />
			<SideBar />
			<Box
				component="main"
				sx={{
					marginLeft: '234px',
					height: '100vh',
					paddingTop: '146px'
				}}
			>
				<SaleHome setGoTotransferT={setGoTotransfer} />
			</Box>
		</>
	);
};
