import { useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { useParams, useNavigate } from 'react-router-dom';
import { TransactionService } from '../../services/transactions.service';
import { RecTokenService } from '../../services/rec.service';
import { AssetService } from '../../services/asset.service';
import dayjs, { Dayjs } from 'dayjs';
import { useSnackbar } from 'notistack';
import { ApiPromise, WsProvider, } from "@polkadot/api";
import { keyring as uiKeyring } from "@polkadot/ui-keyring";
import { waitReady } from "@polkadot/wasm-crypto";
import {sha256} from "js-sha256";
import {
	Box,
	Button,
	Grid,
	Typography,
	Card,
	styled,
	InputBase,
	MenuItem,
	Select,
	InputLabel,
	RadioGroup,
	FormLabel,
	FormControlLabel,
	Radio,
	TextField,
	FormHelperText,
	CircularProgress,
	CircularProgressProps
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import EnergyImg from '../../assets/imgs/energy.png';
import informationIssueImg from '../../assets/imgs/informationIssue.png';
import RecIssueImg from '../../assets/imgs/recIsue.png';
import { Header } from '../../components/header';
import { SideBar } from '../../components/sideBar';
import { colors } from '../../styles';
import { SelectAmount } from './selectAmount';
import { SelectMode } from './selectMode';
import { SelectPeriod } from './selectPeriod';
import { ConfirmationModal } from '../../components/confirmationModal';
import { MemberService } from '../../services/member.service';
import { BackOfficeService } from '../../services/backoffice.service';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

interface IRecLabel {
	id: string;
	label: string;
	optional_id: string;
}

interface IRecRequest {
	id: string;
	name: string;
	optional_id: string;
	type: string;
	descriptions: string;
	enterprise_id: string;
	issued_recs: string;
	retired_recs: string;
	has_been_issued: string;
	has_been_canceled: string;
	user_id: string;
	logo: string;
	hash: string;
	created_at: string;
	updated_at: string;
}

export const RecIssue = () => {
	const memberService = new MemberService();
	const assetService = new AssetService();

	const navigate = useNavigate();
	const { id } = useParams();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [startDate, setStartDate] = useState<Dayjs | null>(null);
	const [endDate, setEndDate] = useState<Dayjs | null>(null);
	const [selectModeLabel, setSelectModeLabel] = useState<IRecLabel>({} as IRecLabel);
	const [backofficeStartDate, setBackofficeStartDate] = useState<dayjs.Dayjs>(dayjs());
	const [backofficeEndDate, setBackofficeEndDate] = useState<dayjs.Dayjs>(dayjs());
	const [backofficeFinalStartDate, setBackofficeFinalStartDate] = useState<dayjs.Dayjs>(dayjs());
	const [backofficeFinalEndDate, setBackofficeFinalEndDate] = useState<dayjs.Dayjs>(dayjs());
	const [user, setUser] = useState<any>({});
	const [period, setPeriod] = useState<string>('');
	const [inputAmount, setInputAmount] = useState<string>('');
	const [errorDate, setErrorDate] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [breakLoop, setBreakLoop] = useState<boolean>(false);
	const [totalAvaliable, setTotalAvaliable] = useState<number>(0);
	const [selectedAmount, setSelectedAmount] = useState<number>(0);
	const [isLoading, setIsloading] = useState<boolean>(false);
	const [isLoadingRecLabels, setIsloadingRecLabels] = useState<boolean>(true);
	const [loadingProgress, setLoadingProgress] = useState<number>(0.0);
	const [assetWalletAddress, setAssetWalletAddress] = useState<string>('');
	const [assetWalletName, setAssetWalletName] = useState<string>('');
	const [assetCode, setAssetCode] = useState<string>();
	const [validateTotalEnergy, setValidateTotalEnergy] = useState<boolean>(false);
	const [passwordAuthorization, setPasswordAuthorization] = useState<string>('');
	const [openAuthorization, setOpenAuthorization] = useState<boolean>(false);
	const [loadingAuthorization, setLoadingAuthorization] = useState<boolean>(false);
	const [recData, setRecData] = useState<Array<IRecLabel>>([]);

	const getUserInfo = async () => {
		const getUser: any = await memberService.getMyInfo();
		if (getUser.status === 200) {
			setUser(getUser.data);
		}
	};

	useEffect(() => {
		getUserInfo();
	}, []);


	const getRecOnchain = async (recLabel: string) => {
		const provider = new WsProvider(`${process.env.REACT_APP_PARACHAIN_ADDRESS}`);
		const api = await ApiPromise.create({ provider });
		if(recLabel){
			const queryRes = await api.query.myRecs.listRecLabel(recLabel);
			const recLabels: any = queryRes.toHuman();
			if(recLabels){
				await api.disconnect()
				return recLabels.isCertified
			}
			return false
		}
		return false
	}

	async function matRequestMHW(powerPlantId: string, amount: string, time: string, date: string, month: string, year: string, end_time:string, end_date: string, end_month: string, end_year: string, operationDate: string, recTypeId: string, dateGeneration: string, timeGeneration: string, end_date_gen: string, end_time_gen: string, mwhRecHash: string, recId: string) {
		try {
			await waitReady();
			uiKeyring.loadAll({ ss58Format: 42, type: "sr25519" });
		} catch (error) {
			console.log(error)
		} finally {
		const provider = new WsProvider(`${process.env.REACT_APP_PARACHAIN_ADDRESS}`);
		const api = await ApiPromise.create({ provider });
		if(!assetWalletAddress){
			enqueueSnackbar("Asset's Wallet was not found", {
				variant: 'error'
			});
			return;
		}
		try {
				const userPair = uiKeyring.getPair(assetWalletAddress);
				try {
					userPair.unlock(passwordAuthorization);
				} catch (error) {
					console.log(error)
					enqueueSnackbar('The password of your wallet is invalid, try again', {
						variant: 'error'
					});
					return;
				}
				const genesisHash = api.genesisHash;
				const runtimeVersion = api.runtimeVersion;
				const nonce = await api.rpc.system.accountNextIndex(userPair.address);
				const amountInWatt = Number(amount) * 1000000;
				if (amountInWatt % 1000000 != 0){
					enqueueSnackbar('Invalid value, it must be multiple of 1 MWh', {
						variant: 'error'
					});
					return;		
				}

				const extrinsic = api.tx.mwhCertificates.requestMwhCertificates(`${process.env.REACT_APP_MULTILEDGERS_PARACHAIN_ID}`, `${process.env.REACT_APP_CCEE_PARACHAIN_ID}`, assetCode, amountInWatt,backofficeStartDate?.unix(),backofficeEndDate?.unix(), backofficeStartDate.year(), operationDate, recTypeId, mwhRecHash, recId).sign(userPair, { genesisHash, blockHash: genesisHash, nonce, runtimeVersion });
				await api.disconnect();
				return extrinsic
			} 
			catch (error) {
				console.log(error)
				enqueueSnackbar('Wallet Not Found', {
					variant: 'error'
				});
				return;
			}
		}

	};

	async function getMWhOnchain(asset: string, startDate: Dayjs, endDate: Dayjs) {
		if(startDate.isAfter(endDate)){
			setLoadingProgress(100)
			setIsloading(false)
			return
		}
		setLoadingProgress(0)
		const backofficeService = new BackOfficeService();
		const response = await backofficeService.getTotalAssetMWhByPeriod(asset, startDate.toISOString(), endDate.toISOString());
		if(response.status == 201){
			console.log()
			setTotalAvaliable(response.data.availableWh/1000000) // Convertendo para MWH
			setBackofficeStartDate(dayjs(response.data.start))
			setBackofficeEndDate(dayjs(response.data.end))
			if (response.data.availableMWh == 0) {
				enqueueSnackbar("You do not have balance in this period.", {variant:'info'})
			}
		}
		else if(response.status == 400){
			enqueueSnackbar("You do not have balance in this period.", {variant:'info'})
			setTotalAvaliable(0)
		}
		else{
			enqueueSnackbar("Please, try again late.", {variant:'info'})
			setTotalAvaliable(0)
		}
		handleInputChange
		setLoadingProgress(100)
		setIsloading(false)
	}

	async function getMWhOnchainByAmount(asset: string, startDate: Dayjs, endDate: Dayjs, amount: number) {
		const backofficeService = new BackOfficeService();
		const amountInWatts = amount * 1000000;
		const response = await backofficeService.getTotalAssetMWhByAmount(asset, startDate.toISOString(), endDate.toISOString(), amountInWatts);
		if(response.status == 201){
			setBackofficeFinalStartDate(dayjs(response.data.start))
			setBackofficeFinalEndDate(dayjs(response.data.end))
			if (response.data.availableMWh == 0) {
				enqueueSnackbar("You do not have balance in this period.", {variant:'info'})
			}
		}
		else{
			enqueueSnackbar("Please, try again late.", {variant:'info'})
		}
	}

	const getMWh = async () => {
		if (id) {
			setBreakLoop(false)
			if (endDate && startDate && assetCode){
				setIsloading(true)
				getMWhOnchain(assetCode,startDate,endDate)
			}
		}
	};

	const getRecs = async () => {
		if (id) {
			setBreakLoop(false)
			const respGetAssetInfo = await assetService.getAssetById(id);
			setAssetWalletAddress(respGetAssetInfo.wallet_address)
			setAssetWalletName(respGetAssetInfo.wallet_name)
			setAssetCode(respGetAssetInfo.code);
			const output = await Promise.all(respGetAssetInfo.assetRecs.map(async (item: { rec: { name: string; id: string; optional_id: string } }) => {
				const isCertified = await getRecOnchain(item.rec.optional_id);
				return {
					label: `${item.rec.name} ${!isCertified ? '(Disabled)': ''}`,
					id: item.rec.id,
					optional_id: item.rec.optional_id,
					isCertified: isCertified
				};
			}));
			setIsloadingRecLabels(false)
			setRecData(output);
		}
	};


	useEffect(() => {
		const controller = new AbortController();
		setLoadingProgress(0)
		getRecs();
		return () => {
			controller.abort();
		  };
	}, []);
	
	useEffect(() => {
		setBreakLoop(true)
		const controller = new AbortController();
		setLoadingProgress(0)
		getMWh();
		return () => {
			controller.abort();
		  };
	}, [startDate, endDate]);

	useEffect(() => {
		const dateStart = startDate?.toDate();
		const dateEnd = endDate?.toDate();

		if (dateStart && dateEnd) {
			const startNewDate = new Date(dateStart);
			const endNewDate = new Date(dateEnd);

			if (endNewDate.getTime() < startNewDate.getTime()) {
				setErrorDate(true);
			} else {
				setErrorDate(false);
			}
		}
	}, [startDate, endDate]);

	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250
			}
		}
	};

	const handleIssueNow = async () => {
		const service = new TransactionService();
		if (!endDate || !startDate || !id || !assetCode || !backofficeFinalStartDate || !backofficeFinalEndDate) {
			enqueueSnackbar('Error, please try again later.', {
				variant: 'info'
			});
			return;
		}
		try {
			await waitReady();
			uiKeyring.loadAll({ ss58Format: 42, type: "sr25519" });
		} catch (error) {
			console.log(error)
		} finally {
			const MwhCertId = '0x' + sha256.update(window.crypto.randomUUID()).hex();
			const RecCertId = '0x' + sha256.update(window.crypto.randomUUID()).hex();
			const [startDateYear, startDateMonth, startDateDay, startDateHour] = backofficeFinalStartDate.toISOString().split(/[-T:.]/);
			const [endDateYear, endDateMonth, endDateDay, endDateHour] = backofficeFinalEndDate.toISOString().split(/[-T:.]/);
			const [,,, currentHour] = dayjs().toISOString().split(/[-T:.]/);

			const startDateFormated = backofficeFinalStartDate.format('YYYY-MM-DD');
			const endDateFormated = backofficeFinalEndDate.format('YYYY-MM-DD');
			const currentDate = dayjs().format('YYYY-MM-DD')

			const signedTransaction = await matRequestMHW(assetCode, inputAmount, startDateHour, startDateDay,startDateMonth, startDateYear, endDateHour, endDateDay,endDateMonth, endDateYear, startDateFormated, selectModeLabel.optional_id ,currentDate, currentHour, endDateFormated, "1 PM", MwhCertId, RecCertId);

			if (!signedTransaction){
				enqueueSnackbar("Wallet Signature problem", {
					variant: 'error'
				});
				setLoading(false);
				return;
			}
			setLoading(true);
			setLoadingAuthorization(true);
			const notify = enqueueSnackbar('Processing on the blockchain.', {
				variant: 'info',
				persist: true
			});

			const createdIssue = await service.createIssue({
				amount: Number(inputAmount),
				asset_id: id,
				end_date: endDate.toDate(),
				rec_id: selectModeLabel.id,
				start_date: startDate.toDate(),
				from: id,
				to: id,
				operation_type: 'issuance',
				type_of_period: 'year',
				enterprise_id: localStorage.getItem('enterprise_id') || '',
				cert_hash: MwhCertId,
				rec_hash: RecCertId,
				holder_wallet: assetWalletAddress,
				extrinsic: signedTransaction
			});
	
			if ([400, 401, 404, 500].includes(createdIssue.status)) {
				setLoading(false);
				enqueueSnackbar(createdIssue.statusText, {
					variant: 'error'
				});
			}
			if (createdIssue.status === 200) {
				setLoading(false);
				closeSnackbar(notify)
				enqueueSnackbar('Issuance have been created successfully', {
					variant: 'success'
				});
				setLoadingAuthorization(false);
				// window.location.reload();
			}else{
				setLoading(false);
				closeSnackbar(notify);
				setLoadingAuthorization(false);
			}
		}
	};

	const handleCloseAuthoraztion = (value: boolean) => {
		setOpenAuthorization(value);
	};

	const onSubmit = async () => {
		if(assetCode && startDate && endDate && inputAmount){
			await getMWhOnchainByAmount(assetCode, backofficeStartDate, backofficeEndDate, Number(inputAmount))
			handleCloseAuthoraztion(true);
		}
	};

	const onSubmitAuthorization = () => {
		handleIssueNow();
		handleCloseAuthoraztion(false);
	};

	const labelName = (title1: string, title2: string, disabled?: boolean) => (
		<Box>
			<Typography
				fontStyle="normal"
				paddingTop="23px"
				fontWeight="700"
				color={!disabled ? '#000' : '#b9b2b2'}
				fontSize="20px"
			>
				{title1}
			</Typography>
			<Typography
				fontStyle="normal"
				fontSize="20px"
				fontWeight="500"
				color={!disabled ? colors.primaryDark : '#b9b2b2'}
			>
				{title2}
			</Typography>
		</Box>
	);

	const BootstrapInput = styled(InputBase)(() => ({
		'& .MuiInputBase-input': {
			display: 'flex',
			alignItems: 'center',
			padding: '14px 25px',
			borderRadius: '9px',
			// backgroundColor: 'transparent',
			border: '1px solid #000',
			'& .MuiSvgIcon-root': {
				marginTop: '100px',
				color: '#fff'
			},
			'&:focus': {
				borderRadius: '10px'
			},
			':after': {
				borderBottom: 'none',
				borderRight: '1px solid #000',
				marginRight: '50px'
			}
		}
	}));

	useEffect(()=>{
		if (selectedAmount > totalAvaliable) {
			setValidateTotalEnergy(true);
		} else {
			setValidateTotalEnergy(false);
		}
		setInputAmount(selectedAmount.toString());

	},[totalAvaliable, selectedAmount])

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		const newValue = value.replace(/[.,]/g, '');
		setSelectedAmount(Number(newValue))
		// if (Number(newValue) > totalAvaliable || Number(newValue) == 0) {
		// 	setValidateTotalEnergy(true);
		// } else {
		// 	setValidateTotalEnergy(false);
		// }
		// setInputAmount(newValue);
	};

	return (
		<>
			<Header />
			<SideBar />
			<Box
				component="main"
				sx={{
					marginLeft: '234px',
					height: '100vh',
					paddingTop: '146px'
				}}
			>
				<Box position="absolute" bottom="0" width="35%" left="152px">
					<img src={RecIssueImg} alt="backgroundimage"></img>
				</Box>
				<Grid container md={12} marginBottom="54px">
					<Grid item md={12} display="flex">
						<Button
							variant="outlined"
							onClick={() => navigate(-1)}
							sx={{
								width: '107px',
								height: '40px',
								borderRadius: '25px',
								border: '1px solid #000',
								padding: '8px 24px',
								color: '#000',
								textTransform: 'initial',
								fontSize: '1.25rem',
								'&:hover': {
									border: '1px solid #000'
								}
							}}
						>
							<Typography fontSize="1.25rem" lineHeight="32px">
								Back
							</Typography>
						</Button>
						<Typography
							fontWeight="700"
							fontSize="1.75rem"
							lineHeight="40px"
							color={colors.primary}
							marginBottom="9px"
							marginLeft="96px"
						>
							REC ISSUER
						</Typography>
					</Grid>
				</Grid>

				<Grid container md={12} gap={5}>
					<Grid item md={4}>
						<Card sx={{ marginLeft: '25px' }}>
							<Box display="flex" alignItems="center">
								<SelectMode />
								<Typography
									sx={{
										marginLeft: '21px',
										fontSize: '1.25rem'
									}}
								>
									SELECT MODE
								</Typography>
							</Box>

							<Box marginTop="65px" marginBottom="75px" marginLeft="34px">
								<InputLabel sx={{ marginBottom: '8px' }}>
									SELECT A REC LABEL
								</InputLabel>
								{recData.length >= 0 &&
									<Select
										// disabled={isLoadingRecLabels}
										sx={{ maxWidth: '352px' }}
										fullWidth
										placeholder="Loading..."
										input={<BootstrapInput />}
										labelId="demo-simple-select-label"
										id="country"
										variant="standard"
										value={selectModeLabel.label}
										MenuProps={MenuProps}
										onChange={e => {
											const recLabelSelected = recData.find(
												objeto => objeto.label === e.target.value
											);
											if (recLabelSelected) {
												setSelectModeLabel(recLabelSelected);
											}
										}}
									>
										{isLoadingRecLabels ? (
										<MenuItem disabled>
											<Box display="flex" justifyContent="space-between" width="100%">
												<Typography>Loading</Typography>
												<CircularProgress size={24} />
											</Box>
									  	</MenuItem>
										)
										:
										recData && recData.length > 0 ? 
											recData.map(({ label, recId, isCertified }: any) => (
												<MenuItem key={recId} value={label} disabled={!isCertified}>
													{label}
												</MenuItem>
											))
											:
											<MenuItem disabled>No options available</MenuItem>
										}
									</Select>
								}
							</Box>
							<Box marginLeft="34px">
								<FormLabel
									sx={{ color: '#000', fontWeight: '400' }}
									id="demo-row-radio-buttons-group-label"
								>
									SELECT A PERIOD CRITERIA
								</FormLabel>
								<RadioGroup
									row
									aria-labelledby="demo-row-radio-buttons-group-label"
									name="row-radio-buttons-group"
									value={period}
									onChange={e => setPeriod(e.target.value)}
								>
									<FormControlLabel
										value="month/year"
										control={<Radio />}
										label={labelName(
											'Per Month and Year',
											'sum of MWh per month and year'
										)}
									/>
								</RadioGroup>
							</Box>

							<Box marginLeft="34px">
								<RadioGroup
									row
									aria-labelledby="demo-row-radio-buttons-group-label"
									name="row-radio-buttons-group"
								>
									<FormControlLabel
										disabled
										value="female"
										control={<Radio />}
										label={labelName(
											'Hourly',
											'sum of kwh per hour, day, month and  year',
											true
										)}
									/>
								</RadioGroup>
							</Box>
						</Card>
					</Grid>
					<Grid item md={4}>
						<Card>
							<Box display="flex" alignItems="center">
								<SelectPeriod />
								<Typography sx={{ marginLeft: '21px', fontSize: '1.25rem' }}>
									SELECT GENERATION PERIOD:
								</Typography>
							</Box>

							{selectModeLabel.label !== 'select' &&
								period === 'month/year' && (
									<>
										<Box marginTop="56px" marginBottom="22px" marginLeft="35px">
											<Typography marginBottom="5px">START DATE</Typography>
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DatePicker
													value={startDate && startDate?.add(3, 'hours')}
													disabled={isLoading}
													onChange={newValue => {
														if(newValue){
															const mybegin = dayjs(newValue.format('YYYY-MM-DD')).add(0, 'hours'); // envia como hora 1 do outro dia
															setStartDate(mybegin);
														}
													}}
													minDate={dayjs().add(-14 , 'months')}
													maxDate={dayjs()}
													renderInput={params => <TextField {...params} />}
												/>
											</LocalizationProvider>
										</Box>

										<Box marginLeft="35px">
											<Typography marginBottom="5px">END DATE</Typography>
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DatePicker
													value={endDate}
													disabled={isLoading || !startDate}
													onChange={newValue => {
														if(newValue){
															const myEnd = dayjs(newValue.format('YYYY-MM-DD')).add(23, 'hours'); // envia como hora 0 do outro dia (final do dia)
															setEndDate(myEnd);
														}
													}}
													minDate={startDate}
													maxDate={startDate && startDate.add(12, 'hours').endOf('year').isBefore(dayjs()) ? startDate.endOf('year') : dayjs() }
													renderInput={params => <TextField {...params} />}
												/>
											</LocalizationProvider>
											{errorDate && (
												<FormHelperText error>
													<Typography
														fontFamily="sen"
														fontSize="20px"
														fontWeight="700"
														color="#F32053"
													>
														Invalid date
													</Typography>
												</FormHelperText>
											)}
										</Box>
									</>
								)}
						</Card>
					</Grid>
					<Grid item md={3}>

								<Card>
									<Box display="flex" alignItems="center">
										<SelectAmount />
										<Typography sx={{ marginLeft: '21px', fontSize: '1.25rem' }}>
											SELECT AMOUNT
										</Typography>
									</Box>
									{selectModeLabel.label !== 'select' &&
										period === 'month/year' &&
										startDate !== null &&
										endDate !== null &&
										!errorDate && (
											<Card
												sx={{
													marginTop: '57px',
													position: 'relative',
													height: '537px'
													// paddingLeft: '50px'
												}}
											>
												<Box marginLeft="90px">
													<Typography
														fontSize="1.25"
														lineHeight="32px"
														color={colors.primaryDark}
													>
														TOTAL AVAILABLE MWh
													</Typography>
												</Box>
												<Box
													display="flex"
													alignItems="center"
													marginLeft="19px"
													marginBottom="48px"
												>
													<img src={EnergyImg} alt="eletric"></img>
													{
														isLoading ?
														<>
															<Card>
																<Box
																display="flex"
																alignItems="center"
																sx={{
																	justifyContent: 'center',
																	alignItems: 'center',
																	textAlign: 'center',
																	marginLeft: '25px',
																	marginTop: '5px',
																	height: '40px'
																}}>
																	<CircularProgress/>
																</Box>
															</Card>
														</>
														:
														<Typography
															sx={{
																marginLeft: '23px',
																fontSize: '36px',
																fontWeight: '700',
																color: '#00CA95'
															}}
														>
															{(totalAvaliable).toLocaleString('en-US', {
																minimumFractionDigits: 0
															})}
														</Typography>
													}
													{/* <Typography
														sx={{
															marginLeft: '23px',
															fontSize: '36px',
															fontWeight: '700',
															color: '#00CA95'
														}}
													>
														{totalAvaliable.toLocaleString('en-US', {
															minimumFractionDigits: 0
														})}
													</Typography> */}
												</Box>
		
												<Box>
													<InputLabel
														error={false}
														shrink
														htmlFor="job_title"
														sx={{
															color: colors.neutralDark,
															fontSize: '25px',
															whiteSpace: 'wrap'
														}}
													>
														Type in amount of MWh to issue
													</InputLabel>
													<NumericFormat
														value={Number(inputAmount)}
														autoComplete='off'
														allowLeadingZeros
														decimalSeparator="."
														thousandSeparator=","
														placeholder="0"
														disabled={isLoading}
														style={{
															maxWidth: '288px',
															outline: validateTotalEnergy ? 'blue' : '#F32053',
															padding: '16px 14px',
															border: `1px solid ${
																validateTotalEnergy ? '#F32053' : '#3A4049'
															}`,
															boxShadow: '0px 2px 8px rgb(0 0 0 / 15%)',
															borderRadius: '9px',
															marginTop: '15px',
															fontSize: '30px',
															color: validateTotalEnergy
																? '#F32053'
																: colors.neutralDark
														}}
														onChange={handleInputChange}
													/>
													{validateTotalEnergy && (
														<FormHelperText
															error
															sx={{
																fontWeight: '700',
																fontSize: '18px',
																lineHeight: '32px',
																'&.Mui-error': {
																	color: '#F32053'
																},
																fontFamily: 'Sen',
																color: '#F32053'
															}}
														>
															UNVAILABLE AMOUNT
														</FormHelperText>
													)}
												</Box>
		
												<Box
													marginTop="74px"
													marginLeft="19px"
													sx={{ position: 'absolute', bottom: '0' }}
												>
													<Box display="flex" alignItems="center">
														<Box marginTop="5px">
															<img src={informationIssueImg}></img>
														</Box>
														<Typography
															sx={{
																color: colors.supportDark,
																fontSize: '20px',
																marginLeft: '17px'
															}}
														>
															Important:
														</Typography>
													</Box>
													<Box marginTop="16px" width="340px">
														<Typography
															color={colors.neutralDark}
															fontSize="20px"
															lineHeight="32px"
														>
															After the REC is issued there will be no way to undo
															this operation.
														</Typography>
													</Box>
													<LoadingButton
														disableElevation
														disabled={validateTotalEnergy || selectedAmount == 0}
														sx={{
															marginTop: '57px',
															borderRadius: '25px',
															backgroundColor:
																!validateTotalEnergy && inputAmount
																	? colors.primary
																	: colors.primaryLight,
															'&:hover': {
																backgroundColor:
																	!validateTotalEnergy && inputAmount
																		? colors.primary
																		: colors.primaryLight
															}
														}}
														loading={loading}
														variant="contained"
														// onClick={form.handleSubmit(onSubmit)}
														onClick={onSubmit}
													>
														<Typography
															sx={{
																color: colors.neutralLight,
																padding: '3px 68px',
																fontSize: '24px',
																lineHeight: '32px',
																fontWeight: '700',
																textTransform: 'initial'
															}}
														>
															Issue now
														</Typography>
													</LoadingButton>
												</Box>
											</Card>
										)}
								</Card>
								{openAuthorization &&
									<ConfirmationModal
										open={openAuthorization}
										title="Issue of RECs"
										subTitle="This action will cause you to authorize the issuance of RECs."
										walletName={assetWalletName}
										walletAddress={assetWalletAddress}
										handleCloseAuthorization={() => handleCloseAuthoraztion(false)}
										setPasswordAuthorization={setPasswordAuthorization}
										onSubmitAuthorization={onSubmitAuthorization}
										loadingAuthorization={loadingAuthorization}
										confirm="Sign"
										cancel="Cancel"
									/>						
								}
					</Grid>
				</Grid>
			</Box>
		</>
	);
};
