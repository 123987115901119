import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useSnackbar } from 'notistack';

import { Box, InputAdornment, TextField } from '@mui/material';

import Search from '../../../assets/imgs/search.svg';
import { RecTokenService } from '../../../services/rec.service';
import { TRecAssets } from '../../../types/recAssets';
import { RecResultClientList } from './recResultClientList';
import { RecResultList } from './recResultList';

interface IRecList {
	infoAssets?: any;
	successInsert?: boolean;
	reloadRecList?: boolean;
	setShowSteps?: (value: boolean) => void;
	dataClients?: Array<any>;
	setSelectedClientId?: (value: string) => void;
}

/* eslint-disable prettier/prettier */
export const RecList = ({ setShowSteps, dataClients, reloadRecList, setSelectedClientId }: IRecList) => {
	const recTokenService = new RecTokenService();
	const [dataListRec, setDataListRec] = useState<Array<TRecAssets>>([]);
	const [searchInput, setSearchInput] = useState<string>('');
	const listTokenAsset = useSelector((state: any) => state.listRecTokenAsset);

	return (
		<>
			<Box sx={{ marginTop: '40px' }}>
				<TextField
					fullWidth
					variant="filled"
					placeholder="search"
					onChange={(e: any) => setSearchInput(e.target.value)}
					sx={{
						m: 1,
						width: '95%',
						'& .MuiFilledInput-root': {
							backgroundColor: '#F7F7F7',
							borderRadius: '16px',
							paddingBottom: '15px',
							paddingLeft: '10px'
						}
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment
								position="end"
								sx={{ paddingTop: '10px', paddingRight: '10px' }}
							>
								<img src={Search}></img>
							</InputAdornment>
						),
						disableUnderline: true
					}}
				/>
				<Box
					height="400px"
					overflow="auto"
					// width="430px"
					sx={{
						'::-webkit-scrollbar': {
							marginLeft: '60px',
							border: '1px solid #D4D7DE',
							width: '10px',
							borderRadius: '15px'
						},
						'::-webkit-scrollbar-thumb': {
							backgroundColor: '#D9D9D9',
							borderRadius: '15px',
							height: '170px',
							width: '15px'
						}
					}}
				>
					{!dataClients?.length ? (
						<RecResultList searchInput={searchInput} reloadRecList={reloadRecList} />
					) : (
						dataClients && (
							<RecResultClientList
								searchInput={searchInput}
								dataClients={dataClients}
								setShowSteps={setShowSteps}
								setSelectedClientId={setSelectedClientId}
							/>
						)
					)}
				</Box>
			</Box>
		</>
	);
};
