import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Header } from '../../components/header';
import { SideBar } from '../../components/sideBar';
import { CheckCertificateHome } from './certificateHome';

import { Box, Menu, Toolbar, Typography, MenuItem } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

import business from '../../assets/icon/business.png';
import logout from '../../assets/icon/logout.png';
import person from '../../assets/icon/person.png';
import profileFace from '../../assets/imgs/pfofileexemplo.jpeg';
import { colors } from '../../styles';
import { Height } from '@mui/icons-material';
import sideBarMultiedger from '../../assets/imgs/logoSidebar.svg';

export const ValidCertificatePage = () => {
	const { id } = useParams();
	const drawerWidth = 0;
	const today = new Date();
	const dd = String(today.getDate()).padStart(2, '0');
	const mm = String(today.getMonth() + 1).padStart(2, '0');
	const hour = String(today.getHours()).padStart(2, '0');
	const minute = String(today.getMinutes()).padStart(2, '0');
	const yyyy = today.getFullYear();
	const date = dd + '/' + mm + '/' + yyyy;
	const time = hour + ':' + minute + 'h';

	return (
		<>
			<AppBar
			position="fixed"
			sx={{
				width: `calc(100% - ${drawerWidth}px)`,
				ml: `${drawerWidth}px`,
				backgroundColor: 'transparent',
				boxShadow: 'none'
			}}
			>
				<Toolbar
					sx={{
						paddingLeft: '0px',
						backgroundColor: '#636C7A',
						minHeight: '76px',
						marginBottom: '7px',
						display: 'flex',
						justifyContent: 'center', // Centralizar horizontalmente
						alignItems: 'center', // Centralizar verticalmente
					}}
				>
					<img
						style={{
							height: '46px',
							width: '100px',
						}}
						src={sideBarMultiedger}
						alt="multidedger"
					></img>
				</Toolbar>
			</AppBar>
			<Box
				component="main"
				sx={{
					height: '100vh',
					paddingTop: '64px',
					// display: 'flex',
					// justifyContent: 'center', // Centralizar horizontalmente
					// alignItems: 'center', // Centralizar verticalmente
				}}
			>
				{id &&
					<CheckCertificateHome certId={id} />
				}
			</Box>
		</>
	);
};
