/* eslint-disable prettier/prettier */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import {
  Box,
  Grid,
  Typography,
  Button
} from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';

import { RecoveryService } from '../../../services/recovery.service';
import { goToRecoveryPassword } from '../../../stores/forgotPassword/forgotPassword.actions'
import { colors } from '../../../styles';
import { EmailForm } from './emailForm';
import { NewUserForm } from './newUserForm';
import { SchemaRecoveryDefault } from './schemaRecovery';

interface IRecoveryForm {
  email?: string;
  token?: string
  password?: string;
  confirmPassword?: string;
}

const VALIDATE_EMAIL = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const RecoveryPassword = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const recoveryService = new RecoveryService();

  const [inputEmail, setInputEmail] = useState<any>();
  const [loadingEmail, setLoadingEmail] = useState<boolean>(false);
  const [noMatchPass, setMatchPassword] = useState<boolean>(false);
  const [inputs, setInputs] = useState<IRecoveryForm>({});
  const [viewForm, setViewForm] = useState<boolean>(false);

  const form = useForm<IRecoveryForm>({
    resolver: yupResolver(SchemaRecoveryDefault)
  });

  const goToLogin = () => {
    dispatch(goToRecoveryPassword(false));
    history('/signin');
  }


  const colorButtonRecovery = () => {
    const { token, password, confirmPassword } = inputs;

    if (inputEmail) {
      return !inputEmail ? colors.primaryLight : colors.primary;
    }

    return !token || !password || !confirmPassword
      ? colors.primaryLight
      : colors.primary
  };

  const onSubmitEmail = async () => {
    if (!VALIDATE_EMAIL.test(inputEmail)) {
      // setErrorEmail()
      enqueueSnackbar('Email is not valid!', {
        variant: 'error'
      })
      return
    }

    setLoadingEmail(true);

    const respRecoveryPassword = await recoveryService.forgotPassword(inputEmail);

    if (respRecoveryPassword.status === 204) {
      setLoadingEmail(false);
      setViewForm(true);
      enqueueSnackbar('Token sent to your email', {
        variant: 'success'
      });
    }

    if ([400, 404, 500].includes(respRecoveryPassword.status)) {
      setLoadingEmail(false);
      enqueueSnackbar(respRecoveryPassword.statusText, {
        variant: 'error'
      });
    }
  }

  const onSubmit = async () => {
    const { token, password, confirmPassword } = form.getValues();
    if (!token || !password) return;
    setLoadingEmail(true);

    if (password !== confirmPassword) {
      setMatchPassword(true);
      setLoadingEmail(false);
      return;
    } else {
      setMatchPassword(false);
    }

    const respResetPassword = await recoveryService.resetPassword(token, password);
    if (respResetPassword.status === 204) {
      setLoadingEmail(false);
      history('/signin');
      enqueueSnackbar('The password was reset successfully ', {
        variant: 'success'
      });
    }
    if ([400, 404, 500].includes(respResetPassword.status)) {
      setLoadingEmail(false);
      enqueueSnackbar(respResetPassword.statusText, {
        variant: 'error'
      });
    }

  }

  return (
    <>
      <form onSubmit={() => { }}>
        <Grid container columnSpacing={3} md={12} padding="55px 20px 38px 44px">
          <Grid item xs={12} md={12} marginBottom="77px">
            <Box>
              <Typography
                fontFamily="sen"
                fontStyle="normal"
                fontWeight="700"
                fontSize="1.5rem"
                lineHeight="32px"
                color={colors.neutralDark}
              >
                ACCESS RECOVERY
              </Typography>
            </Box>
          </Grid>
          {!viewForm ? (
            <EmailForm setInputEmail={setInputEmail} />
          ) : (
            <NewUserForm
              form={form}
              setInputEmail={setInputEmail}
              inputEmail={inputEmail}
              setInputs={setInputs}
              noMatchPass={noMatchPass}
            />
          )}

          <Grid
            item
            xs={12}
            md={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginTop="23px"
          >
            <LoadingButton
              disableElevation
              disabled={!VALIDATE_EMAIL.test(inputEmail)}
              sx={{
                marginBottom: '23px',
                borderRadius: '25px',
                backgroundColor: colorButtonRecovery(),
                '&:hover': {
                  backgroundColor: colorButtonRecovery()
                }
              }}
              loading={loadingEmail}
              variant="contained"
              onClick={inputEmail ? onSubmitEmail : form.handleSubmit(onSubmit)}
            >
              <Typography
                sx={{
                  color: colors.neutralLight,
                  padding: '8px 32px',
                  fontSize: '24px',
                  lineHeight: '32px',
                  fontWeight: '700',
                  textTransform: 'initial'
                }}
              >
                Recover Account
              </Typography>
            </LoadingButton>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              color={colors.primary}
              lineHeight="24px"
              fontWeight="400"
              marginRight="20px"
            >
              Already have account
            </Typography>
            <Button onClick={goToLogin}>
              <Typography color={colors.primary}>Login</Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}
