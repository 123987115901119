import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';

const COLORS = ['#636C7A', '#00CA95', '#FFBB28', '#FF8042', '#aa8042', '#a2d', '#11d', '#aaa'];

const PieGraph = ( data: any ) => {
	const [pieData, setPieData] = useState<{name: string, value: number}[]>();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [chartWidth, setChartWidth] = useState(850);
    const [chartHeight, setChartHeight] = useState(480);

    useEffect(() => {
        function handleResize() {
            setChartWidth(window.innerWidth * 0.285);
            setChartHeight(window.innerHeight * 0.5);
        }

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    useEffect(()=>{
        if (data?.pieData?.length > 0){
            setPieData(data.pieData)
        }else{
            setPieData(undefined)
        }
    },[data])

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsLoaded(true)
        }, 1200);

        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <>
            {isLoaded?
                pieData ? 
                <PieChart width={chartWidth} height={chartHeight}>
                <Pie
                    data={pieData}
                    cx={chartWidth/2 - 20}
                    cy={chartHeight/2-20}
                    outerRadius={chartWidth*0.22}
                    fill="#8884d8"
                    fontSize={20}
                    dataKey="value"
                    label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
                        const RADIAN = Math.PI / 180;
                        const radius = 30 + innerRadius + (outerRadius - innerRadius);
                        const x = cx + radius * 1.32*Math.cos(-midAngle * RADIAN);
                        const y = cy + radius * Math.sin(-midAngle * RADIAN);
                        return (
                            <>
                                <text
                                    x={x}
                                    y={y}
                                    // fill={COLORS[index % COLORS.length]}
                                    fill="#6F7075" 
                                    textAnchor="middle"
                                    dominantBaseline="central"
                                >
                                    {pieData[index].name}
                                </text>
                                <text
                                    x={x}
                                    y={y + 20}
                                    // fill={COLORS[index % COLORS.length]}
                                    fill="#6F7075" 
                                    textAnchor="middle"
                                    dominantBaseline="central"
                                >
                                    ({value}%)
                                </text>
                            </>
                        );
                    }}
                >
                    {pieData.map((entry: any, index: number) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend />
                </PieChart>
                :
                <Box
                    textAlign='center'
                    display='flex'
                    height='80%'
                    alignItems='center'
                >
                    <Typography
                        fontFamily="Sen"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="20px"
                        lineHeight="44px"
                        color="#6F7075"
                        width='100%'
                        >
                        No registered Power Plants
                    </Typography>
                </Box>
            :
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80%'
                }}
            >
                <CircularProgress />
            </Box>
            }
        </>
  );
};

export default PieGraph;
