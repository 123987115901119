/* eslint-disable prettier/prettier */
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { ApiPromise, WsProvider, } from "@polkadot/api";
import { keyring as uiKeyring } from "@polkadot/ui-keyring";
import { waitReady } from "@polkadot/wasm-crypto";
import recDefaultImage from '../../../assets/imgs/renewable-energy-certificate.jpg';
import {sha256} from "js-sha256";
import {
	Grid,
	FormControl,
	InputLabel,
	OutlinedInput,
	MenuItem,
	Select,
	InputBase,
	styled,
	TextField,
	Typography,
	Box,
	FormHelperText
} from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';

import { AddLogo } from '../../../components/addLogo';
import { MyButton } from '../../../components/myButton';
import { UploadLogo } from '../../../components/uploadLogo';
import { RecTokenService } from '../../../services/rec.service';
import { MemberService } from '../../../services/member.service';
import { EnterpriseService } from '../../../services/enterprise.service';

import { listRecTokenAsset } from '../../../stores/recTokenAsset/recTokenAsset.actions';
import { colors } from '../../../styles';
import { SchemaTokenForm } from './schemaTokenForm';
import { ConfirmationModal } from '../../../components/confirmationModal';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';


export type TRectTokeForm = {
	setViewForm: (value: boolean) => void;
};
interface IRectTokeForm {
	name: string;
	type: string;
	optional_id: string;
	descriptions: string;
}

export const RecTokenForm = ({ setViewForm }: TRectTokeForm) => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const enterpriseService = new EnterpriseService();
	const memberService = new MemberService();
	const { id } = useParams();
	const recTokenService = new RecTokenService();
	const ENTERPRISE_ID = localStorage.getItem('enterprise_id') || '';
	const [user, setUser] = useState<any>({});

	const [loading, setLoading] = useState<boolean>(false);
	const [inputName, setInputName] = useState<string>('');
	const [typeSelect, setTypeSelect] = useState<string>('');
	const [uploadFile, setUploadFile] = useState<File | null>(null);
	const [loadImage, setLoadImage] = useState<boolean>(false);

	const [passwordAuthorization, setPasswordAuthorization] =
		useState<string>('');
  const [openAuthorization, setOpenAuthorization] = useState<boolean>(false);
	const [loadingAuthorization, setLoadingAuthorization] =
		useState<boolean>(false);

	const form = useForm<IRectTokeForm>({
		resolver: yupResolver(SchemaTokenForm)
	});

	const BootstrapInput = styled(InputBase)(() => ({
		'& .MuiInputBase-input': {
			display: 'flex',
			alignItems: 'center',
			padding: '14px 25px',
			borderRadius: '9px',
			// backgroundColor: 'transparent',
			border: '1px solid #000',
			'& .MuiSvgIcon-root': {
				marginTop: '100px',
				color: '#fff'
			},
			'&:focus': {
				borderRadius: '10px'
			},
			':after': {
				borderBottom: 'none',
				borderRight: '1px solid #000',
				marginRight: '50px'
			}
		}
	}));

	const getUserInfo = async () => {
		const getUser: any = await memberService.getMyInfo();
		if (getUser.status === 200) {
			setUser(getUser.data);
		}
		// if error
	};

	useEffect(() => {
		getUserInfo();
	}, []);

  	const onSubmit = () => {
		if (!user.wallet_address){
			enqueueSnackbar("You haven't registered your wallet in your profile yet.", {
				variant: 'error'
			});
			return; 
		}
		setOpenAuthorization(true);
	};

	const handleCloseAuthoraztion = () => {
		setOpenAuthorization(false);
	};

	const onSubmitAuthorization = async () => {
		const createRec = async () => {
			try {
				await waitReady();
				uiKeyring.loadAll({ ss58Format: 42, type: "sr25519" });
			} catch (error) {
				console.log(error)
			} finally {
				const provider = new WsProvider(`${process.env.REACT_APP_PARACHAIN_ADDRESS}`);
				const api = await ApiPromise.create({ provider });
				try {
					const userPair = uiKeyring.getPair(user.wallet_address);
					try {
						userPair.unlock(passwordAuthorization);
					} catch (error) {
						console.log(error)
						enqueueSnackbar('The password of your wallet is invalid, try again', {
							variant: 'error'
						});
						return;
					}
					if(!id){
						return;
					}
					const genesisHash = api.genesisHash;
					const runtimeVersion = api.runtimeVersion;
					
					let nonce = await api.rpc.system.accountNextIndex(user.wallet_address);
	
					const header = await api.derive.accounts.info(user.wallet_address)
	
					const value = { ...form.getValues() };
	
					if (!value.name) return;
					const formatedCurrentDate = dayjs().format('YYYY-MM-DD')
					const recLabelID = window.crypto.randomUUID()
					// const sgsSignature = '0x' + sha256.update(window.crypto.randomUUID()).hex()
					const signedTransaction = api.tx.recs.createRecLabel(`${process.env.REACT_APP_MULTILEDGERS_PARACHAIN_ID}`, `${process.env.REACT_APP_CCEE_PARACHAIN_ID}`, recLabelID, value.type, value.name, formatedCurrentDate, "").sign(userPair, { genesisHash, blockHash: genesisHash, nonce, runtimeVersion });
					//OK

					if (!signedTransaction){
						enqueueSnackbar("Wallet Signature problem", {
							variant: 'error'
						});
						return;
					}

					setLoading(true);
					setLoadingAuthorization(true);
					const notify = enqueueSnackbar('Processing on the blockchain.', {
						variant: 'info',
						persist: true
					});

					const resSubmitRecToken = await recTokenService.addRecToken({
						name: value.name,
						enterprise_id: ENTERPRISE_ID,
						asset_id: id,
						optional_id: recLabelID,
						type: value.type,
						descriptions: value.descriptions || "",
						extrinsic: signedTransaction
					});

					await api.disconnect()

					if (resSubmitRecToken.status === 200) {
						setLoading(false);
						setLoadingAuthorization(false);
						closeSnackbar(notify)
						enqueueSnackbar('Rec was created sucessfully', {
							variant: 'success'
						});
						dispatch(listRecTokenAsset());
						setViewForm(false);
					}
	
					if ([401, 400, 404].includes(resSubmitRecToken.status)) {
						setLoading(false);
						closeSnackbar(notify)
						setLoadingAuthorization(false);
						enqueueSnackbar(resSubmitRecToken.statusText, {
							variant: 'error'
						});
					}
					setOpenAuthorization(false);
				} catch (error) {
					console.log(error)
					enqueueSnackbar('Wallet not found, please consider recovering it on the My Profile page. A new tab will be created in 5 seconds.', {
						variant: 'error'
					});
					const timeout = setTimeout(()=>{
						window.open(`${process.env.REACT_APP_CLIENT_URL}/profile`, "_blank", 'noopener,noreferrer'	);
					},5000)
					clearTimeout(timeout);
					return;
				}
				
			}
		}

		createRec()
	};

	const colorButton = () => {
		return !inputName || !typeSelect ? colors.primaryLight : colors.primary;
	};

	const disabledButton = () => {
		return !inputName || !typeSelect ? true: false;
	};

	const inputNameField = (e: any) => {
		form.setValue('name', e.target.value);
		setInputName(e.target.value);
	};

	const selectTypeField = (e: any) => {
		form.setValue('type', e.target.value);
		setTypeSelect(e.target.value);
	};

	const saveLogo = async (file: any) => {
		const formData = new FormData();
		formData.append('logo', file);

		setLoadImage(true);
		const saveLogoImage = await enterpriseService.addLogo(formData);
		if (saveLogoImage.status === 200) {
			setLoadImage(false);
			enqueueSnackbar('The logo has been uploaded', {
				variant: 'success'
			});
			return true;
		} else {
			setLoadImage(false);
			enqueueSnackbar('The logo was not saved on server', {
				variant: 'error'
			});
			return false;
		}
	};

	const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		if (files?.length) {
			const file = files[0];
			const logoSaved = await saveLogo(file);

			if (logoSaved) {
				setUploadFile(file);
			}
		}
	};

	return (
		<>
			<form onSubmit={() => {}}>
				<Grid
					container
					md={12}
					rowGap={3}
					columnSpacing={2}
					position="relative"
				>
					<Grid item md={3}>
						<Box
							sx={{
								position: 'flex',
								display: 'flex',
								height: '100%',
								justifyContent: 'center',
							}}
						>
							<UploadLogo
								error={false}
								dataUser={[]}
								id="input-load-image"
								image={uploadFile ? URL.createObjectURL(uploadFile) : recDefaultImage}
								name="fileLogo"
								textLabel="Insert Logo"
								onChange={handleImageUpload} //handleImageUpload
								loadImage={loadImage}
								DisabledUpload={true}
							/>
						</Box>
					</Grid>
					<Grid item md={9}>
						<Grid item md={10} marginTop={'16px'}>
							<Controller
								control={form.control}
								name="name"
								render={({ field }) => (
									<FormControl variant="standard" fullWidth>
										<InputLabel
											required
											error={!!form.formState.errors.name}
											shrink
											htmlFor="name"
										>
											REC NAME
										</InputLabel>
										<OutlinedInput
											fullWidth
											style={{
												border: 'none',
												borderRadius: '9px',
												marginTop: '20px'
											}}
											error={!!form.formState.errors.name}
											placeholder="type here..."
											{...field}
											type="string"
											onChange={e => inputNameField(e)}
										/>
										{!!form.formState.errors.name && (
											<FormHelperText error>
												{form?.formState?.errors?.name?.message}
											</FormHelperText>
										)}
									</FormControl>
								)}
							/>
						</Grid>
						<Grid item md={4} marginTop={'16px'}>
							<Controller
								control={form.control}
								name="type"
								render={({ field }) => (
									<FormControl variant="standard" fullWidth>
										<InputLabel
											required
											error={false}
											shrink
											htmlFor="type"
											sx={{ position: 'relative', marginTop: '3px' }}
										>
											REC TYPE
										</InputLabel>
										<Select
											{...field}
											displayEmpty
											fullWidth
											input={<BootstrapInput />}
											labelId="demo-simple-select-label"
											id="type"
											variant="standard"
											value={typeSelect}
											onChange={e => selectTypeField(e)}
										>
											<MenuItem value="">
												<em>select...</em>
											</MenuItem>
											<MenuItem
												value={'regular'}
												sx={{
													borderBottom: `1px solid ${colors.neutralMedium}`,
													margin: 'auto 10px'
												}}
											>
												Regular
											</MenuItem>
											<MenuItem sx={{ margin: 'auto 10px' }} value={'special'}>
												Special
											</MenuItem>
										</Select>
									</FormControl>
								)}
							/>
						</Grid>
						<Grid item md={10} marginTop={'16px'}>
							<Controller
								control={form.control}
								name="descriptions"
								render={({ field }) => (
									<FormControl variant="standard" fullWidth>
										<InputLabel error={false} shrink htmlFor="descriptions">
											REC DESCRIPTION
										</InputLabel>
										<TextField
											sx={{ marginTop: '23px' }}
											placeholder="Type here..."
											{...field}
											id="outlined-multiline-flexible"
											label=""
											multiline
											rows={4}
											maxRows={4}
											onChange={e => {
												form.setValue('descriptions', e.target.value);
											}}
										/>
										{/* {!!form.formState.errors.description && (
											<FormHelperText error>
											{form?.formState?.errors?.description?.message}
											</FormHelperText>
										)} */}
									</FormControl>
								)}
							/>
						</Grid>
						<Grid item marginTop="50px">
							<LoadingButton
								onClick={onSubmit}
								loading={loading}
								disabled={disabledButton()}
								variant="contained"
								sx={{
									color: '#fff',
									backgroundColor: colorButton(),
									borderRadius: '25px',
									padding: '8px 32px',
									marginRight: '48px',
									boxShadow: 'none',
									'&:hover': {
										backgroundColor: colors.primaryLight
									}
								}}
							>
								<Typography
									fontWeight="700"
									fontSize="20px"
									textTransform="initial"
								>
									Create Rec
								</Typography>
							</LoadingButton>
							<MyButton
								onClick={() => setViewForm(false)}
								variant="outlined"
								color="#000"
								border="1px solid"
								borderRadius="25px"
								padding="4px 34px"
								boxShadow="none"
							>
								<Typography
									fontWeight="400"
									fontSize="20px"
									textTransform="initial"
								>
									Cancel
								</Typography>
							</MyButton>
						</Grid>
					</Grid>
					{openAuthorization &&
						<ConfirmationModal
							open={openAuthorization}
							title="Creation of a new REC label"
							subTitle="This action will make you authorize the creation of a new REC."
							walletName={user.wallet_name}
							walletAddress={user.wallet_address}
							handleCloseAuthorization={handleCloseAuthoraztion}
							setPasswordAuthorization={setPasswordAuthorization}
							onSubmitAuthorization={onSubmitAuthorization}
							loadingAuthorization={loadingAuthorization}
							confirm="Sign"
							cancel="Cancel"
						/>
					}
				</Grid>
			</form>
		</>
	);
};
