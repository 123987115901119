import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Typography, Paper } from '@mui/material';
import Popover from '@mui/material/Popover';

import profileFace from '../../../../assets/imgs/airbnb.png';
import information from '../../../../assets/imgs/information.png';
import { colors } from '../../../../styles';
import { TRecAssets } from '../../../../types/recAssets';
import { IRecListToken } from '../../../../types/recListToken';
import { formatTextLimit } from '../../../../utils/formatTextLenght';
import ImageDefaultClient from '../../../../assets/imgs/CompanyImage2.jpg';

import { useSnackbar } from 'notistack';

export type TRecResultList = {
	dataClients: Array<any>;
	searchInput: string;
	setShowSteps: any;
	setSelectedClientId: any;
};

interface IItemClient {
	id: string;
	name: string;
	logo: string;
	isActivate: boolean;
}

export const RecResultClientList = ({
	searchInput,
	dataClients,
	setShowSteps,
	setSelectedClientId
}: TRecResultList) => {
	const { enqueueSnackbar } = useSnackbar();
	const getRecAssets = useSelector((state: any) => state.getRecsAsset);
	const [filteredList, setfilteredList] = useState<Array<any>>([]);
	const [dataAssetRec, setDataAssetRec] = useState<Array<any>>(dataClients);
	const [internalSelectedClientId, setInternalSelectedClientId] = useState<string>('');
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	useEffect(() => {
		setfilteredList(dataAssetRec);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataAssetRec]);

	useEffect(() => {
		if (!searchInput) {
			setfilteredList(dataAssetRec);
			return;
		}

		const filterSearch = dataAssetRec.filter((resultList: any) => {
			if (searchInput) {
				return (
					resultList.name
						.toLowerCase()
						.trim()
						.indexOf(searchInput.toLowerCase()) !== -1
				);
			}
		});

		setfilteredList(filterSearch.length > 0 ? filterSearch : filteredList);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchInput]);

	const handleItemClick = (value: any) => {
		if (!value.isActivate){
			enqueueSnackbar("This client is not activated", {variant: 'info'})
		}
		else{
			setSelectedClientId(value.id);
			setInternalSelectedClientId(value.id)
			setShowSteps(true);
		}
	};

	return (
		<>
			<Box
				display="flex"
				flexDirection="column"
				marginLeft="15px"
				marginTop="21px"
				marginRight="10px"
				// width="400px"
			>
				{!dataClients.length && (
					<Box display="flex" justifyContent="center" marginTop="90px">
						<Typography
							fontSize="27px"
							fontWeight="700"
							color={colors.primaryDark}
							sx={{ opacity: '.4' }}
						>
							No REC labels created yet
						</Typography>
					</Box>
				)}
				{filteredList &&
					filteredList.map((value: IItemClient, index: number) => (
						<Box key={index} display="flex" sx={{
							opacity: value.isActivate ? 1 : 0.5,
						}}>
							<Box
								sx={{
									marginRight: '9px',
									borderRadius: '55px',
									width: '51px',
									height: '51px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									backgroundColor: '#fff',
									
								}}
							>
								{value.logo ?
									<img
										width="47px"
										height="47px"
										style={{
											marginLeft: '4px',
											borderRadius: '25px',
											marginRight: '4px'
										}}
										src={value.logo}
									></img>
									:
									<img
										src={ImageDefaultClient}
										style={{
											borderRadius: '25px',
											marginRight: '4px',
											// border: '16px',
											objectFit: 'contain' 
										}}
										width="51px"
										height="51px"
									/>
								}
							</Box>
							<Box
								onClick={() => handleItemClick(value)}
								sx={{
									position: 'relative',
									width: '311px',
									height: '48px',
									cursor: !value.isActivate ? 'not-allowed': 'pointer',
									backgroundColor:
										internalSelectedClientId === value.id ? '#00CA95' : '#F6F6F6',
									color: internalSelectedClientId === value.id ? '#fff' : '#000',
									borderRadius: '100px',
									display: 'flex',
									alignItems: 'center',
									marginBottom: '24px'
								}}
							>
								<Typography marginLeft="28px" fontSize="20px">
									{formatTextLimit(value.name, 22)}
								</Typography>
							</Box>
						</Box>
					))}
			</Box>
		</>
	);
};
