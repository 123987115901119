/* eslint-disable prettier/prettier */
import { Grid, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { colors } from '../../../styles';

export const SkeletonInforEnterprise = () => {
  return (
    <>
      <Grid container md={12} rowSpacing={4}>
        <Grid item md={8}>
          <Typography marginBottom="8px" color={colors.primary}>
            CNPJ
          </Typography>
          <Skeleton width={'60%'} height={30} variant="rounded"></Skeleton>
        </Grid>
        <Grid item md={4}>
          <Typography marginBottom="8px" color={colors.primary}>
            COUNTRY
          </Typography>
          <Skeleton variant="rounded" height={30}></Skeleton>
        </Grid>
        <Grid item md={8}>
          <Typography marginBottom="8px" color={colors.primary}>
            COMPANY NAME
          </Typography>
          <Skeleton  variant="rounded" width={'60%'} height={30}></Skeleton>
        </Grid>
        <Grid item md={4}>
          <Typography marginBottom="8px" color={colors.primary}>
            CERTIFIED BY
          </Typography>
          <Skeleton  variant="rounded" width={'100%'} height={30}></Skeleton>
        </Grid>
        <Grid item md={8}>
          <Typography marginBottom="8px" color={colors.primary}>
            ADDRESS
          </Typography>
          <Skeleton variant="rounded" width={'60%'} height={30}></Skeleton>
        </Grid>
        <Grid item md={4}>
          <Typography marginBottom="8px" color={colors.primary}>
            CERTIFIED
          </Typography>
          <Skeleton variant="rounded" width={'100%'} height={30}></Skeleton>
        </Grid>
        <Grid item md={8}>
          <Typography marginBottom="8px" color={colors.primary}>
            COMPLEMENT
          </Typography>
          <Skeleton variant="rounded" width={'60%'} height={30}></Skeleton>
        </Grid>
        <Grid item md={4}>
          <Typography marginBottom="8px" color={colors.primary}>
            ZIPCODE
          </Typography>
          <Skeleton variant="rounded" height={30}></Skeleton>
        </Grid>
        <Grid item md={8}>
          <Typography marginBottom="8px" color={colors.primary}>
            STATE
          </Typography>
          <Skeleton variant="rounded" width={'60%'} height={30}></Skeleton>
        </Grid>
        <Grid item md={4}>
          <Typography marginBottom="8px" color={colors.primary}>
            CITY
          </Typography>
          <Skeleton variant="rounded" height={30}></Skeleton>
        </Grid>
        <Grid item md={8}>
          <Typography marginBottom="8px" color={colors.primary}>
            OWNER NAME
          </Typography>
          <Skeleton variant="rounded" width={'60%'} height={30}></Skeleton>
        </Grid>
        <Grid item md={4}>
          <Typography marginBottom="8px" color={colors.primary}>
            JOB TITLE
          </Typography>
          <Skeleton variant="rounded" height={30}></Skeleton>
        </Grid>
      </Grid>
    </>
  )
}
