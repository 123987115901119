// Core
import React, { FC } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { colors } from '../../../styles';

export const TitleInfo: FC<TypographyProps> = ({ children, ...props }) => {
	return (
		<Typography
			{...props}
			sx={{
				fontSize: '0.87rem',
				color: colors.neutralDark
			}}
		>
			{children}
		</Typography>
	);
};
