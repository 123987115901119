import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useSnackbar } from 'notistack';

import { Avatar, Box, Button, Grid, Stack, Typography } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import trashIcon from '../../../../assets/icon/trash.svg';
import profileFace from '../../../../assets/imgs/pfofileexemplo.jpeg';
import { ConfirmationModal, ModalType } from '../../../../components/confirmationModal';
import { EnterpriseService } from '../../../../services/enterprise.service';
import { listMember } from '../../../../stores/listMember/listMember.actions';
import { colors } from '../../../../styles';
import { ModalMember } from '../modalMember';

interface IListMemberData {
	id: string;
	first_name: string;
	last_name: string;
	image: string;
	logo: string;
}

interface IListMember {
	dataListMember: any;
	setRoleFilter: (value: string) => void;
}

export const ListMember = ({ dataListMember, setRoleFilter }: IListMember) => {
	const enterpriseService = new EnterpriseService();
	const { enqueueSnackbar } = useSnackbar();
	const dispath = useDispatch();
	const ENTERPRISE_ID = localStorage.getItem('enterprise_id') || '';

	const [open, setOpen] = useState<boolean>(false);
	const [openRemove, setOpenRemove] = useState<boolean>(false);

	const [clickStyleAdmin, setClickStyleAdmin] = useState<boolean>(false);
	const [clickStyleSeller, setClickStyleSeller] = useState<boolean>(false);
	const [clickStyleClient, setClickStyleClient] = useState<boolean>(false);
	const [allMembers, setAllMembers] = useState<boolean>(false);
	const [indexTest, setIndexTest] = useState<number>();
	const [idMember, setIdMember] = useState<string>('');
	const [loadingRemove, setLoadingRemove] = useState<boolean>(false);

	function stringToColor(string: string) {
		let hash = 0;
		let i;

		/* eslint-disable no-bitwise */
		for (i = 0; i < string.length; i += 1) {
			hash = string.charCodeAt(i) + ((hash << 5) - hash);
		}

		let color = '#';

		for (i = 0; i < 3; i += 1) {
			const value = (hash >> (i * 8)) & 0xff;
			color += `00${value.toString(16)}`.slice(-2);
		}
		/* eslint-enable no-bitwise */

		return color;
	}

	function stringAvatar(name: string) {
		return {
			sx: {
				bgcolor: stringToColor(name)
			},
			children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
		};
	}

	const openModal = () => {
		setOpen(!open);
	};

	useEffect(() => {
		setAllMembers(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClose = () => {
		setOpenRemove(false);
	};

	const filterAdmin = () => {
		setClickStyleAdmin(true);
		setClickStyleSeller(false);
		setClickStyleClient(false);
		setAllMembers(false);
		setRoleFilter('admin');
	};

	const filterClients = () => {
		setClickStyleClient(true);
		setClickStyleAdmin(false);
		setClickStyleSeller(false);
		setAllMembers(false);
		setRoleFilter('cnpj');
	};

	const filterSeller = () => {
		setClickStyleSeller(true);
		setClickStyleClient(false);
		setAllMembers(false);
		setClickStyleAdmin(false);
		setRoleFilter('seller');
	};

	const filterAllMembers = () => {
		setAllMembers(true);
		setClickStyleAdmin(false);
		setClickStyleSeller(false);
		setClickStyleClient(false);
		setRoleFilter('allMembers');
	};

	const classessd = {
		color: 'none'
	};

	const removeMember = (id: string) => {
		setIdMember(id);
		setOpenRemove(true);
	};

	const submitRemoveMember = async () => {
		setLoadingRemove(true);
		const respRemove = await enterpriseService.removeMemberEnt(
			ENTERPRISE_ID,
			idMember
		);
		if ([400, 404, 500].includes(respRemove.status)) {
			setLoadingRemove(false);
			enqueueSnackbar(respRemove.statusText, {
				variant: 'error'
			});
		}
		if (respRemove.status === 200) {
			setLoadingRemove(false);
			enqueueSnackbar('User was removed successfully', {
				variant: 'success'
			});
			dispath(listMember());
			handleClose();
		}
	};


	return (
		<>
			<Grid container md={12}>
				<Grid item md={12} marginBottom="32px">
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
					>
						<Typography color="#fff" fontSize="1.5rem" fontWeight="700">
							Members
						</Typography>
						<Box display="flex" alignItems="center" onClick={openModal}>
							<Typography color="#fff" marginRight="16px">
								Add
							</Typography>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									width: '40px',
									height: '40px',
									backgroundColor: colors.neutralLight,
									borderRadius: '20px',
									cursor: 'pointer'
								}}
							>
								<AddIcon sx={{ color: colors.primary }} />
							</Box>
						</Box>
					</Box>
					<ModalMember setOpen={setOpen} open={open} />
					{
						openRemove &&
							<ConfirmationModal
								type={ModalType.warning}
								open={openRemove}
								warningMessage={`Are you sure you want to remove ${dataListMember.find((value: { id: any; })=> value.id == idMember)?.first_name}?`}
								title={`Remove ${dataListMember.find((value: { id: any; })=> value.id == idMember)?.first_name} from the organization`}
								// title={'Remove Member'}
								subTitle={`This action will remove this member`}
								handleCloseAuthorization={handleClose}
								onSubmitAuthorization={submitRemoveMember}
								loadingAuthorization={false}
								confirm="Remove"
								cancel="Cancel"
							/>
					}
				</Grid>
				<Grid item md={12} margin="auto" marginBottom="42px" display="flex">
					<Button
						onClick={filterAllMembers}
						variant={allMembers ? 'contained' : 'outlined'}
						sx={{
							width: '120px',
							height: '25px',
							marginRight: '25px',
							border: `${allMembers ? '' : '1px solid #fff'}`,
							backgroundColor: `${allMembers ? '#fff' : ''}`,
							borderRadius: '4px',
							color: `${allMembers ? '#000' : '#fff'}`,
							'&:hover': {
								backgroundColor: `${allMembers ? '#f4f1f1' : 'none'}`
							}
						}}
					>
						<Typography sx={{ textTransform: 'initial' }}>All</Typography>
					</Button>
					<Button
						onClick={filterAdmin}
						variant={clickStyleAdmin ? 'contained' : 'outlined'}
						sx={{
							width: '120px',
							height: '25px',
							marginRight: '25px',
							border: `${clickStyleAdmin ? '' : '1px solid #fff'}`,
							backgroundColor: `${clickStyleAdmin ? '#fff' : ''}`,
							borderRadius: '4px',
							color: `${clickStyleAdmin ? '#000' : '#fff'}`,
							'&:hover': {
								backgroundColor: `${clickStyleAdmin ? '#f4f1f1' : 'none'}`
							}
						}}
					>
						<Typography sx={{ textTransform: 'initial' }}>Admins</Typography>
					</Button>
					<Button
						onClick={filterSeller}
						variant={clickStyleSeller ? 'contained' : 'outlined'}
						sx={{
							width: '120px',
							height: '25px',
							borderRadius: '4px',
							marginRight: '25px',
							border: `${clickStyleSeller ? '' : '1px solid #fff'}`,
							backgroundColor: `${clickStyleSeller ? '#fff' : ''}`,
							color: `${clickStyleSeller ? '#000' : '#fff'}`,
							'&:hover': {
								backgroundColor: `${clickStyleSeller ? '#f4f1f1' : 'none'}`
							}
						}}
					>
						<Typography sx={{ textTransform: 'initial' }}>Sellers</Typography>
					</Button>
					<Button
						onClick={filterClients}
						variant={clickStyleClient ? 'contained' : 'outlined'}
						sx={{
							width: '120px',
							height: '25px',
							marginRight: '25px',
							border: `${clickStyleClient ? '' : '1px solid #fff'}`,
							backgroundColor: `${clickStyleClient ? '#fff' : ''}`,
							borderRadius: '4px',
							color: `${clickStyleClient ? '#000' : '#fff'}`,
							'&:hover': {
								backgroundColor: `${clickStyleClient ? '#f4f1f1' : 'none'}`
							}
						}}
					>
						<Typography sx={{ textTransform: 'initial' }}>Clients</Typography>
					</Button>
				</Grid>
				<Box
					sx={{
						overflow: 'auto',
						width: '100%',
						height: '453px',
						'::-webkit-scrollbar': {
							border: '1px solid #D4D7DE',
							width: '15px',
							borderRadius: '15px'
						},
						'::-webkit-scrollbar-thumb': {
							backgroundColor: '#D9D9D9',
							borderRadius: '15px',
							height: '170px',
							width: '15px'
						}
					}}
				>
					{dataListMember?.length > 0 &&
						dataListMember.map((value: IListMemberData, index: number) => (
							<Grid
								item
								md={10}
								display="flex"
								alignItems="center"
								marginBottom="16px"
							>
								<>
									{value?.logo ? (
										<Box>
											<Avatar
												style={{
													width: '51px',
													height: '51px',
													marginRight: '4px',
													borderRadius: '25px',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													fontWeight: '700',
													fontSize: '16px',
													fontFamily: 'sen'
												}}
												src={value?.logo}
											/>
										</Box>
									) : (
										<Stack direction="row" spacing={2}>
											<Avatar
												style={{
													width: '51px',
													height: '51px',
													marginRight: '4px',
													borderRadius: '25px',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													fontWeight: '700',
													fontSize: '16px',
													fontFamily: 'sen'
												}}
												// prettier-ignore
												{...stringAvatar(`${value.first_name} ${value.last_name}`)}
											/>
										</Stack>
									)}
									<Box
										sx={{
											position: 'relative',
											flexGrow: '1'
										}}
									>
										<Box
											style={classessd}
											sx={{
												position: 'relative',
												zIndex: '10',
												display: 'flex',
												alignItems: 'center',
												paddingLeft: '30px',
												height: '48px',
												backgroundColor: '#d9d9d9',
												borderRadius: '100px',
												marginRight: `${index === indexTest ? '110px' : ''}`,
												transition: `${index === indexTest ? '.6s' : '.4s'}`
											}}
										>
											<Typography
												maxWidth={index === indexTest ? '60%' : '230px'}
												overflow="hidden"
												textOverflow="ellipsis"
												whiteSpace="nowrap"
												color={index === indexTest ? '#fff' : ''}
											>
												{`${value?.first_name} ${value?.last_name}`}
											</Typography>
										</Box>
										<Box
											onMouseEnter={() => setIndexTest(index)}
											onMouseLeave={() => setIndexTest(-1)}
											onClick={() => removeMember(value.id)}
											sx={{
												cursor: 'pointer',
												position: 'absolute',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-end',
												paddingRight: '20px',
												zIndex: '1',
												top: '0',
												right: '-50px',
												width: '100%',
												height: '48px',
												backgroundColor: '#F32053',
												borderRadius: '100px'
											}}
										>
											<Typography
												marginRight="20px"
												fontSize="1.20rem"
												color={colors.neutralLight}
											>
												DELETE ?
											</Typography>
											<img src={trashIcon}></img>
										</Box>
									</Box>
								</>
							</Grid>
						))}
				</Box>
			</Grid>
		</>
	);
};
