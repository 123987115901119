import { Box, Typography } from '@mui/material';

import { colors } from '../../styles';

interface IModeSelect {
	numberMode: number;
}

/* eslint-disable prettier/prettier */
export const ModeSelect = ({ numberMode }: IModeSelect) => {
	return (
		<>
			<Box
				sx={{
					width: '96px',
					height: '96px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					border: '1px solid #3A4049',
					borderRadius: '50%',
				}}>
				<Typography
					sx={{
						fontWeight: '700',
						fontSize: '3rem',
						lineHeight: '57px',
						color: colors.primaryDark
					}}>
					{numberMode}
				</Typography>
			</Box>
		</>
	)
}