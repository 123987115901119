import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Typography, Paper } from '@mui/material';
import Popover from '@mui/material/Popover';
import { ApiPromise, WsProvider } from '@polkadot/api';
import information from '../../../../assets/imgs/information.png';
import recImage from '../../../../assets/imgs/renewable-energy-certificate.jpg';
import { colors } from '../../../../styles';
import { TRecAssets } from '../../../../types/recAssets';
import { IRecListToken } from '../../../../types/recListToken';
import { formatTextLimit } from '../../../../utils/formatTextLenght';

export type TRecResultList = {
	searchInput: string;
	dataListRec?: Array<TRecAssets>;
	reloadRecList?: boolean;
};

export const RecResultList = ({ searchInput, reloadRecList }: TRecResultList) => {
	const getRecAssets = useSelector((state: any) => state.getRecsAsset);

	const [filteredList, setfilteredList] = useState<Array<any>>([]);
	const [dataAssetRec, setDataAssetRec] = useState<Array<any>>([]);
	const [recLabelOnchain, setRecLabelOnchain] = useState<Array<any>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isLoadingOnchain, setIsLoadingOnchain] = useState<boolean>(true);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const openPopover = Boolean(anchorEl);

	useEffect(() => {
		const getRecs = async () => {
			const respGetRecAssets = await getRecAssets;
			if (respGetRecAssets.status == 200) {
				setDataAssetRec(respGetRecAssets.data.assetRecs);
			}
			setIsLoading(false)
		};

		getRecs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getRecAssets, reloadRecList]);

	useEffect(()=>{
		const getOnchain = async () => {
			const provider = new WsProvider(`${process.env.REACT_APP_PARACHAIN_ADDRESS}`);
			const api = await ApiPromise.create({ provider });
			const queryRes = await api.query.myRecs.listRecLabel.entries();
			const recLabels = queryRes.map((vaar) => (vaar[1].toHuman()));
			setRecLabelOnchain(recLabels);
			await api.disconnect()
			setIsLoadingOnchain(false)
		}
		getOnchain()
	},[])

	useEffect(()=>{
		const getAssetCertificateStatus = async () => {
			const updatedValues = [...filteredList];
			const objectToUpdate  = updatedValues.filter((value)=> (value.rec.type == 'special'));
			const ress = objectToUpdate.map((value) => {
				const objj = recLabelOnchain.find((obj) => {
					return value.rec.optional_id == obj.recLabelId
				});
				if (objj) {
					value.is_pending = !objj.isCertified;
				}
			})
			if(ress){
				setfilteredList(updatedValues);
			}
		}
		if(!isLoading){
			getAssetCertificateStatus()
		}
	},[isLoading, recLabelOnchain])

	useEffect(() => {
		setfilteredList(dataAssetRec);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataAssetRec]);

	useEffect(() => {
		if (!searchInput) {
			setfilteredList(dataAssetRec);
			return;
		}

		const filterSearch = dataAssetRec.filter((resultList: any) => {
			if (searchInput) {
				return (
					resultList.rec.name
						.toLowerCase()
						.trim()
						.indexOf(searchInput.toLowerCase()) !== -1
				);
			}
		});

		setfilteredList(filterSearch.length > 0 ? filterSearch : filteredList);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchInput]);


	return (
		<>
			<Box
				display="flex"
				flexDirection="column"
				marginLeft="15px"
				marginTop="21px"
				marginRight="10px"
			>
				{!filteredList.length && !isLoadingOnchain && (
					<Box display="flex" justifyContent="center" marginTop="90px">
						<Typography
							fontSize="27px"
							fontWeight="700"
							color={colors.primaryDark}
							sx={{ opacity: '.4' }}
						>
							No REC labels created yet
						</Typography>
					</Box>
				)}
				{filteredList && !isLoadingOnchain &&
					filteredList.map((value: TRecAssets, index: number) => (
						<Box key={index} display="flex">
							<Box
								sx={{
									marginRight: '9px',
									borderRadius: '55px',
									width: '51px',
									height: '51px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									backgroundColor: '#fff'
								}}
							>
								<img
									width="47px"
									height="47px"
									style={{
										marginLeft: '4px',
										// borderRadius: '25px',
										marginRight: '4px'
									}}
									src={recImage} // Change for value.rec.logo
								></img>
							</Box>
							<Box
								sx={{
									position: 'relative',
									width: '100%',
									height: '48px',
									backgroundColor: `${
										value.rec.type === 'special' && value.is_pending
											? '#FFC658'
											: '#F6F6F6'
									}`,
									color: `${
										value.rec.type === 'special' && value.is_pending
											? '#fff'
											: '#000'
									}`,
									borderRadius: '100px',
									display: 'flex',
									alignItems: 'center',
									marginBottom: '24px'
								}}
							>
								<Typography marginLeft="28px" fontSize="20px">
									{formatTextLimit(value.rec.name, 22)}
								</Typography>
								{value.rec.type === 'special' && value.is_pending && (
									<>
										<Box
											onMouseEnter={handlePopoverOpen}
											onMouseLeave={handlePopoverClose}
											sx={{
												position: 'absolute',
												right: '0',
												width: '44px',
												height: '44px',
												borderRadius: '22px',
												backgroundColor: '#fff',
												marginRight: '3px',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center'
											}}
										>
											<img src={information}></img>
										</Box>
										<Popover
											id="mouse-over-popover"
											sx={{
												pointerEvents: 'none'
											}}
											open={openPopover}
											anchorEl={anchorEl}
											anchorOrigin={{
												vertical: 'top',
												horizontal: 'right'
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'left'
											}}
											onClose={handlePopoverClose}
											disableRestoreFocus
										>
											<Paper
												sx={{
													backgroundColor: '#414141'
												}}
											>
												<Typography sx={{ p: 1, color: '#fff' }}>
													Pending Verification
												</Typography>
											</Paper>
										</Popover>
									</>
								)}
							</Box>
						</Box>
					))}
			</Box>
		</>
	);
};
