import { configureStore } from '@reduxjs/toolkit';

import getRecsAsset from './assetRecs/assetRecs.reducer';
import loginAuthReducer from './authLogin/authLogin.reducer';
import signupAuthReducer from './authSignup/authSignup.reducer';
import goToRecoveryPassword from './forgotPassword/forgotPassword.reducer';
import goToBuyerTransaction from './goToBuyerTransaction/goToBuyerTransaction.reducer';
import goToTransfer from './goToTransfer/goToTransfer.reducer';
import listMemberReducer from './listMember/listMember.reducer';
import nextStepReducer from './nextStep/nextStep.reducer';
import nextStepAssetReducer from './nextStepAseet/nextStepAsset.reducer';
import recoveryWalletReducer from './recoverWallet/recoverWallet.reducer';
import recTokenAsset from './recTokenAsset/recTokenAsset.reducer';
import totalRecAvailable from './totalAvailableRec/totalAvailableRec.reducer';
import getCheckBox from './transferRec/transferRec.reducer';
import editBuyerId from './editBuyerManage/editBuyerManage.reducer';
import recoveryWalletAsset from './recoveryWalletAsset/recoveryWalletAsset.reducer';
import createWalletAsset from './createWalletAsset/createWalletAsset.reducer';
import myReducer from './userProfile/userProfile.reducer';

const store = configureStore({
	reducer: {
		authLogin: loginAuthReducer,
		authSignup: signupAuthReducer,
		listMemberData: listMemberReducer,
		nextStep: nextStepReducer,
		nextStepAsset: nextStepAssetReducer,
		recovery: recoveryWalletReducer,
		recoveryPassword: goToRecoveryPassword,
		listRecTokenAsset: recTokenAsset,
		recovery: recoveryWalletReducer,
		getCheckBox: getCheckBox,
		goToTransfer: goToTransfer,
		getRecsAsset: getRecsAsset,
		totalRecAvailable,
		goToBuyerTransaction,
		editBuyerId,
		recoveryWalletAsset,
		createWalletAsset,
		myReducer
	}
});

export default store;
