import {
	Box,
	Grid,
	InputAdornment,
	OutlinedInput,
	TextField,
	Typography
} from '@mui/material';

import wind from '../../../assets/imgs/WIND.png';
import edit from '../../../assets/icon/edit.svg';

import { useEffect, useState } from 'react';
import { AssetService } from '../../../services/asset.service';
import { Link } from 'react-router-dom';
import Ellipse10 from '../../../assets/imgs/PowerPlantIA.jpg';

import search from '../../../assets/imgs/search.svg';

export const UnitsList = ({ handleAssetEditInfo, setSelectedAsset }: any) => {
	const [assets, setAssets] = useState([]);
	const [assetsFiltered, setAssetsFiltered] = useState([]);
	const [assetsOverflow, setAssetsOverflow] = useState('hidden');

	const [searchInput, setSearchInput] = useState<string>('');

	useEffect(() => {
		const assetService = new AssetService();

		const listAssets = async () => {
			const response: any = await assetService.listAsset();
			setAssets(response.data);
			setAssetsFiltered(response.data);

			if (response.data.length > 5) {
				setAssetsOverflow('scroll');
			} else {
				setAssetsOverflow('hidden');
			}
		};
		listAssets();
	}, []);

	useEffect(() => {
		const filteredAssets = assets.filter((asset: any) => {
			return asset.name.toLowerCase().includes(searchInput.toLowerCase());
		});
		if (filteredAssets.length > 5) {
			setAssetsOverflow('scroll');
		} else {
			setAssetsOverflow('hidden');
		}
		setAssetsFiltered(filteredAssets);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchInput]);

	return (
		<Box>
			<TextField
				fullWidth
				variant="filled"
				placeholder="search"
				sx={{
					m: 1,
					maxWidth: '634px',
					'& .MuiFilledInput-root': {
						backgroundColor: 'f4eded',
						borderRadius: '16px',
						paddingBottom: '15px',
						paddingLeft: '10px'
					}
				}}
				onChange={e => setSearchInput(e.target.value)}
				InputProps={{
					endAdornment: (
						<InputAdornment
							position="end"
							sx={{ paddingTop: '10px', paddingRight: '10px' }}
						>
							<img src={search}></img>
						</InputAdornment>
					),
					disableUnderline: true
				}}
			/>
			<Grid container md={12} sx={{ marginTop: '23px', marginBottom: '9px' }}>
				<Grid item md={6}>
					<Typography
						color="#636C7A"
						fontFamily="Sen"
						fontWeight="400"
						fontSize="20px"
						lineHeight="32px"
					>
						POWER UNIT
					</Typography>
				</Grid>
				<Grid item md={6}>
					<Typography
						color="#636C7A"
						fontFamily="Sen"
						fontWeight="400"
						fontSize="20px"
						lineHeight="32px"
					>
						CREATION DATE
					</Typography>
				</Grid>
			</Grid>
			<hr style={{ marginRight: '50px' }} />
			<Box
				sx={{
					height: '410px',
					'overflow-x': 'hidden',
					'overflow-y': assetsOverflow,
					'::-webkit-scrollbar': {
						border: '1px solid #D4D7DE',
						width: '15px',
						borderRadius: '15px'
					},
					'::-webkit-scrollbar-thumb': {
						backgroundColor: '#D9D9D9',
						borderRadius: '15px',
						height: '170px',
						width: '15px'
					}
				}}
			>
				{assetsFiltered?.map((asset: any) => {
					return (
						<>
							<Grid container md={12} sx={{ marginTop: '23px' }}>
								<Grid item md={1}>
									<Box
										sx={{
											width: '40px',
											height: '40px',
											backgroundColor: '#F7F7F7',
											borderRadius: '50%',
											':hover': { cursor: 'pointer' }
										}}
										onClick={() => setSelectedAsset(asset)}
									>
										{asset?.logo ? (
											<img
												src={asset?.logo}
												width={'48px'}
												height={'48px'}
												alt="unit2"
											/>
										) : (
											<img
												src={Ellipse10}
												width={'48px'}
												height={'48px'}
												alt="unisst"
											/>
										)}
									</Box>
								</Grid>
								<Grid item md={5}>
									<Typography
										color="#484848"
										fontFamily="Sen"
										fontWeight="400"
										fontSize="16px"
										lineHeight="19px"
										sx={{
											marginLeft: '22px',
											marginTop: '7px',
											':hover': { cursor: 'pointer' }
										}}
										onClick={() => setSelectedAsset(asset)}
									>
										{asset.name}
									</Typography>
								</Grid>
								<Grid item md={5}>
									<Typography
										color="#484848"
										fontFamily="Sen"
										fontWeight="400"
										fontSize="16px"
										lineHeight="19px"
										sx={{ marginTop: '7px' }}
									>
										{asset.created_at.slice(0, 10)}
									</Typography>
								</Grid>
								<Grid item md={1}>
									<Box
										onClick={() => {
											handleAssetEditInfo(asset);
										}}
										sx={{
											marginTop: '7px',
											':hover': { cursor: 'pointer' }
										}}
									>
										<a href="/rec/assets"></a>
										<img src={edit} />
									</Box>
								</Grid>
							</Grid>
							<hr
								style={{
									marginTop: '10px',
									marginLeft: '60px',
									marginRight: '50px'
								}}
							/>
						</>
					);
				})}
			</Box>
		</Box>
	);
};
