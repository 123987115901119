import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Box, Button, Card, Typography } from '@mui/material';
import copy from 'copy-to-clipboard';

import { colors } from '../../../styles';

/* eslint-disable prettier/prettier */
export const MnemonicAsset = (props: any) => {
	const { enqueueSnackbar } = useSnackbar();
	const [words, setWords] = useState<Array<any>>([]);

	useEffect(()=>{
		setWords(props.mnemonic.split(' '));
	},[])

	const copyWords = () => {
		if (navigator.clipboard) {
			copy(words.toString().split(',').join(' '));
			// navigator.clipboard.writeText(words.toString().split(',').join(' '));
			enqueueSnackbar('Words copied', {
				variant: 'info'
			});
		}
	};

	return (
		<>
			<Card
				sx={{
					width: '608px',
					height: '393px',
					borderRadius: '16px',
					marginTop: '32px'
				}}
			>
				<Box sx={{ height: '79px', backgroundColor: colors.primary }}>
					<Typography
						display="flex"
						alignItems="center"
						height="100%"
						color={colors.neutralLight}
						marginLeft="44px"
					>
						GENERATED 12 WORD MNEMONIC SEED:
					</Typography>
				</Box>
				<Box
					sx={{
						padding: '32px 10px 6px 80px',
						display: 'flex',
            // justifyContent: 'center',
						flexWrap: 'wrap'
					}}
				>
					{words.map((value: any) => (
						<Typography
							fontSize="1.75rem"
							marginBottom="32px"
							paddingRight="28px"
							fontWeight="700"
							lineHeight="40px"
							color="#F32053"
						>
							{value}
						</Typography>
					))}
				</Box>
				<Box display="flex" justifyContent="center">
					<Button
						onClick={copyWords}
						variant="outlined"
						sx={{
							borderRadius: '25px',
							borderColor: '#000',
							color: '#000',
							padding: '8px 24px',
							miWidth: '262px',
							height: '40px',
							'&:hover': {
								borderColor: '#000'
							}
						}}
					>
						<Typography fontSize="1.25rem" sx={{ textTransform: 'initial' }}>
							Copy mnemonic words
						</Typography>
					</Button>
				</Box>
			</Card>
		</>
	);
};
