/* eslint-disable prettier/prettier */
import { useState } from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useSnackbar } from 'notistack';

import { ApiPromise, WsProvider, } from "@polkadot/api";
import { keyring as uiKeyring } from "@polkadot/ui-keyring";
import { waitReady } from "@polkadot/wasm-crypto";

import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	InputBase,
	styled,
	Typography,
	OutlinedInput,
	FormHelperText
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import { EnterpriseService } from '../../../services/enterprise.service';
import { MemberService } from '../../../services/member.service';
import { colors } from '../../../styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { SchemaFormConfig } from './schemaConfig';
import { ConfirmationModal } from '../../../components/confirmationModal';

export type TFormConfig = {
	// enterprise_id: string;
	id: string;
	name: string;
	country: string;
	state: string;
	city: string;
	address: string;
	number: string;
	complement: string;
	cnpj: string;
	zipcode: string;
	wallet: string;
	logo: string;
	certified_by: string;
};

export type TForm = {
	setSuccess: (value: boolean) => void;
	dataEnterprise: TFormConfig;
	setBackInfoUser: (value: boolean) => void;
};

export const FormConfig = ({
	setSuccess,
	dataEnterprise,
	setBackInfoUser
}: TForm) => {
	const enterpriseService = new EnterpriseService();
	const memberService = new MemberService();
	const [loading, setLoading] = useState<boolean>(false);
	const [isLoadingButtonDisable, setIsLoadingButtonDisable] = useState<boolean>(false);
	const [user, setUser] = useState<any>({});
	const [countries, setCountries] = useState<Array<any>>();
	const [states, setStates] = useState<Array<any>>([]);
	const [countriesToState, setCountriesToState] = useState<any>();
	const { enqueueSnackbar , closeSnackbar } = useSnackbar();
	const [passwordAuthorization, setPasswordAuthorization] = useState<string>('');
	const [openAuthorization, setOpenAuthorization] = useState<boolean>(false);
	const [loadingAuthorization, setLoadingAuthorization] =	useState<boolean>(false);
	const form = useForm<TFormConfig>({ resolver: yupResolver(SchemaFormConfig) });

	const getUserInfo = async () => {
		const getUser: any = await memberService.getMyInfo();
		if (getUser.status === 200) {
			setUser(getUser.data);
		}
		// if error
	};

	useEffect(() => {
		getUserInfo();
	}, []);

	const loadDataEdit = () => {
		form.setValue('name', dataEnterprise.name);
		form.setValue('city', dataEnterprise.city);
		form.setValue('address', dataEnterprise.address);
		form.setValue('number', dataEnterprise.number);
		form.setValue('complement', dataEnterprise.complement);
		form.setValue('cnpj', dataEnterprise.cnpj);
		form.setValue('zipcode', dataEnterprise.zipcode);
		form.setValue('state', dataEnterprise.state);
		form.setValue('certified_by', dataEnterprise.certified_by);
		form.setValue('country', dataEnterprise.country);
		setCountriesToState(dataEnterprise.country);
	};

	useEffect(() => {
		loadDataEdit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const getCoutries = async () => {
			const respCountries = await enterpriseService.getCountries();
			if (respCountries.status === 200) {
				const { data } = respCountries.data;
				setCountries(data);
			}
		};
		getCoutries();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const getStates = async () => {
			if (countriesToState) {
				const respStates = await enterpriseService.getStates(countriesToState);
				if (respStates.status === 200) {
					const { data } = respStates.data;
					setStates(data.states);
				}
			}
		};
		getStates();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countriesToState]);

	const onSubmit = () => {
		if (!user.wallet_address){
			enqueueSnackbar("You haven't registered your wallet in your profile yet.", {
				variant: 'error'
			});
			return; 
		}
		setOpenAuthorization(true);
	};

	const handleCloseAuthoraztion = () => {
		setOpenAuthorization(false);
	};

	const onSubmitAuthorization = async () => {
		
		const updateEnterprise = async () => {
			try {
				await waitReady();
				uiKeyring.loadAll({ ss58Format: 42, type: "sr25519" });
			} catch (error) {
				console.log(error)
			} finally {
				const provider = new WsProvider(`${process.env.REACT_APP_PARACHAIN_ADDRESS}`);
				const api = await ApiPromise.create({ provider });
				try {
					const userPair = uiKeyring.getPair(user.wallet_address);
					try {
						userPair.unlock(passwordAuthorization);
					} catch (error) {
						console.log(error)
						enqueueSnackbar('The password of your wallet is invalid, try again', {
							variant: 'error'
						});
						return; 
					}
					const genesisHash = api.genesisHash;
					const runtimeVersion = api.runtimeVersion;
					
					let nonce = await api.rpc.system.accountNextIndex(user.wallet_address);

					const header = await api.derive.accounts.info(user.wallet_address)

					const notify = enqueueSnackbar('Processing on the blockchain.', {
						variant: 'info',
						persist: true
					});

					const value = { ...form.getValues() };
					const cnpjNormalize = value.cnpj.replace(/[./-]/g, '');
					if (!value.cnpj || !value.name) return;
					`${process.env.REACT_APP_CLIENT_PARACHAIN_ID}`
					const signedTransaction = api.tx.organization.updateOrganization(`${process.env.REACT_APP_CLIENT_PARACHAIN_ID}`, `${process.env.REACT_APP_MULTILEDGERS_PARACHAIN_ID}`, cnpjNormalize, value.name, [[user.wallet_address, "Owner"]]).sign(userPair, { genesisHash, blockHash: genesisHash, nonce, runtimeVersion });

					setOpenAuthorization(false)
					const respSubmitEnt = await enterpriseService.updateEnterprise({
						enterprise_id: dataEnterprise.id,
						country: countriesToState,
						cnpj: cnpjNormalize,
						name: value.name,
						zipcode: value.zipcode.toString(),
						state: value.state,
						city: value.city,
						address: value.address,
						number: value.number.toString(),
						complement: value.complement,
						certified_by: value.certified_by,
						extrinsic: signedTransaction
					});
					await api.disconnect()

					if (respSubmitEnt.status === 200) {
						setLoading(false);
						setLoadingAuthorization(false);
						setSuccess(true);
						closeSnackbar(notify);
						enqueueSnackbar('Enterprise has been update sucessfully', {
							variant: 'success'
						});
					}
					
					if (respSubmitEnt.status === 401) {
						setLoading(false);
						setSuccess(false);
						closeSnackbar(notify);
						enqueueSnackbar(respSubmitEnt.statusText, {
							variant: 'error'
						});
					}
					handleCloseAuthoraztion();
				} catch (error) {
					console.log(error)
					enqueueSnackbar('Wallet not found, please consider recovering it on the My Profile page. A new tab will be created in 5 seconds.', {
						variant: 'error'
					});
					setTimeout(()=>{
						window.open(`${process.env.REACT_APP_CLIENT_URL}/profile`, "_blank", 'noopener,noreferrer');
					},5000)
					// clearTimeout(timeout);
					return;
				}
			}
		};

		updateEnterprise();

		
	};

	const goToConfig = () => {
		setBackInfoUser(true);
	};

	const BootstrapInput = styled(InputBase)(() => ({
		'& .MuiInputBase-input': {
			display: 'flex',
			alignItems: 'center',
			padding: '14px 25px',
			borderRadius: '9px',
			// backgroundColor: 'transparent',
			border: '1px solid #000',
			'& .MuiSvgIcon-root': {
				marginTop: '100px',
				color: '#fff'
			},
			'&:focus': {
				borderRadius: '10px'
			},
			':after': {
				borderBottom: 'none',
				borderRight: '1px solid #000',
				marginRight: '50px'
			}
		}
	}));

	const setCountriesSelect = (e: any) => {
		form.setValue('country', e.target.value);
		setCountriesToState(e.target.value);
	};

	const getZipCode = async (e: any) => {
		form.setValue('zipcode', e.target.value);
		const resZipCode = await enterpriseService.getZipCode(Number(e.target.value));

		if (resZipCode?.status === 200) {
			const { localidade, logradouro } = resZipCode.data;
			form.setValue('city', localidade);
			form.setValue('address', logradouro);
		}
	};

	const handleKeyPress = (event: { key: string; }) => {
		if (event.key === 'Enter') {
			onSubmit();
		}
		if (event.key === 'Escape') {
			goToConfig();
		}
	};
	
	useEffect(() => {
		// Add event listener when component mounts
		document.addEventListener('keydown', handleKeyPress);
	
		// Remove event listener when component unmounts
		return () => {
		  document.removeEventListener('keydown', handleKeyPress);
		};
	}, []);

	const validForm = () => {
		const value = { ...form.getValues() };
		setIsLoadingButtonDisable(areAllFieldsFilled(value))
	};

	function areAllFieldsFilled(obj: { [key: string]: string | undefined }): boolean {
		return Object.values(obj).every(value => value != null && value !== '');
	}

	return (
		<>
			<form onSubmit={() => {}}>
				<Grid container md={12} rowGap={3} columnSpacing={2} marginTop="-12px">
					<Grid item md={6}>
						<Controller
							control={form.control}
							name="country"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel
										required
										error={false}
										shrink
										htmlFor="country"
										sx={{ position: 'relative', marginTop: '3px' }}
									>
										COUNTRY
									</InputLabel>
									<Select
										{...field}
										fullWidth
										input={<BootstrapInput />}
										labelId="demo-simple-select-label"
										id="country"
										variant="standard"
										value={countriesToState}
										onChange={(e: any) => {
											setCountriesSelect(e)
											validForm()
										}}
									>
										{countries &&
											countries.map(({ iso3, country }) => (
												<MenuItem key={iso3} value={country}>
													{country}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={6}>
						<Controller
							control={form.control}
							name="cnpj"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth disabled={dataEnterprise.cnpj ? true : false}>
									<InputLabel
										required
										error={!!form.formState.errors.cnpj}
										shrink
										htmlFor="cnpj"
									>
										CNPJ
									</InputLabel>
									<InputMask
										autoComplete="off"
										type="string"
										mask="99.999.999/9999-99"
										value={field.value}
										onChange={(e: any) => {
											form.setValue('cnpj', e.target.value);
											validForm()
										}}
									>
										{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
										{/* @ts-ignore */}
										{() => (
											<OutlinedInput
												autoFocus
												fullWidth
												style={{
													border: 'none',
													borderRadius: '9px',
													marginTop: '20px'
												}}
												error={!!form.formState.errors.cnpj}
												placeholder="type here..."
												type="string"
											/>
										)}
									</InputMask>
									{!!form.formState.errors.cnpj && (
										<FormHelperText error>
											{form?.formState?.errors?.cnpj?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={12}>
						<Controller
							control={form.control}
							name="name"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel
										required
										error={!!form.formState.errors.name}
										shrink
										htmlFor="name"
									>
										COMPANY NAME
									</InputLabel>
									<OutlinedInput
										fullWidth
										style={{
											border: 'none',
											borderRadius: '9px',
											marginTop: '20px'
										}}
										error={!!form.formState.errors.name}
										placeholder="type here..."
										{...field}
										type="string"
										onChange={e => {
											form.setValue('name', e.target.value);
											validForm()
										}}
									/>
									{!!form.formState.errors.name && (
										<FormHelperText error>
											{form?.formState?.errors?.name?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={4}>
						<Controller
							control={form.control}
							name="zipcode"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel
										required
										error={!!form.formState.errors.zipcode}
										shrink
										htmlFor="zipcode"
									>
										ZIPCODE
									</InputLabel>
									<OutlinedInput
										autoComplete="off"
										fullWidth
										style={{
											border: 'none',
											borderRadius: '9px',
											marginTop: '20px'
										}}
										error={!!form.formState.errors.zipcode}
										placeholder="type here..."
										{...field}
										type="string"
										onChange={e => {
											validForm()
											getZipCode(e)
										}}
									/>
									{!!form.formState.errors.zipcode && (
										<FormHelperText error>
											{form?.formState?.errors?.zipcode?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={8}>
						<Controller
							control={form.control}
							name="state"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel
										required
										error={false}
										shrink
										htmlFor="state"
										sx={{ position: 'relative' }}
									>
										STATE
									</InputLabel>
									<Select
										{...field}
										fullWidth
										input={<BootstrapInput />}
										labelId="demo-simple-select-label"
										id="state"
										variant="standard"
										onChange={e => {
											form.setValue('state', e.target.value);
											validForm();
										}}
										sx={{
											':before': {
												borderRight: '1px solid #A2CBFE',
												marginRight: '50px',
												height: '100%'
											}
										}}
									>
										{states &&
											states.map(({ iso3, name }) => (
												<MenuItem key={iso3} value={name}>
													{name}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							)}
						/>
					</Grid>

					<Grid item md={4}>
						<Controller
							control={form.control}
							name="city"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel
										required
										error={!!form.formState.errors.city}
										shrink
										htmlFor="city"
									>
										CITY
									</InputLabel>
									<OutlinedInput
										fullWidth
										style={{
											border: 'none',
											borderRadius: '9px',
											marginTop: '20px'
										}}
										error={!!form.formState.errors.city}
										placeholder="type here..."
										{...field}
										type="string"
										onChange={e => {
											form.setValue('city', e.target.value);
											validForm();
										}}
									/>
									{!!form.formState.errors.city && (
										<FormHelperText error>
											{form?.formState?.errors?.city?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={8}>
						<Controller
							control={form.control}
							name="address"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel
										required
										error={!!form.formState.errors.address}
										shrink
										htmlFor="address"
									>
										ADDRESS
									</InputLabel>
									<OutlinedInput
										fullWidth
										style={{
											border: 'none',
											borderRadius: '9px',
											marginTop: '20px'
										}}
										error={!!form.formState.errors.address}
										placeholder="type here..."
										{...field}
										type="string"
										onChange={e => {
											form.setValue('address', e.target.value);
											validForm();
										}}
									/>
									{!!form.formState.errors.address && (
										<FormHelperText error>
											{form?.formState?.errors?.address?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>

					<Grid item md={4}>
						<Controller
							control={form.control}
							name="number"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel
										required
										error={!!form.formState.errors.number}
										shrink
										htmlFor="number"
									>
										NUMBER
									</InputLabel>
									<OutlinedInput
										fullWidth
										style={{
											border: 'none',
											borderRadius: '9px',
											marginTop: '20px'
										}}
										error={!!form.formState.errors.number}
										placeholder="type here..."
										{...field}
										type="string"
										onChange={e => {
											form.setValue('number', e.target.value);
											validForm();
										}}
									/>
									{!!form.formState.errors.number && (
										<FormHelperText error>
											{form?.formState?.errors?.number?.message}
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={8}>
						<Controller
							control={form.control}
							name="complement"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel error={false} shrink htmlFor="complement">
										COMPLEMENT
									</InputLabel>
									<OutlinedInput
										fullWidth
										style={{
											border: 'none',
											borderRadius: '9px',
											marginTop: '20px'
										}}
										error={false}
										placeholder="type here..."
										{...field}
										type="string"
										onChange={e => {
											form.setValue('complement', e.target.value);
											validForm();
										}}
									/>
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item md={4}>
						<Controller
							control={form.control}
							name="certified_by"
							render={({ field }) => (
								<FormControl variant="standard" fullWidth>
									<InputLabel
										required
										error={false}
										shrink
										htmlFor="certified_by"
										sx={{ position: 'relative' }}
									>
										CERTIFIED BY
									</InputLabel>
									<Select
										{...field}
										fullWidth
										input={<BootstrapInput />}
										labelId="demo-simple-select-label"
										id="certified_by"
										variant="standard"
										onChange={e => {
											form.setValue('certified_by', e.target.value);
											validForm();
										}}
										sx={{
											':before': {
												borderRight: '1px solid #A2CBFE',
												marginRight: '50px',
												height: '100%'
											}
										}}
									>
										<MenuItem key={1} value={'SGS'}>
											{'SGS'}
										</MenuItem>
									</Select>
								</FormControl>
							)}
						/>
					</Grid>
					<Grid md={10}
						item
						marginTop="25px"
						display="flex"
						justifyContent="flex-end"
						width="100%"
					>
						<Button
							onClick={goToConfig}
							variant="outlined"
							sx={{
								borderRadius: '25px',
								border: '1px solid #001',
								padding: '4px 30px',
								width: '107px',
								marginRight: '52px',
								'&:hover': {
									borderColor: '#68707c',
									backgroundColor: 'transparent'
								}
							}}
						>
							<Typography
								color="#000"
								fontSize="1.25rem"
								sx={{
									textTransform: 'initial'
								}}
							>
								Back
							</Typography>
						</Button>
						<LoadingButton
							variant="contained"
							loading={loading}
							disabled={!isLoadingButtonDisable}
							onClick={() => form.handleSubmit(onSubmit)()}
							sx={{
								width: '169px',
								backgroundColor: colors.primary,
								borderRadius: '25px',
								padding: '8px 24px',
								'&:hover': {
									backgroundColor: '#68707c'
								}
							}}
						>
							<Typography
								color="#fff"
								fontSize="1.25rem"
								fontWeight="400"
								sx={{
									textTransform: 'initial'
								}}
							>
								Save data
							</Typography>
						</LoadingButton>
						{openAuthorization &&
							<ConfirmationModal
								open={openAuthorization}
								title="Editing enterprise information"
								subTitle="This action will allow you to change enterprise information"
								walletName={user.wallet_name}
								walletAddress={user.wallet_address}
								handleCloseAuthorization={handleCloseAuthoraztion}
								setPasswordAuthorization={setPasswordAuthorization}
								onSubmitAuthorization={onSubmitAuthorization}
								loadingAuthorization={loadingAuthorization}
								confirm="Sign"
								cancel="Cancel"
							/>
						}
					</Grid>
				</Grid>
			</form>
		</>
	);

};