/* eslint-disable prettier/prettier */
import { useState } from 'react';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { colors } from '../../../styles';
import { formatTextLimit } from '../../../utils/formatTextLenght';
import { TbCertificate, TbCertificateOff} from 'react-icons/tb';

export const InfoEnterprise = ({ dataUser, enterpriseIsCertified, isLoading }: any) => {
  const [data] = dataUser;

  const formatCnpj = (cnpj: string) => {
    if (!cnpj) return;
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  }

  const formatZipCode = (zipCode: string) => {
    if (!zipCode) return;
    return zipCode.replace(/^(\d{5})(\d{3})/, "$1-$2");
  }

  return (
    <>
      <Grid container md={12} rowSpacing={4}>
        <Grid item md={8}>
          <Typography color={colors.primary} marginBottom="8px">
            CNPJ
          </Typography>
          <Typography fontSize="1.25rem">{formatCnpj(data.cnpj)}</Typography>
        </Grid>
        <Grid item md={4}>
          <Typography color={colors.primary} marginBottom="8px">
            COUNTRY
          </Typography>
          <Typography fontSize="1.25rem">{data.country}</Typography>
        </Grid>
        <Grid item md={8}>
          <Typography color={colors.primary} marginBottom="8px">
            COMPANY NAME
          </Typography>
          <Typography fontSize="1.25rem">
            {formatTextLimit(data?.name, 50)}
          </Typography>
        </Grid>
        <Grid item md={4}>
          <Typography color={colors.primary} marginBottom="8px">
            CERTIFIED BY
          </Typography>
          <Typography fontSize="1.25rem">
            {formatTextLimit(data.certified_by, 47)}
          </Typography>
        </Grid>
        <Grid item md={8}>
          <Typography color={colors.primary} marginBottom="8px">
            ADDRESS
          </Typography>
          <Typography fontSize="1.25rem">
            {formatTextLimit(data.address, 47)}
          </Typography>
        </Grid>
        <Grid item md={4}>
          <Typography color={colors.primary}>
            CERTIFIED
          </Typography>
          <Tooltip title={enterpriseIsCertified ? 'Certified' : 'Not certified'} placement='right'>
            <Box width={40}>
              {isLoading ?
              <Typography color={colors.primary}>...</Typography>
              :
              enterpriseIsCertified ?
              <TbCertificate color='#00CA95' size={40}/>
              :
              <TbCertificateOff color='#F32053' size={40}/>
              }
            </Box>

          </Tooltip>
        </Grid>
        <Grid item md={8}>
          <Typography color={colors.primary} marginBottom="8px">
            COMPLEMENT
          </Typography>
          <Typography fontSize="1.25rem">{formatTextLimit(data?.complement, 55)}</Typography>
        </Grid>
        <Grid item md={4}>
          <Typography color={colors.primary} marginBottom="8px">
            ZIPCODE
          </Typography>
          <Typography fontSize="1.25rem">{formatZipCode(data.zipcode)}</Typography>
        </Grid>
        <Grid item md={8}>
          <Typography color={colors.primary} marginBottom="8px">
            STATE
          </Typography>
          <Typography fontSize="1.25rem">{data.state}</Typography>
        </Grid>
        <Grid item md={4}>
          <Typography color={colors.primary} marginBottom="8px">
            CITY
          </Typography>
          <Typography fontSize="1.25rem">
            {formatTextLimit(data?.city, 30)}
          </Typography>
        </Grid>
        <Grid item md={8}>
          <Typography color={colors.primary} marginBottom="8px">
            OWNER NAME
          </Typography>
          <Typography fontSize="1.25rem">
            {formatTextLimit(data?.owner_name, 50)}
          </Typography>
        </Grid>
        <Grid item md={4}>
          <Typography color={colors.primary} marginBottom="8px">
            JOB TITLE
          </Typography>
          <Typography fontSize="1.25rem">
            {formatTextLimit(data?.owner_job_title, 40)}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
