import { Box, Grid, OutlinedInput, Typography } from '@mui/material';

import { Header } from '../../components/header';
import { UnitManager } from '../../components/infoCards/UnitManager';
import { WalletList } from '../../components/lists/WalletList';
import { SideBar } from '../../components/sideBar';
import { UnitsList } from '../../components/lists/UnitsList';
import { TransactionService } from '../../services/transactions.service';
import { useEffect, useState } from 'react';
import { TableHistory } from '../TransactionHistory/table';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { colors } from '../../styles';
import { ICertificateOrg } from '../../types/certificateOrg';

interface IAssetTransactionDataRequest {
	amount: string;
	asset_id: string;
	asset_country: string;
	asset_state: string;
	asset_city: string;
	asset_name: string;
	asset_source: string;
	asset_unit_id: string;
	block: string;
	certificate_id: string;
	created_at: string;
	end_date: string;
	start_date: string;
	enterprise_id: string;
	enterprise_name: string;
	provider_name: string;
	holder_name: string;
	from: string;
	hash: string;
	id: string;
	rec_label: string;
	report: string;
	status: string;
	to: string;
	type: string;
	updated_at: string;
	user_id: string;
}

interface ITransactionTableData {
	id: string;
	date: string;
	sellerOrg: string;
	seller: string;
	buyer: string;
	opType: string;
	status: string;
	sourceType: string;
	orgName: string;
	unitID: number;
	country: string;
	state: string;
	city: string;
	startDate: string;
	endDate: string;
	period: string;
	uniqueId: string;
	amount: number;
}


export const AssetView = ({
	asset,
	handleAssetEditInfo,
	setSelectedAsset,
	setAssetInfo
}: any) => {
	// useEffect gettting the asset data by id
	const transactionService = new TransactionService();
	const [transactions, setTransactions] = useState<ICertificateOrg[]>();
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const [selectedPage, setSelectedPage] = useState<number>(1);
	const [selectedSize, setSelectedSize] = useState<number>(5);
	const [totalPages, setTotalPages] = useState<number>(1);

	useEffect(() => {
		const getTransactionsByAsset = async () => {
			const result = await transactionService.getTransactionsByAsset(asset.id, selectedPage, selectedSize);
			setTotalPages(Math.ceil(result[1]/selectedSize));
			const transfers: IAssetTransactionDataRequest[] = result[0];
			const respAllCertFormated: ICertificateOrg[] = transfers.map(
				(item: any) => ({
					id: item.id,
					beneficiaryId: item.to,
					isCheckbox: true,
					statusCheck: false,
					enterpriseId: item.enterprise_id,
					assetId: item.asset_id,
					assetSourceType: item.asset_source,
					recId: item.rec_id,
					assetName: item.asset_name,
					date: format(new Date(item.created_at), 'yyyy-MM-dd HH:mm'),
					holder: item.provider_name,
					beneficiary: item.holder_name,
					beneficiaryWallet: item.holder_wallet,
					from: item.from,
					to: item.to,
					status: item.rec_status,
					opType: item.operation_type,
					orgName: item.enterprise_name,
					powerUnit: item.asset_name,
					assetCountry: item.asset_country,
					assetState: item.asset_state,
					assetCity: item.asset_city,
					co2PerMWh: item.asset_co2_per_mwh,
					startOfOperation: item.asset_start_of_operation,
					certificate_id_range: item.certificate_id_range,
					period: format(new Date(item.start_date), 'yyyy-MMM'),
					startDate: new Date(item.start_date),
					endDate: new Date(item.end_date),
					assetUnitID: item.asset_unit_id,
					amountRec: Number(item.amount),
					recLabel: item.rec_label,
					recHash: item.rec_hash,
					blockHash: item.block_hash,
					amount: Number(item.amount),
					originCertificates: item.origin_certificates
				})
			);
			setTransactions(respAllCertFormated);
			setIsLoaded(true);
		};
		const values = getTransactionsByAsset();
	}, [asset, selectedPage]);

	const headCells: any = [
		{
			id: 'date',
			numeric: true,
			disablePadding: false,
			label: 'Date UTC-3'
		},
		{
			id: 'beneficiary',
			numeric: true,
			disablePadding: false,
			label: 'Provider'
		},
		{
			id: 'holder',
			numeric: true,
			disablePadding: false,
			label: 'Holder'
		},
		{
			id: 'opType',
			numeric: true,
			disablePadding: false,
			label: 'Op. Type'
		},
		{
			id: 'status',
			numeric: true,
			disablePadding: false,
			label: 'Status'
		},
		{
			id: 'orgName',
			numeric: true,
			disablePadding: false,
			label: 'Org Name'
		},
		{
			id: 'unitID',
			numeric: true,
			disablePadding: false,
			label: 'Power Unit ID'
		},
		{
			id: 'period',
			numeric: true,
			disablePadding: false,
			label: 'Period'
		},
		{
			id: 'uniqueId',
			numeric: true,
			disablePadding: false,
			label: 'Unique ID'
		},
		{
			id: 'amount',
			numeric: true,
			disablePadding: false,
			label: 'Amount'
		},
		{
			id: 'report',
			numeric: true,
			disablePadding: false,
			label: 'Report'
		}
	];

	return (
		<>
			<Header />
			<SideBar />
			<Box
				component="main"
				sx={{
					padding: '150px 24px 0 200px',
					height: '100vh',
					overflowX: 'hidden'
				}}
			>
				<Grid container md={12}>
					<Grid item md={7}>
						<UnitManager asset={asset} setAssetInfo={setAssetInfo} />
						<WalletList assetRecs={asset} />
					</Grid>
					<Grid item md={5}>
						<UnitsList
							handleAssetEditInfo={handleAssetEditInfo}
							setSelectedAsset={setSelectedAsset}
						/>
					</Grid>
				</Grid>
				<Box sx={{ marginTop: '30px'}}>
					<TableHistory
						title="Asset Transaction History"
						titles={headCells}
						transactions={transactions}
						setSelectedPage={setSelectedPage}
						setSelectedSize={setSelectedSize}
						totalPages={totalPages}
						isLoaded={isLoaded}
						sx={{
							border: `1px solid ${colors.primaryLight}`,
							borderRadius: '16px',
							overflow: 'hidden'
						}}
					/>
				</Box>
			</Box>
		</>
	);
};
