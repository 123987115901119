export const menuSeller = [
	{
		id: 1,
		title: 'Operations',
		link: '/rec/seller-certificates'
	},
	{
		id: 2,
		title: 'Orders',
		link: '/rec/sales-history'
	},
	{
		id: 3,
		title: 'Clients',
		link: '/rec/clients'
	}
];
