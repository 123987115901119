import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux'

import { useSnackbar } from 'notistack';

import { Box, Card, Tooltip, Typography } from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import { keyring } from "@polkadot/ui-keyring";
import { waitReady } from "@polkadot/wasm-crypto";

import Wallet from '../../../assets/imgs/walletBackground.png';
import { ProfileService } from '../../../services/profile.service';
import { AssetService } from '../../../services/asset.service';
import { walletAssetCreate } from '../../../stores/createWalletAsset/createWalletAsset.actions'
import { formatTextLimit } from '../../../utils/formatTextLenght';
import { mnemonicValidate } from "@polkadot/util-crypto";

export type TMnemonicSelectedAsset = {
  words: Array<object>;
  nameWallet: string;
  walletAddress: string;
  passwordInput: string;
  confirmPasswordInput: string;
  setValidatePassword?: any;
  recovery?: boolean;
};

export const MnemonicSelectedAsset = ({
  words,
  walletAddress,
  nameWallet,
  passwordInput,
  confirmPasswordInput,
  recovery,
  setValidatePassword
}: TMnemonicSelectedAsset) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const parametros = useParams();
  const profileService = new ProfileService();
  const assetService = new AssetService();
  const [loading, setLoading] = useState<boolean>(false);
  const [openAuthorization, setOpenAuthorization] = useState<boolean>(false);
  const [loadingAuthorization, setLoadingAuthorization] =
    useState<boolean>(false);
  const history = useNavigate();

  const handleCloseAuthoraztion = (value: boolean) => {
    setOpenAuthorization(value);
  };

  const onSubmit = () => {
    const createWallet = async () => {
      try {
      	await waitReady();
      	keyring.loadAll({ ss58Format: 42, type: "sr25519" });
      } catch (error) {
				console.log(error)
			} finally {
        const isValidMnemonic = mnemonicValidate(words.join(' '));
				if(!isValidMnemonic){
					enqueueSnackbar('Mnemonic is not valid.', {
						variant: 'info'
					});	
					return
				}
      	const {pair} = keyring.addUri(words.join(' '), passwordInput, { name: nameWallet });
      	onSubmitAuthorization();
      }
      // create wallet store
      dispatch(walletAssetCreate(true))
      history('/rec/assets')
    }
    createWallet();
  };

  const onSubmitAuthorization = async () => {
    if (!nameWallet) return;

    if (passwordInput !== confirmPasswordInput) {
      setValidatePassword(true);
      return;
    }
    setLoading(true);
    setLoadingAuthorization(true);
    if(parametros.id && nameWallet && walletAddress){
      const respCreateWallet = await assetService.createWallet(nameWallet, walletAddress, parametros.id);
      setValidatePassword(false);
      if (respCreateWallet.status === 200) {
        setLoading(false);
        setLoadingAuthorization(false);
        history('/rec/assets');
        enqueueSnackbar('Wallet has been created successfully', {
          variant: 'success'
        });
      }
      if ([400, 404, 303, 500].includes(respCreateWallet.status)) {
        setLoading(false);
        setLoadingAuthorization(false);
        enqueueSnackbar(respCreateWallet.statusText, {
          variant: 'error'
        });
      }
      handleCloseAuthoraztion(false);
    }
  };

  return (
    <>
      <Card
        sx={{
          backgroundColor: 'transparent'
          // opacity: '1'
        }}
      >
        <Box display="flex" alignItems="center" marginBottom="37px">
          <Box marginRight="23px">
            <img src={Wallet} alt="wallet" />
          </Box>
          <Box>
            <Typography
              marginBottom="12px"
              fontWeight={700}
              fontSize={'22px'}
              color={'#CAD5E8'}
            >
              {nameWallet}
            </Typography>
            <Tooltip title={walletAddress} placement='top'>
              <Typography fontSize="1.25rem" color="#CAD5E8" lineHeight="32px">
                {formatTextLimit(walletAddress, 40)}
              </Typography>

            </Tooltip>
          </Box>
        </Box>

        <Box>
          <Box
            sx={{
              display: 'flex',
              overflow: 'hidden',
              flexWrap: 'wrap',
              alignItems: `${!words.length ? 'center' : 'flex-start'}`,
              justifyContent: `${!words.length ? 'center' : 'auto'}`,
              padding: '28px 0px 2px 70px',
              border: '1px solid #CAD5E8',
              borderRadius: '16px',
              width: '613px',
              height: '254px'
            }}
          >
            {!words.length && (
              <Box display="flex">
                <Typography fontSize="36px" fontWeight="700" color="#a5aab1">
                  Mnemonic
                </Typography>
              </Box>
            )}
            {words.map((value: any) => (
              <Typography
                fontSize="1.75rem"
                paddingRight="32px"
                // textAlign="center"
                height="0px"
                fontWeight="700"
                lineHeight="40px"
                color="#CAD5E8"
              >
                {value}
              </Typography>
            ))}
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          marginRight="60px"
          marginTop="96px"
        >
          <LoadingButton
            variant="contained"
            onClick={onSubmit}
            loading={loading}
            disabled={!passwordInput || !confirmPasswordInput || !nameWallet}
            sx={{
              minWidth: '273px',
              padding: '8px 32px',
              borderRadius: '25px',
              boxShadow: 'none',
              background: `${nameWallet}` ? '#00CA95' : '#CAD5E8',
              '&:hover': {
                backgroundColor: `${nameWallet}` ? '#00CA95' : '#CAD5E8'
              }
            }}
          >
            <Typography
              lineHeight="32px"
              fontSize="1.5rem"
              fontWeight="700"
              color="#fff"
              sx={{
                textTransform: 'initial'
              }}
            >
              Create wallet now
            </Typography>
          </LoadingButton>
        </Box>
      </Card>
    </>
  );
};
