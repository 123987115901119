// Core
import React, { FC } from 'react';

import { Tooltip, Typography, TypographyProps } from '@mui/material';

export const TextTableBody: FC<TypographyProps> = ({
	sx,
	children,
	...props
}) => {
	return (
		<Tooltip placement="top-start" title={children}>
			<Typography
				{...props}
				sx={{
					width: '100px',
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					fontSize: '12px',
					fontWeight: '700',
					color: 'rgb(37, 39, 51, .8)',
					...sx
				}}
			>
				{children}
			</Typography>
		</Tooltip>
	);
};
