// Core
import React, { FC } from 'react';

import { Box, Button, ButtonProps, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { colors } from '../../styles';

export type TMyButton = {
	onClick?: (value: any) => void;
	onChange?: (value: any) => void;
};

export const ButtonAdd: FC<TMyButton> = ({ onClick, onChange }) => {
	return (
		<Box
			onClick={onClick}
			onChange={onChange}
			sx={{
				cursor: 'pointer',
				width: '50px',
				height: '51px',
				background: colors.primary,
				borderRadius: '50%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				'&:hover': {
					backgroundColor: '#4f5661'
				}
			}}
		>
			<AddIcon sx={{ color: '#fff', fontSize: '34px' }} />
		</Box>
	);
};
