import { LoginService } from '../../services/login.service';
const INITIAL_STATE: any[] = [];

interface IPayload {
	first_name: string;
	last_name: string;
	password: string;
	token: string;
}

interface IAction {
	type: string;
	payload: IPayload;
}

const loginService = new LoginService();

// Create functions
const authSignup = (
	first_name: string,
	last_name: string,
	password: string,
	token: string
) => {
	return loginService.signup({
		first_name,
		last_name,
		password,
		token
	});
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function (state = INITIAL_STATE, action: IAction) {
	switch (action.type) {
		case 'SIGNUP_USER':
			return authSignup(
				action.payload.first_name,
				action.payload.last_name,
				action.payload.password,
				action.payload.token
			);
		case 'SIGNUP_DEFAULT':
			return INITIAL_STATE;
		default:
			return state;
	}
}
