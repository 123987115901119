/* eslint-disable import/extensions */
import { Api } from './api/axios-config';

interface IResMember {
	status: number;
	statusText: string;
	data: Array<object>;
}

export type TMember = {
	email: string;
	role: string;
	enterprise_id: string;
};

export class MemberService {
	async listMember() {
		try {
			const resListMember: IResMember = await Api.get(`/users`);
			return resListMember;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async getMyInfo() {
		try {
			const getMyInfo: IResMember = await Api.get(`/users/my-info`);
			return getMyInfo;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async inviteMember(member: TMember) {
		try {
			const resInviteMember = await Api.post(`/enterprises/invite`, member);
			return resInviteMember;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}
}
