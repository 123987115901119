import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { goToTransactionBuyer } from '../../../stores/goToBuyerTransaction/goToBuyerTransaction.actions';
import { colors } from '../../../styles';
import { menuOwner } from '../ownerMenu';

interface IMenuOwner {
	id: number;
	title: string;
	link: string;
}

export const OwnerView = () => {
	const dispatch = useDispatch();
	const selectedMenu = parseInt(localStorage.getItem('menuClick') || '');
	const [selectedItem, setSelectedItem] = useState<any>(selectedMenu);

	const handleItemClick = (idMenu: number) => {
		localStorage.setItem('menuClick', idMenu.toString());
		setSelectedItem(idMenu);
	};
	
	useEffect(()=>{
		const cleaningSelectedBuyer = () => {
			dispatch(goToTransactionBuyer(false, ''))
		}
		cleaningSelectedBuyer()
	},[])

	useEffect(() => {
		setSelectedItem(selectedMenu);
	}, [selectedMenu]);

	return (
		<>
			{menuOwner &&
				menuOwner.map((value: IMenuOwner, index: number) => (
					<>
						<ListItemButton
							key={value.id}
							onClick={() => handleItemClick(value.id)}
							sx={{
								textAlign: 'center',
								borderBottom: '1px solid rgba(202,213,232,0.3)',
								color: colors.primaryLight,
								backgroundColor:
									selectedItem === value.id ? 'rgb(202, 213, 232, .3)' : null,
								transition: 'background-color 0.3s ease-in-out',
								a: {
									width: '100%',
								},
								display: 'flex',
								justifyContent: 'center',
								width: '100%',
								// height: '140px',
								padding: '0px',
								'&:hover': {
									backgroundColor: 'rgb(202, 213, 232, .2)'
								}
							}}
						>
							<Link to={value.link} style={{ textDecoration: 'none' }}>
								<Box
									sx={{
										padding: '42px 12px'
									}}
								>
									<ListItemText
										sx={{
											color: colors.neutralMedium,
											lineHeight: '32px',
											fontWeight: '700'
										}}
									>
										<Typography
											sx={{
												fontSize: selectedItem === value.id ? '1.5rem' : '1rem',
												transition: 'font-size 0.3s ease-in-out'
											}}
										>
											{value.title}
										</Typography>
									</ListItemText>
								</Box>
							</Link>
						</ListItemButton>
					</>
				))}
		</>
	);
};
