import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Grid, Typography, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { CertificatesService } from '../../services/certificates.service';
import { ICertificate } from '../../types/certificateTranfer';
import { renderPDF } from '../../certificateModel/model';
import { colors } from '../../styles';
import { ICertificateOrg } from '../../types/certificateOrg';

interface IRequest {
	certId: string
}

export const CheckCertificateHome = ({ certId }: IRequest) => {
	const certificatesService = new CertificatesService();
	const [pdfBase64, setPDFBase64] = useState<string>("");
	const [loaded, setLoaded] = useState<boolean>(false);
	const [failed, setFailed] = useState<boolean>(false);
	useEffect(() => {
		const checkCert = async () => {
			let respAllCertFormated = await certificatesService.checkCertificate(certId);
			setLoaded(true)
			if (respAllCertFormated.status === 200 && respAllCertFormated.data) {
				await renderPDF({
						id: respAllCertFormated.data.id,
						beneficiaryId: respAllCertFormated.data.to,
						createdAt: respAllCertFormated.data.created_at,
						provider: respAllCertFormated.data.provider_name,
						status: respAllCertFormated.data.operation_status,
						beneficiary: respAllCertFormated.data.holder_name,
						beneficiaryWallet: respAllCertFormated.data.holder_wallet,
						sourceType: respAllCertFormated.data.asset_source,
						orgName: respAllCertFormated.data.enterprise_name,
						powerUnit: respAllCertFormated.data.asset_name,
						country: respAllCertFormated.data.asset_country,
						state: respAllCertFormated.data.asset_state,
						city: respAllCertFormated.data.asset_city,
						period: respAllCertFormated.data.start_date,
						unitID: respAllCertFormated.data.asset_unit_id,
						amountRec: respAllCertFormated.data.amount,
						asset_name: respAllCertFormated.data.asset_name,
						operationType: respAllCertFormated.data.operation_type,
						recLabel:respAllCertFormated.data.rec_label,
						hash: respAllCertFormated.data.rec_hash,
						blockHash: respAllCertFormated.data.block_hash,
						startDate: respAllCertFormated.data.start_date,
						endDate: respAllCertFormated.data.end_date,
						origins: respAllCertFormated.data.origin_certificates,
						assetCo2PerMWh: respAllCertFormated.data.asset_co2_per_mwh,
						assetStartOfOperation: respAllCertFormated.data.asset_start_o_operation,
						certificateIdRange: respAllCertFormated.data.certificate_id_range,
						setPDFBase64: setPDFBase64,
						download: false
				})
				setLoaded(true);
			}else{
				setFailed(true)
			}
		};
		checkCert();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	
	return (
		<>
			<Grid container md={12} sx={{
				height: '100%',
				display:'flex',
				justifyContent: 'center', // Centralizar horizontalmente
				alignItems: 'center',
				}} marginBottom="20px">
				{loaded ?
					<>
						{!failed ?
							<>
								{pdfBase64 &&
									<>
										<Grid item md={8}>
											<Typography
												fontWeight="700"
												fontSize={{ xs: '1.2rem', md: '1.75rem' }} // Ajusta o tamanho da fonte em diferentes breakpoints
												lineHeight={{ xs: '30px', md: '90px' }} // Ajusta a altura da linha em diferentes breakpoints
												color={colors.primary}
												justifyContent="center"
												textAlign="center"
											>
												Certificate successfully validated! ✓
											</Typography>
										</Grid>
										<Grid item md={8} sx={{height: '85%'}} >
												<div style={{height: '100%'}}>
													<iframe
														title="PDF Preview"
														src={pdfBase64}
														width="100%"
														height="100%"
														/>
												</div>
										</Grid>
									</>
								}
							</>
							:
							<Grid item md={8}>
								<Typography
									fontWeight="700"
									fontSize="1.75rem"
									lineHeight="90px"
									color={colors.primary}
									marginBottom="9px"
									justifyContent={'center'}
									textAlign={'center'}
								>
									Certificate is not valid! ✘
								</Typography>
							</Grid>
						}
					</>
					:
					<Box sx={{
						display: 'flex',
						justifyContent: 'center', // Centralizar horizontalmente
						alignItems: 'center', // Centralizar verticalmente
						height: '100vh', // Ocupar a altura da viewport
					}}>
						<CircularProgress color="info" size={80}/>
					</Box>
				}
			</Grid>
		</>

	);
};
