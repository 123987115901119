/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useSnackbar } from 'notistack';

import { Box, Card, Grid, Typography } from '@mui/material';

import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../../../styles';
// import { RecoveryWallet } from '../recoveryWallet';
import { RecoveryWalletAsset } from '../recoveryWalletAsset';

const useStyles = makeStyles(theme => ({
	[theme.breakpoints.down(1690)]: {
		cardContainer: {
			maxWidth: '1200px'
		}
	}
}));

export const CardWalletAsset = (props: any) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const recovery = useSelector((state: any) => state.recovery);
	const [textHash, setTextHash] = useState<string>('');
	const [mnemonicPhrase, setMnemonicPhrase] = useState<string>('');

	useEffect(() => {
		setTextHash(props.walletAddress);
		setMnemonicPhrase(props.mnemonic)
	}, []);

	type TypeTitleCard = { title: string };
	const TitleCard: React.FC<TypeTitleCard> = ({ title }) => {
		return (
			<Typography
				color={colors.primaryDark}
				fontSize="2.25rem"
				fontWeight="700"
				lineHeight="48px"
			>
				{title}
			</Typography>
		);
	};

	return (
		<>
			<Grid container md={12}>
				<Grid
					item
					md={12}
					marginLeft="152px"
					marginTop="128px"
					width="100%"
					display="flex"
					justifyContent="center"
				>
					<Card sx={{ position: 'relative' }}>
						<Card sx={{ marginTop: '36px' }}>
							<Box
								className={classes.cardContainer}
								sx={{
									width: '1438px',
									minHeight: '738px',
									backgroundColor: 'rgba(202,213,232,0.3)',
									borderRadius: '32px',
									padding: '61px'
								}}
							></Box>
						</Card>
						<Card
							sx={{
								boxShadow: 'none',
								backgroundColor: 'transparent',
								position: 'absolute',
								top: '96px',
								left: '62px',
								right: '26px',
								bottom: '26px',
								zIndex: '1'
							}}
						>
							<Box
								display="flex"
								alignItems="center"
								justifyContent="space-between"
							>
								<TitleCard title="Asset Wallet Recovery" />
								<Box display="flex" alignItems="center">
									<Typography
										color={colors.primaryDark}
										fontSize="1.125rem"
										fontWeight="400"
										lineHeight="24px"
										marginRight="45px"
									>
										Recovering your Asset wallet
									</Typography>
								</Box>
							</Box>
							<>{<RecoveryWalletAsset recovery={recovery} wallet_address={props.walletAddress}/>}</>
						</Card>
					</Card>
				</Grid>
			</Grid>
		</>
	);
};
