import { MemberService } from '../../services/member.service';

const INITIAL_STATE: any[] = [];

interface IAction {
	type: string;
}

const listMemberService = new MemberService();

const listMember = async () => {
	const respListMember: any = await listMemberService.listMember();
	return respListMember;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function (state = INITIAL_STATE, action: IAction) {
	switch (action.type) {
		case 'LIST_MEMBER':
			return listMember();
		default:
			return state;
	}
}
