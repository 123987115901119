import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import leftArrowSymbol from '../../assets/icon/esquerda.png';
import rightArrowSymbol from '../../assets/icon/direita.png';
import { SmallThinChart } from '../../components/charts/thinChartSmall';
import { Example } from '../../components/charts/sampleChart';
import { GrExpand } from 'react-icons/gr';
import { GrContract } from 'react-icons/gr';
import { IEnergyBankData } from '../../types/dashboard';

interface EnergyBankProps {
    title: string;
    energyBankData: IEnergyBankData;
    totalMWh: string;
    selectedYear: string;
    selectedMonth: string;
    selectedDay: string;
    expandedChart: boolean;
    setExpandedChart: (value: boolean) => void;
    setSelectedYear: (value: string) => void;
    setSelectedMonth: (value: string) => void;
    setSelectedDay: (value: string) => void;
}

const monthOrder: Record<string, number> = {
    'Jan': 0,
    'Feb': 1,
    'Mar': 2,
    'Apr': 3,
    'May': 4,
    'Jun': 5,
    'Jul': 6,
    'Aug': 7,
    'Sep': 8,
    'Oct': 9,
    'Nov': 10,
    'Dec': 11
  };

const EnergyBankComponent: React.FC<EnergyBankProps> = ({
    title,
    energyBankData,
    totalMWh,
    selectedYear,
    selectedMonth,
    selectedDay,
    expandedChart,
    setExpandedChart,
    setSelectedYear,
    setSelectedMonth,
    setSelectedDay,
}): ReactElement => {

    const [showRightArrow, setShowRightArrow] = useState<boolean>(true);
	const [showLeftArrow, setShowLeftArrow] = useState<boolean>(true);
	const [isExpanded, setIsExpanded] = useState<boolean>(expandedChart);
	const [availableMonths, setAvailableMonths] = useState<string[]>([]);
	const [availableYears, setAvailableYears] = useState<string[]>([]);
    const [chartOption, setChartOption] = useState('year');
    const [currentDate, setCurrentDate] = useState(dayjs());
    const chartContainerRef = useRef(null);

    useEffect(()=>{
        const intervalId = setInterval(()=>{
            setCurrentDate(dayjs())
        },60000)

        return () => {
            clearInterval(intervalId);
          };
    },[])

    let colorYear = '#000000';
	let colorMonth = '#000000';
	let colorHour = '#000000';
	let colorDay = '#000000';

	let backgroundColorYear = '#FFFFFF';
	let backgroundColorMonth = '#FFFFFF';
	let backgroundColorHour = '#FFFFFF';
	let backgroundColorDay = '#FFFFFF';

	let borderColorYear = '#000000';
	let borderColorMonth = '#000000';
	let borderColorHour = '#000000';
	let borderColorDay = '#000000';

    const clearChartStyle = () => {
		colorYear = '#000000';
		colorMonth = '#000000';
		colorHour = '#000000';
		colorDay = '#000000';

		backgroundColorYear = '#FFFFFF';
		backgroundColorMonth = '#FFFFFF';
		backgroundColorHour = '#FFFFFF';
		backgroundColorDay = '#FFFFFF';

		borderColorYear = '#000000';
		borderColorMonth = '#000000';
		borderColorHour = '#000000';
		borderColorDay = '#000000';
	};
    if (chartOption === 'year') {
		clearChartStyle();
		colorYear = '#FFFFFF';

		backgroundColorYear = '#636C7A';

		borderColorYear = '#636C7A';
	}

	if (chartOption === 'month') {
		colorYear = '#000000';
		colorMonth = '#FFFFFF';

		backgroundColorYear = '#FFFFFF';
		backgroundColorMonth = '#636C7A';

		borderColorYear = '#000000';
		borderColorMonth = '#636C7A';
	}
	if (chartOption === 'day') {
		colorDay = '#FFFFFF';

		backgroundColorDay = '#636C7A';

		borderColorDay = '#636C7A';
	}
	if (chartOption === 'hour') {
		colorHour = '#FFFFFF';
		backgroundColorHour = '#636C7A';
		borderColorHour = '#636C7A';
	}

    const getAvailableYears = () =>{
        if(energyBankData && energyBankData.data && energyBankData.data.length > 0){
			const res = energyBankData.data.map((asset)=>{
				return asset.years.map((year) => {
                    return year.year || ''
                })
			})
            const result: string [] = [];
            res.forEach(subArray => {
                subArray.forEach(item => {
                    const existingItem = result.find(i => i === item);
                    if (!existingItem) {
                    result.push(item);
                    }
                });
            });
            setAvailableYears(result)
            return result;
		}
	}

	const getAvailableMonths = (selectedYear: string) => {
        const result: {month: string;total: number;} [] = [];
		if(energyBankData?.data && selectedYear){
			energyBankData.data.map((asset)=>{
                const res = asset.years.filter((value: {year: any, months: any})=>{
                    if(value.year == selectedYear && value.months){
                        return value.months
                    }
                })
                res.forEach(subArray => {
                    subArray.months.forEach(item => {
                        const existingItem = result.find(i => i.month === item.month);
                        if (!existingItem) {
                            result.push({month: item.month, total: item.total});
                        }
                    });
                });
                const monthsss = result.map((item) => {
                    return item.month
                })
                const sortedMonths = monthsss.sort((a, b) => monthOrder[a] - monthOrder[b]);
                setAvailableMonths(sortedMonths)
                return result;
			})
		}
	}

	useEffect(() => {
		setIsExpanded(expandedChart)
	}, [expandedChart]);

    useEffect(() => {
		getAvailableYears()
        getAvailableMonths(selectedYear)
	}, [energyBankData, selectedYear, selectedMonth]);

    useEffect(() => {
        if(chartOption == 'year'){
			setShowLeftArrow(false);
			setShowRightArrow(false);
		} else if(chartOption == 'month'){
			    //YEAR
				const previousYear = (Number(selectedYear) - 1).toString()
				const nextYear = (Number(selectedYear) + 1).toString()
				if (availableYears.includes(previousYear)){
					setShowLeftArrow(true)
				}else{
					setShowLeftArrow(false)
				}
				if (availableYears.includes(nextYear)){
					setShowRightArrow(true)
				}else{
					setShowRightArrow(false)
				}
		} else if(chartOption == 'day'){
			    //MONTH
				const getMonthIndex = availableMonths.indexOf(selectedMonth)
				if (getMonthIndex == 0){
					setShowRightArrow(true)
					setShowLeftArrow(false)
                    if (availableMonths.length == 1){
					    setShowRightArrow(false)
                    }
				}else if(getMonthIndex == availableMonths.length - 1){
                    setShowLeftArrow(true)
					setShowRightArrow(false)
                }else{
                    setShowLeftArrow(true)
					setShowRightArrow(true)
                }
			} else {
			    //DAY
				const dateObject = dayjs(`${selectedYear}-${selectedMonth}-${selectedDay}`);
				if (Number(selectedDay) >= dateObject.daysInMonth()){
					setShowRightArrow(false)
                    setShowLeftArrow(true)
				}
				else{
					setShowLeftArrow(true)
					if (Number(selectedDay) < 2){
                         setShowRightArrow(true)
                         setShowLeftArrow(false)
					}
					else{
						setShowRightArrow(true)
					}
				}
			}
    }, [chartOption, selectedYear, selectedMonth, selectedDay]);

  return (
    <Box
        sx={{
            height: innerHeight > 800 ? innerHeight*0.615 : '490px',
            marginTop: isExpanded? '3%': '5%',
            backgroundColor: '#FFFFFF',
            border: '1.38408px solid #D4D7DE',
            borderRadius: '44px',
            overflow: 'hidden'
        }}
    >
        <Grid container md={12}>
            <Grid item md={energyBankData?.data ? 6: 8}>
                <Box sx={{ marginTop: '5%', marginLeft: '10.5%' }}>
                    <Typography
                        fontFamily="Sen"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize={title.length > 18 ? "25.218px": "33.218px"}
                        lineHeight="44px"
                        color="#6F7075"
                    >
                        {title}
                    </Typography>
                </Box>
            </Grid>
            {energyBankData?.data &&
                <Grid item md={2}>
                    <Box sx={{height: '100%', display: 'flex'}}>
                        <Typography
                            fontFamily="Sen"
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="22.218px"
                            color="#6F7075"
                            marginY="auto"
                            marginLeft="auto"
                            marginTop='20%'
                        >
                            {chartOption == 'year' ? '':
                            chartOption == 'month' ? '' :
                            chartOption == 'day' ? `${selectedYear}`:`${selectedMonth} ${selectedYear}`}
                        </Typography>
                    </Box>
                </Grid>
            }
            <Grid item md={3}>
                <Box sx={{height: '100%', display: 'flex'}}>
                    {energyBankData?.date &&
                        <Typography
                            fontFamily="Sen"
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="12.218px"
                            color="#6F7075"
                            marginY="auto"
                            marginLeft="auto"
                        >
                            {currentDate.diff(energyBankData.date,'minutes') < 60 ?
                                `Refreshed ${currentDate.diff(energyBankData.date,'minutes')} min ago`
                            :
                                currentDate.diff(energyBankData.date,'hours') < 8 ?
                                    `Refreshed ${currentDate.diff(energyBankData.date,'hours')} hours ago`
                                :
									`Refreshed on ${dayjs(energyBankData.date).format('MMM DD, HH:mm')}`
                            }
                        </Typography>
                    }
                </Box>
            </Grid>
            <Grid item md={1}>
                <Box
                    sx={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        // paddingRight: '50%',
                        // marginLeft: '50%',
                        ':hover': { cursor: 'pointer' }
                    }}
                    onClick={() => {
                        if (isExpanded) {
                            setExpandedChart(false);
                        }else{
                            setExpandedChart(true);
                        }
                    }}
                >
                    {isExpanded ? <GrContract size={15} />: <GrExpand size={15}  />}
                </Box>
            </Grid>
        </Grid>
        {energyBankData?.data &&
            <Grid container md={12}>
                <Grid item md={8}>
                    <Box sx={{marginLeft: '8%' }}>
                        <Typography
                            fontFamily="Sen"
                            fontStyle="normal"
                            fontWeight="400"
                            fontSize={title.length > 18 ? "22.218px": "27.6817px"}
                            lineHeight="44px"
                            color="#000000"
                        >
                            Total Years: {(Number(totalMWh)/1000000).toLocaleString('en-US', {
                            minimumFractionDigits: 0
                        })}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={4} sx={{ marginTop: '8px' }}>
                    <Grid container md={12}>
                        <Grid item md={1}>
                            <Box
                                sx={{
                                    ':hover': {
                                        cursor: 'pointer'
                                    }
                                }}
                                onClick={()=>{
                                    if (chartOption === 'month') {
                                        const previousYear = (Number(selectedYear) - 1).toString()
                                        if (availableYears.includes(previousYear)){
                                            setSelectedYear(previousYear)
                                        }
                                    }
                                    if (chartOption === 'day') {
                                        const getMonthIndex = availableMonths.indexOf(selectedMonth)
                                        if (getMonthIndex > 0){
                                            setSelectedMonth(availableMonths[getMonthIndex-1])
                                        }
                                    }
                                    if (chartOption === 'hour') {
                                        const selectedDayNuber = Number(selectedDay);
                                        if (selectedDayNuber > 1){
                                            if (selectedDayNuber -1 < 10) {
                                                setSelectedDay(`0${selectedDayNuber -1}`)
                                            }else{
                                                setSelectedDay(`${selectedDayNuber -1}`)
                                            }
                                        }
                                    }
                                }}
                            >
                                {showLeftArrow && chartOption != 'year' &&
                                    <img src={leftArrowSymbol} alt="<" />
                                }
                            </Box>
                        </Grid>
                        <Grid item md={9} sx={{ transform: 'translateY(-8px)' }}>
                            <Typography
                                fontFamily="Sen"
                                fontStyle="normal"
                                fontWeight="700"
                                fontSize="27.6817px"
                                lineHeight="34px"
                                height="100%"
                                textAlign={'center'}
                                color="#000000"
                                sx={{
                                    transition: 'width 0.3s ease, margin 0.3s ease',
                                    '@media screen and (max-width: 1440px)': {
                                        fontSize: '18px'
                                    }
                                }}
                            >
                                {chartOption === 'year'
                                    ? 'Last years'
                                    : chartOption === 'day'
                                    ? `${selectedMonth}`
                                    : chartOption === 'hour'
                                    ? `Day ${selectedDay}`
                                    : `${selectedYear}`}
                            </Typography>
                        </Grid>
                        <Grid item md={1}>
                            <Box
                                sx={{
                                    ':hover': {
                                        cursor: 'pointer'
                                    }
                                }}
                                onClick={()=>{
                                    if (chartOption === 'month') {
                                        const nextYear = (Number(selectedYear) + 1).toString()
                                        if (availableYears.includes(nextYear)){
                                            setSelectedYear(nextYear)
                                        }
                                    }
                                    if (chartOption === 'day') {
                                        const getMonthIndex = availableMonths.indexOf(selectedMonth)
                                        if (getMonthIndex < availableMonths.length - 1){
                                            setSelectedMonth(availableMonths[getMonthIndex+1])
                                        }
                                    }
                                    if (chartOption === 'hour' && selectedDay !== null) {
                                        const dateObject = dayjs(`${selectedYear}-${selectedMonth}-${selectedDay}`);
                                        if (Number(selectedDay) < dateObject.daysInMonth()){
                                            if ((Number(selectedDay)+1) < 10) {
                                                setSelectedDay(`0${(Number(selectedDay)+1)}`)
                                            }else{
                                                setSelectedDay(`${(Number(selectedDay)+1)}`)
                                            }
                                        }
                                    }
                                }}
                            >
                                {showRightArrow && chartOption != 'year' &&
                                    <img src={rightArrowSymbol} alt=">" />
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        }
        <Grid container md={12}>
            <Grid item md={11} ref={chartContainerRef} display={'flex'}>
            {energyBankData?.data ?
                chartOption === 'hour' || chartOption == 'day' ? (
                    <SmallThinChart
                        energyBank={energyBankData}
                        chartOption={chartOption}
                        selectedYear={selectedYear}
                        selectedMonth={selectedMonth}
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        setChartOption={setChartOption}
                        chartContainerRef={chartContainerRef}
                        expandedChart={isExpanded}
                    />
                ) : (
                    <Example
                        energyBank={energyBankData}
                        availableMonths={availableMonths}
                        chartOption={chartOption}
                        setSelectedYear={setSelectedYear} 
                        setSelectedMonth={setSelectedMonth}
                        selectedYear={selectedYear}
                        setChartOption={setChartOption}
                        chartContainerRef={chartContainerRef}
                        expandedChart={isExpanded}
                    />
                )
                :
                (
                    <Box
                    textAlign='center'
                    display='flex'
                    height='500px'
                    width='100%'
                    sx={{ marginLeft: '10%'}}
                    alignItems='center'
                >
                    <Typography
                        fontFamily="Sen"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="20px"
                        lineHeight="44px"
                        color="#6F7075"
                        width='100%'
                        >
                        No MWh available
                    </Typography>
                </Box>
                )
            }
            </Grid>
        </Grid>
        
        <Box
            sx={{
                marginTop: expandedChart ? '3%' : '8%',
                marginX: '10%',
                display: 'flex',
                '@media screen and (max-width: 1440px)': {
                    marginTop: '0px',
                }
            }}
        >
            {energyBankData?.data &&
                <Grid container md={12} sx={{ justifyContent: 'space-between' }}>
                    <Grid item md={3} sx={{
                            display: 'flex',
                            justifyContent: 'center',
                    }}>
                        <Box
                            sx={{
                                border: `1.38408px solid ${borderColorYear}`,
                                backgroundColor: backgroundColorYear,
                                borderRadius: '31.8339px',
                                width: '106.29px',
                                height: '38.75px',
                                textAlign:'center',
                                padding: 'auto',
                                ':hover': {
                                    cursor: 'pointer'
                                },
                                transition: 'width 0.3s ease, margin 0.3s ease',
                                '@media screen and (max-width: 1440px)': {
                                    marginTop: '6px',
                                    width: '65px',
                                    height: '28.75px',
                                }
                            }}
                            onClick={() => {
                                clearChartStyle();
                                setChartOption('year');
                            }}
                        >
                            <Typography
                                fontFamily="Sen"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="16.60px"
                                lineHeight="38px"
                                color={colorYear}
                                sx={{
                                    '@media screen and (max-width: 1440px)': {
                                        fontSize: '14px',
                                        marginTop: '-5px',
                                    }
                                }}
                            >
                                YEAR
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={3} sx={{
                            display: 'flex',
                            justifyContent: 'center',
                    }}>
                        <Box
                            sx={{
                                border: `1.38408px solid ${borderColorMonth}`,
                                borderRadius: '31.8339px',
                                width: '106.29px',
                                height: '38.75px',
                                textAlign:'center',
                                padding: 'auto',
                                backgroundColor: backgroundColorMonth,
                                ':hover': {
                                    cursor: 'pointer'
                                },
                                transition: 'width 0.3s ease, margin 0.3s ease',
                                '@media screen and (max-width: 1440px)': {
                                    marginTop: '6px',
                                    width: '65px',
                                    height: '28.75px',
                                }
                            }}
                            onClick={() => {
                                clearChartStyle();
                                setChartOption('month');
                            }}
                        >
                            <Typography
                                fontFamily="Sen"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="16.60px"
                                lineHeight="38px"
                                color={colorMonth}
                                sx={{
                                    '@media screen and (max-width: 1440px)': {
                                        fontSize: '14px',
                                        marginTop: '-5px',
                                    }
                                }}
                            >
                                MONTH
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={3} sx={{
                            display: 'flex',
                            justifyContent: 'center',
                    }}>
                        <Box
                            sx={{
                                border: `1.38408px solid ${borderColorDay}`,
                                borderRadius: '31.8339px',
                                width: '106.29px',
                                height: '38.75px',
                                textAlign:'center',
                                padding: 'auto',
                                backgroundColor: backgroundColorDay,
                                ':hover': {
                                    cursor: 'pointer'
                                },
                                transition: 'width 0.3s ease, margin 0.3s ease',
                                '@media screen and (max-width: 1440px)': {
                                    marginTop: '6px',
                                    width: '65px',
                                    height: '28.75px',
                                }
                            }}
                            onClick={() => {
                                clearChartStyle();
                                setChartOption('day');
                                if(availableMonths.indexOf(selectedMonth) == -1){
                                    setSelectedMonth(availableMonths[0])
                                }
                            }}
                        >
                            <Typography
                                fontFamily="Sen"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="16.60px"
                                lineHeight="38px"
                                color={colorDay}
                                sx={{
                                    '@media screen and (max-width: 1440px)': {
                                        fontSize: '14px',
                                        marginTop: '-5px',
                                    }
                                }}
                            >
                                DAY
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={3} sx={{
                            display: 'flex',
                            justifyContent: 'center',
                    }}>
                        <Box
                            sx={{
                                border: `1.38408px solid ${borderColorHour}`,
                                borderRadius: '31.8339px',
                                width: '106.29px',
                                height: '38.75px',
                                textAlign:'center',
                                padding: 'auto',
                                backgroundColor: backgroundColorHour,
                                ':hover': {
                                    cursor: 'pointer'
                                },
                                transition: 'width 0.3s ease, margin 0.3s ease',
                                '@media screen and (max-width: 1440px)': {
                                    marginTop: '6px',
                                    width: '65px',
                                    height: '28.75px',
                                }
                            }}
                            onClick={() => {
                                clearChartStyle();
                                setChartOption('hour');
                                if(availableMonths.indexOf(selectedMonth) == -1){
                                    setSelectedMonth(availableMonths[0])
                                }
                            }}
                        >
                            <Typography
                                fontFamily="Sen"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="16.60px"
                                lineHeight="38px"
                                color={colorHour}
                                sx={{
                                    '@media screen and (max-width: 1440px)': {
                                        fontSize: '14px',
                                        marginTop: '-5px',
                                    }
                                }}
                            >
                                HOUR
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            }
        </Box>
    </Box>
    
  );
};

export default EnergyBankComponent;