import React from 'react';

import { Box, Card, Grid } from '@mui/material';

import Girl from '../../assets/imgs/girlSmall.png';
import RocketLogin from '../../assets/imgs/imageRocket.png';
import SloganMulti from '../../assets/imgs/sloganMulti.png';
// eslint-disable-next-line import/extensions
import { colors } from '../../styles/colors';
// eslint-disable-next-line import/extensions
import { CardSignin } from './cardSignin';

export const Signin = () => {
	return (
		<Grid container md={12} sm={12} position="relative">
			<Grid
				item
				md={6}
				sm={6}
				xs={6}
				sx={{
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					height: '100vh',
					filter: 'grayscale(.1%)',
					backgroundColor: colors.neutralLight
				}}
			>
				<Card
					sx={{
						marginTop: '152px',
						marginLeft: '100px',
						alignSelf: 'flex-start'
					}}
				>
					<Box>
						<img src={SloganMulti} alt="sloganMultiedgers"></img>
					</Box>
				</Card>
				<Box
					sx={{
						background: `${colors.neutralLight} url(${RocketLogin}) no-repeat left top fixed`,
						width: '100%',
						height: '100%',
						position: 'absolute',
						backgroundSize: 'contain',
						opacity: '.6',
						backgroundBlendMode: 'luminosity',
						zIndex: -1
					}}
				></Box>
				<Box
					sx={{
						position: 'absolute',
						top: '30px',
						right: '164px',
						filter: 'grayscale(100%)',
						zIndex: 10,
						opacity: '.3'
					}}
				>
					<img src={Girl} alt="girl"></img>
				</Box>
				<Card
					sx={{
						marginTop: '90px',
						marginLeft: '100px',
						alignSelf: 'flex-start'
					}}
				></Card>
				<Card
					sx={{
						boxShadow: '0px 4px 34px gray',
						position: 'absolute',
						right: '-274px',
						zIndex: 2,
						borderRadius: '40px',
						width: '539px',
						maxHeight: '741px',
						height: '100%'
					}}
				>
					<CardSignin />
				</Card>
			</Grid>
			<Grid
				item
				md={6}
				sm={6}
				xs={6}
				sx={{
					background: colors.primary,
					zIndex: -1,
					display: 'flex',
					justifyContent: 'flex-end'
				}}
			>
				<Box
					sx={{
						background: `${colors.primary} url(${RocketLogin}) no-repeat 50px bottom fixed`,
						width: '100%',
						height: '100%',
						position: 'absolute',
						bottom: '0px',
						opacity: '.3',
						backgroundBlendMode: 'luminosity',
						zIndex: -1
					}}
				></Box>
			</Grid>
		</Grid>
	);
};
