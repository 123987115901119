import { Box, Grid, Skeleton, Typography } from '@mui/material';

import { BoxCounter } from '../../components/boxCounter';
import { EnterpriseInfo } from '../../components/enterpriseInfo';
import { Header } from '../../components/header';
import { SideBar } from '../../components/sideBar';
import enterpriseExampleLogo from '../../assets/imgs/enterpriseDefaultLogo2.png';
import { useEffect, useRef, useState } from 'react';
import { EnterpriseService } from '../../services/enterprise.service';
import { AssetService } from '../../services/asset.service';
import { ClientService } from '../../services/client.service';
import { CircularProgress } from 'material-ui-core';
import PieGraph from './pieGraph';
import { VerticalBarChart } from './verticalBarGraph';
import EnergyBankComponent from './energyBankComponent';
import { IEnergyBankData } from '../../types/dashboard';
import localforage from 'localforage';
import dayjs from 'dayjs';
import { BackOfficeService } from '../../services/backoffice.service';

interface IEnterpriseData {
	name: string;
	cnpj: string;
	total_recs: string;
	total_mwh: string;
	retired_recs: string;
	issued_recs: string;
	logo: string;
}

interface IDashboard {
	role: string;
}

export const Dashboard = ({role}: IDashboard) => {

	const enterpriseService = new EnterpriseService();
	const assetService = new AssetService();
	const [energyBankData, setEnergyBankData] = useState<IEnergyBankData>({} as IEnergyBankData);
	const [selectedYear, setSelectedYear] = useState(dayjs().year().toString());
	const [selectedMonth, setSelectedMonth] = useState('Jan');
	const [selectedDay, setSelectedDay] = useState('1');
	const [pieData, setPieData] = useState();
	const [verticalBarChart, setVerticalBarChart] = useState();
	const [expandedChart, setExpandedChart] = useState(false);
	const [getNewEnergyBank, setGetNewEnergyBank] = useState(false);
	const [loadedEnterprise, setLoadedEnterprise] = useState(false);
	const [enterprise, setEnterprise] = useState<IEnterpriseData>();
	const [innerHeight, setInnerHeight] = useState(850);
	const chartContainerRef = useRef(null);

	useEffect(() => {
		async function getLocal() {
			try {
				const valor = await localforage.getItem('enterpriseEnergyBankData');
				if (valor === null) {
					setGetNewEnergyBank(true);
					setEnergyBankData({} as IEnergyBankData);
				} else {
					if (valor) {
						const res = valor as IEnergyBankData;
						const expiredData = dayjs(res.expirationDate)
						if (dayjs().isAfter(expiredData)) {
							setGetNewEnergyBank(true);
						}
						setEnergyBankData(valor as IEnergyBankData);
					}
				}
				return valor;
			} catch (error) {
				setGetNewEnergyBank(true);
				return {} as IEnergyBankData;
			}
		}
	
		getLocal();
	}, []);
	useEffect(() => {
        function handleResize() {
            setInnerHeight(window.innerHeight * 0.7);
        }

        handleResize();

        window.addEventListener('resize', handleResize); 

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

	const getListMemberEnte = async () => {
		const getEnterprise: any = await enterpriseService.listEnterprise();
		if (getEnterprise.status === 200) {
			localStorage.removeItem('enterpriseUrl');
			localStorage.setItem('enterpriseUrl', getEnterprise.data[0].logo);
			setEnterprise({
				name: getEnterprise.data[0].name,
				cnpj: getEnterprise.data[0].cnpj,
				total_recs: getEnterprise.data[0].total_recs,
				retired_recs: getEnterprise.data[0].retired_recs,
				issued_recs: getEnterprise.data[0].issued_recs,
				total_mwh: getEnterprise.data[0].total_mwh,
				logo: getEnterprise.data[0].logo
			});
			setLoadedEnterprise(true)
			
		}
	};

	const getAssetRecs = async () => {
		const getAllAssets: any = await assetService.listAsset();
		if (getAllAssets.status === 200 && getAllAssets.data.length > 0) {
			const res = getAllAssets.data.map((asset: any) => {
				return {name: asset.name , value: Number(asset.total_mwh)}
			})
			const sortedData = res.sort((a: any, b: any) => b.value - a.value);
			const totalValue = sortedData.reduce((total: any, obj: any) => total + obj.value, 0);
			const dataWithPercentage = sortedData.map((obj: { name: any; value: number; }) => ({
				name: obj.name,
				value: parseFloat(((obj.value / totalValue) * 100).toFixed(2)),
			  }));
			setPieData(dataWithPercentage);
		}
	};

	const getClients = async () => {
		const clientService = new ClientService();
		const getAllClients: any = await clientService.listClients();
		if (getAllClients.status === 200) {
			const res = getAllClients.data.map((client: any) => {
				return {beneficiary: client.beneficiary , total_recs: Number(client.retired_recs)}
			})
			const sortedData = res.sort((a: any, b: any) => b.total_recs - a.total_recs);
			const totalValue = sortedData.reduce((total: any, obj: any) => total + obj.total_recs, 0);
			const dataWithPercentage = sortedData.map((obj: { beneficiary: any; total_recs: number; }) => ({
				beneficiary: obj.beneficiary,
				total_recs: parseFloat(((obj.total_recs / totalValue) * 100).toFixed(2)),
			  }));
			setVerticalBarChart(sortedData);
		}
	};

	useEffect(() => {
		  const getEnergyBank = async () =>  {

			if(enterprise?.cnpj){
				const backofficeService = new BackOfficeService();
				const response = await backofficeService.getEnterprisEnergyBank(enterprise?.cnpj);
				if(response.status == 200 && response?.data?.data?.length > 0 ){
					const energyBank: IEnergyBankData = response.data;
					setEnergyBankData(energyBank)
					localforage.setItem('enterpriseEnergyBankData', energyBank)
					.then()
					.catch(error => {
						console.error('Error: ', error);
					});
					await enterpriseService.updateEnterpriseTotalMWh({
						totalMWh: energyBank.total
					});
					energyBank.data.map(async (some)=>{
						await assetService.updateTotalMWh(some.asset, some.total);
					})
				}else{
					if(response?.data?.date && response?.data?.expirationDate){
						setEnergyBankData({
						date: response.data.date,
						expirationDate: response.data.expirationDate
						} as IEnergyBankData);
					}
				}
			}
		};
		if (getNewEnergyBank){
			getEnergyBank();
		}
	}, [getNewEnergyBank, enterprise]);
	  
	useEffect(() => {
		getListMemberEnte();
		getAssetRecs();
		getClients();
	}, []);


	return (
		<>
			<Header />
			<SideBar />
			<Box
				component="main"
				sx={{
					padding: '8% 24px 0px 200px',
					backgroundColor: '#F5F5F5',
					height: '100vh',
					width: '100%',
					overflow: 'hidden'
				}}
			>
				<Grid container md={12} sx={{height: innerHeight < 200 ? '100px' : 'auto',}}>
					<Grid item sm={12} xs={12} md={5}>
						<Grid container md={12} justifyContent="space-between">
							<Grid item md={2}>
								<Box
									sx={{
										height: 'auto',
										width: '100%',
										maxWidth: '96px',
										// border: '0.782407px solid #D4D7DE',
										// borderRadius: '12.5185px'
									}}
								>
									{loadedEnterprise ?
										<img
											src={enterprise?.logo ? enterprise?.logo : enterpriseExampleLogo }
											alt={'Enterprise'}
											style={{
												height: 'auto',
												width: '100%',
												maxWidth: '96px',
												// borderRadius: '12.5185px'
											}}
										/>
									:
									(
										<CircularProgress />
									)
									}
								</Box>
							</Grid>
							<Grid item md={9.8}>
								{enterprise?.cnpj ?
									<EnterpriseInfo
										enterprise={enterprise?.name || ''}
										cnpj={
											enterprise?.cnpj.replace(
												/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
												'$1.$2.$3/$4-$5'
											) || ''
										}
									/>
									:
									<EnterpriseInfo
									enterprise={enterprise?.name || ''}
									cnpj={'No CNPJ was registered yet'}
								/>
								}
							</Grid>
						</Grid>
					</Grid>
					<Grid item sm={12} xs={12} md={7}sx={{height: 'auto'}} >
						<Grid container md={12}  xs={12} rowSpacing={1} sx={{ height: 'auto',justifyContent: 'space-around' }}>
							<Grid item md={3.8} xs={12} sx={{height: 'auto'}}>
								<BoxCounter
									// refreshDate={refreshDate}
									title="Total Energy Bank"
									value={(Number(enterprise?.total_mwh)/1000000).toLocaleString('en-US', {
										minimumFractionDigits: 0
									}) || '0'}
									unit="MWh"
								/>
							</Grid>
							<Grid item md={3.8} xs={12}>
								<BoxCounter
									title="Total Issued REC's"
									value={Number(enterprise?.issued_recs).toLocaleString('en-US', {
										minimumFractionDigits: 0
									}) || '0'}
									unit="REC"
								/>
							</Grid>
							<Grid item md={3.8} xs={12}>
								<BoxCounter
									title="Total Retired REC's"
									value={Number(enterprise?.retired_recs).toLocaleString('en-US', {
										minimumFractionDigits: 0
									}) || '0'}
									unit="REC"
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Box style={{ overflow: 'auto'}}>
					<Box display="flex" width={expandedChart ? "170%" : "120%"} height={innerHeight}>
						<Grid md={12} container columnGap={2}>
							<Grid item md={expandedChart ? 6.8 : 5.3}>
								{enterprise ? (
									<EnergyBankComponent
										title="Energy Bank"
										energyBankData={energyBankData}
										expandedChart={expandedChart}
										totalMWh={enterprise.total_mwh}
										selectedYear={selectedYear}
										selectedMonth={selectedMonth}
										selectedDay={selectedDay}
										setExpandedChart={setExpandedChart}
										setSelectedYear={setSelectedYear}
										setSelectedMonth={setSelectedMonth}
										setSelectedDay={setSelectedDay}
									/>
									)
									:
									<Skeleton variant='rectangular' sx={{height: '625px',
										width:'900px',
										marginTop: '5%',
										borderRadius: '44px',
										overflow: 'hidden'}}>
									</Skeleton>
								}
							</Grid>
							<Grid item md={expandedChart ? 2.5 : 3.4}>
								{enterprise ? (
									<Box
										sx={{
											height: innerHeight > 600 ? '88%' : '88%',
											marginTop: '8%',
											backgroundColor: '#FFFFFF',
											border: '1.38408px solid #D4D7DE',
											borderRadius: '44.2907px'
										}}
									>
										<Box sx={{ marginTop: '6%', marginLeft: '10%' }}>
											<Typography
												fontFamily="Sen"
												fontStyle="normal"
												fontWeight="700"
												fontSize="33.218px"
												lineHeight="44px"
												color="#6F7075"
											>
												Power Units RECs
											</Typography>
										</Box>
										<PieGraph pieData={pieData} />
									</Box>
								)
								:
								<Skeleton variant='rectangular' sx={{height: '625px',
									width:'580px',
									marginTop: '7%',
									borderRadius: '44px',
									overflow: 'hidden'}}>
								</Skeleton>
								}
								
							</Grid>
							<Grid item md={expandedChart ? 2.5 : 3}>
							{enterprise ? (
								<Box
									sx={{
										height: innerHeight > 600 ? '88%' : '88%',
										width: '100%',
										marginTop: '8%',
										backgroundColor: '#FFFFFF',
										border: '1.38408px solid #D4D7DE',
										borderRadius: '44.2907px'
									}}
								>
									<Box sx={{ marginTop: '6%', marginLeft: '10%' }}>
										<Typography
											fontFamily="Sen"
											fontStyle="normal"
											fontWeight="700"
											fontSize="33.218px"
											lineHeight="44px"
											color="#6F7075"
										>
											Top Clients
										</Typography>
									</Box>
									{verticalBarChart &&
										<VerticalBarChart data={verticalBarChart}/>
									}
								</Box>
							)
							:
							<Skeleton variant='rectangular' sx={{height: '625px',
								width:'580px',
								marginTop: '7%',
								borderRadius: '44px',
								overflow: 'hidden'}}>
							</Skeleton>
							}
								
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Box>
		</>
	);
};
