import * as React from 'react';

import Box from '@mui/material/Box';

import { Header } from '../../components/header';
import { SideBar } from '../../components/sideBar';
import { ContentHome } from './contentHome';

export const Home = () => {
	return (
		<Box sx={{ display: 'flex' }}>
			<Header />
			<SideBar />
			<Box
				sx={{
					position: 'fixed',
					flexGrow: 1,
					bgcolor: 'background.default',
					p: 3,
					display: 'flex',
					marginTop: '210px',
					marginLeft: '287px',
					height: '100vh'
				}}
			>
				<ContentHome />
			</Box>
		</Box>
	);
};
