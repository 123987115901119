/* eslint-disable import/extensions */
import { Api } from './api/axios-config';

interface IUser {
	first_name: string;
	last_name: string;
	password: string;
	token: string;
}
export class RecoveryService {
	async forgotPassword(email: string) {
		try {
			const resForgotPassword = await Api.post(`/password/forgot/`, {
				email
			});
			return resForgotPassword;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async resetPassword(token: string, password: string) {
		try {
			const resResetPassword = await Api.post(`/password/reset/`, {
				token,
				password
			});
			return resResetPassword;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}
}
