import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { EnterpriseService } from '../../services/enterprise.service';
import sideBarMultiedger from '../../assets/imgs/logoSidebar.svg';
import { ProfileUsers } from '../../profile-users';
import { colors } from '../../styles';
import { OwnerView } from './ownerView';
import { SellerView } from './sellerView';
import { ClientView } from './clientView';

const drawerWidth = 153;

// src/types.ts


export const SideBar = () => {
	const enterpriseService = new EnterpriseService();
	const userProfileRedux = useSelector((state: any) => state.myReducer.myVariable);
	const enterpriseUrl = localStorage.getItem('enterpriseUrl');
	const enterpriseLogoStorage = localStorage.getItem('enterpriseUrl') || ''
	const [enterpriseLogo, setEnterpriseLogo] = useState<null | string>(enterpriseLogoStorage);
	const [isLogoLoaded, setIsLogoLoaded] = useState(false);

	useEffect(() => {
		const getListUserInfo = async () => {
			if(!enterpriseLogoStorage.includes('http')){
				const getEnterprise: any = await enterpriseService.listEnterprise();
				if (getEnterprise.status === 200 && getEnterprise.data.logo) {
					localStorage.setItem('enterprise_avatar_url',getEnterprise.data[0].logo)
					location.reload()
				}
			}
		};
		if (!enterpriseLogoStorage) {
			if(enterpriseLogo && enterpriseLogo.includes('http')){
				setEnterpriseLogo(enterpriseLogoStorage)
			}
			setIsLogoLoaded(true)
			getListUserInfo();
		  }

	}, [enterpriseLogoStorage]);

	return (
		<>
		{
			<Drawer
			sx={{
					borderRadius: 'none',
					paddingRight: '0px',
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						border: 'none',
						backgroundColor: colors.primary,
						width: drawerWidth,
						boxSizing: 'border-box'
					}
				}}
				variant="permanent"
				anchor="left"
			>
				<List sx={{ paddingTop: '0px', overflowY: 'hidden' }}>
					<ListItem
						sx={{
							flexDirection: 'column'
						}}
						disablePadding
					>
						<ListItemButton
							sx={{
								padding: '0',
								borderBottom: '1px solid rgba(202,213,232,0.3)',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								textAlign: 'center',
								width: '100%'
							}}
						>
							<Box
								sx={{
									width: '100px',
									padding: '36px 1px',
									'&:hover': {
										backgroundColor: 'none'
									}
								}}
							>
								<ListItemText
									sx={{
										marginTop: '0px',
										marginBottom: '0px',
										height: 'auto'
									}}
								>
									{enterpriseUrl && enterpriseLogo && enterpriseLogo.includes('http') ? (
										<img
											style={{
												height: '80px',
												width: '100px',
												filter: `grayscale('100%')`
											}}
											src={enterpriseLogo}
											alt={sideBarMultiedger}
										></img>
									) : (
										<img src={sideBarMultiedger} alt="multidedger"></img>
									)}
								</ListItemText>
							</Box>
						</ListItemButton>
	
						{userProfileRedux === ProfileUsers.OWNER && (
							<>
								<OwnerView />
							</>
						)}
						{userProfileRedux === ProfileUsers.SELLER && (
							<>
								<SellerView />
							</>
						)}
						{userProfileRedux === ProfileUsers.CNPJ && (
							<>
								<ClientView />
							</>
						)}
					</ListItem>
				</List>
			</Drawer>

		}
		</>
	);
};
