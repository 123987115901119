import * as Yup from 'yup';

export const SchemaBuyerDefault = Yup.object().shape({
	beneficiary: Yup.string().required('type beneficiary'),
	cpfCnpj: Yup.string().required('type cpf/cnpj'),
	country: Yup.string().required('type country'),
	zipCode: Yup.string().required('type zip code'),
	state: Yup.string().required('type state'),
	city: Yup.string().required('type city'),
	address: Yup.string().required('type your address'),
	numberAddress: Yup.string().required('type the numnber'),
	email: Yup.string().email().required('type your valid E-mail')
});
