import { FC, useEffect, useRef, ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import PdfPrinter from 'pdfmake';
import dayjs from 'dayjs';
import { IOrigins } from '../../../types/certificateOrg';

import {
	Box,
	Paper,
	PaperProps,
	TableContainer,
	Typography,
	Table as TableM,
	TableSortLabel,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TextField,
	ButtonBase,
	Checkbox,
	FormGroup,
	FormControlLabel,
	CircularProgress,
	Pagination,
	PaginationItem
} from '@mui/material';

import { visuallyHidden } from '@mui/utils';

import filterIcon from '../../../assets/icon/filterTableHeader.png';

import PDF from '../../../assets/imgs/pdf.png';
import { renderPDF } from '../../../certificateModel/model';
import { getTotalAvailableRec } from '../../../stores/totalAvailableRec/totalAvailableRec.actions';
import { transferRec } from '../../../stores/transferRec/transferRec.actions';
import { colors } from '../../../styles';
import { ICertificateOrg } from '../../../types/certificateOrg';
import { ICertificate } from '../../../types/certificateTranfer';
import { HeadCell } from '../../../types/headCell';
import { customScroll } from '../../../view/commonStyles';
import { TextTableBody } from './textTableBody';
import { useSnackbar } from 'notistack';

interface ITable extends PaperProps {
	title: string;
	titles: HeadCell[];
	transactions: ICertificateOrg[] | null;
	isLoaded: boolean;
	totalPages: number;
	getSelectedCertificates: ICertificateOrg;
	setQuantityRowSelected: (value: number) => void;
	setStateObject: (value: boolean) => void;
	setSelectedPage: (value: number) => void;
	setSelectedSize: (value: number) => void;
}
let newObject: any[] = [];
type Order = 'asc' | 'desc';

interface ISortInput {
	[key: string]: number | string;
}
const initSortInput: ISortInput = {
	date: '',
	sellerOrg: '',
	seller: '',
	buyer: '',
	opType: '',
	status: '',
	sourceType: '',
	orgName: '',
	unitID: 0,
	location: '',
	period: '',
	uniqueId: '',
	amount: 0
};

export const Table: FC<ITable> = ({
	sx,
	title,
	titles,
	transactions,
	getSelectedCertificates,
	isLoaded,
	setQuantityRowSelected,
	setStateObject,
	setSelectedPage,
	setSelectedSize,
	totalPages,
	...props
}) => {
	const [pdfBase64, setPDFBase64] = useState('');
	const dispath = useDispatch();
	const [order, setOrder] = useState<Order>('desc');
	const [orderBy, setOrderBy] = useState<string>('date');
	const [selectAllRow, setSelectAllRow] = useState<boolean>(false);
	const [checked, setChecked] = useState({
		active: false,
		retired: false
	});
	const [page, setPage] = useState(1);
	const [sortedData, setSortedData] = useState<
		ICertificateOrg[] | undefined | null
	>(transactions);
	const [isQueryLoaded, setIsQueryLoaded] = useState<boolean>(false);
	const [selected, setSelected] = useState(false);
	const [sortInput, setSortInput] = useState(initSortInput);
	const { enqueueSnackbar } = useSnackbar();
	const firstDivRef = useRef(null);
	const secondDivRef = useRef(null);

	const handleCreatePDF = async ( transaction: ICertificateOrg ) => {
		enqueueSnackbar('Your download will start soon', {
			variant: 'info'
		});
		const res = await renderPDF({
			id: transaction.id,
			beneficiaryId: transaction.beneficiaryId,
			createdAt: transaction.date,
			provider: transaction.holder,
			status: transaction.status,
			beneficiary: transaction.beneficiary,
			beneficiaryWallet: transaction.beneficiaryWallet,
			sourceType: transaction.assetSourceType,
			orgName: transaction.orgName,
			powerUnit: transaction.powerUnit,
			country: transaction.assetCountry,
			state: transaction.assetState,
			city: transaction.assetCity,
			period: transaction.period,
			unitID: transaction.assetUnitID,
			amountRec: transaction.amountRec.toString(),
			asset_name: transaction.powerUnit,
			operationType: transaction.opType,
			recLabel: transaction.recLabel,
			hash: transaction.recHash,
			startDate: transaction.startDate.toString(),
			endDate: transaction.endDate.toString(),
			origins: transaction.originCertificates,
			assetCo2PerMWh: transaction.co2PerMWh,
			assetStartOfOperation: transaction.startOfOperation,
			certificateIdRange: transaction.certificate_id_range,
			blockHash: transaction.blockHash,
			setPDFBase64: setPDFBase64,
			download: true
		});

		if(!res){
			enqueueSnackbar('Error to download this certificate. Check if your enteprise has a valid logo or this certificate was finished completely', {
				variant: 'error'
			});
		}
	};

	useEffect(() => {
		setSortedData(transactions);
		setIsQueryLoaded(isLoaded);
	}, [transactions]);

	useEffect(() => {
		setIsQueryLoaded(isLoaded);
	}, [isLoaded]);

	const handleScrollFirst = (scroll: any) => {
		const current: any = secondDivRef.current;
		if (current) {
			current.scrollLeft = scroll.target.scrollLeft;
		}
	};

	const handleScrollSecond = (scroll: any) => {
		const current: any = firstDivRef.current;
		if (current) {
			current.scrollLeft = scroll.target.scrollLeft;
		}
	};

	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value);
		setSelectedPage(value);
		setSelectAllRow(false);
	};

	useEffect(() => {
		if (checked.active && checked.retired) {
			setSortedData(transactions);
			return;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checked.active, checked.retired]);

	const filterStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, checked: checkedBox, value: valueBox } = event.target;

		setChecked({
			...checked,
			[name]: checkedBox
		});

		if (checkedBox) {
			let newData: ICertificateOrg[] | null = transactions;
			if (newData) {
				newData = newData.filter((value: ICertificateOrg) => {
					return value.status.toLowerCase() === valueBox;
				});
				setSortedData(newData);
			}
		} else {
			setSortedData(transactions);
			// setStateObject(true);
			// setQuantityRowSelected(0);
			// dispath(getTotalAvailableRec([]));
		}
	};

	type TLabel = {
		label: string;
		name: string;
		space?: boolean;
		onChange: any;
		checkedBox: boolean;
		value: string;
	};

	const CheckRec: FC<TLabel> = ({ label, space, checkedBox, value, name }) => {
		return (
			<FormGroup
				sx={{
					paddingLeft: space ? '95px' : '20px',
					paddingTop: '22px',
					position: 'relative'
				}}
			>
				<FormControlLabel
					sx={{ position: 'relative' }}
					control={
						<Checkbox
							onChange={e => filterStatus(e)}
							name={name}
							value={value}
							checked={checkedBox}
							sx={{
								color: '#00CA95',
								'&.Mui-checked': {
									color: '#00CA95'
								}
							}}
						/>
					}
					label={
						<Typography
							color="#00CA95"
							fontSize="0.75rem"
							fontWeight="700"
							fontFamily="Mulish"
							letterSpacing="0.4px"
							lineHeight="15px"
						>
							{label}
						</Typography>
					}
				/>
			</FormGroup>
		);
	};

	useEffect(() => {
		if (newObject.length > 0) {
			newObject = [];
		}
	}, []);

	const selectAllCerts = (e: any, certs: any) => {
		if (e.target.checked) {
			setSelectAllRow(true);
			setStateObject(true);
			const filteredArray = certs.filter((obj: { amount: number; }) => obj.amount > 0);
			filteredArray.map((object: { amount: number; id: any; })=>{
				newObject = newObject
				.filter(item => item.id !== object.id)
				.concat(object);
				
				setQuantityRowSelected(newObject.length);
				try {
					dispath(getTotalAvailableRec(newObject));
				} catch (error) {
					console.log(error);
					try {
						dispath(getTotalAvailableRec(newObject));
					} catch (error) {
						console.log(error);
					}
				}
			})
		} else {
			setSelectAllRow(false);
			setStateObject(true);
			certs.map((object: { id: any; })=>{
				newObject = newObject.filter(item => item.id !== object.id);
				setQuantityRowSelected(newObject.length);
				try {
					dispath(getTotalAvailableRec(newObject));
				} catch (error) {
					console.log(error);
				}
			})
		}
		// dispath(transferRec(e.target.checked));
		// setSelected(e.target.checked);
	};

	// PROBLEM HERE
	const sendTransfer = (e: any, object: any) => {
		if (e.target.checked) {
			setStateObject(true);
			
			newObject = newObject
			.filter(item => item.id !== object.id)
			.concat(object);

			setQuantityRowSelected(newObject.length);
			try {
				dispath(getTotalAvailableRec(newObject));
			} catch (error) {
				console.log(error);
				try {
					dispath(getTotalAvailableRec(newObject));
				} catch (error) {
					console.log(error);
				}
			}
		} else {
			setStateObject(true);
			newObject = newObject.filter(item => item.id !== object.id);
			setQuantityRowSelected(newObject.length);
			try {
				dispath(getTotalAvailableRec(newObject));
			} catch (error) {
				console.log(error);
			}
		}

		dispath(transferRec(e.target.checked));
		setSelected(e.target.checked);
	};

	function descendingComparator<T>(a: T, b: T, orderByTile: keyof T) {
		if (
			orderByTile === 'sellerOrg' ||
			orderByTile === 'seller' ||
			orderByTile === 'buyer' ||
			orderByTile === 'opType' ||
			orderByTile === 'status' ||
			orderByTile === 'sourceType' ||
			orderByTile === 'orgName' ||
			orderByTile === 'location'
		) {
			const first: any = a[orderByTile];
			const second: any = b[orderByTile];
			if (
				!!first.replace(/\d+/, '') &&
				!!second.replace(/\d+/, '') &&
				first.replace(/\d+/, '') === second.replace(/\d+/, '') &&
				(first.replace(/\D+/, '') || second.replace(/\D+/, ''))
			) {
				return +second.replace(/\D+/, '') < +first.replace(/\D+/, '')
					? -1
					: +second.replace(/\D+/, '') > +first.replace(/\D+/, '')
					? 1
					: 0;
			}
		}
		if (orderByTile === 'date') {
			const first: any = a[orderByTile];
			const firstReFormat = first.replace(/(\d{2})\/(\d{2})/, '$2/$1');
			const second: any = b[orderByTile];
			const secondReFormat = second.replace(/(\d{2})\/(\d{2})/, '$2/$1');
			return (
				new Date(secondReFormat).valueOf() - new Date(firstReFormat).valueOf()
			);
		}
		if (orderByTile === 'period') {
			const first: any = `01/${a[orderByTile]}`;
			const second: any = `01/${b[orderByTile]}`;
			return new Date(second).valueOf() - new Date(first).valueOf();
		}
		if (b[orderByTile] < a[orderByTile]) {
			return -1;
		}
		if (b[orderByTile] > a[orderByTile]) {
			return 1;
		}

		return 0;
	}

	function getComparator<Key extends keyof any>(
		orderComparator: Order,
		orderByComparator: Key
	): (
		a: { [key in Key]: number | string | any },
		b: { [key in Key]: number | string | any }
	) => number {
		return orderComparator === 'desc'
			? (a, b) => descendingComparator(a, b, orderByComparator)
			: (a, b) => -descendingComparator(a, b, orderByComparator);
	}

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: string
	) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const createSortHandler =
		(property: string) => (event: React.MouseEvent<unknown>) => {
			handleRequestSort(event, property);
		};

	const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setSortInput({ ...sortInput, [name]: value.toLowerCase() });
	};

	const selecAllRowTable = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setSelectAllRow(true);
		} else {
			setSelectAllRow(false);
		}
	};

	const handleBlur = () => {
		const validate = Object.keys(sortInput).filter(i => !!sortInput[i]);
		let newData: ICertificateOrg[] | null = transactions;
		if (validate.length) {
			validate?.forEach((key: any) => {
				if (newData) {
					newData = newData.filter((i: any) => {
						if (typeof i[key] === 'number') {
							return i[key] === +sortInput[key];
						} else {
							return i[key]
								.toLowerCase()
								.includes(sortInput[key]?.toString().toLowerCase());
						}
					});
				}
			});
			setSortedData(newData);
		} else {
			setSortedData(transactions);
		}
	};

	const handleKeyDown = (
		event: React.KeyboardEvent<HTMLInputElement>
	): void => {
		if (event.key === 'Enter') {
			handleBlur();
		}
	};

	return (
		<>
			<Paper {...props} sx={{ boxShadow: 'none', ...sx }}>
				<TableContainer
					sx={{
						overflow: !!sortedData?.length ? 'hidden' : 'auto',
						...customScroll
					}}
					onScroll={handleScrollFirst}
					ref={firstDivRef}
				>
					<Box display="flex">
						{/* <CheckRec
							label="Active REC's"
							name="active"
							space={true}
							value="active"
							checkedBox={checked.active}
							onChange={filterStatus}
						/>
						<CheckRec
							label="Retired REC's"
							name="retired"
							value="retired"
							checkedBox={checked.retired}
							onChange={filterStatus}
						/> */}
						{isLoaded && (
							<Pagination
								renderItem={item => (
									<PaginationItem sx={{ color: '#6F7075' }} {...item} />
								)}
								count={totalPages}
								disabled={!isLoaded}
								showFirstButton
								showLastButton
								color="standard"
								shape="rounded"
								page={page}
								onChange={handleChange}
								sx={{ marginLeft: 'auto', marginTop: '1%', marginRight: '2%' }}
							/>
						)}
					</Box>

					<TableM>
						<TableHead>
							<TableRow
								sx={{
									position: 'relative'
								}}
							>
								{titles &&
									// eslint-disable-next-line @typescript-eslint/no-shadow
									titles.slice().map((title, index) => (
										<TableCell
											align={title.numeric ? 'left' : 'center'}
											key={title.id}
											padding={title.disablePadding ? 'none' : 'normal'}
											sortDirection={orderBy === title.id ? order : false}
											sx={{
												borderTopLeftRadius: index === 0 ? '16px' : 0
											}}
										>
											{/* here */}
											{title.id === 'checkbox' && (
												<Box position="relative" top="15px">
													<Checkbox
														color="success"
														disabled={!!sortedData?.length ? false : true}
														checked={selectAllRow}
														onChange={(e: any) => selectAllCerts(e, sortedData)}
														sx={{
															color: '#8E8E8E',
															'&.Mui-checked': {
																color: '#00CA95'
															}
														}}
													/>
												</Box>
											)}

											{title.id !== 'report' && title.id !== 'checkbox' ? (
												<TableSortLabel
													sx={{ width: '94px' }}
													hideSortIcon
													direction={orderBy === title.id ? order : 'asc'}
													onClick={createSortHandler(title.id)}
												>
													<Box
														display="flex"
														justifyContent="space-between"
														alignItems="center"
														width="100%"
													>
														<Typography
															sx={{
																width: '100%',
																whiteSpace: 'nowrap',
																fontSize: '12px',
																fontWeight: '700',
																color: '#9FA2B4'
															}}
														>
															{title.label}
														</Typography>
														<img src={filterIcon}></img>
													</Box>
													{orderBy === title.id ? (
														<Box component="span" sx={visuallyHidden}>
															{order === 'desc'
																? 'sorted descending'
																: 'sorted ascending'}
														</Box>
													) : null}
												</TableSortLabel>
											) : (
												<Typography
													sx={{
														fontSize: '0.75rem',
														fontWeight: '700',
														color: '#9FA2B4'
													}}
												>
													{title.label}
												</Typography>
											)}
											{title.id !== 'report' && title.id !== 'checkbox' && (
												<Box display="flex">
													<TextField
														name={title.id}
														placeholder="search ..."
														sx={{
															input: {
																width:
																	title.id === 'amountRec' ? '100%' : '100%',
																borderRadius: '4px',
																backgroundColor: 'rgba(217, 217, 217, 0.8)',
																boxShadow: 'none !important',
																padding: '4px 6px',
																fontSize: '12px',
																fontWeight: '400'
															},
															fieldset: {
																border: 'none !important',
																boxShadow: 'none !important'
															}
														}}
														onBlur={handleBlur}
														onKeyUp={handleKeyDown}
														onChange={onChangeInput}
													/>
												</Box>
											)}
										</TableCell>
									))}
							</TableRow>
						</TableHead>
					</TableM>
				</TableContainer>

				<TableContainer
					onScroll={handleScrollSecond}
					ref={secondDivRef}
					sx={{
						height: 'calc(100vh - 480px)',
						minHeight: '400px',
						...customScroll
					}}
				>
					<TableM>
						<TableBody  sx={{padding: '1px'}}>
							{sortedData &&
								sortedData
									.slice()
									.sort(getComparator(order, orderBy))
									.map(transaction => (
										<TableRow key={transaction.id}>
											<TableCell sx={{padding: 'normal'}}>
												<Checkbox
													disabled={transaction.amount <= 0}
													onChange={(e: any) => sendTransfer(e, transaction)}
													color="success"
													checked={
														getSelectedCertificates?.totalRec &&
														getSelectedCertificates?.totalRec.some(
															(item: any) => item.id === transaction.id
														)
															? true
															: false
													}
													sx={{
														color: '#8E8E8E',
														'&.Mui-checked': {
															color: '#00CA95'
														}
													}}
												/>
											</TableCell>
											<TableCell sx={{width: '94px'}}>
												<TextTableBody sx={{ fontSize: '13px', whiteSpace: 'wrap', width: '94px'}}>{transaction.date}</TextTableBody>
											</TableCell>
											<TableCell sx={{width: '94px'}}>
												<TextTableBody sx={{width: '94px'}}>{transaction.holder}</TextTableBody>
											</TableCell>
											<TableCell sx={{width: '94px'}}>
												<TextTableBody sx={{width: '94px'}}>{transaction.beneficiary}</TextTableBody>
											</TableCell>
											<TableCell sx={{width: '94px'}}>
												<TextTableBody sx={{width: '94px'}}>{transaction.status}</TextTableBody>
											</TableCell>
											<TableCell sx={{width: '94px'}}>
												<TextTableBody sx={{width: '94px'}}>{transaction.assetSourceType}</TextTableBody>
											</TableCell>
											<TableCell sx={{width: '94px'}}>
												<TextTableBody sx={{width: '94px'}}>{transaction.orgName}</TextTableBody>
											</TableCell>
											<TableCell sx={{width: '94px'}}>
												<TextTableBody sx={{width: '94px'}}>{transaction.recLabel}</TextTableBody>
											</TableCell>
											<TableCell sx={{width: '94px'}}>
												<TextTableBody sx={{width: '94px'}}>{transaction.powerUnit}</TextTableBody>
											</TableCell>
											<TableCell sx={{width: '94px'}}>
												<TextTableBody sx={{width: '94px'}}>{transaction.assetState}</TextTableBody>
											</TableCell>
											<TableCell sx={{width: '94px'}}>
												<TextTableBody sx={{width: '94px'}}>{transaction.period}</TextTableBody>
											</TableCell>
											<TableCell sx={{width: '94px'}}>
												<TextTableBody sx={{width: '94px'}}>{transaction.assetUnitID}</TextTableBody>
											</TableCell>
											<TableCell sx={{width: '94px'}}>
												<TextTableBody sx={{width: '94px'}}>{transaction.amountRec}</TextTableBody>
											</TableCell>
											<TableCell>
												<ButtonBase
													component="a"
													onClick={async () =>
														handleCreatePDF(transaction)
													}
												>
													<img src={PDF}></img>
												</ButtonBase>
											</TableCell>
										</TableRow>
									))}
						</TableBody>
					</TableM>
					{!isQueryLoaded && (
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '100%'
								// paddingTop: '30px'
							}}
						>
							<CircularProgress />
						</Box>
					)}
					{sortedData && sortedData?.length < 1 && isQueryLoaded && (
						<Typography
							sx={{
								display: 'flex',
								paddingTop: '20px',
								width: '100%',
								justifyContent: 'center',
								alignItems: 'center',
								fontSize: '1.75rem',
								fontWeight: '700',
								color: colors.primary
							}}
						>
							Empty table!
						</Typography>
					)}
				</TableContainer>
			</Paper>
		</>
	);
};
