// Core
import React, { FC } from 'react';

import { Typography, TypographyProps } from '@mui/material';

export const TextInfo: FC<TypographyProps> = ({ children, ...props }) => {
	return (
		<Typography
			{...props}
			sx={{
				textTransform: 'uppercase',
				fontSize: '1rem',
				color: '#000'
			}}
		>
			{children}
		</Typography>
	);
};
