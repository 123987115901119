interface MyReducerState {
	myVariable: string | null;
  }
  
  const initialState: MyReducerState = {
	myVariable: null,
  };
  
  type MyReducerAction = {
	type: 'SET_USER_ROLE';
	payload: string;
  };
  
  const myReducer = (state: MyReducerState = initialState, action: MyReducerAction) => {
	switch (action.type) {
	  case 'SET_USER_ROLE':
		return {
		  ...state,
		  myVariable: action.payload,
		};
	  default:
		return state;
	}
  };
  
  export default myReducer;