import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { getTotalAvailableRec } from '../../stores/totalAvailableRec/totalAvailableRec.actions';
import { Header } from '../../components/header';
import { SideBar } from '../../components/sideBar';
import { CertificateHome } from './certificateHome';
import { RecTransfer } from './recTransfer';
import { useDispatch } from 'react-redux';


export const Certificate = () => {
	const [goTotransfer, setGoTotransfer] = useState<boolean>(false);
	const dispath = useDispatch();

	useEffect(()=>{
		dispath(getTotalAvailableRec([]));
	},[])

	return (
		<>
			<Header />
			<SideBar />
			<Box
				component="main"
				sx={{
					marginLeft: '234px',
					height: '100vh',
					paddingTop: '146px'
				}}
			>
				{goTotransfer ? (
					<RecTransfer setGoTotransferT={setGoTotransfer} />
				) : (
					<CertificateHome setGoTotransferT={setGoTotransfer} />
				)}
			</Box>
		</>
	);
};
