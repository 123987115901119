import { useEffect, useState } from 'react';

import { Box, Menu, Toolbar, Typography, MenuItem } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

import business from '../../assets/icon/business.png';
import logout from '../../assets/icon/logout.png';
import person from '../../assets/icon/person.png';
import profileFace from '../../assets/imgs/pfofileexemplo.jpeg';
import { ProfileService } from '../../services/profile.service';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../styles';
import { Height } from '@mui/icons-material';
import dayjs from 'dayjs';

const drawerWidth = 153;

export const Header = () => {
	const profileService = new ProfileService();
	const history = useNavigate();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [userLogo, setUserLogo] = useState<null | string>(null);
	const [isLogoLoaded, setIsLogoLoaded] = useState(false);
	const [currentDate, setCurrentDate] = useState(dayjs().format('ddd, DD MMM YYYY HH:mm'));

	const open = Boolean(anchorEl);
	const fullName = localStorage.getItem('fullName');
	const userLogoStorage = localStorage.getItem('user_avatar_url') || ''

	useEffect(()=>{
		const intervalId = setInterval(()=>{
			setCurrentDate(dayjs().format('ddd, DD MMM YYYY HH:mm'))
		},45000)

		return () => {
            clearInterval(intervalId);
          };
    },[])

	useEffect(() => {
		const getListUserInfo = async () => {
			if(!userLogoStorage.includes('http')){
				const getListUser: any = await profileService.getUserProfile();
				if (getListUser.status === 200 && getListUser.data.logo) {
					localStorage.setItem('user_avatar_url',getListUser.data.logo)
					location.reload()
				}
			}
		};

		if (!userLogo) {
			if(userLogoStorage.includes('http')){
				setUserLogo(userLogoStorage)
			}
			setIsLogoLoaded(true)
			getListUserInfo();
		  }

	}, [userLogoStorage]);

	function stringToColor(string: string) {
		let hash = 0;
		let i;

		/* eslint-disable no-bitwise */
		for (i = 0; i < string.length; i += 1) {
			hash = string.charCodeAt(i) + ((hash << 5) - hash);
		}

		let color = '#';

		for (i = 0; i < 3; i += 1) {
			const value = (hash >> (i * 8)) & 0xff;
			color += `00${value.toString(16)}`.slice(-2);
		}
		/* eslint-enable no-bitwise */

		return color;
	}

	function stringAvatar(name: string) {
		return {
			sx: {
				bgcolor: stringToColor(name)
			},
			children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
		};
	}

	const handleClickMenu = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const toProfile = () => {
		localStorage.setItem('menuClick', "")
		history('/profile');
	};

	const goToRecTemplate = () => {
		localStorage.setItem('menuClick', '');
		history('/business-template');
	};

	const handleLogout = () => {
		localStorage.removeItem('token');
		localStorage.removeItem('fullName');
		localStorage.removeItem('enterprise_id');
		localStorage.removeItem('user_avatar_url');
		history('/signin');
	};

	return (
		<AppBar
			position="fixed"
			sx={{
				width: `calc(100% - ${drawerWidth}px)`,
				ml: `${drawerWidth}px`,
				backgroundColor: 'transparent',
				boxShadow: 'none'
			}}
		>
			<Toolbar
				sx={{
					paddingLeft: '0px',
					backgroundColor: '#f7fbff',
					minHeight: '76px',
					// marginBottom: '75px'
				}}
			>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center'
					}}
				>
					<Box marginLeft="147px">
						<Typography
							fontSize="1.68rem"
							letterSpacing=".27px"
							lineHeight="26px"
							fontWeight="700"
							padding="25px"
							paddingBottom="26px"
							color={colors.primaryDark}
						>
							Welcome to MULTI ASSET TOKENIZER
						</Typography>
					</Box>

					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-around',
							alignItems: 'center',
							marginRight: '149px'
						}}
					>
						<Typography
							fontSize="1.25rem"
							fontStyle="normal"
							lineHeight="32px"
							color={colors.primaryDark}
							marginRight="20px"
						>
							{currentDate}
						</Typography>
						<Box
							sx={{
								position: 'relative',
								width: '59px',
								height: '59px',
								borderRadius: '50px',
								border: '2px solid #DFE0EB',
								padding: '15px'
							}}
						>
							{isLogoLoaded  && (
								userLogo !== null ? 
								// <>
								// 	<img
								// 		src={userLogo}
								// 		style={{
								// 			borderRadius: '40px',
								// 			position: 'absolute',
								// 			top: '1px',
								// 			left: '1px',
								// 			right: 0,
								// 			cursor: 'pointer'
								// 		}}
								// 		width="53px"
								// 		alt="profile"
								// 		onClick={handleClickMenu}
								// 	></img>
								// </>
								<Stack direction="row" spacing={2}>
									<Avatar
										style={{
											borderRadius: '53px',
											position: 'absolute',
											top: '1px',
											left: '1px',
											right: 0,
											cursor: 'pointer',
											width: '53px',
											height: '53px'
										}}
										onClick={handleClickMenu}
										src={userLogo}
									/>
								</Stack>
								:
								<Stack direction="row" spacing={2}>
									<Avatar
										style={{
											borderRadius: '53px',
											position: 'absolute',
											top: '1px',
											left: '1px',
											right: 0,
											cursor: 'pointer',
											width: '53px',
											height: '53px'
										}}
										onClick={handleClickMenu}
										{...stringAvatar(fullName ? fullName : ' Name')}
									/>
								</Stack>
							)
							}
							<Menu
								id="basic-menu"
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'center'
								}}
								sx={{
									transform: 'translate(-160px, 5px)',
									'& .MuiPaper-root': {
										borderRadius: '25px 25px 12px 12px',
										width: '356px'
									},
									'& ul': {
										paddingBottom: '0',
										'& li': {
											paddingLeft: '32px'
										}
									}
								}}
							>
								<Box
									sx={{
										backgroundColor: '#fff',
										marginBottom: '8px'
									}}
								>
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
									{isLogoLoaded && (
										userLogo !== null ?
										<Avatar
											style={{
												borderRadius: '30px',
												width: '30px',
												height: '30px',
												marginRight: '22px',
												marginLeft: '25px',
												fontSize: '18px'
											}}
											src={userLogo}
											onClick={handleClickMenu}
										/>
										:
										<Avatar
											style={{
												borderRadius: '30px',
												width: '35px',
												height: '35px',
												marginRight: '22px',
												marginLeft: '25px',
												fontSize: '18px'
											}}
											{...stringAvatar(fullName ? fullName : 'User Name')}
										/>
									)
									}
										<Typography
											fontWeight="700"
											fontSize="1.12rem"
											lineHeight="27px"
											letterSpacing=".26px"
											color="#252733"
										>
											{fullName}
										</Typography>
									</Box>
								</Box>
								<Box
									sx={{
										position: 'absolute',
										marginTop: '10px',
										marginLeft: '31px'
									}}
								>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center'
										}}
									>
										<img src={person} alt="person" />
										<Typography
											color="#636C7A"
											lineHeight="2rem"
											sx={{
												marginLeft: '29px'
											}}
										>
											My Profile
										</Typography>
									</Box>
								</Box>
								<MenuItem
									sx={{
										position: 'relative',
										background: colors.primaryLight,
										margin: '1px',
										opacity: '.3',
										height: '48px',
										zIndex: '1',
										width: '100%'
									}}
									onClick={toProfile}
								></MenuItem>
								<Box
									sx={{
										position: 'absolute',
										marginTop: '10px',
										marginLeft: '31px'
									}}
								>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center'
										}}
									>
										<img src={business} alt="business" />
										<Typography
											color="#636C7A"
											lineHeight="2rem"
											sx={{
												marginLeft: '29px'
											}}
										>
											Business Templates
										</Typography>
									</Box>
								</Box>
								<MenuItem
									onClick={goToRecTemplate}
									sx={{
										background: colors.primaryLight,
										margin: '1px',
										opacity: '.3',
										height: '48px'
									}}
								></MenuItem>
								<Box
									sx={{
										position: 'absolute',
										marginTop: '10px',
										marginLeft: '31px'
									}}
								>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											cursor: 'pointer'
										}}
									>
										<img src={logout} alt="logout" />
										<Typography
											color="#636C7A"
											lineHeight="2rem"
											sx={{
												marginLeft: '29px'
											}}
										>
											Logout
										</Typography>
									</Box>
								</Box>
								<MenuItem
									onClick={handleLogout}
									sx={{
										background: colors.primaryLight,
										margin: '1px',
										opacity: '.3',
										height: '48px'
									}}
								></MenuItem>
							</Menu>
						</Box>
					</Box>
				</Box>
			</Toolbar>
		</AppBar>
	);
};
