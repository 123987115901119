/* eslint-disable prettier/prettier */
import { Button, Typography } from '@mui/material';

export type TMyButton = {
  variant: 'text' | 'outlined' | 'contained';
  margin?: string;
  border?: string
  padding?: string;
  backgroundColor?: string;
  borderRadius?: string;
  color: string;
  boxShadow?: string;
  text?: string;
  marginRight?: string;
  marginLeft?: string;
  marginTop?: string;
  marginBottom?: string;
  onClick?: (value: any) => void;
  children: React.ReactNode;
}

export const MyButton = ({
  backgroundColor,
  color,
  border,
  borderRadius,
  variant,
  text,
  onClick,
  children,
  boxShadow,
  margin,
  padding,
  marginRight,
  marginLeft,
  marginTop,
  marginBottom,
  ...props
}: TMyButton) => {
  return (
    <>
      <Button
        variant={variant}
        onClick={onClick}
        sx={{
          marginTop,
          marginBottom,
          marginRight,
          marginLeft,
          border,
          boxShadow: 'none',
          padding,
          backgroundColor,
          color,
          borderRadius,
          '&:hover': {
            backgroundColor
          }
        }}
      >
        <Typography>{children || text}</Typography>
      </Button>
    </>
  )

}