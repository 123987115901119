import { Grid, Typography } from '@mui/material';

import { colors } from '../../../styles';
import { IListWallet } from '../../../types/wallet';
import { formatTextLimit } from '../../../utils/formatTextLenght';

/* eslint-disable prettier/prettier */

export type TDataUserProfile = {
  dataUserProfile: IListWallet;
}
export const InfoProfile = ({ dataUserProfile }: TDataUserProfile) => {

  return (
    <>
      <Grid container md={12} rowSpacing={4}>
        <Grid item md={8}>
          <Typography color={colors.primary} marginBottom="8px">
            FIRST NAME
          </Typography>
          <Typography fontSize="1.25rem">{dataUserProfile?.first_name}</Typography>
        </Grid>
        <Grid item md={4}>
          <Typography color={colors.primary} marginBottom="8px">
            LAST NAME
          </Typography>
          <Typography fontSize="1.25rem">{dataUserProfile?.last_name}</Typography>
        </Grid>
        <Grid item md={8}>
          <Typography color={colors.primary} marginBottom="8px">
            JOB TITLE
          </Typography>
          <Typography fontSize="1.25rem">{dataUserProfile?.job_title}</Typography>
        </Grid>
        <Grid item md={4}>
          <Typography color={colors.primary} marginBottom="8px">
            PHONE
          </Typography>
          <Typography fontSize="1.25rem">{dataUserProfile?.phone}</Typography>
        </Grid>
        <Grid item md={8}>
          <Typography color={colors.primary} marginBottom="8px">
            COUNTRY
          </Typography>
          <Typography fontSize="1.25rem">{dataUserProfile?.country}</Typography>
        </Grid>
        <Grid item md={4}>
          <Typography color={colors.primary} marginBottom="8px">
            CPF
          </Typography>
          <Typography fontSize="1.25rem">{dataUserProfile?.cpf}</Typography>
        </Grid>
        <Grid item md={8}>
          <Typography color={colors.primary} marginBottom="8px">
            STATE
          </Typography>
          <Typography fontSize="1.25rem">{dataUserProfile?.state}</Typography>
        </Grid>
        <Grid item md={4}>
          <Typography color={colors.primary} marginBottom="8px">
            CITY
          </Typography>
          <Typography fontSize="1.25rem">{dataUserProfile?.city}</Typography>
        </Grid>
        <Grid item md={8}>
          <Typography color={colors.primary} marginBottom="8px">
            ADDRESS
          </Typography>
          <Typography fontSize="1.25rem">{formatTextLimit(dataUserProfile?.address, 87)}</Typography>
        </Grid>
        <Grid item md={4}>
          <Typography color={colors.primary} marginBottom="8px">
            NUMBER
          </Typography>
          <Typography fontSize="1.25rem">{formatTextLimit(String(dataUserProfile?.address_number), 10)}</Typography>
        </Grid>
        <Grid item md={8}>
          <Typography color={colors.primary} marginBottom="8px">
            E-MAIL
          </Typography>
          <Typography fontSize="1.25rem">{dataUserProfile?.email}</Typography>
        </Grid>
        <Grid item md={4}>
          <Typography color={colors.primary} marginBottom="8px">
            ZIPCODE
          </Typography>
          <Typography fontSize="1.25rem">{dataUserProfile?.zipcode}</Typography>
        </Grid>
      </Grid>
    </>
  )
}