import { BackofficeApi } from './api/axios-config';


export class BackOfficeService {
	async getEnterprisEnergyBank(enterprise: string) {
		try {
			const respCreateAsset: any = await BackofficeApi.get(`/spreadsheets/enterprise/${enterprise}/energy-bank`);
			return respCreateAsset;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async getAssetEnergyBank(asset: string) {
		try {
			const respCreateAsset: any = await BackofficeApi.get(`/spreadsheets/asset/code/${asset}/energy-bank`);
			return respCreateAsset;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async getTotalAssetMWhByPeriod(asset: string, startDate: string, endDate: string) {
		try {
			const respCreateAsset: any = await BackofficeApi.post(`/assets/available-wh`,
			{
				ceg:asset,
				startDate,
				endDate
			});
			return respCreateAsset;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async getTotalAssetMWhByAmount(asset: string, startDate: string, endDate: string, amount: number) {
		try {
			const respCreateAsset: any = await BackofficeApi.post(`/assets/available-wh-amount`,
			{
				ceg:asset,
				startDate,
				endDate,
				amount
			});
			return respCreateAsset;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}
}
