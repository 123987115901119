import * as Yup from 'yup';

export const SchemaFormConfig = Yup.object().shape({
	// name: Yup.string().required('type the name of company'),
	// number: Yup.string().required('type your umber'),
	// cnpj: Yup.string().required('type your cnpj'),
	// city: Yup.string().required('Select a city'),
	// address: Yup.string().required('type your address')
	// zipcode: Yup.string().required('type your zip code')
});
