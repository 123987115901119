import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Header } from '../../components/header';
import { SideBar } from '../../components/sideBar';
import { CertificateHome } from '../Certificate/certificateHome';
import { RecTransfer } from '../Certificate/recTransfer';
import { OperationBoard } from './operationBoard';
import { useDispatch } from 'react-redux';
import { getTotalAvailableRec } from '../../stores/totalAvailableRec/totalAvailableRec.actions';


export const Operations = () => {
	const [goTotransfer, setGoTotransfer] = useState<boolean>(false);
	const dispath = useDispatch();

	useEffect(()=>{
		dispath(getTotalAvailableRec([]));
	},[])
	return (
		<>
			<Header />
			<SideBar />
			<Box
				component="main"
				sx={{
					marginLeft: '234px',
					height: '100vh',
					paddingTop: '146px'
				}}
			>
				{goTotransfer ? (
					<OperationBoard setGoTotransferT={setGoTotransfer} />
				) : (
					<CertificateHome
						setGoTotransferT={setGoTotransfer}
						buttonRetired={false}
					/>
				)}
			</Box>
		</>
	);
};
