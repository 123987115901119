const INITIAL_STATE = false;

interface IPayload {
	status: boolean;
}

interface IAction {
	type: string;
	payload: IPayload;
}

const goRecoveryPasswoword = (renderRecovery: boolean) => {
	localStorage.setItem('persistRecovery', JSON.stringify(renderRecovery));
	return renderRecovery;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function (state = INITIAL_STATE, action: IAction) {
	switch (action.type) {
		case 'GO_RECOVERY_PASSWORD':
			return goRecoveryPasswoword(action.payload.status);
		default:
			return state;
	}
}
