import { createTheme } from '@mui/material/styles';

export const customTheme = createTheme({
	components: {
		MuiCard: {
			styleOverrides: {
				root: {
					boxShadow: 'none'
				}
			}
		},
		MuiDialog: {
			styleOverrides: {
				root: {
					'& .MuiPaper-root': {
						borderRadius: '40px',
						border: '1px solid #DEE2E6'
					}
				}
			}
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					'& .MuiOutlinedInput-notchedOutline': {
						borderRadius: '9px',
						border: '1px solid #3A4049'
					},
					'&:focus': {
						borderColor: 'red'
					}
				}
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: '#000',
					fontWeight: 400,
					fontSize: '16px',
					fontFamily: 'Sen',
					fontStyle: 'normal',
					lineHeight: '19px',
					'& .Mui-focused': {
						color: '#000'
					}
				}
			}
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					fontFamily: 'Sen',
					fontStyle: 'normal',
					fontWeight: '400',
					fontsize: '16px'
				}
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					backgroundColor: '#fff',
					borderRadius: '9px',
					boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
					'&:hover': {
						borderColor: 'red'
					}
				}
			}
		}
	}
});
