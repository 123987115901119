// Core
import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICertificateOrg, IOrigins } from '../../../types/certificateOrg';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import {
	Paper,
	Box,
	PaperProps,
	TableBody,
	TableSortLabel,
	TableHead,
	TableRow,
	Table as TableM,
	Typography,
	TableContainer,
	ButtonBase,
	Checkbox,
	CircularProgress,
	Pagination,
	PaginationItem,
	Tooltip
} from '@mui/material';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { visuallyHidden } from '@mui/utils';

import { colors } from '../../../styles';
import { ITransaction } from '../../../types/buyerTransaction';
import { HeadCell } from '../../../types/headCell';
import { customScroll } from '../../../view/commonStyles';
import { renderPDF } from '../../../certificateModel/model';

// Components
import { TableCell } from './tableCell';
import { TextTableBody } from './textTableBody';
import { LoadingButton } from '@mui/lab';
import userEvent from '@testing-library/user-event';

let newObject: any[] = [];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (
		orderBy === 'orgName' ||
		orderBy === 'opType' ||
		orderBy === 'status' ||
		orderBy === 'sourceType' ||
		orderBy === 'location'
	) {
		const first: any = a[orderBy];
		const second: any = b[orderBy];
		if (
			!!first.replace(/\d+/, '') &&
			!!second.replace(/\d+/, '') &&
			first.replace(/\d+/, '') === second.replace(/\d+/, '') &&
			(first.replace(/\D+/, '') || second.replace(/\D+/, ''))
		) {
			return +second.replace(/\D+/, '') < +first.replace(/\D+/, '')
				? -1
				: +second.replace(/\D+/, '') > +first.replace(/\D+/, '')
				? 1
				: 0;
		}
	}
	if (orderBy === 'date') {
		const first: any = a[orderBy];
		const firstReFormat = first.replace(/(\d{2})\/(\d{2})/, '$2/$1');
		const second: any = b[orderBy];
		const secondReFormat = second.replace(/(\d{2})\/(\d{2})/, '$2/$1');
		return (
			new Date(secondReFormat).valueOf() - new Date(firstReFormat).valueOf()
		);
	}
	if (orderBy === 'period') {
		const first: any = `01/${a[orderBy]}`;
		const second: any = `01/${b[orderBy]}`;
		return new Date(second).valueOf() - new Date(first).valueOf();
	}
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}

	return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
	order: Order,
	orderBy: Key
): (
	a: { [key in Key]: number | string | any },
	b: { [key in Key]: number | string | any }
) => number {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}
interface ITable extends PaperProps {
	title: string;
	titles: HeadCell[];
	transactions: ICertificateOrg[] | null;
	isLoaded: boolean;
	isClientActivate?: boolean;
	tableTransaction?: boolean;
	totalPages: number;
	setSelectedPage: (value: number) => void;
	setSelectedSize: (value: number) => void;
}

export const Table: FC<ITable> = ({
	sx,
	title,
	titles,
	transactions,
	isLoaded,
	isClientActivate,
	tableTransaction,
	totalPages,
	setSelectedPage,
	setSelectedSize,
	...props
}) => {
	const history = useNavigate();
	const backRetirement = localStorage.getItem('retrimentPage');
	const [pdfBase64, setPDFBase64] = useState('');
	const [order, setOrder] = React.useState<Order>('desc');
	const [orderBy, setOrderBy] = React.useState<string>('date');
	const [quantityRowSelected, setQuantityRowSelected] = useState<number>(0);
	const [validatedReirement, setValidatedRetirement] = useState<boolean>(false);
	const [isloaded, setIsLoaded] = useState<boolean>(isLoaded);
	const [totalAmount, setTotalAmount] = useState<number>(0);
	const [tranferRetired, setTranferRetired] = useState<any>();
	const { enqueueSnackbar } = useSnackbar();
	const [sortedData, setSortedData] = useState(transactions);
	const [page, setPage] = useState(1);

	const handleCreatePDF = async ( transaction: ICertificateOrg ) => {
		enqueueSnackbar('Your download will start soon', {
			variant: 'info'
		});

		const res = await renderPDF({
			id: transaction.id,
			beneficiaryId: transaction.beneficiaryId,
			createdAt: transaction.date,
			provider: transaction.holder,
			status: transaction.status,
			beneficiary: transaction.beneficiary,
			beneficiaryWallet: transaction.beneficiaryWallet,
			sourceType: transaction.assetSourceType,
			orgName: transaction.orgName,
			powerUnit: transaction.powerUnit,
			country: transaction.assetCountry,
			state: transaction.assetState,
			city: transaction.assetCity,
			period: transaction.period,
			unitID: transaction.assetUnitID,
			amountRec: transaction.amountRec.toString(),
			asset_name: transaction.powerUnit,
			operationType: transaction.opType,
			recLabel: transaction.recLabel,
			hash: transaction.recHash,
			startDate: transaction.startDate.toString(),
			endDate: transaction.endDate.toString(),
			origins: transaction.originCertificates,
			assetCo2PerMWh: transaction.co2PerMWh,
			assetStartOfOperation: transaction.startOfOperation,
			certificateIdRange: transaction.certificate_id_range,
			blockHash: transaction.blockHash,
			setPDFBase64: setPDFBase64,
			download: true
		});

		if(!res){
			enqueueSnackbar('Error to download this certificate. Check if your enteprise has a valid logo or this certificate was finished completely', {
				variant: 'error'
			});
		}
	};

	useEffect(()=>{
		// Cria um delay para dar tempo de carregar o componente com as transações
		setTimeout(()=>{
			setIsLoaded(isLoaded)
		},800)
	},[isLoaded])

	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value);
		setSelectedPage(value)
	};

	useEffect(() => {
		if(transactions && transactions?.length > 0){
			setSortedData(transactions);
		}
	}, [transactions]);

	const firstDivRef = useRef(null);
	const secondDivRef = useRef(null);

	const handleScrollFirst = (scroll: any) => {
		const current: any = secondDivRef.current;
		if (current) {
			current.scrollLeft = scroll.target.scrollLeft;
		}
	};

	const handleScrollSecond = (scroll: any) => {
		const current: any = firstDivRef.current;
		if (current) {
			current.scrollLeft = scroll.target.scrollLeft;
		}
	};

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: string
	) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const createSortHandler =
		(property: string) => (event: React.MouseEvent<unknown>) => {
			handleRequestSort(event, property);
		};

	const validateRetiredRec = (
		arr: ICertificateOrg[]
	): {
		validateCertificate: boolean;
		errors: string[];
	} => {
		const errors = [];
		const checkBeneficiary = !arr.every(
			element => element.beneficiary === arr[0].beneficiary
		);
		const checkRec = !arr.every(element => element.recId === arr[0].recId);
		const checkSource = !arr.every(
			element => element.assetSourceType === arr[0].assetSourceType
		);
		const checkRecAmount = !arr.every(element => element.amount > 0);
		const checkPeriod = !arr.every(
			element => element.period.split('/')[1] === arr[0].period.split('/')[1]
		);
		const checkRetiredRec = !arr.every(element => element.opType != 'retirement');

		if (checkBeneficiary) errors.push('Holder');
		if (checkRec) errors.push('REC');
		if (checkSource) errors.push('Source');
		if (checkPeriod) errors.push('Period');
		if (checkRecAmount) errors.push('Amount');
		if (checkRetiredRec) errors.push('Cert Retired');
		return {
			validateCertificate:
				checkBeneficiary ||
				checkRec ||
				checkSource ||
				checkPeriod ||
				checkRetiredRec ||
				checkRecAmount,
			errors
		};
	};

	const sendRecTeriment = (event: any, object: any, idRec: string) => {
		if (backRetirement === 'tOperation') {
			localStorage.removeItem('retrimentPage');
			newObject = [];
		}

		if (event.target.checked) {
			newObject = newObject
				.filter(item => item.id !== object.id)
				.concat(object);

			const amountTotal: number = newObject.reduce((acumulador, objeto) => {
				return Number(acumulador) + Number(objeto.amount);
			}, 0);
			setTotalAmount(amountTotal);

			const { validateCertificate, errors } = validateRetiredRec(newObject);

			setValidatedRetirement(validateCertificate);
			if (validateCertificate) {
				enqueueSnackbar(
					`You can not combine these certificates,
					this fields are differents: ${errors.join(', ')}`,
					{
						variant: 'error',
						autoHideDuration: 5000
					}
				);
			}

			setTranferRetired(newObject);
			setQuantityRowSelected(newObject.length);
		} else {
			newObject = newObject.filter(item => item.id !== object.id);
			const { validateCertificate, errors } = validateRetiredRec(newObject);

			setValidatedRetirement(validateCertificate);
			setTranferRetired(newObject);
			setQuantityRowSelected(newObject.length);
		}
	};

	const submitRetirementRec = () => {
		history(`/rec/operation-history/rec-retirement/`, {
			state: { data: tranferRetired, totalAmount }
		});
	};

	return (
		<Paper
			{...props}
			sx={{
				// width: ''
				boxShadow: 'none',
				...sx
			}}
		>
			<TableContainer
				sx={{ overflow: 'hidden' }}
				onScroll={handleScrollFirst}
				ref={firstDivRef}
			>
				<Box display="flex" alignItems="center" justifyContent="space-between">
					<Typography
						sx={{
							padding: '16px 0 0 16px',
							fontSize: '1.5rem',
							fontWeight: '700',
							color: colors.primaryDark
						}}
					>
						{title}
					</Typography>
					{isLoaded &&
						<Pagination renderItem={(item) => (
								<PaginationItem
									sx={{color: '#6F7075'}}
									{...item}
								/>
							)}
							count={totalPages}
							disabled={!isLoaded}
							showFirstButton
							showLastButton
							color='standard'
							shape='rounded'
							page={page}
							onChange={handleChange}
							sx={{marginLeft: 'auto', marginTop: '1%', marginRight: '2%'}}
						/>
					}
					{tableTransaction && (
						<Tooltip title={!isClientActivate ? 'Client not activated': ''}>
							<Box paddingTop="20px">
								<LoadingButton
									disableElevation
									onClick={submitRetirementRec}
									loading={false}
									variant="contained"
									disabled={!isClientActivate || validatedReirement || quantityRowSelected < 1}
									sx={{
										height: '40px',
										width: '202px',
										backgroundColor: validatedReirement
											? colors.primaryLight
											: colors.primary,
										borderRadius: '25px',
										'&.MuiButton-root.Mui-disabled': {
											backgroundColor: colors.primaryLight
										},
										padding: '4px 34px',
										marginRight: '17px',
										boxShadow: 'none',
										'&:hover': {
											backgroundColor: validatedReirement
												? colors.primaryLight
												: colors.primary
										}
									}}
								>
									<Typography
										fontWeight="400"
										fontSize="20px"
										textTransform="initial"
										color="#fff"
									>
										Retire Rec
									</Typography>
								</LoadingButton>
							</Box>
						</Tooltip>
					)}
				</Box>
				<TableM>
					<TableHead>
						<TableRow>
							{titles.slice().map((value, index) => (
								<TableCell
									align={value.numeric ? 'left' : 'center'}
									key={value.id}
									padding={value.disablePadding ? 'none' : 'normal'}
									sortDirection={orderBy === value.id ? order : false}
									sx={{
										paddingRight:
											(titles.length - 1 === index && '40px') || '16px',
										borderTopLeftRadius: index === 0 ? '16px' : 0
									}}
								>
									{value.id === 'checkbox' && tableTransaction && (
										<Box position="relative" top="15px" width="0">
											<Checkbox
												color="success"
												disabled
												sx={{
													color: '#8E8E8E',
													'&.Mui-checked': {
														color: '#00CA95'
													}
												}}
											/>
										</Box>
									)}
									{value.id !== 'report' ? (
										<TableSortLabel
											active={orderBy === value.id}
											direction={orderBy === value.id ? order : 'asc'}
											onClick={createSortHandler(value.id)}
											sx={{ width: '10px' }}
										>
											<Typography
												sx={{
													whiteSpace: 'pre',
													fontSize: '0.75rem',
													fontWeight: '700',
													color: '#9FA2B4'
												}}
											>
												{value.label}
											</Typography>
											{orderBy === value.id ? (
												<Box component="span" sx={visuallyHidden}>
													{order === 'desc'
														? 'sorted descending'
														: 'sorted ascending'}
												</Box>
											) : null}
										</TableSortLabel>
									) : (
										<Typography
											sx={{
												whiteSpace: 'pre',
												fontSize: '.75rem',
												fontWeight: '700',
												color: '#9FA2B4'
											}}
										>
											{value.label}
										</Typography>
									)}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
				</TableM>
			</TableContainer>
			<TableContainer
				onScroll={handleScrollSecond}
				ref={secondDivRef}
				sx={{
					height: 'calc(100vh - 640px)',
					minHeight: '300px',
					...customScroll
				}}
			>
				<TableM stickyHeader aria-label="sticky table">
					<TableBody>
						{sortedData &&
							sortedData
								.slice()
								.sort(getComparator(order, orderBy))
								.map(transaction => (
									<TableRow key={transaction.id}>
										{tableTransaction && (
											<TableCell>
												<Checkbox
													onChange={(e: any) =>
														sendRecTeriment(e, transaction, transaction.id)
													}
													color="success"
													disabled={transaction.opType == 'retired' || transaction.amount <= 0}
													sx={{
														color: '#8E8E8E',
														'&.Mui-checked': {
															color: '#00CA95'
														}
													}}
												/>
											</TableCell>
										)}
										<TableCell>
											<TextTableBody sx={{ whiteSpace: 'normal' }}>
												{transaction.date}
											</TextTableBody>
										</TableCell>
										<TableCell>
											<TextTableBody>{transaction.orgName}</TextTableBody>
										</TableCell>
										<TableCell>
											<TextTableBody>{transaction.opType}</TextTableBody>
										</TableCell>
										<TableCell>
											<TextTableBody>{transaction.status}</TextTableBody>
										</TableCell>
										{tableTransaction && (
											<TableCell sx={{ paddingRight: '25px' }}>
												<TextTableBody>{transaction.recLabel}</TextTableBody>
											</TableCell>
										)}
										<TableCell>
											<TextTableBody>{transaction.assetSourceType}</TextTableBody>
										</TableCell>
										<TableCell>
											<TextTableBody>{transaction.assetUnitID}</TextTableBody>
										</TableCell>
										<TableCell>
											<TextTableBody>{transaction.assetState}</TextTableBody>
										</TableCell>
										<TableCell>
											<TextTableBody>{transaction.period}</TextTableBody>
										</TableCell>
										<TableCell>
											<TextTableBody>{transaction.assetUnitID}</TextTableBody>
										</TableCell>
										<TableCell>
											<TextTableBody>{transaction.amount}</TextTableBody>
										</TableCell>
										<TableCell>
											<ButtonBase
											onClick={async () => handleCreatePDF(transaction)}
											>
												<PictureAsPdfIcon
													sx={{
														fill: '#000'
													}}
												/>
											</ButtonBase>
										</TableCell>
									</TableRow>
								))}
					</TableBody>
				</TableM>
				{!isloaded && (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '100%'
							// paddingTop: '30px'
						}}
					>
						<CircularProgress />
					</Box>
				)}
				{isloaded && (sortedData == null || sortedData.length < 1) && (
					<Typography
						sx={{
							display: 'flex',
							paddingTop: '20px',
							width: '100%',
							justifyContent: 'center',
							alignItems: 'center',
							fontSize: '1.75rem',
							fontWeight: '700',
							color: colors.primary
						}}
					>
						Empty table!
					</Typography>
				)}
			</TableContainer>
		</Paper>
	);
};
