import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

export const WalletList = ({ assetRecs }: any) => {
	const [recOverflow, setRecOverflow] = useState('hidden');

	useEffect(() => {
		if (assetRecs?.assetRecs?.length > 4) {
			setRecOverflow('scroll');
		}
	}, [assetRecs]);

	return (
		<>
			<Box
				component="main"
				sx={{
					marginTop: '40px',
					marginLeft: '30px'
				}}
			>
				<Typography
					fontFamily={'Sen'}
					fontStyle="normal"
					fontWeight="700"
					fontSize="16px"
					lineHeight="19px"
					color="#636C7A"
					sx={{ marginBottom: '20px' }}
				>
					WALLET
				</Typography>
				<Grid
					container
					md={12}
					sx={{
						width: '821px',
						height: '28px',
						marginBottom: '9px'
					}}
				>
					<Grid item md={4} sx={{ marginBottom: '9px' }}>
						<Typography
							fontFamily="Sen"
							fontStyle="normal"
							fontWeight="400"
							fontSize="16px"
							lineHeight="19px"
							color="#6F7075"
						>
							REC TYPE
						</Typography>
					</Grid>
					<Grid item md={3} sx={{ marginBottom: '9px' }}>
						<Typography
							fontFamily="Sen"
							fontStyle="normal"
							fontWeight="400"
							fontSize="16px"
							lineHeight="19px"
							color="#6F7075"
						>
							CREATION DATE
						</Typography>
					</Grid>
					<Grid item md={3} sx={{ marginBottom: '9px' }}>
						<Typography
							fontFamily="Sen"
							fontStyle="normal"
							fontWeight="400"
							fontSize="16px"
							lineHeight="19px"
							color="#6F7075"
						>
							REC HASH
						</Typography>
					</Grid>
					<Grid item md={2} sx={{ marginBottom: '9px' }}>
						<Typography
							fontFamily="Sen"
							fontStyle="normal"
							fontWeight="400"
							fontSize="16px"
							lineHeight="19px"
							color="#6F7075"
						>
							BALANCE
						</Typography>
					</Grid>
				</Grid>
				{assetRecs?.assetRecs?.length > 0 && (
					<Box
						sx={{
							height: '140px',
							maxWidth: '850px',
							marginRight: '20px',
							'overflow-x': 'hidden',
							'overflow-y': recOverflow,
							'::-webkit-scrollbar': {
								border: '1px solid #D4D7DE',
								width: '15px',
								borderRadius: '15px'
							},
							'::-webkit-scrollbar-thumb': {
								backgroundColor: '#D9D9D9',
								borderRadius: '15px',
								height: '50px',
								width: '15px'
							}
						}}
					>
						{assetRecs?.assetRecs?.map((item: any) => {
							return (
								<Grid
									container
									md={12}
									sx={{
										width: '821px',
										height: '28px',
										border: '1px solid #E0E0E0',
										borderRadius: '20px',
										backgroundColor: '#e1e4ed',
										marginBottom: '9px'
									}}
								>
									<Grid item md={4} sx={{ marginBottom: '9px' }}>
										<Box
											sx={{
												backgroundColor: '#6F7075',
												width: '170px',
												height: '28px',
												borderRadius: '20px'
											}}
										>
											<Typography color="#FFFFFF" sx={{
												marginLeft: '16px',
												display: 'flex',
												alignItems: 'center',
												height: '28px',
												lineHeight: '28px',
												}}>
												{item.rec.type.toUpperCase()}
											</Typography>
										</Box>
									</Grid>
									<Grid item md={3} sx={{ marginBottom: '9px' }}>
										<Typography
											fontFamily="Sen"
											fontStyle="normal"
											fontWeight="400"
											fontSize="16px"
											display='flex'
											alignItems='center'
											height='28px'
											lineHeight='28px'
											color="#000000"
										>
											{item.rec.created_at.slice(0, 10)}
										</Typography>
									</Grid>
									<Grid item md={3} sx={{ marginBottom: '9px' }}>
										<Tooltip placement="top-start" title={item.rec.id}>
										<Typography
												style={{
												display: 'flex',
												alignItems: 'center',
												height: '28px',
												lineHeight: '28px',
											}}
										>{item.rec.id.slice(0, 15)}...</Typography>
										</Tooltip>
									</Grid>
									<Grid item md={2} sx={{ marginBottom: '9px' }}>
										<Typography
												style={{
												display: 'flex',
												alignItems: 'center',
												height: '28px',
												lineHeight: '28px',
											}}
										>{item.total_recs}</Typography>
									</Grid>
								</Grid>
							);
						})}
					</Box>
				)}
			</Box>
			{assetRecs?.assetRecs?.length === 0 && (
				<Box
					sx={{
						width: '821px',
						height: '140px',
						border: '1px solid #E0E0E0',
						borderRadius: '20px',
						backgroundColor: '#e1e4ed',
						marginBottom: '9px'
					}}
				>
					<Typography
						fontFamily="Sen"
						fontStyle="normal"
						fontWeight="700"
						fontSize="16px"
						lineHeight="19px"
						color="#6F7075"
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '100%'
						}}
					>
						No records were found
					</Typography>
				</Box>
			)}
		</>
	);
};
