/* eslint-disable prettier/prettier */
import {
  FormHelperText,
  Grid,
  OutlinedInput,
  InputLabel,
  Box
} from '@mui/material';

import recoveryImg from '../../../assets/imgs/recoveryPassword.png';
import { colors } from '../../../styles';

type InputFormReturn = {
  email?: string;
  token?: string;
  password?: string;
  confirmPassword?: string;
};

export type TInputForm = {
  // form: UseFormReturn<InputFormReturn>;
  setInputEmail: (e: string) => void;
};

export const EmailForm = ({ setInputEmail }: TInputForm) => {
  const style = {
    borderRadius: '9px',
    marginTop: '20px'
  };

  return (
    <>
      <Grid item xs={6} md={12} marginBottom="20px">
        <InputLabel
          error={false}
          shrink
          htmlFor="email"
          required
        >
          Please input your e-mail account
        </InputLabel>
        <OutlinedInput
          fullWidth
          // style={style}
          error={false}
          placeholder="e-mail..."
          type="string"
          onChange={e => setInputEmail(e.target.value)}
        />
        {/* {!!form.formState.errors.email && (
          <FormHelperText error>
            {form?.formState?.errors?.email?.message}
          </FormHelperText>
        )} */}
        <FormHelperText
          sx={{
            marginTop: '37px',
            fontSize: '20px',
            fontWeight: '100',
            color: colors.neutralDark,
            marginBottom: '30px'
          }}>
          An e-mail will be sent to your inbox soon...
        </FormHelperText>
      </Grid>
      <Grid
        item
        md={12}
        display="flex"
        justifyContent="center"
        marginBottom='40px'
      >
        <Box>
          <img src={recoveryImg} alt="recovery"></img>
        </Box>
      </Grid>
    </>
  )
}
