import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Card, Typography } from '@mui/material';

import Plus from '../../../assets/imgs/plus.png';
import { colors } from '../../../styles';
import { ListMember } from './listMember';
import { ModalMember } from './modalMember';

export type TCardMember = {
	dataUser: Array<object>;
};
interface IListMemberData {
	first_name: string;
	last_name: string;
}

const paddingEmpty = '42px 39px 62px 39px';
const paddingList = '40px 24px 45px 26px';

export const CardMember = () => {
	const [open, setOpen] = useState<boolean>(false);
	const [roleFilter, setRoleFilter] = useState<string>('');
	const [defaultList, setDefaultList] = useState<Array<IListMemberData>>();
	const [dataListMember, setDataListMember] =
		useState<Array<IListMemberData>>();

	const listMemberEnterprise = useSelector(
		(state: any) => state.listMemberData
	);

	const addMemberModal = () => {
		setOpen(!open);
	};

	useEffect(() => {
		const filtered = dataListMember?.filter(
			(value: any) => value.role === roleFilter
		);
		if (roleFilter === 'allMembers') {
			setDefaultList(dataListMember);
			return;
		}
		setDefaultList(filtered);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [roleFilter]);

	const getListMember = async () => {
		const respListMember = await listMemberEnterprise;
		if (respListMember.status === 200) {
			setDataListMember(respListMember.data);
			setDefaultList(respListMember.data);
		}
	};

	useEffect(() => {
		getListMember();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listMemberEnterprise]);

	return (
		<>
			<Card
				sx={{
					padding: !dataListMember?.length ? paddingEmpty : paddingList,
					boxShadow: 'none',
					backgroundColor: colors.primary,
					border: '1px solid #D4D7DE',
					maxWidth: '474px',
					maxHeight: !dataListMember?.length ? '424px' : '625px',
					borderRadius: '32px'
				}}
			>
				{!dataListMember?.length ? (
					<>
						<Box>
							<Typography
								fontWeight="700"
								fontSize="2.25rem"
								lineHeight="48px"
								color="#fff"
								marginBottom="10px"
							>
								Members
							</Typography>
							<Typography
								fontSize="1rem"
								fontWeight="400"
								lineHeight="24px"
								color="#fff"
								marginBottom="59px"
							>
								You currently don´t have any member yet
							</Typography>
						</Box>
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							flexDirection="column"
						>
							<Box
								width="83px"
								height="83px"
								onClick={addMemberModal}
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									backgroundColor: colors.neutralLight,
									border: '1px solid #6F7075',
									borderRadius: '50%',
									marginBottom: '72px',
									cursor: 'pointer'
								}}
							>
								<img src={Plus} alt="plus" />
							</Box>
							<Box>
								<Typography
									fontSize="1rem"
									fontWeight="400"
									lineHeight="24px"
									color="#fff"
								>
									Add your first member to get started
								</Typography>
							</Box>
							<ModalMember setOpen={setOpen} open={open} />
						</Box>
					</>
				) : (
					<ListMember
						dataListMember={defaultList}
						setRoleFilter={setRoleFilter}
					/>
				)}
			</Card>
		</>
	);
};
