// Core
export const customScroll = {
	'&::-webkit-scrollbar': {
		height: '14px',
		width: '20px'
	},

	'&::-webkit-scrollbar-track': {
		border: `1px solid #CDD3E1`,
		borderRadius: '16px'
	},

	'&::-webkit-scrollbar-thumb': {
		background: '#D9D9D9',
		borderRadius: '16px'
	},

	'&::-webkit-scrollbar-thumb:hover': {
		background: '#D9D9D9'
	}
};
