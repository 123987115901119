import { Box, Typography } from '@mui/material';
import React, { PureComponent, useEffect, useState, RefObject } from 'react';
import { IEnergyBankData } from '../../../types/dashboard';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer
} from 'recharts';

export type SampleChartProps = {
	energyBank: IEnergyBankData;
	chartOption: string;
	availableMonths: string[];
	selectedYear: string;
	expandedChart: boolean;
	setChartOption: (value: string) => void;
	setSelectedYear: (value: string) => void;
	setSelectedMonth: (value: string) => void;
	chartContainerRef: RefObject<HTMLDivElement>;
};

const monthOrder: Record<string, number> = {
    'Jan': 0,
    'Feb': 1,
    'Mar': 2,
    'Apr': 3,
    'May': 4,
    'Jun': 5,
    'Jul': 6,
    'Aug': 7,
    'Sep': 8,
    'Oct': 9,
    'Nov': 10,
    'Dec': 11
  };

export const Example = ({
	energyBank,
	availableMonths,
	chartOption,
	expandedChart,
	setChartOption,
	setSelectedYear, 
	setSelectedMonth,
	selectedYear,
	chartContainerRef
}: SampleChartProps) => {
    const [fatherWidth, setFatherWidth] = useState(818);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [dataMonths, setDataMonths] = useState<any>(false);
    const [dataYears, setDataYears] = useState<any>(false);
	const handleChartOptionChange = (e: any) => {
		if (chartOption === 'year') {
			setChartOption('month');
			setSelectedYear(e.name)
		} else {
			setChartOption('day');
			setSelectedMonth(e.name)
		}
	};

	// Delay para que o recharts faça animação do gráfico (necessário quando a quantidade de dados é pouca)
	useEffect(() => {
		setIsLoaded(false)
		const timeoutId = setTimeout(() => {
			setIsLoaded(true)
		}, 300);

		return () => clearTimeout(timeoutId);
	}, [chartOption, selectedYear]);

    useEffect(() => {
		function handleResize() {
			if (chartContainerRef.current) {
			setFatherWidth(chartContainerRef.current.clientWidth);
		  }
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [chartContainerRef, expandedChart]);
	
	  const getMonthsOfYear = (year: string) => {
		const res = energyBank.data.map((assets)=>{
			const months = assets.years
				?.find(y => y.year === year)
				?.months?.slice(0, 12)
				.map(({ month, total }) => ({ name: month, MWh: Math.round(total/1000000 * 100) / 100, asset: assets.asset})) || [];
			return months;
		})
		const result: {name: string;MWh: number; asset: string}[] = [];
		res.forEach(subArray => {
			subArray.forEach(item => {
				const existingItem = result.find(i => i.name === item.name);
			
				if (existingItem) {
				existingItem.MWh += item.MWh;
				existingItem.MWh = parseFloat(existingItem.MWh.toFixed(2))
				} else {
				result.push({ ...item});
				}
			});
			});
		const sortedMonths = result.sort((a, b) => monthOrder[a.name] - monthOrder[b.name]);
		return sortedMonths;
	  };
	const getDataYears = () => {
		const asdojds = energyBank.data.map((asset)=>{
			const res = asset.years?.map(year => {
				return {
					name: year.year,
					MWh: Math.round(year.total/1000000 * 100) / 100 || 0.0, // Se o total não estiver definido, assume 0
				};
			}) || [];
			return res;
		})

		const result: {name: string;MWh: number;}[] = [];

		asdojds.forEach(subArray => {
			subArray.forEach(item => {
				const existingItem = result.find(i => i.name === item.name);
			
				if (existingItem) {
				existingItem.MWh += item.MWh;
				existingItem.MWh = parseFloat(existingItem.MWh.toFixed(2))
				} else {
				result.push({ ...item });
				}
			});
			});
		const sortedYears = result.sort((a, b) => parseInt(a.name, 10) - parseInt(b.name, 10));
		return sortedYears;
	};
	useEffect(()=>{
		if(energyBank?.data?.length >= 1){
			setDataMonths(getMonthsOfYear(selectedYear));
			setDataYears(getDataYears());
		}
	},[energyBank,selectedYear])

	const AxisLabel = () => {
		const isVert = true;
		const cx = isVert ? 20 : 100 + (200 / 2);
		const cy = isVert ? (300 / 2) + 2 : 2 + 300 + 10;
		const rot = isVert ? `270 ${cx} ${cy}` : 0;
		return (
			// <Typography
			// 	fontFamily="Sen"
			// 	fontStyle="normal"
			// 	fontWeight="700"
			// 	fontSize="33.218px"
			// 	lineHeight="44px"
			// 	color="#6F7075"
			// 	dir='vertical'
			// >
			// 	Power Units RECs
			// </Typography>
			<text fontFamily='Sen'fontSize={22} color='#aF7075' x={cx} y={cy} transform={`rotate(${rot})`} textAnchor="middle">
			MWh
			</text>
		);
	};
	return (
		<Box sx={{ height: '320px', margin: '3% 0 0 5%' }}>
			{isLoaded && dataYears && dataMonths &&
				<BarChart
					width={expandedChart ? fatherWidth> 1500 ? 1200: fatherWidth: fatherWidth> 810? 808: fatherWidth}
					height={320}
					cx={60}
					data={chartOption === 'year' ? dataYears : dataMonths}
					margin={{
						top: 5,
						right: 0,
						left: 15,
						bottom: 5
					}}
				>
					<XAxis
						dataKey="name"
						fontFamily="Sen"
						color="#0000"
						fontSize={expandedChart? '22px': '16px'}
						scale="point"
						padding={{
							left: expandedChart ? dataYears.length <= 5  ?
							150 : 90: dataYears.length <= 5 ? 82 : 42,
							right: expandedChart ? 100: 50
						}}
						tickLine={false}
					/>
					<YAxis  label={{ value: 'MWh', angle: -90, fontFamily: 'Sen', fontSize: '20px', position: 'insideLeft'}}/>
					<Tooltip />
					<Bar
						dataKey="MWh"
						fill="#636C7A"
						onClick={handleChartOptionChange}
						background={{ fill: '#D8D9DD' }}
						maxBarSize={100}
						label={{
							position: 'insideTop',
							fill: '#FFFFFF',
							fontFamily: 'Sen',
							fontSize: expandedChart ? '16px' : '12px',
							fontWeight: '400'
						}}
					/>
				</BarChart>
			}
		</Box>
	);
};
