import { useSelector } from 'react-redux';

import { Box, Card, Grid, Typography } from '@mui/material';

import Computer from '../../assets/imgs/computer.svg';
import Globe from '../../assets/imgs/globe.png';
import MultiEdger from '../../assets/imgs/multiedger.svg';
// eslint-disable-next-line import/extensions
import * as colors_1 from '../../styles/colors';
// eslint-disable-next-line import/extensions
import { CardSignup } from './cardSignup';
import { RecoveryPassword } from './recoveryPassword';

export const Signup = () => {
	// const isRecoveryPassword = useSelector(
	// 	(state: any) => state.recoveryPassword
	// );

	let persistRecoveryPassword = "";

	try {
		persistRecoveryPassword = JSON.parse(
			localStorage.getItem('persistRecovery') || ''
		);
	} catch (error) {
		console.log(error)
	}

	return (
		<Grid container md={12} sm={12} xs={12} position="relative">
			<Grid
				item
				md={6}
				sm={6}
				xs={6}
				sx={{
					// position: 'relative',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					height: '100vh',
					filter: 'grayscale(.1%)'
				}}
			>
				<Box
					sx={{
						alignSelf: 'flex-start',
						marginLeft: '118px',
						marginTop: '160px'
					}}
				>
					<Typography
						fontSize="3rem"
						fontWeight="700"
						lineHeight="64px"
						color={colors_1.colors.primaryLight}
					>
						Welcome to <br /> Multi Asset Tokenizer
					</Typography>
				</Box>
				<Card
					sx={{
						position: 'absolute',
						background: `${colors_1.colors.primary}`,
						height: '100%',
						width: '100%',
						backgroundBlendMode: 'luminosity',
						zIndex: -1
					}}
				>
					<Box
						sx={{
							width: '100%',
							height: '100%',
							opacity: '.3',
							backgroundBlendMode: 'luminosity',
							background: `${colors_1.colors.primary} url(${Globe}) no-repeat left bottom`
						}}
					></Box>
				</Card>

				<Card
					sx={{
						boxShadow: '0px 4px 34px gray',
						position: 'absolute',
						right: '-274px',
						zIndex: 2,
						borderRadius: '40px',
						width: '568px'
					}}
				>
					{!persistRecoveryPassword ? <CardSignup /> : <RecoveryPassword />}
				</Card>
			</Grid>
			<Grid
				item
				md={6}
				sm={6}
				xs={6}
				sx={{
					background: colors_1.colors.neutralLight,
					zIndex: -1,
					display: 'flex',
					justifyContent: 'flex-end'
				}}
			>
				<Card
					sx={{
						marginTop: '152px',
						marginLeft: '100px',
						alignSelf: 'flex-start',
						marginRight: '157px'
					}}
				>
					<Box>
						<img src={MultiEdger} alt="slogan"></img>
					</Box>
				</Card>
				<Box
					sx={{
						// paddingTop: '150px',
						background: `${colors_1.colors.neutralLight} url(${Computer}) no-repeat right 160px fixed`,
						width: '100%',
						height: '100%',
						position: 'absolute',
						backgroundBlendMode: 'luminosity',
						zIndex: -1
					}}
				></Box>
			</Grid>
		</Grid>
	);
};
