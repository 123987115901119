import * as Yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(Yup);

export const SchemaRecoveryDefault = Yup.object().shape({
	token: Yup.string().required('type tyour token'),
	password: Yup.string()
		.required()
		.password()
		.minNumbers(1, '1 number')
		.minSymbols(1, '1 special character')
		.min(8, '8 characters')
		.minUppercase(1, '1 capital leter')
		.minLowercase(1, '1 leter')
});
