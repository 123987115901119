import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { IOrigins } from '../types/certificateOrg';
import dayjs from "dayjs";
import CERTIFICATE_BACKGROUND from '../assets/imgs/CERTIFICATE_BACKGROUND.png';
import CERTIFICATE_BACKGROUND_ATTACHMENTS from '../assets/imgs/CERTIFICATE_BACKGROUND_ATTACHMENTS.png';
import { EnterpriseService } from "../services/enterprise.service";
import { ClientService } from "../services/client.service";
import { ApiPromise, WsProvider } from "@polkadot/api";

interface IOnchainCert {
    amount: string,
}

interface IRenderPDF {
    id: string,
    beneficiaryId: string,
    createdAt: string,
    provider: string,
    status: string,
    beneficiary: string,
    beneficiaryWallet: string,
    sourceType: string,
    orgName: string,
    powerUnit: string,
    country: string,
    state: string,
    city: string,
    period: string,
    unitID: string,
    amountRec: string,
    asset_name: string,
    operationType: string,
    recLabel: string,
    hash: string,
    blockHash: string,
    startDate: string,
    endDate: string,
    assetCo2PerMWh?: string,
    assetStartOfOperation?: string,
    certificateIdRange?: string,
    origins: IOrigins[],
    setPDFBase64: (value: string) => void,
    download: boolean,
}

const renderPDF = async ({
    id,
    beneficiaryId,
    createdAt,
    provider,
    status,
    beneficiary,
    beneficiaryWallet,
    sourceType,
    orgName,
    powerUnit,
    country,
    state,
    city,
    period,
    unitID,
    amountRec,
    asset_name,
    operationType,
    recLabel,
    hash,
    blockHash,
    startDate,
    endDate,
    origins,
    assetCo2PerMWh,
    assetStartOfOperation,
    certificateIdRange,
    setPDFBase64,
    download,
}: IRenderPDF) => {
    const consumptionDate = `${new Date(startDate).getUTCFullYear()}-01 - ${new Date(startDate).getUTCFullYear()}-12`
    const enterpriseService =  new EnterpriseService();
    const clientService =  new ClientService();
    
    async function getEnterprise() {
        const resp: any = await enterpriseService.getEnterpriseLogo();
        if (resp.status == 200){
            return resp.data
        }
        return ''
    }

    async function getClientAddress() {
        const resp: any = await clientService.listClientById(beneficiaryId);
        const result = `${resp.country} - ${resp.state} - ${resp.city}`
        if (resp.country){
            return result
        }
        return ''
    }

    async function queryRec() {
        const wsProvider = new WsProvider(`${process.env.REACT_APP_PARACHAIN_ADDRESS}`);
        const api = await ApiPromise.create({
          provider: wsProvider
        });
        try {
            const queryResult = await api.query.matRec.recCertificateCurrentBalance(beneficiaryWallet,hash);
            const onchainCert = queryResult.toJSON() as unknown as IOnchainCert;
            await api.disconnect();
            return onchainCert
        } catch (error) {
            await api.disconnect();
            return {} as IOnchainCert
        }
    }

    function assingCertData(cert:IOnchainCert) {
        amountRec = cert.amount
    }

    async function getBase64ImageFromURL(url: string) {
        return new Promise((resolve, reject) => {
          var img = new Image();
          img.setAttribute("crossOrigin", "anonymous");
          img.onload = () => {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
      
            var ctx = canvas.getContext("2d");
            ctx?.drawImage(img, 0, 0);
      
            var dataURL = canvas.toDataURL("image/png");
      
            resolve(dataURL);
          };
      
          img.onerror = error => {
            reject(error);
          };
      
          img.src = url;
        });
    }

    const enterpriseLogo = await getEnterprise() as string;
    if(!enterpriseLogo){
        return "";
    }





    const certAttachments = await getBase64ImageFromURL(CERTIFICATE_BACKGROUND_ATTACHMENTS) as string;
    const certBackground = await getBase64ImageFromURL(CERTIFICATE_BACKGROUND) as string;
    if (!download){
        const queryOnchain: IOnchainCert = await queryRec();
        if(queryOnchain.amount && origins){
            assingCertData(queryOnchain)
        }
    }
    console.log(
        id,
        beneficiaryId,
        createdAt,
        provider,
        status,
        beneficiary,
        sourceType,
        orgName,
        powerUnit,
        country,
        state,
        city,
        period,
        unitID,
        amountRec,
        asset_name,
        operationType,
        recLabel,
        hash,
        blockHash,
        startDate)
    if (
        !id ||
        !beneficiaryId ||
        !createdAt ||
        !provider ||
        !status ||
        !beneficiary ||
        !sourceType ||
        !orgName ||
        !powerUnit ||
        !country ||
        !state ||
        !city ||
        !period ||
        !unitID ||
        !amountRec ||
        !asset_name ||
        !operationType ||
        !recLabel ||
        !hash ||
        !blockHash ||
        !startDate
    )
    {
        return "";
    }

    try {
        let clientAddress = ""
        if (operationType == 'issuance'){
            provider = beneficiary;
            // startDate = `${startDate.slice(0,4)}-01`
            // endDate = `${endDate.slice(0,4)}-12`
        }
        if (operationType == 'retirement'){
            status = 'Retired';
            clientAddress = await getClientAddress() as string;
            if(!clientAddress){
                return "";
            }
        }else{
            if (amountRec == '0' || status == 'done') {
                status = 'Depleted';
            } else{
                status = 'Active';
            }
        }

        let AttachmentsContent: any[] = [];

        if (operationType == "issuance"){
            AttachmentsContent.push(
                '\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n',
                {
                    text: `${unitID}`,
                    style: 'tinyData',
                    absolutePosition: {x: 18, y: 176}
                },
                {
                    text: `${powerUnit}`,
                    style: `${powerUnit.length > 22 ? 'tinyData' : powerUnit.length > 15 ? 'tinyData' : 'tinyData'}`,
                    absolutePosition: {x: 118, y: 176}
                },
                {
                    text: `${country} - `,
                    style: 'tinyData',
                    absolutePosition: {x: 217, y: 176}
                },
                {
                    text: `${city}`,
                    style: 'tinyData',
                    absolutePosition: {x: 245, y: 176}
                },
                {
                    text: `${sourceType}`,
                    style: 'tinyData',
                    absolutePosition: {x: 365, y: 176}
                },
                {
                    text: `${dayjs(assetStartOfOperation).format('YYYY-MM')}`,
                    style: 'tinyData',
                    absolutePosition: {x: 407, y: 176}
                },
                {
                    text: `${dayjs(startDate).format('YYYY-MM')} - `,
                    style: 'tinyData',
                    absolutePosition: {x: 467, y: 176}
                },
                {
                    text: `${dayjs(endDate).format('YYYY-MM')}`,
                    style: 'tinyData',
                    absolutePosition: {x: 502, y: 176}
                },
                {
                    text: `${assetCo2PerMWh}`,
                    style: 'tinyData',
                    absolutePosition: {x: 567, y: 176}
                },
                {
                    text: `${certificateIdRange}`,
                    style: 'tinyData',
                    absolutePosition: {x: 605, y: 176}
                },
                {
                    text: `${amountRec}`,
                    style: 'tinyData',
                    absolutePosition: {x: 783, y: 176}
                },
                {
                    image: 'organizationLogo',
                    width: 56,
                    height: 56,
                    absolutePosition: {x: 756, y: 18}
                },
                {
                    text: `${blockHash}`,
                    style: 'footer',
                    absolutePosition: {x: 389, y: 555}
                },
                {
                    text: `${hash}`,
                    style: 'footer',
                    absolutePosition: {x: 389, y: 569},
                },
                {
                    text: `${createdAt} GMT`,
                    style: 'footer',
                    absolutePosition: {x: 586, y: 540}
                },
                {
                    qr:`${process.env.REACT_APP_CLIENT_URL}/rec/certificates/${id}`,
                    fit: 51,
                    version: 7,
                    absolutePosition: {x: 18, y: 518}
                },
                {
                    text: `2`,
                    style: 'footer',
                    absolutePosition: {x: 815, y: 580}
                },
                );
        }
        else {
            const myOrigins = origins as IOrigins[] ;
            let yOffset = 176; 
            let counter = 0;
            let Totalcounter = 0;
            let pageCounter = 2;
            if(myOrigins){
                AttachmentsContent.push(
                    '\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n',
                )
            }
            myOrigins?.forEach(variable => {
                if (Number(variable.amount) > 0 ){
                    AttachmentsContent.push(
                        {
                            text: `${variable.asset_unit_id}`,
                            style: 'tinyData',
                            absolutePosition: {x: 18, y: yOffset}
                        },
                        {
                            text: `${variable.asset_name}`,
                            style: `${asset_name.length > 22 ? 'tinyData' : asset_name.length > 15 ? 'tinyData' : 'tinyData'}`,
                            absolutePosition: {x: 118, y: yOffset}
                        },
                        {
                            text: `${variable.asset_country} - `,
                            style: 'tinyData',
                            absolutePosition: {x: 217, y: yOffset}
                        },
                        {
                            text: `${variable.asset_city}`,
                            style: 'tinyData',
                            absolutePosition: {x: 245, y: yOffset}
                        },
                        {
                            text: `${variable.asset_source}`,
                            style: 'tinyData',
                            absolutePosition: {x: 365, y: yOffset}
                        },
                        {
                            text: `${dayjs(variable.asset_start_of_operation).format('YYYY-MM')}`,
                            style: 'tinyData',
                            absolutePosition: {x: 407, y: yOffset}
                        },
                        {
                            text: `${dayjs(variable.start_date).format('YYYY-MM')} - `,
                            style: 'tinyData',
                            absolutePosition: {x: 467, y: yOffset}
                        },
                        {
                            text: `${dayjs(variable.end_date).format('YYYY-MM')}`,
                            style: 'tinyData',
                            absolutePosition: {x: 504, y: yOffset}
                        },
                        {
                            text: `${variable.asset_co2_per_mwh}`,
                            style: 'tinyData',
                            absolutePosition: {x: 567, y: yOffset}
                        },
                        {
                            text: `${variable.certificate_id_range}`,
                            style: 'tinyData',
                            absolutePosition: {x: 605, y: yOffset}
                        },
                        {
                            text: `${variable.amount}`,
                            style: 'tinyData',
                            absolutePosition: {x: 783, y: yOffset}
                        },
                    );
                    yOffset += 10
                    counter += 1
                    Totalcounter += 1
                }
                if(counter == 32 || Totalcounter == myOrigins.length){
                    AttachmentsContent.push(
                        {
                            image: 'organizationLogo',
                            width: 56,
                            height: 56,
                            absolutePosition: {x: 756, y: 18}
                        },
                        {
                            text: `${blockHash}`,
                            style: 'footer',
                            absolutePosition: {x: 389, y: 555}
                        },
                        {
                            text: `${hash}`,
                            style: 'footer',
                            absolutePosition: {x: 389, y: 569},
                        },
                        {
                            text: `${createdAt} GMT`,
                            style: 'footer',
                            absolutePosition: {x: 586, y: 540}
                        },
                        {
                            qr:`${process.env.REACT_APP_CLIENT_URL}/rec/certificates/${id}`,
                            fit: 51,
                            version: 7,
                            absolutePosition: {x: 18, y: 518}
                        },
                        {
                            text: `${pageCounter}`,
                            style: 'footer',
                            absolutePosition: {x: 815, y: 580}
                        },
                    )
                    yOffset = 176;
                    counter = 0;
                    if(Totalcounter < myOrigins.length){
                        AttachmentsContent.push(
                            '\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n',
                        )
                        pageCounter += 1;
                    }
                }
            });
        }

        const docDefinition = {
            background: function (page: number) {
                if (page === 1) {
                    return [
                        {
                            image: 'certBackground',
                            height: 595.276,
                            width: 841.890,
                        }
                    ];
                }else{
                    return [
                        {
                            image: 'certBackgroundAttachments',
                            height: 595.276,
                            width: 841.890,
                        }
                    ];
                }
            },
            info: {
                title: 'Renew Energy Certificate',
                subject: 'certificate',
                keywords: 'rec',
            },
            images: {
                certBackground: certBackground,
                certBackgroundAttachments: certAttachments,
                organizationLogo: {
                    url: enterpriseLogo,
                    headers: {
                        myheader: '123',
                        myotherheader: 'abc',
                    },
                }
            },
            pageSize: {
                height: 595.276,  // Largura em pontos (A4)
                width: 841.890, // Altura em pontos (A4)
            },
            styles: {
                header: {
                    fontSize: 14,
                    bold: true,
                    lineHeight: 1.2,
                    characterSpacing: 4,
                    fillColor: '#2361AE'
                },
                subHeader: {
                    fontSize: 12,
                    color: '#8194A7',
                    lineHeight: 3,
                },
                mediumData: {
                    fontSize: 14,
                    bold: true,
                    characterSpacing: 2,
                    color: '#636C7A'
                },
                smallData: {
                    fontSize: 10,
                    bold: true,
                    color: '#636C7A'
                },
                tinyData: {
                    fontSize: 8,
                    bold: true,
                    color: '#636C7A'
                },
                reallyTinyData: {
                    fontSize: 6,
                    bold: false,
                    color: '#636C7A'
                },
                amountData: {
                    fontSize: 20,
                    bold: true,
                    characterSpacing: 2,
                    color: '#636C7A'
                },
                footer: {
                    fontSize: 8,
                    bold: false,
                    color: '#FFF'
                },
            },
            pageMargins: 0,

            content: [
                {
                    image: 'organizationLogo',
                    width: 56,
                    height: 56,
                    absolutePosition: {x: 756, y: 18}
                },
                {
                    text: `${amountRec}`,
                    style: 'amountData',
                    absolutePosition: {x: 98, y: 109}
                },
                {
                    text: `${recLabel}`,
                    style: 'mediumData',
                    absolutePosition: {x: 248, y: 109}
                },
                {
                    text: `${sourceType}`,
                    style: 'mediumData',
                    absolutePosition: {x: 451, y: 109}
                },
                {
                    text: `${status}`,
                    style: 'mediumData',
                    absolutePosition: {x: 625, y: 109}
                },
                {
                    text: `${provider}`,
                    style: `${provider.length > 40 ? 'reallyTinyData' : provider.length > 30 ? 'tinyData' : 'smallData'}`,
                    absolutePosition: {x: 146, y: 210}
                },
                {
                    text: `${beneficiary}`,
                    style: `${beneficiary.length > 40 ? 'reallyTinyData' : beneficiary.length > 30 ? 'tinyData' : 'smallData'}`,
                    absolutePosition: {x: 489, y: 210}
                },
                {
                    text: operationType == 'retirement' ? `${clientAddress}` : `${country} - ${state} - ${city}`,
                    style: operationType == 'retirement' ? `${clientAddress}`.length> 42 ? 'tinyData' :'smallData' : `${country} - ${state} - ${city}`.length > 42 ? 'tinyData' :'smallData',
                    absolutePosition: {x: 146, y: 288}
                },
                {
                    text: `${consumptionDate}`,
                    style: 'smallData',
                    absolutePosition: {x: 489, y: 288}
                },
                {
                    text: `${id}`,
                    style: 'tinyData',
                    absolutePosition: {x: 23, y: 380}
                },
                {
                    text: `${hash}`,
                    style: 'tinyData',
                    absolutePosition: {x: 225, y: 380}
                },
                {
                    text: `${amountRec}`,
                    style: 'tinyData',
                    absolutePosition: {x: 597, y: 380}
                },
                {
                    text: `${dayjs(createdAt).format('YYYY-MM-DD')}`,
                    style: 'tinyData',
                    absolutePosition: {x: 672, y: 380}
                },
                {
                    text: `${dayjs(startDate).format('YYYY-MM-DD')}`,
                    style: 'tinyData',
                    absolutePosition: {x: 752, y: 380}
                },
                {
                    qr:`${process.env.REACT_APP_CLIENT_URL}/rec/certificates/${id}`,
                    fit: 51,
                    version: 7,
                    absolutePosition: {x: 35, y: 541}
                },
                {
                    text: `${blockHash.slice(0,40)}`,
                    style: 'footer',
                    absolutePosition: {x: 323, y: 507}
                },
                {
                    text: `${blockHash.slice(40,90)}`,
                    style: 'footer',
                    absolutePosition: {x: 323, y: 517}
                },
                {
                    text: `${hash.slice(0,40)}`,
                    style: 'footer',
                    absolutePosition: {x: 283, y: 545},
                },
                {
                    text: `${hash.slice(40,90)}`,
                    style: 'footer',
                    absolutePosition: {x: 283, y: 555},
                },
                {
                    text: `${createdAt} GMT`,
                    style: 'footer',
                    absolutePosition: {x: 631, y: 479}
                },
                {
                    text: `${1}`,
                    style: 'footer',
                    absolutePosition: {x: 790, y: 580}
                },
                AttachmentsContent,
            ],
        };
    
        const pdfGenerator = pdfMake.createPdf(docDefinition);
        if (download){
            pdfGenerator.download(`Certificate - ${asset_name} - ${period}.pdf`);
        }
        const produceBase64 = async () => {
            pdfGenerator.getDataUrl((base64) => {
                setPDFBase64(base64)
                return true;
            });
        }
        // pdfGenerator.open() // Open a new Browser tap
        await produceBase64();
        return "ok"
    } catch (error) {
        console.log(error)
        return ""
    }
}

export { renderPDF };