export const formatTextLimit = (
	text: string,
	// size: number,
	limitText: number
) => {
	if (!text) return;
	const lengthText = text.length;

	if (lengthText > limitText) {
		return `${text.slice(0, limitText)}...`;
	}
	return text;
};
