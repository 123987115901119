import { useEffect, useState, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';

import { useSnackbar } from 'notistack';

import { Box, Button, Grid, Typography } from '@mui/material';

import { makeStyles } from '@material-ui/core/styles';
import { ApiPromise, WsProvider } from '@polkadot/api';
import EnterpriseDefault from '../../assets/imgs/enterpriseDefaultLogo2.png';
import { AddLogo } from '../../components/addLogo';
import { SkeletonLogo } from '../../components/addLogo/skeletonLogo';
import { Header } from '../../components/header';
import { SideBar } from '../../components/sideBar';
import { UploadLogo } from '../../components/uploadLogo';
import { EnterpriseService } from '../../services/enterprise.service';
import { listMember } from '../../stores/listMember/listMember.actions';
import { colors } from '../../styles';
import { CardMember } from './cardMember';
import { FormConfig } from './formConfig';
import { InfoEnterprise } from './infoUser';
import { SkeletonInforEnterprise } from './infoUser/skeletonInfoEnterprise';

interface IListMemberEnt {
	id: string;
	name: string;
	country: string;
	state: string;
	city: string;
	address: string;
	number: string;
	complement: string;
	cnpj: string;
	zipcode: string;
	wallet: string;
	logo: string;
	certified_by: string;
	is_activate: boolean;
}

const useStyles = makeStyles(theme => ({
	[theme.breakpoints.down(1500)]: {
		containerInfo: {
			marginLeft: '63px !important'
		}
	}
}));

export const Configure = () => {
	const dispath = useDispatch();
	const enterpriseService = new EnterpriseService();
	const { enqueueSnackbar } = useSnackbar();
	const responsiveClasses = useStyles();

	const [listMemberEnt, setListMemberEnt] = useState<Array<IListMemberEnt>>([]);
	const [backInfoUser, setBackInfoUser] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [enterpriseIsCertified, setEnterpriseIsCertified] = useState<boolean>(false);
	const [editInfMemberEnt, setEditInfMemberEnt] = useState<IListMemberEnt>({} as IListMemberEnt);
	const [loadinEnt, setLoadinEnt] = useState<boolean>(true);
	const [success, setSuccess] = useState<boolean>(false);
	const [uploadFile, setUploadFile] = useState<File | null>(null);
	const [loadImage, setLoadImage] = useState<boolean>(false);

	const getListMemberEnte = async () => {
		setLoadinEnt(true);
		const getList: any = await enterpriseService.listEnterprise();
		setLoadinEnt(false);
		if (getList.status === 200) {
			setListMemberEnt(getList.data);
			setEditInfMemberEnt(getList.data[0]);
		}
	};

	useEffect(()=>{
		const getAssetCertificateStatus = async () => {
			setIsLoading(true)
			const provider = new WsProvider(`${process.env.REACT_APP_PARACHAIN_ADDRESS}`);
			const api = await ApiPromise.create({ provider });
			const data = await api.query.myOrganization.myOrganization();
			const jsonData: any = data.toHuman();
			if(jsonData?.liquidity != undefined && jsonData?.liquidity != null){
				setEnterpriseIsCertified(jsonData?.liquidity)
			}
			await api.disconnect()
			setIsLoading(false)
		}
		getAssetCertificateStatus()
	},[])

	// useEffect(() => {
	// 	// dispath(listMember());
	// 	getListMemberEnte();
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	useEffect(() => {
		dispath(listMember());
		getListMemberEnte();
		setBackInfoUser(false);
		setSuccess(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [success, backInfoUser]);

	const backToForm = () => {
		setListMemberEnt([]);
	};

	const saveLogo = async (file: any) => {
		const formData = new FormData();
		formData.append('logo', file);

		setLoadImage(true);
		const saveLogoImage: any = await enterpriseService.addLogo(formData);
		if (saveLogoImage.status === 200) {
			setLoadImage(false);
			enqueueSnackbar('The logo has been uploaded', {
				variant: 'success'
			});
			localStorage.setItem('enterpriseUrl', saveLogoImage.data.url);
			return true;
		} else {
			setLoadImage(false);
			enqueueSnackbar('The logo was not saved on server', {
				variant: 'error'
			});
			return false;
		}
	};

	const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		if (files?.length) {
			const file = files[0];
			const logoSaved = await saveLogo(file);

			if (logoSaved) {
				setUploadFile(file);
				location.reload()
			}
		}
	};

	return (
		<>
			<Header />
			<SideBar />
			<Box
				component="main"
				sx={{
					marginLeft: '234px',
					// p: 3,
					height: '100vh',
					paddingTop: '151px'
				}}
			>
				<Grid container md={12}>
					<Grid item md={12}>
						<Box display="flex" alignItems="center">
							<Box
								marginRight="54px"
								display={listMemberEnt.length ? 'block' : 'none'}
							>
								<Button
									onClick={backToForm}
									variant="outlined"
									sx={{
										width: '107px',
										height: '40px',
										borderRadius: '25px',
										border: '1px solid #000',
										padding: '8px 24px',
										color: '#000',
										'&:hover': {
											border: '1px solid #000'
										}
									}}
								>
									Edit
								</Button>
							</Box>
							<Typography
								fontWeight="700"
								fontSize="1.75rem"
								color={colors.primary}
								lineHeight="47px"
							>
								Enterprise information and customization
							</Typography>
						</Box>
					</Grid>
				</Grid>

				<Grid
					container
					md={12}
					sm={12}
					display="flex"
					flexWrap="nowrap"
					marginTop="72px"
					columnGap={2}
				>
					<Grid item md={1.2} sm={4}>
						{loadinEnt ? (
							<SkeletonLogo />
						) : (
							<UploadLogo
								error={true}
								dataUser={listMemberEnt}
								imgDefault={editInfMemberEnt?.logo ? editInfMemberEnt?.logo : EnterpriseDefault}
								id="input-load-image"
								image={uploadFile ? URL.createObjectURL(uploadFile) : editInfMemberEnt?.logo ? editInfMemberEnt?.logo : EnterpriseDefault}
								name="fileLogo"
								textLabel="Insert Logo"
								onChange={handleImageUpload}
								loadImage={loadImage}
							/>
						)}
					</Grid>
					<Grid
						item
						md={4}
						sm={6}
						className={responsiveClasses.containerInfo}
						marginLeft="13px"
						paddingRight="10px"
						// overflow={listMemberEnt.length ? 'auto': ''}
						height="620px"
						sx={{
							'::-webkit-scrollbar': {
								marginLeft: '60px',
								border: '1px solid #D4D7DE',
								width: '5px',
								borderRadius: '15px'
							},
							'::-webkit-scrollbar-thumb': {
								backgroundColor: '#D9D9D9',
								borderRadius: '15px',
								height: '170px',
								width: '15px'
							}
						}}
					>
						{loadinEnt ? (
							<SkeletonInforEnterprise />
						) : (
							<>
								{listMemberEnt.length ? (
									<InfoEnterprise isLoading={isLoading} enterpriseIsCertified={enterpriseIsCertified} dataUser={listMemberEnt} />
								) : (
									<FormConfig
										setSuccess={setSuccess}
										dataEnterprise={editInfMemberEnt}
										setBackInfoUser={setBackInfoUser}
									/>
								)}
							</>
						)}
					</Grid>
					<Grid item md={6} sm={4} paddingLeft="161px" marginTop="-20px">
						<CardMember />
					</Grid>
				</Grid>
			</Box>
		</>
	);
};
