/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useSnackbar } from 'notistack';

import { Box, Card, Grid, Tooltip, Typography } from '@mui/material';

import copyClipBoard from '../../../assets/imgs/copyClipBoard.png';

import copy from 'copy-to-clipboard';

import { makeStyles } from '@material-ui/core/styles';

import Wallet from '../../../assets/imgs/wallet.svg';
import { colors } from '../../../styles';
// import { Mnemonic } from '../mnemonic';
// import { NewWallet } from '../newWallet';
// import { RecoveryWallet } from '../recoveryWallet';
import { useParams } from 'react-router-dom';
import { MnemonicAsset } from '../mnemonicAsset';
import { NewWalletAsset } from '../newWalletAsset';
import { formatTextLimit } from '../../../utils/formatTextLenght';

const useStyles = makeStyles(theme => ({
  [theme.breakpoints.down(1690)]: {
    cardContainer: {
      maxWidth: '1200px'
    }
  }
}));

/* eslint-disable prettier/prettier */
export const CardWalletAsset = (props: any) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const nextStepWallet = useSelector((state: any) => state.nextStep);
  // const recovery = useSelector((state: any) => state.recovery);

  const idontknow = useParams()
  const getNameWallet = localStorage.getItem('nameWallet');
  const [textHash, setTextHash] = useState<string>('');
  const [mnemonicPhrase, setMnemonicPhrase] = useState<string>('');

  useEffect(() => {
    setTextHash(props.walletAddress);
    setMnemonicPhrase(props.mnemonic)
  }, []);

  type TypeTitleCard = { title: string };
  const TitleCard: React.FC<TypeTitleCard> = ({ title }) => {
    return (
      <Typography
        color={colors.primaryDark}
        fontSize="2.25rem"
        fontWeight="700"
        lineHeight="48px"
      >
        {title}
      </Typography>
    );
  };

  const clipBoard = () => {
    if (navigator.clipboard) {
      copy(textHash);
      enqueueSnackbar('Hash copied', {
        variant: 'info'
      });
    }
  };

  return (
    <>
      <Grid container md={12}>
        <Grid
          item
          md={12}
          marginLeft="152px"
          marginTop="128px"
          width="100%"
          display="flex"
          justifyContent="center"
        >
          <Card sx={{ position: 'relative' }}>
            <Card sx={{ marginTop: '36px' }}>
              <Box
                className={classes.cardContainer}
                sx={{
                  width: '1438px',
                  minHeight: !nextStepWallet ? '560px' : '738px',
                  backgroundColor: 'rgba(202,213,232,0.3)',
                  borderRadius: '32px',
                  padding: '61px'
                }}
              ></Box>
            </Card>
            <Card
              sx={{
                boxShadow: 'none',
                backgroundColor: 'transparent',
                position: 'absolute',
                top: '96px',
                left: '62px',
                right: '26px',
                bottom: '26px',
                zIndex: '1'
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <TitleCard title="Create an organization wallet" />
                <Box display="flex" alignItems="center">
                  <Typography
                    color={colors.primaryDark}
                    fontSize="1.125rem"
                    fontWeight="400"
                    lineHeight="24px"
                    marginRight="45px"
                  >
                    Create an organization wallet
                  </Typography>
                  <Typography
                    color={colors.neutralBlack}
                    fontSize="1.75rem"
                    fontWeight="700"
                    lineHeight="40px"
                    marginRight="52px"
                  >
                    {nextStepWallet && `2/2`}
                    {!nextStepWallet && `1/2`}
                  </Typography>
                </Box>
              </Box>
              {!nextStepWallet ? (
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Box marginTop="33px" maxWidth="609px">
                      <Typography
                        fontSize="1.25rem"
                        color={colors.primary}
                        lineHeight="32px"
                      >
                        This is your wallet creation area, with a mnenomic words
                        generated. A mnemonic is a random auto generated
                        acronyms or phrases used to help you remember something
                        important.
                      </Typography>
                    </Box>
                    <Box marginTop="49px" display="flex">
                      <img src={Wallet} />
                      <Box marginLeft="23px">
                        <Typography marginBottom="14px">
                          {getNameWallet}
                        </Typography>
                        <Box display="flex">
                          <Typography
                            fontSize="1.25rem"
                            lineHeight="32px"
                            marginRight="20px"
                          >
                            <Tooltip title={textHash}>
                              <Typography fontSize="20px">{formatTextLimit(textHash, 40)}</Typography>
                            </Tooltip>
                          </Typography>
                          <Box onClick={clipBoard} sx={{ cursor: 'pointer' }}>
                            <img src={copyClipBoard}></img>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box maxWidth="609px" marginTop="50px">
                      <Typography fontSize="20px">
                        Please write your mnemonic seed and <strong>keep it in a safe place</strong>.
                        The mnemonic can be used to restore your wallet.
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    {mnemonicPhrase && <MnemonicAsset mnemonic={mnemonicPhrase} />}
                  </Box>
                </Box>
              ) : (
                <>{<NewWalletAsset mnemonic={mnemonicPhrase} walletAddress={textHash} />}</>
              )}
            </Card>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
