/* eslint-disable import/extensions */
import { Api } from './api/axios-config';
import { GenericExtrinsic } from '@polkadot/types';
import { AnyTuple } from '@polkadot/types-codec/types';

interface IdataApi {
	status: number;
	statusText: string;
	data: Array<IRecRequest>;
}

interface IRecRequest {
	id: string;
	name: string;
	optional_id: string;
	type: string;
	descriptions: string;
	enterprise_id: string;
	issued_recs: string;
	retired_recs: string;
	has_been_issued: string;
	has_been_canceled: string;
	user_id: string;
	logo: string;
	hash: string;
	created_at: string;
	updated_at: string;
}

interface IRecTokenService {
	name: string;
	enterprise_id: string;
	asset_id: string;
	optional_id: string;
	type: string;
	descriptions: string;
	extrinsic?: GenericExtrinsic<AnyTuple>,
}

export class RecTokenService {
	async addRecToken(recToken: IRecTokenService) {
		try {
			const resRecToken: IdataApi = await Api.post(`/recs`, recToken);
			return resRecToken;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async insertRecToken(asseIdRec: string, recId: string) {
		try {
			const instRec: IdataApi = await Api.patch(
				`/assets/${asseIdRec}/insert-rec/${recId}`
			);
			return instRec;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async listRecToken() {
		try {
			const resRecListToken = await Api.get(`/recs`);
			return resRecListToken.data;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async infoViewRecToken(idAsset: string) {
		try {
			const resRecListToken = await Api.get<IdataApi>(`/assets/${idAsset}`);
			return resRecListToken;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}
}
