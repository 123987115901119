import {
	Box,
	Grid,
	InputAdornment,
	OutlinedInput,
	TextField,
	Typography
} from '@mui/material';

import { Header } from '../../components/header';
import { SideBar } from '../../components/sideBar';
import wind from '../../assets/imgs/WIND.png';
import Ellipse10 from '../../assets/imgs/PowerPlantIA.jpg';

import plus from '../../assets/icon/plus.svg';
import path from '../../assets/imgs/Path.png';

import edit from '../../assets/icon/edit.svg';
import { FormAsset } from './formAsset';
import { useEffect, useState } from 'react';

import { AssetService } from '../../services/asset.service';
import { VscEdit } from "react-icons/vsc";
import search from '../../assets/imgs/search.svg';
import { AssetView } from '../AssetView';
import { colors } from '../../styles';

export type IAsset = {
	enterprise_id: string;
	unitName: string;
	country: string;
	state: string;
	city: string;
	address: string;
	type: string;
	cnpj: string;
	startOfOperation: string;
	co2PerMWh: string;
	installedCapacity: string;
	unitCode: string;
	cceeCode: string;
	certifier: string;
};

export const AssetManager = () => {
	const [editInfAsset, setEditInfAsset] = useState<Array<IAsset>>([]);
	const [backInfoUser, setBackInfoUser] = useState<boolean>(false);
	const [success, setSuccess] = useState<boolean>(false);

	const [searchInput, setSearchInput] = useState<string>('');

	const [editAsset, setEditAsset] = useState<boolean>(false);

	const [assets, setAssets] = useState([]);

	const [createAsset, setCreateAsset] = useState<boolean>(false);

	const [formAsset, setFormAsset] = useState<boolean>(false);

	const [assetsFiltered, setAssetsFiltered] = useState([] as any);

	const [selectedAsset, setSelectedAsset] = useState<IAsset>({
		enterprise_id: '',
		unitName: '',
		country: '',
		state: '',
		city: '',
		address: '',
		type: '',
		cnpj: '',
		startOfOperation: '',
		co2PerMWh: '',
		installedCapacity: '',
		unitCode: '',
		cceeCode: '',
		certifier: ''
	});

	const [selectedAssetId, setSelectedAssetId] = useState<string>('');

	const [assetInfo, setAssetInfo] = useState<boolean>(false);

	const [assetsOverflow, setAssetsOverflow] = useState<string>('hidden');

	useEffect(() => {
		const assetService = new AssetService();

		const listAssets = async () => {
			const response: any = await assetService.listAsset();
			setAssets(response.data);
			setAssetsFiltered(response.data);

			if (response && response.data.length > 7) {
				setAssetsOverflow('scroll');
			} else {
				setAssetsOverflow('hidden');
			}
		};
		listAssets();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// filter assets by searchInput
	useEffect(() => {
		const filteredAssets = assets.filter((asset: any) => {
			return asset.name.toLowerCase().includes(searchInput.toLowerCase());
		});
		if (filteredAssets.length > 7) {
			setAssetsOverflow('scroll');
		} else {
			setAssetsOverflow('hidden');
		}
		setAssetsFiltered(filteredAssets);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchInput]);

	const handleAssetClick = (asset: any) => {
		setSelectedAsset(asset);
		setAssetInfo(true);
	};

	const handleAssetEditInfo = (asset: any) => {
		setSelectedAsset(asset);
		setFormAsset(true);
		setEditAsset(true);
		setAssetInfo(false);
	};

	return (
		<>
			{assetInfo && (
				<AssetView
					asset={selectedAsset}
					setSelectedAsset={setSelectedAsset}
					setAssetInfo={setAssetInfo}
					handleAssetEditInfo={handleAssetEditInfo}
				/>
			)}
			{!assetInfo && (
				<>
					<Header />
					<SideBar />
					<Box
						component="main"
						sx={{
							marginLeft: '234px',
							height: '100vh',
							paddingTop: '151px'
						}}
					>
						<Grid container md={12} columnGap={20}>
							<Grid item md={5}>
								<Box>
									<Typography
										variant="h4"
										color="#636C7A"
										style={{ fontWeight: 700 }}
									>
										Power Units
									</Typography>
									<TextField
										fullWidth
										autoComplete="off"
										variant="filled"
										placeholder="search"
										onChange={(e: any) => setSearchInput(e.target.value)}
										sx={{
											m: 1,
											maxWidth: '634px',
											marginLeft: '0px',
											'& .MuiFilledInput-root': {
												backgroundColor: 'f4eded',
												borderRadius: '16px',
												paddingBottom: '15px',
												paddingLeft: '10px'
											}
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment
													position="end"
													sx={{ paddingTop: '10px', paddingRight: '10px' }}
												>
													<img src={search}></img>
												</InputAdornment>
											),
											disableUnderline: true
										}}
									/>
									<Grid
										container
										md={12}
										sx={{ marginTop: '23px', marginBottom: '9px'}}
									>
										<Grid item md={5}>
											<Typography
												color="#636C7A"
												fontFamily="Sen"
												fontWeight="400"
												fontSize="20px"
												lineHeight="32px"
											>
												POWER UNIT
											</Typography>
										</Grid>
										<Grid item md={5}>
											<Typography
												color="#636C7A"
												fontFamily="Sen"
												fontWeight="400"
												fontSize="20px"
												lineHeight="32px"
											>
												CREATION DATE
											</Typography>
										</Grid>
									</Grid>
									<Box
										sx={{
											maxWidth: '634px',
											height: '530px',
											'overflow-x': 'hidden',
											'overflow-y': assetsOverflow,
											'::-webkit-scrollbar': {
												border: '1px solid #D4D7DE',
												width: '15px',
												borderRadius: '15px'
											},
											'::-webkit-scrollbar-thumb': {
												backgroundColor: '#D9D9D9',
												borderRadius: '15px',
												height: '170px',
												width: '15px'
											}
										}}
									>
										{assetsFiltered && assetsFiltered.length > 0 ? assetsFiltered.map((asset: any) => {
											return (
												<>
													<Grid container md={12} sx={{ marginTop: '23px' }}>
														<Grid item md={1}>
															<Box
																sx={{
																	width: '40px',
																	height: '40px',
																	backgroundColor: '#F7F7F7',
																	borderRadius: '50%',
																	':hover': { cursor: 'pointer' }
																}}
																onClick={() => handleAssetClick(asset)}
															>
																{asset?.logo ? (
																	<img
																		src={asset?.logo}
																		width={'40px'}
																		height={'40px'}
																		alt="unit2"
																	/>
																) : (
																	<img
																		src={Ellipse10}
																		width={'40px'}
																		height={'40px'}
																		alt="unisst"
																	/>
																)}
															</Box>
														</Grid>
														<Grid item md={5}>
															<Typography
																color="#484848"
																fontFamily="Sen"
																fontWeight="400"
																fontSize="16px"
																lineHeight="19px"
																onClick={() => handleAssetClick(asset)}
																sx={{
																	marginLeft: '22px',
																	marginTop: '7px',
																	':hover': { cursor: 'pointer' }
																}}
															>
																{asset.name}
															</Typography>
														</Grid>
														<Grid item md={5}>
															<Typography
																color="#484848"
																fontFamily="Sen"
																fontWeight="400"
																fontSize="16px"
																lineHeight="19px"
																sx={{ marginTop: '7px' }}
															>
																{asset.created_at.slice(0, 10)}
															</Typography>
														</Grid>
														<Grid item md={1}>
															{((!editAsset && !formAsset) || (selectedAsset.cnpj === asset.cnpj)) &&
																<Box
																	sx={{
																		marginTop: '7px',
																		':hover': { cursor: 'pointer' }
																	}}
																	onClick={() => {
																		// setEditAsset(false);
																		// setFormAsset(false);
																		setFormAsset(true);
																		setEditAsset(true);
																		setSelectedAsset(asset);
																	}}
																>
																	<VscEdit size={20}/>
																</Box>
															}
														</Grid>
													</Grid>
													<hr
														style={{
															margin: '10px 30px auto',
															borderColor: '#F7F7F7'
														}}
													/>
												</>
											);
										})
										:
										<Typography
											fontFamily="Sen"
											fontStyle="normal"
											fontWeight="600"
											fontSize="20px"
											lineHeight="100px"
											color="#636C7A"
											sx={{ marginTop: '10px' }}
											textAlign={'center'}
											height={100}
										>
											No Assets registered yet
										</Typography>
									}
									</Box>
								</Box>
							</Grid>
							<Grid item md={4}>
								{!formAsset ? (
									<Grid container md={7} sx={{ marginLeft: '470px' }}>
										<Grid item md={6}>
											<Typography
												fontFamily="Sen"
												fontStyle="normal"
												fontWeight="700"
												fontSize="24px"
												lineHeight="32px"
												color="#636C7A"
												sx={{ marginTop: '10px' }}
											>
												Register new
											</Typography>
										</Grid>
										<Grid item md={1}>
											<Box
												sx={{
													width: '49.54px',
													height: '51px',
													backgroundColor: '#636C7A',
													borderRadius: '50%',
													padding: '16px'
												}}
												onClick={() => {
													setSelectedAsset({} as IAsset)
													setFormAsset(!formAsset)
													}
												}
											>
												<img src={plus} />
											</Box>
										</Grid>
									</Grid>
								) : (
									<FormAsset
										setSuccess={setSuccess}
										dataUser={editInfAsset}
										setFormAsset={setFormAsset}
										editAsset={editAsset}
										selectedAsset={selectedAsset}
										setEditAsset={setEditAsset}
									/>
								)}
							</Grid>
						</Grid>
					</Box>{' '}
				</>
			)}
		</>
	);
};
