/* eslint-disable prettier/prettier */
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useSnackbar } from 'notistack';

import {
	Box,
	Dialog,
	Typography,
	DialogTitle,
	DialogContent,
	Grid,
	FormControl,
	InputLabel,
	OutlinedInput,
	Select,
	styled,
	InputBase,
	MenuItem
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import { ConfirmationModal } from '../../../../components/confirmationModal';
import { MemberService } from '../../../../services/member.service';
import { colors } from '../../../../styles';

interface MemberForm {
	email: string;
	role: string;
}

export type TModalMember = {
	setOpen: (value: boolean) => void;
	open: boolean;
};

const BootstrapInput = styled(InputBase)(() => ({
	'& .MuiInputBase-input': {
		display: 'flex',
		alignItems: 'center',
		padding: '14px 14px',
		borderRadius: '9px',
		backgroundColor: 'transparent',
		border: '1px solid #000',
		'& .MuiSvgIcon-root-MuiSelect-icon': {
			marginTop: '10px'
		},
		'&:focus': {
			borderRadius: '10px'
		},
		':after': {
			borderBottom: 'none',
			borderRight: '1px solid #000',
			marginRight: '50px'
		}
	}
}));

export const ModalMember = ({ setOpen, open }: TModalMember) => {
	const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
	const [selectRole, setSelectRole] = useState<string>('');
	const [loadingInvite, setLoadingInvite] = useState<boolean>(false);
	const [passwordAuthorization, setPasswordAuthorization] = useState<string>('');
	const [openAuthorization, setOpenAuthorization] = useState<boolean>(false);
	const [loadingAuthorization, setLoadingAuthorization] =
		useState<boolean>(false);

	const memberService = new MemberService();
	const { enqueueSnackbar } = useSnackbar();
	const handleClose = () => {
		setOpen(false);
	};

	const form = useForm<MemberForm>();

	const typeEmail = (e: any) => {
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/
		if(emailRegex.test(e.target.value)){
			setIsValidEmail(true);
		}else{
			setIsValidEmail(false);
		}
		form.setValue('email', e.target.value);
	};

	const selectMemberType = (e: any) => {
		setSelectRole(e.target.value);
		form.setValue('role', e.target.value);
	};

	const onSubmit = () => {
		setOpenAuthorization(true);
	};

	const handleCloseAuthoraztion = () => {
		setOpenAuthorization(false);
	};

	const onSubmitAuthorization = async () => {
		const { email, role } = form.getValues();
		if (!email || !role) return;

		setLoadingInvite(true);
		setLoadingAuthorization(true);
		const respInviteMember = await memberService.inviteMember({
			email,
			role,
			enterprise_id: localStorage.getItem('enterprise_id') || ''
		});

		if (respInviteMember.status === 200) {
			setLoadingInvite(false);
			setLoadingAuthorization(false);
			enqueueSnackbar('The invite was sended to your email', {
				variant: 'success'
			});
			return;
		}
		if (respInviteMember.status === 401 || 404) {
			setLoadingInvite(false);
			setLoadingAuthorization(false);
			enqueueSnackbar(respInviteMember.statusText, {
				variant: 'error'
			});
			return;
		}
		if (respInviteMember.status === 500) {
			setLoadingInvite(false);
			enqueueSnackbar('error sending invite to your email', {
				variant: 'error'
			});
			return;
		}
		handleCloseAuthoraztion();
	};

	return (
		<>
			<Dialog
				sx={{
					'& .MuiPaper-root': {
						borderRadius: '40px !important',
						backgroundColor: '#fff !important',
						boxShadow: '0px 4px 35px rgba(0, 0, 0, 0.08)',
						width: '539px',
						padding: '66px 44px 53px 44px'
					}
				}}
				aria-describedby="confirmationDialog"
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						backgroundColor: '#F1F3F4',
						borderRadius: '18px'
					}
				}}
			>
				<Box
					onClick={handleClose}
					position="absolute"
					top="10px"
					right="25px"
					sx={{ cursor: 'pointer' }}
				>
					<Typography
						fontWeight="100"
						fontSize="30px"
						color={colors.neutralDark}
					>
						x
					</Typography>
				</Box>

				<Box>
					<Typography
						fontWeight="700"
						fontSize="1.5rem"
						lineHeight="32px"
						color={colors.neutralDark}
					>
						add a new member
					</Typography>
				</Box>
				<DialogTitle
					sx={{
						padding: 0,
						marginTop: '40px',
						marginBottom: '52px'
					}}
				>
					<Typography
						fontSize="3rem"
						fontWeight="700"
						lineHeight="64px"
						color="#000"
						marginLeft="0"
					>
						Invitation
					</Typography>
				</DialogTitle>
				<DialogContent sx={{ padding: 0 }}>
					<form onSubmit={() => {}}>
						<Grid container md={12} rowSpacing={3}>
							<Grid item md={12}>
								<Controller
									control={form.control}
									name="email"
									render={({ field }) => (
										<FormControl variant="standard" fullWidth>
											<InputLabel error={false} shrink htmlFor="email">
												Please enter e-mail
											</InputLabel>
											<OutlinedInput
												fullWidth
												style={{
													border: 'none',
													borderRadius: '9px',
													marginTop: '20px',
													color: '#000'
												}}
												error={false}
												placeholder="type here..."
												{...field}
												type="string"
												onChange={(e: any) => typeEmail(e)}
											/>
										</FormControl>
									)}
								/>
							</Grid>
							<Grid item md={12} marginBottom="106px">
								<Controller
									control={form.control}
									name="role"
									render={({ field }) => (
										<FormControl variant="standard" fullWidth>
											<InputLabel
												error={false}
												shrink
												htmlFor="role"
												sx={{ position: 'relative', marginTop: '3px' }}
											>
												Select member type
											</InputLabel>
											<Select
												{...field}
												fullWidth
												input={<BootstrapInput />}
												labelId="demo-simple-select-label"
												id="role"
												variant="standard"
												onChange={(e: any) => selectMemberType(e)}
											>
												<MenuItem value={'admin'}>Admin</MenuItem>
												<MenuItem value={'seller'}>Seller</MenuItem>
											</Select>
										</FormControl>
									)}
								/>
							</Grid>
							<Grid item md={12} display="flex" justifyContent="center">
								<LoadingButton
									variant="contained"
									disabled={!isValidEmail || !selectRole}
									loading={loadingInvite}
									onClick={() => form.handleSubmit(onSubmit)()}
									sx={{
										width: '215px',
										padding: '8px 43px',
										borderRadius: '25px',
										background: colors.neutralDark,
										'&:hover': {
											backgroundColor: '#555555'
										}
									}}
								>
									<Typography
										lineHeight="32px"
										fontSize="1.5rem"
										fontWeight="400"
										sx={{
											textTransform: 'initial'
										}}
									>
										Invite now
									</Typography>
								</LoadingButton>
								{openAuthorization &&
									<ConfirmationModal
										open={openAuthorization}
										title="Inviting a new member"
										subTitle="This action will make you authorize the invitation of a new member"
										handleCloseAuthorization={handleCloseAuthoraztion}
										// setPasswordAuthorization={setPasswordAuthorization}
										onSubmitAuthorization={onSubmitAuthorization}
										loadingAuthorization={loadingAuthorization}
										confirm="Sign"
										cancel="Cancel"
									/>
								}
							</Grid>
							<Box marginTop="56px" sx={{ width: '100%' }}>
								<Typography lineHeight="24px" textAlign="center">
									Invitation will be sent right away!
								</Typography>
							</Box>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
		</>
	);
};
