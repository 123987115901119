import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { goToTransactionBuyer } from '../../stores/goToBuyerTransaction/goToBuyerTransaction.actions';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Header } from '../../components/header';
import { SideBar } from '../../components/sideBar';
import { colors } from '../../styles';
import { AddBuyer } from './addBuyer/addBuyer';
import { TransactionBuyer } from './transactionBuyer';
import { useParams } from 'react-router-dom';
interface IGotoTransferBuyers {
	status: boolean;
	id: string;
}

export const Buyers = () => {
	const dispatch = useDispatch();
	const goToTransferBuyer: IGotoTransferBuyers = useSelector(
		(state: any) => state.goToBuyerTransaction
	);
	const { clientId } = useParams();

	return (
		<>
			<Header />
			<SideBar />
			<Box
				component="main"
				sx={{
					marginLeft: '229px',
					height: '100vh',
					paddingTop: '151px',
					marginRight: '50px'
				}}
			>
				<Grid container md={12} marginBottom="54px">
					{goToTransferBuyer.status && (
						<Button
							variant="outlined"
							onClick={() => dispatch(goToTransactionBuyer(false, ''))}
							sx={{
								width: '107px',
								marginRight: '108px',
								height: '40px',
								borderRadius: '25px',
								border: '1px solid #000',
								padding: '8px 24px',
								color: '#000',
								textTransform: 'initial',
								fontSize: '1.25rem',
								'&:hover': {
									border: '1px solid #000'
								}
							}}
						>
							<Typography fontSize="1.25rem" lineHeight="32px">
								Back
							</Typography>
						</Button>
					)}
					<Grid item md={6}>
						<Typography
							fontWeight="700"
							fontSize="1.75rem"
							lineHeight="40px"
							color={colors.primary}
							marginBottom="9px"
						>
							Buyer Manager
						</Typography>
					</Grid>
				</Grid>
				{!goToTransferBuyer.status ? (
					<AddBuyer idBuyer={goToTransferBuyer.id} />
				) : (
					<TransactionBuyer />
				)}
			</Box>
		</>
	);
};
