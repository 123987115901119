// Core
import React, { FC } from 'react';

import { Box, BoxProps, Typography } from '@mui/material';

import { colors } from '../../../styles';

// Types
interface ICard extends BoxProps {
	title: string;
	number: string | number;
	unit: string;
}

export const CardInfo: FC<ICard> = ({ title, number, unit, ...props }) => {
	return (
		<Box
			{...props}
			sx={{
				minWidth: '310px',
				padding: '16px 30px',
				display: 'inline-block',
				borderRadius: '16px',
				boxShadow: '0px 5px 30px rgba(0, 0, 0, 0.05)'
			}}
		>
			<Typography
				sx={{
					marginBottom: '10px',
					fontSize: '1rem',
					fontWeight: '700',
					color: '#000'
				}}
			>
				{title}
			</Typography>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'flex-end',
					justifyContent: 'space-between'
				}}
			>
				<Typography
					sx={{
						fontSize: '1.75rem',
						fontWeight: '400',
						color: colors.primaryDark
					}}
				>
					{number}
				</Typography>
				<Typography
					sx={{
						fontSize: '1rem',
						color: colors.primaryDark
					}}
				>
					{unit}
				</Typography>
			</Box>
		</Box>
	);
};
