import axios from 'axios';

import { Api } from './api/axios-config';
import { IconButtonClassKey } from '@mui/material';

interface ICreateClient {
	enterprise_id: string;
	cnpj: string;
	address: string;
	city: string;
	phone: string;
	zipcode: string;
	number: string;
	beneficiary: string;
	state: string;
	email: string;
	country: string;
}

interface IClient {
	beneficiary: string;
	id: string;
	state: string;
	regDate: string;
	registrarId: string;
	email: string;
	country: string;
	totalRecs: string;
	totalRetired: string;
	totalCertificates: string;
}

export class ClientService {
	async addClient(newClient: ICreateClient) {
		try {
			const client = await Api.post('/clients/cnpj', newClient);
			return client;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async listClients() {
		try {
			const clients = await Api.get(`/clients`);
			return clients;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async listClientById(id: string) {
		try {
			const client = await Api.get(`/clients/${id}`);
			return client.data;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async listClientTransactions(id: string) {
		try {
			const transactions = await Api.get(`/transactions/clients/${id}`);
			return transactions.data;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async listClientCertificates(id: string, page: number, size: number) {
		try {
			const certificates = await Api.get(`/certificates/clients/${id}?page=${page}&size=${size}`);
			return certificates;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async disableCNPJClient(client_id: string) {
		try {
			const respUpdateLogo = await Api.patch(
				`/clients/cnpj/disable`,
				{client_id}
			);
			return respUpdateLogo;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async activeCNPJClient(client_id: string) {
		try {
			const respUpdateLogo = await Api.patch(
				`/clients/cnpj/enable`,
				{client_id}
			);
			return respUpdateLogo;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async updateLogoById(client_id: string, logo: any) {
		try {
			const respUpdateLogo = await Api.patch(
				`/clients/${client_id}/logo`,
				logo
			);
			return respUpdateLogo;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async getCountries() {
		return axios.get(`https://countriesnow.space/api/v0.1/countries`);
	}

	async getStates(country: string) {
		return axios.post(`https://countriesnow.space/api/v0.1/countries/states`, {
			country
		});
	}

	async getCities(country: string, state: string) {
		return axios.post(
			`https://countriesnow.space/api/v0.1/countries/state/cities`,
			{
				country,
				state
			}
		);
	}

	async getZipCode(enterpriseZipCode: number) {
		try {
			const resZipCode = await axios.get(
				`https://viacep.com.br/ws/${enterpriseZipCode}/json/`
			);
			return resZipCode;
		} catch (error) {
			console.log(error);
		}
	}
}
