import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Grid, Typography, Card, Box } from '@mui/material';
import { TransactionService } from '../../services/transactions.service';

import { colors } from '../../styles';
import { ICertificateOrg } from '../../types/certificateOrg';
import { HeadCell } from '../../types/headCell';
import { Table } from './table';
import { format, parseISO } from 'date-fns';
import { ProfileService } from '../../services/profile.service';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { TableSeller } from './tableSeller';
import { ProfileUsers } from '../../profile-users';


interface ICertificateHome {
	setGoTotransferT: any;
}

interface ITransactionTableData {
	id: string;
	date: string;
	sellerOrg: string;
	seller: string;
	buyer: string;
	opType: string;
	status: string;
	sourceType: string;
	orgName: string;
	unitID: number;
	country: string;
	state: string;
	city: string;
	period: string;
	uniqueId: string;
	amount: number;
}

interface IAssetTransactionDataRequest {
	amount: string;
	asset_id: string;
	asset_country: string;
	asset_state: string;
	asset_city: string;
	asset_name: string;
	asset_source: string;
	asset_unit_id: string;
	enterprise_name: string;
	provider_name: string;
	holder_name: string;
	block: string;
	certificate_id: string;
	created_at: string;
	end_date: string;
	enterprise_id: string;
	from: string;
	hash: string;
	id: string;
	rec_label: string;
	report: string;
	start_date: string;
	status: string;
	to: string;
	type: string;
	updated_at: string;
	user_id: string;
}

export const SaleHome = ({ setGoTotransferT }: ICertificateHome) => {
	const profileService = new ProfileService();
	const history = useNavigate();
	// const [goTotransfer, setGoTotransfer] = useState<boolean>(false);
	const [stateObject, setStateObject] = useState<boolean>(false);
	const [quantityRowSelected, setQuantityRowSelected] = useState<number>(0);
	const [totalAmountRec, setTotalAmountRec] = useState<number>(0);
	const [validateStatusRec, setValidateStatusRec] = useState<boolean>(false);
	const [userProfile, setUserProfile] = useState<string>('');

	const [selectedPage, setSelectedPage] = useState<number>(1);
	const [selectedSize, setSelectedSize] = useState<number>(5);
	const [totalPages, setTotalPages] = useState<number>(1);

	const [transactions, setTransactions] = useState<ICertificateOrg[]>();
	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	useEffect(() => {
		const profileUser = async () => {
			const myProfile: any = await profileService.getUserProfile()
			if(myProfile.status == 200 && myProfile.data.role){
				setUserProfile(myProfile.data.role);
			}else{
				history('/signin')
			}
		};
		profileUser();
	}, []);

	const { enqueueSnackbar } = useSnackbar();

	const getTransferRow: ICertificateOrg = useSelector(
		(state: any) => state.totalRecAvailable
	);

	const CopyGetSelectedCertificates = {...getTransferRow};

	useEffect(() => {
		const transactionService = new TransactionService();
		const getMyTransactions = async () => {
			let result: [IAssetTransactionDataRequest[], number] = [[],0];
			if(!userProfile) {
				setIsLoaded(true);
				setTransactions([])
			}
			if (userProfile == ProfileUsers.OWNER) {
				result = await transactionService.getSellersTransactions(selectedPage,selectedSize);
			} else if (userProfile == ProfileUsers.SELLER) {
				result = await transactionService.getMyTransactions(selectedPage,selectedSize);
			}else{
				setIsLoaded(true)
				setTransactions([]);
			}
			setIsLoaded(true)
			setTotalPages(result[1])
			const respAllCertFormated: ICertificateOrg[] = result[0].map(
				(item: any) => ({
					id: item.id,
					beneficiaryId: item.to,
					isCheckbox: true,
					statusCheck: false,
					enterpriseId: item.enterprise_id,
					assetId: item.asset_id,
					assetSourceType: item.asset_source,
					recId: item.rec_id,
					assetName: item.asset_name,
					date: format(new Date(item.created_at), 'yyyy-MM-dd HH:mm'),
					holder: item.provider_name,
					beneficiary: item.holder_name,
					beneficiaryWallet: item.holder_wallet,
					from: item.from,
					to: item.to,
					status: item.rec_status,
					opType: item.operation_type,
					orgName: item.enterprise_name,
					powerUnit: item.asset_name,
					assetCountry: item.asset_country,
					assetState: item.asset_state,
					assetCity: item.asset_city,
					co2PerMWh: item.asset_co2_per_mwh,
					startOfOperation: item.asset_start_of_operation,
					certificate_id_range: item.certificate_id_range,
					period: format(new Date(item.start_date), 'yyyy-MMM'),
					startDate: new Date(item.start_date),
					endDate: new Date(item.end_date),
					assetUnitID: item.asset_unit_id,
					amountRec: Number(item.amount),
					recLabel: item.rec_label,
					recHash: item.rec_hash,
					blockHash: item.block_hash,
					amount: Number(item.amount),
					originCertificates: item.origin_certificates
				})
			);
			setTransactions(respAllCertFormated);
		};
		getMyTransactions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedPage, userProfile]);

	const [gotSellerTransaction, setGotSellerTransaction] = useState<
		any | null
	>();

	useEffect(() => {
		setGotSellerTransaction(transactions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const validateRetiredRec = () => {
		for (let i = 0; i < CopyGetSelectedCertificates?.totalRec.length; i++) {
			if (CopyGetSelectedCertificates?.totalRec[i].status.toLowerCase() === 'retired') {
				return true;
			}
		}
		return false;
	};

	useEffect(() => {
		if (stateObject) {
			const validateCertificate = validateRetiredRec();
			setValidateStatusRec(validateCertificate);
			setStateObject(false);
			if (validateCertificate) {
				enqueueSnackbar('Certificate unavaliable to transfer', {
					variant: 'error'
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stateObject]);

	const headCells: HeadCell[] = [
		{
			id: 'date',
			numeric: true,
			disablePadding: false,
			label: 'Date UTC-3'
		},
		{
			id: 'assetName',
			numeric: true,
			disablePadding: false,
			label: 'Seller Org'
		},
		{
			id: 'holder',
			numeric: true,
			disablePadding: false,
			label: 'Provider'
		},
		{
			id: 'beneficiary',
			numeric: true,
			disablePadding: false,
			label: 'Holder'
		},
		{
			id: 'opType',
			numeric: true,
			disablePadding: false,
			label: 'Op. Type'
		},
		{
			id: 'status',
			numeric: true,
			disablePadding: false,
			label: 'Status'
		},
		{
			id: 'assetSourceType',
			numeric: true,
			disablePadding: false,
			label: 'Source Type'
		},
		{
			id: 'assetName',
			numeric: true,
			disablePadding: false,
			label: 'Org Name'
		},
		{
			id: 'assetUnitID',
			numeric: true,
			disablePadding: false,
			label: 'Power Unit ID'
		},
		{
			id: 'assetState',
			numeric: true,
			disablePadding: false,
			label: 'Location'
		},
		{
			id: 'period',
			numeric: true,
			disablePadding: false,
			label: 'Period'
		},
		{
			id: 'assetUnitID',
			numeric: true,
			disablePadding: false,
			label: 'Unique ID'
		},
		{
			id: 'amount',
			numeric: true,
			disablePadding: false,
			label: 'Amount'
		},
		{
			id: 'report',
			numeric: true,
			disablePadding: false,
			label: 'Report'
		}
	];

	const onSubmitTransfer = () => {
		setGoTotransferT(true);
		// dispatchEvent();
		// setGoTotransfer(true);
	};

	useEffect(() => {
		if (stateObject) {
			const amountTotal = CopyGetSelectedCertificates.totalRec.reduce((acumulador: any, objeto: any) => {
				return acumulador + objeto.amountRec;
			}, 0);

			setTotalAmountRec(amountTotal);

			setStateObject(false);
		}
	}, [CopyGetSelectedCertificates, stateObject]);

	return (
		<>
			<Grid container md={12} marginBottom="15px" display="flex">
				<Grid item md={7}>
					<Grid item md={12} marginBottom="37px">
						<Typography
							fontWeight="700"
							fontSize="1.75rem"
							lineHeight="40px"
							color={colors.primary}
							marginBottom="9px"
						>
							ORDERS VIEW
						</Typography>
					</Grid>
				</Grid>
			</Grid>

			<Grid container md={12}>
				<Grid item sx={{ width: '99%', overflowX: 'auto' }}>
					{userProfile === 'owner' && (
						<Table
							title="Seller Transaction History"
							setSelectedPage={setSelectedPage}
							setSelectedSize={setSelectedSize}
							totalPages={totalPages}
							titles={headCells}
							transactions={transactions ? transactions : null}
							isLoaded={isLoaded}
							sx={{
								border: `1px solid ${colors.primaryLight}`,
								borderRadius: '16px',
								overflow: 'hidden'
							}}
						/>
					)}
					{userProfile === 'seller' && (
						<TableSeller
							title="Seller Transaction History"
							setSelectedPage={setSelectedPage}
							setSelectedSize={setSelectedSize}
							totalPages={totalPages}
							titles={headCells}
							transactions={transactions ? transactions : null}
							isLoaded={isLoaded}
							sx={{
								border: `1px solid ${colors.primaryLight}`,
								borderRadius: '16px',
								overflow: 'hidden'
							}}
						/>
					)}
				</Grid>
			</Grid>
		</>
	);
};
