/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import {
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  InputLabel,
  Box,
  InputAdornment,
  IconButton,
  Typography
} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { colors } from '../../../styles';

type InewUserFormReturn = {
  email?: string;
  token?: string
  password?: string;
  confirmPassword?: string;
};

export type TNewUserForm = {
  form: UseFormReturn<InewUserFormReturn>;
  noMatchPass?: boolean;
  setInputEmail: (e: string) => void;
  inputEmail: string;
  setInputs: (e: any) => void;
};

interface IState {
  showPassword: boolean;
  showPasswordConfirm: boolean;
}

export const NewUserForm = ({
  form,
  noMatchPass,
  inputEmail,
  setInputEmail,
  setInputs
}:
  TNewUserForm) => {
  const [passwordValue, setPasswordValue] = useState<string>('');

  const [values, setValues] = useState<IState>({
    showPassword: false,
    showPasswordConfirm: false
  });

  const style = {
    borderRadius: '9px',
    marginTop: '20px'
  };

  useEffect(() => {
    if (inputEmail) {
      setInputEmail('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };

  const handleClickShowPasswordConfirm = () => {
    setValues({
      ...values,
      showPasswordConfirm: !values.showPasswordConfirm
    });
  };

  const handleField = (type: any, e: any) => {
    form.setValue(type, e.target.value);

    setInputs((prevState: any) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  useEffect(() => {
    // form.reset(defaultValues);
    const { password } = form.getValues();
    setPasswordValue(password || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const TextPassword = ({ color }: any) => {
    return (
      <Box
        textAlign="left"
        color={color}
        lineHeight="24px"
        marginLeft="95px"
        marginTop="17px"
      >
        <Typography fontSize="1rem" color={colors.supportDark2}>
          New password must contain at Least:
        </Typography>
        <Typography textAlign="left" fontSize="1rem">
          . 8 characters
          <br />. 1 capital leter
          <br />. 1 special character
          <br />. 1 number
        </Typography>
      </Box>
    );
  };
  return (
    <>
      <Grid item xs={6} md={12} marginBottom="42px">
        <Controller
          control={form.control}
          name="token"
          render={({ field }) => (
            <FormControl variant="standard" fullWidth>
              <InputLabel
                error={!!form.formState.errors.token}
                shrink
                htmlFor="token"
                required
              >
                Please input received Token
              </InputLabel>
              <OutlinedInput
                style={style}
                error={!!form.formState.errors.token}
                placeholder="token..."
                {...field}
                type="string"
                onChange={e => handleField('token', e)}
              />
              {!!form.formState.errors.token && (
                <FormHelperText error>
                  {form?.formState?.errors?.token?.message}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={6} md={12} marginBottom="15px">
        <Controller
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormControl variant="standard" fullWidth>
              <InputLabel
                // error={!!form.formState.errors.password}
                shrink
                htmlFor="password"
                required
              >
                Create new password
              </InputLabel>
              <OutlinedInput
                // error={!!form.formState.errors.password}
                style={style}
                id="outlined-adornment-password"
                type={values.showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end" sx={{ marginRight: '25px' }}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {values.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="password..."
                {...field}
                onChange={e => handleField('password', e)}
              />
              {!!form.formState.errors.password ? (
                <>
                  <Box
                    textAlign="left"
                    color={colors.supportDark2}
                    lineHeight="24px"
                    marginLeft="95px"
                    marginTop="17px"
                  >
                    <Typography fontSize="1rem">
                      New password must contain at Least:
                    </Typography>
                    <Typography textAlign="left" fontSize="1rem" color="red">
                      {form?.formState?.errors?.password?.message}
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  {!passwordValue && (
                    <TextPassword color={colors.supportDark2} />
                  )}
                  {passwordValue && <TextPassword color={colors.success} />}
                </>
              )}
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={6} md={12} marginBottom="84px">
        <Controller
          control={form.control}
          name="confirmPassword"
          render={({ field }) => (
            <FormControl variant="standard" fullWidth>
              <InputLabel
                error={noMatchPass}
                shrink
                htmlFor="confirmPassword"
                required
              >
                Confirm new password
              </InputLabel>
              <OutlinedInput
                {...field}
                style={style}
                error={noMatchPass}
                type={values.showPasswordConfirm ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end" sx={{ marginRight: '25px' }}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordConfirm}
                      edge="end"
                    >
                      {values.showPasswordConfirm ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="password..."
                onChange={e => handleField('confirmPassword', e)}
              />
              {noMatchPass && (
                <FormHelperText error>
                  {'The password dont match!'}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
      </Grid>
    </>
  )
}