import { Box, Grid, Typography } from '@mui/material';
import { colors } from '../../styles';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

interface BoxCounterProps {
	refreshDate?: string;
	title: string;
	value: string;
	unit: string;
}

export const BoxCounter = ({ refreshDate, title, value, unit }: BoxCounterProps) => {
    const [currentDate, setCurrentDate] = useState(dayjs());

	useEffect(()=>{
		const intervalId = setInterval(()=>{
			setCurrentDate(dayjs())
		},60000)

		return () => {
            clearInterval(intervalId);
          };
    },[])

	return (
		<Box
			sx={{
				width: '100%',
				maxWidth: '313px',
				height: 'auto', // A altura será determinada pelo conteúdo
                padding: '1em',
				borderRadius: '10px',
				boxShadow: '0px 5px 30px rgba(0, 0, 0, 0.05)',
				backgroundColor: '#FFFFFF',
				'@media screen and (max-width: 1440px)': {
					height: '100px',
				}
			}}
		>
			<Grid container md={12} sx={{ paddingTop: '10px' }}>
				<Grid item md={7.5}>
					<Typography fontSize="16px" lineHeight="24px" fontWeight="700" sx={{
										'@media screen and (max-width: 1440px)': {
											fontSize:"12px"
										}
					}}>
						{title}
					</Typography>
				</Grid>
				<Grid item md={4.5}>
					{refreshDate &&
						<Typography
							fontFamily="Sen"
							fontStyle="normal"
							fontWeight="700"
							fontSize="10px"
							lineHeight="24px"
							color="#6F7075"
							marginY="auto"
							marginLeft="auto"
						>
							{currentDate.diff(refreshDate,'minutes') < 60 ?
								`Refreshed ${currentDate.diff(refreshDate,'minutes')} min ago`
							:
								currentDate.diff(refreshDate,'hours') < 8 ?
									`Refreshed ${currentDate.diff(refreshDate,'hours')} hours ago`
								:
									`Refreshed on ${dayjs(refreshDate).format('MMM DD, HH:mm')}`
							}
						</Typography>
					}
				</Grid>
			</Grid>
			<Grid container md={12} sx={{ paddingTop: '10px' }}>
				<Grid item md={9}>
					<Typography
						fontSize="28px"
						lineHeight="40px"
						fontWeight="400"
						color="#3A4049"
					>
						{value}
					</Typography>
				</Grid>
				<Grid item md={3} sx={{ paddingLeft: '20px' }}>
					<Typography
						fontSize="16px"
						lineHeight="40px"
						fontWeight="400"
						color={colors.primaryDark}
					>
						{unit}
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
};
