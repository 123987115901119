import { FC, useEffect, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';

import {
	Box,
	Paper,
	PaperProps,
	TableContainer,
	Typography,
	Table as TableM,
	TableSortLabel,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TextField,
	CircularProgress
} from '@mui/material';

import { visuallyHidden } from '@mui/utils';

import filterIcon from '../../../assets/icon/filterTableHeader.png';

import { getTotalAvailableRec } from '../../../stores/totalAvailableRec/totalAvailableRec.actions';
import { transferRec } from '../../../stores/transferRec/transferRec.actions';
import { colors } from '../../../styles';
import { ITransactionCertificate } from '../../../types/buyerTransaction';
import { HeadCell } from '../../../types/headCell';
import { customScroll } from '../../../view/commonStyles';
import { TextTableBody } from './textTableBody';
import { ICertificateOrg } from '../../../types/certificateOrg';

interface ITable extends PaperProps {
	title: string;
	titles: HeadCell[];
	transactions: ICertificateOrg[] | null;
	isLoaded: boolean;
	setValueAmount: (value: any) => void;
	setValidateButton: (value: boolean) => void;
	setValidateEmptyField: (value: boolean) => void;
	setDataTableSelected: (value: any) => void;
}
let newObject: any[] = [];
type Order = 'asc' | 'desc';

interface ISortInput {
	[key: string]: number | string;
}
const initSortInput: ISortInput = {
	date: '',
	sellerOrg: '',
	seller: '',
	buyer: '',
	opType: '',
	status: '',
	sourceType: '',
	orgName: '',
	unitID: 0,
	location: '',
	period: '',
	uniqueId: '',
	amount: 0
};

export const Table: FC<ITable> = ({
	sx,
	title,
	titles,
	transactions,
	isLoaded,
	setValueAmount,
	setDataTableSelected,
	setValidateButton,
	setValidateEmptyField,
	...props
}) => {
	const dispath = useDispatch();
	const [order, setOrder] = useState<Order>('desc');
	const [orderBy, setOrderBy] = useState<string>('');
	const [validateAmount, setValidateAmount] = useState<boolean>(false);
	const [errors, setErrors] = useState<any>([false]);
	const [values, setValues] = useState<any>([]);

	const [sortedData, setSortedData] = useState<ICertificateOrg[] | undefined | null>(transactions);

	const firstDivRef = useRef(null);
	const secondDivRef = useRef(null);

	useEffect(() => {
		setSortedData(transactions);
		setDataTableSelected(sortedData);
	}, [transactions]);

	const handleScrollFirst = (scroll: any) => {
		const current: any = secondDivRef.current;
		if (current) {
			current.scrollLeft = scroll.target.scrollLeft;
		}
	};

	const handleScrollSecond = (scroll: any) => {
		const current: any = firstDivRef.current;
		if (current) {
			current.scrollLeft = scroll.target.scrollLeft;
		}
	};

	useEffect(() => {
		if (newObject.length > 0) {
			newObject = [];
		}
	}, []);

	function descendingComparator<T>(a: T, b: T, orderByTile: keyof T) {
		if (
			orderByTile === 'sellerOrg' ||
			orderByTile === 'seller' ||
			orderByTile === 'buyer' ||
			orderByTile === 'opType' ||
			orderByTile === 'status' ||
			orderByTile === 'sourceType' ||
			orderByTile === 'orgName' ||
			orderByTile === 'location'
		) {
			const first: any = a[orderByTile];
			const second: any = b[orderByTile];
			if (
				!!first.replace(/\d+/, '') &&
				!!second.replace(/\d+/, '') &&
				first.replace(/\d+/, '') === second.replace(/\d+/, '') &&
				(first.replace(/\D+/, '') || second.replace(/\D+/, ''))
			) {
				return +second.replace(/\D+/, '') < +first.replace(/\D+/, '')
					? -1
					: +second.replace(/\D+/, '') > +first.replace(/\D+/, '')
					? 1
					: 0;
			}
		}
		if (orderByTile === 'period') {
			const first: any = `01/${a[orderByTile]}`;
			const second: any = `01/${b[orderByTile]}`;
			return new Date(second).valueOf() - new Date(first).valueOf();
		}
		if (b[orderByTile] < a[orderByTile]) {
			return -1;
		}
		if (b[orderByTile] > a[orderByTile]) {
			return 1;
		}

		return 0;
	}

	function getComparator<Key extends keyof any>(
		orderComparator: Order,
		orderByComparator: Key
	): (
		a: { [key in Key]: number | string | any },
		b: { [key in Key]: number | string | any }
	) => number {
		return orderComparator === 'desc'
			? (a, b) => descendingComparator(a, b, orderByComparator)
			: (a, b) => -descendingComparator(a, b, orderByComparator);
	}

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: string
	) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const createSortHandler =
		(property: string) => (event: React.MouseEvent<unknown>) => {
			handleRequestSort(event, property);
		};

	const getTotalValueAmount = (newValues: Array<any>) => {
		const resTotal = newValues.reduce(
			(acc: any, cur: any) =>
				acc + Number(cur?.amountRec ? cur.amountRec : cur),
			0
		);
		setValueAmount(resTotal);
	};

	useEffect(() => {
		const sizeTransaction = transactions?.length;
		if (sizeTransaction !== undefined && transactions !== null) {
			getTotalValueAmount(transactions);
			for (let i = 0; i < sizeTransaction; i++) {
				setValues((prevState: any) => ({
					...prevState,
					[i]: transactions[0].amountRec ? transactions[i].amountRec : '0'
				}));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const amountRecInput = (
		index: number,
		event: any,
		amountTotal: number,
		idRec: string
	) => {
		const newArrayValues = Object.values(values);
		const { value } = event.target;
		const newValues = [...newArrayValues];
		const newErrors = [...errors];

		// fazer o push do valor digitado na posicao correspondente do array
		if (value && sortedData?.length) {
			sortedData[index].amountRec = Number(value);
			setDataTableSelected(sortedData);
		}

		newValues[index] = value;
		const result = newValues.some((el: any) => el === '0' || el === '');
		setValidateEmptyField(result);

		setValues(newValues);

		if (Number(value) > Number(amountTotal)) {
			newErrors[index] = true;
			setErrors(newErrors);
			setValidateButton(true);
		} else {
			newErrors[index] = false;
			setErrors(newErrors);
			setValidateButton(false);
		}

		getTotalValueAmount(newValues);

		// const resTotal = newValues.reduce(
		// 	(acc: any, cur: any) => acc + Number(cur),
		// 	0
		// );
		// setValueAmount(resTotal);
	};

	return (
		<>
			<Paper {...props} sx={{ boxShadow: 'none', ...sx }}>
				<TableContainer
					sx={{
						overflow: !!sortedData?.length ? 'hidden' : 'auto',
						...customScroll
					}}
					onScroll={handleScrollFirst}
					ref={firstDivRef}
				>
					<Typography
						sx={{
							padding: '16px 0 0 16px',
							fontSize: '1.5rem',
							fontWeight: '700',
							fontFamily: 'Sen',
							letterSpacing: '.4px',
							color: colors.primary,
							marginBottom: '25px'
						}}
					>
						{title}
					</Typography>
					<TableM>
						<TableHead>
							<TableRow
								sx={{
									position: 'relative'
								}}
							>
								{titles &&
									// eslint-disable-next-line @typescript-eslint/no-shadow
									titles.slice().map((title, index) => (
										<TableCell
											align={title.numeric ? 'left' : 'center'}
											key={title.id}
											padding={title.disablePadding ? 'none' : 'normal'}
											sortDirection={orderBy === title.id ? order : false}
											sx={{
												paddingRight:
													(titles.length - 1 === index && '40px') || '18px',
												borderTopLeftRadius: index === 0 ? '16px' : 0
											}}
										>
											<TableSortLabel
												sx={{ width: '100%' }}
												hideSortIcon
												direction={orderBy === title.id ? order : 'asc'}
												onClick={createSortHandler(title.id)}
											>
												<Box
													display="flex"
													justifyContent="space-between"
													alignItems="center"
													width="100%"
												>
													<Typography
														sx={{
															width: '100%',
															whiteSpace: 'nowrap',
															fontSize: '12px',
															fontWeight: '700',
															color: '#9FA2B4'
														}}
													>
														{title.label}
													</Typography>
													<img src={filterIcon}></img>
												</Box>
												{orderBy === title.id ? (
													<Box component="span" sx={visuallyHidden}>
														{order === 'desc'
															? 'sorted descending'
															: 'sorted ascending'}
													</Box>
												) : null}
											</TableSortLabel>
										</TableCell>
									))}
							</TableRow>
						</TableHead>
					</TableM>
				</TableContainer>

				<TableContainer
					onScroll={handleScrollSecond}
					ref={secondDivRef}
					sx={{
						overflowX: 'hidden',
						height: 'calc(100vh - 590px)',
						minHeight: '300px',
						...customScroll
					}}
				>
					<TableM>
						<TableBody>
							{sortedData &&
								sortedData
									.slice()
									.sort(getComparator(order, orderBy))
									.map((transaction, index) => (
										<TableRow key={transaction.id}>
											{/* {transaction.originCertificates.map((cert, index) =>(
												<TableRow key={transaction.id}>
													<TableCell>
														<TextTableBody>{cert.asset_name}</TextTableBody>
													</TableCell>
													<TableCell sx={{ padding: '5px' }}>
														<TextTableBody>{transaction.period}</TextTableBody>
													</TableCell>
													<TableCell sx={{ paddingLeft: '0px' }}>
														<NumericFormat
															value={values[index]}
															onChange={e =>
																amountRecInput(
																	index,
																	e,
																	transaction.amount,
																	transaction.id
																)
															}
															style={{
																borderColor: errors[index] ? 'red' : '',
																maxWidth: '80px',
																padding: '5px 10px',
																height: '25px',
																boxShadow: 'none',
																borderRadius: '5px',
																border: '1px solid #CAD5E8'
															}}
														/>
													</TableCell>
													<TableCell>
														<TextTableBody sx={{ paddingLeft: '8px' }}>
															{transaction.amount}
														</TextTableBody>
													</TableCell>
												</TableRow>
											))} */}
											<TableCell>
												<TextTableBody>{transaction.assetName}</TextTableBody>
											</TableCell>
											<TableCell sx={{ padding: '5px' }}>
												<TextTableBody>{transaction.period}</TextTableBody>
											</TableCell>
											<TableCell sx={{ paddingLeft: '0px' }}>
												<NumericFormat
													value={values[index]}
													onChange={event =>
														amountRecInput(
															index,
															event,
															transaction.amount,
															transaction.id
														)
													}
													style={{
														borderColor: errors[index] ? 'red' : '',
														maxWidth: '80px',
														padding: '5px 10px',
														height: '25px',
														boxShadow: 'none',
														borderRadius: '5px',
														border: '1px solid #CAD5E8'
													}}
												/>
											</TableCell>
											<TableCell>
												<TextTableBody sx={{ paddingLeft: '8px' }}>
													{transaction.amount}
												</TextTableBody>
											</TableCell>
										</TableRow>
									))}
						</TableBody>
					</TableM>
					{!isLoaded && (
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '100%'
								// paddingTop: '30px'
							}}
						>
							<CircularProgress />
						</Box>
					)}
					{sortedData && sortedData.length < 1 && isLoaded && (
						<Typography
							sx={{
								display: 'flex',
								paddingTop: '20px',
								width: '100%',
								justifyContent: 'center',
								alignItems: 'center',
								fontSize: '1.75rem',
								fontWeight: '700',
								color: colors.primary
							}}
						>
							Empty table!
						</Typography>
					)}
				</TableContainer>
			</Paper>
		</>
	);
};
