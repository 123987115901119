import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import { Box, Grid, Typography } from '@mui/material';

import recover from '../../../assets/imgs/dowload.png';
import profileFace from '../../../assets/imgs/pfofileexemplo.jpeg';
import { recoveryWallet } from '../../../stores/recoverWallet/recoverWallet.actions';

/* eslint-disable prettier/prettier */

export type TListWallet = {
  dataWalletList: Array<object>;
}

export const ListWallet = ({ dataWalletList }: any) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  
  const goToRecoveryWallet = () => {
    dispatch(recoveryWallet(true));
    history('/profile/wallet/recovery');
  }

  function stringToColor(string: string) {
		let hash = 0;
		let i;

		/* eslint-disable no-bitwise */
		for (i = 0; i < string.length; i += 1) {
			hash = string.charCodeAt(i) + ((hash << 5) - hash);
		}

		let color = '#';

		for (i = 0; i < 3; i += 1) {
			const value = (hash >> (i * 8)) & 0xff;
			color += `00${value.toString(16)}`.slice(-2);
		}
		/* eslint-enable no-bitwise */

		return color;
	}

  function stringAvatar(name: string) {
		return {
			sx: {
				bgcolor: stringToColor(name)
			},
			children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
		};
	}
  return (
    <>
      <Grid container md={12}>
        <Box
          sx={{
            overflow: 'auto',
            width: '100%',
            height: '453px',
            '::-webkit-scrollbar': {
              border: '1px solid #D4D7DE',
              width: '15px',
              borderRadius: '15px'
            },
            '::-webkit-scrollbar-thumb': {
              backgroundColor: '#D9D9D9',
              borderRadius: '15px',
              height: '170px',
              width: '15px'
            }
          }}
        >
          {dataWalletList.wallet_name &&
            (
              <Grid
                item
                md={10}
                display="flex"
                alignItems="center"
                marginBottom="16px"
              >
                <>
                  <Box
                    sx={{
                      marginRight: '9px',
                      borderRadius: '55px',
                      width: '51px',
                      height: '51px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#fff',
                    }}>
                      {dataWalletList.logo ? 
                        <>
                          {/* <img
                            src={dataWalletList.logo}
                            style={{
                              borderRadius: '43px',
                              position: 'absolute',
                              top: '1px',
                              left: '1px',
                              right: 0,
                              cursor: 'pointer'
                            }}
                            width="43px"
                            alt="profile"
                          ></img> */}
                          <Avatar
                          alt="User Avatar"
                          style={{
                            borderRadius: '53px',
                            width: '53px',
                            height: '53px'
                          }}
                          src={dataWalletList.logo}
                          />
                        </>
                        :
                        <Avatar
                        style={{
                          borderRadius: '53px',
                          width: '53px',
                          height: '53px'
                        }}
                      {...stringAvatar(dataWalletList.wallet_name ? dataWalletList.first_name + ' ' + dataWalletList.last_name : 'User Name')}/>  
                      }
                    {/* <Avatar
                      style={{
                        borderRadius: '53px',
                        width: '53px',
                        height: '53px'
                      }}
                    {...stringAvatar(dataWalletList.wallet_name ? dataWalletList.first_name + ' ' + dataWalletList.last_name : 'User Name')}/> */}
                  </Box>
                  <Box sx={{ position: 'relative', flexGrow: '2' }} width="90%">
                    <Box
                      sx={{
                        position: 'relative',
                        zIndex: '10',
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: '30px',
                        height: '48px',
                        backgroundColor: '#d9d9d9',
                        borderRadius: '100px'
                      }}
                    >
                      <Typography>{`${dataWalletList.wallet_name}`}</Typography>
                    </Box>
                    <Box
                      onClick={goToRecoveryWallet}
                      sx={{
                        cursor: 'pointer',
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        paddingRight: '20px',
                        zIndex: '1',
                        top: '0',
                        right: '-50px',
                        width: '96px',
                        height: '48px',
                        backgroundColor: '#F32053',
                        borderRadius: '100px'
                      }}
                    >
                      <img src={recover}></img>
                    </Box>
                  </Box>
                </>
              </Grid>
            )}
        </Box>
      </Grid>
    </>
  )
}
