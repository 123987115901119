import { Api } from './api/axios-config';
import { GenericExtrinsic } from '@polkadot/types';
import { AnyTuple } from '@polkadot/types-codec/types';

export type ITransactionsIssue = {
	enterprise_id: string;
	asset_id: string;
	rec_id: string;
	operation_type: string;
	type_of_period: string;
	start_date: Date;
	end_date: Date;
	from: string;
	to: string;
	amount: number;
	cert_hash: string;
	rec_hash: string;
	holder_wallet: string;
	extrinsic?: GenericExtrinsic<AnyTuple>;
};

export type IOriginCertificate = {
	origin_id: string;
	amount: number;
};

export type ITransactionsRetiremnt = {
	enterprise_id: string;
	asset_id: string;
	rec_id: string;
	operation_type: string;
	client_id: string;
	start_date: Date;
	end_date: Date;
	origins: IOriginCertificate[];
	rec_hash: string;
	holder_wallet: string;
	extrinsic?: GenericExtrinsic<AnyTuple>;
};

interface IdataApi {
	status: number;
	statusText: string;
	data: Array<any>;
}

export class TransactionService {
	async createIssue(issueData: ITransactionsIssue) {
		try {
			const respIssues: IdataApi = await Api.post(
				`/transactions/issues`,
				issueData
			);
			return respIssues;
		} catch (error: any) {
			console.log(error)
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async createRetirement(retirementData: ITransactionsRetiremnt) {
		try {
			const respIssues: IdataApi = await Api.post(
				`/transactions/retirements`,
				retirementData
			);
			return respIssues;
		} catch (error: any) {
			console.log(error)
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async getTransactionsByAsset(id: string, selectedPage: number, selectedSize: number) {
		try {
			const respIssues: any = await Api.get(`/transactions/assets/${id}?page=${selectedPage}&size=${selectedSize}`);
			return respIssues.data;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async getTransactionsBySeller(id: string) {
		try {
			const transactions: any = await Api.get(`/transactions/sellers/${id}`);
			return transactions.data;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async getMyTransactions(page:number, size:number) {
		try {
			const transactions: any = await Api.get(`/transactions/my?page=${page}&size=${size}`);
			return transactions.data;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}

	async getSellersTransactions(page:number, size:number) {
		try {
			const transactions: any = await Api.get(`/transactions/sellers?page=${page}&size=${size}`);
			return transactions.data;
		} catch (error: any) {
			if(error.response.data && error.response.status){
				const { message } = error.response.data;
				return { status: error.response.status, statusText: message };
			}else{
				return { status: 400, statusText: 'Error, try again' };
			}
		}
	}
}
